<template>
  <tr class="background-primary-alpha-1">
    <td class="no-border-bottom hide-mobile">
      <span class="spacer">&nbsp;</span>
    </td>

    <td class="no-border-bottom hide-mobile">
      <span class="spacer">&nbsp;</span>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-200">
      {{ formatUnixTimestamp(timespan.lower) }}
    </td>

    <td class="no-border-bottom timetracker-td">
      <input class="no-margin input input-blend input-block" :value="comment"
             @change="changeComment" v-if="!$impersonatingUserHasROAccess.value">
      <span v-else>{{ comment }}</span>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-300 text-align-right color-neutral-darker">
      <div class="font-weight-700">
        <input type="time"
               class="no-margin input input-blend padding-small-x border-color-neutral-alpha-5 border-style-solid border-width-thin border-color-neutral-focus radius-small"
               :value="formatUnixTimestamp(timespan.lower, { format: 'time' })"
               @change="debouncedChangeTimespanStartTime($event)" ref="start_time"
               v-if="userCanAdjustTimelogStartTime">
        <span v-else>{{ formatUnixTimestamp(timespan.lower, { format: 'time' }) }}</span>
        -
        <input type="time"
               class="no-margin input input-blend padding-small-x border-color-neutral-alpha-5 border-style-solid border-width-thin border-color-neutral-focus radius-small"
               :value="formatUnixTimestamp(timespan.upper, { format: 'time' })"
               @change="debouncedChangeTimespanEndTime($event)" ref="end_time">
      </div>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-100">
      <h5 class="hide-nonmobile no-margin-top">Total time:</h5>
      <input name="duration" :value="hhMM" pattern="\d{2}:\d{2}"
             size="5" maxlength="5" placeholder="00:00"
             class="no-margin input input-blend padding-micro-x border-color-neutral-alpha-5 border-style-solid border-width-thin border-color-neutral-focus radius-small text-align-center"
             @change="debouncedChangeTimespanDuration($event)" ref="hhMM"
             v-if="userCanAdjustTimelogDuration">
      <span v-else>{{ hours }}:{{ minutes }}</span>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-100 timetracker-td-actions text-align-right">
      <span class="position-relative" v-if="!$impersonatingUserHasROAccess.value">
        <a :href="`#timelog-${id}-options`" class="btn btn-link no-padding font-size-large"
           title="More options" data-modal-change-hash="false" data-modal-disable-overlay="false"
           data-toggle-modal-default>

          <i class="symbol symbol-kebab-horizontal"></i>
          <span class="sr-only">More options</span>
        </a>

        <div class="modal modal-default"  :id="`timelog-${id}-options`" ref="timelogOpts"
             data-modal-width="400px">

            <div class="text-align-center">
              <h1 class="no-margin-top color-inherit">Options</h1>
              <ul class="list-group list-group-compact">
                <li><a href="#" @click.prevent="confirmDeletion">Delete timelog</a></li>
              </ul>
            </div>
        </div>
      </span>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import { TZDateMini } from '@date-fns/tz';
import * as _ from 'lodash-es'

import {
  formatDate,
  formatTime,
  hhMMToSecs,
  parseUnixTimestamp,
  timeStrToHrsAndMins,
  secondsToTimeStr,
  utcOffset
} from '../lib/date'
import { stringifyValidationErrors } from '../lib/string'
import Modal from '../imports/modal'

export default {
  name: 'TimelogRow',

  props: {
    id: {
      type: Number,
      required: true
    },
    comment: {
      type: String,
      required: true
    },
    timespan: {
      type: Object,
      required: true
    },
    synced_to_wrike: {
      type: Boolean,
      required: true
    },
  },

  data() {
    const secsDiff = this.timespan.upper - this.timespan.lower,
          hours = String(Math.floor(secsDiff / 3_600)).padStart(2, '0'),
          minutes = String(Math.floor((secsDiff % 3_600) / 60)).padStart(2, '0')

    return {
      _hours: hours,
      hoursWas: hours,
      _minutes: minutes,
      minutesWas: minutes,
    }
  },

  created() {
    this.debouncedChangeTimespanStartTime = _.debounce(this.changeTimespanStartTime,
                                                      this.$page.props.app_config.autocomplete_debounce_min)

    this.debouncedChangeTimespanEndTime = _.debounce(this.changeTimespanEndTime,
                                                     this.$page.props.app_config.autocomplete_debounce_min)

    this.debouncedChangeTimespanDuration = _.debounce(this.changeTimespanDuration,
                                                      this.$page.props.app_config.autocomplete_debounce_min)
  },

  beforeUnmount() {
    this.debouncedChangeTimespanDuration.cancel()
    this.debouncedChangeTimespanStartTime.cancel()
    this.debouncedChangeTimespanDuration.cancel()
  },

  computed: {
    userCanAdjustTimelogStartTime() {
      return this.$page.props.app_settings.users_can_adjust_timelog_start_time && !this.$impersonatingUserHasROAccess.value
    },

    userCanAdjustTimelogDuration() {
      return this.$page.props.app_settings.users_can_adjust_timelog_duration && !this.$impersonatingUserHasROAccess.value
    },

    totalSeconds() {
      return this.timespan.upper - this.timespan.lower
    },

    seconds() {
      return Math.floor((this.totalSeconds % 3_600) % 60)
    },

    hhMM() {
      const hours = String(Math.floor(this.totalSeconds / 3_600)).padStart(2, '0'),
            minutes = String(Math.floor((this.totalSeconds % 3_600) / 60)).padStart(2, '0')

      return `${hours}:${minutes}`
    },

    maxNumSecs() {
      return this.$page.props.app_settings.max_num_hours_worked_per_timelog * 3_600
    },
  },

  methods: {
    resetDuration() {
      this.$refs.hhMM.value = this.hhMM
    },

    formatUnixTimestamp(unixTime, { format = 'date' } = {}) {
      switch(format) {
        case 'time':
          return formatTime(parseUnixTimestamp(unixTime), { format: 'EU', timeZone: this.$timeZone })
        default:
          return formatDate(parseUnixTimestamp(unixTime), {
            format: 'short',
            timeZone: this.$timeZone
          })
      }
    },

    async changeComment(event) {
      const newComment = event.target.value
      await axios.patch(this.$route('timelogs.update', { id: this.id }), {
        comment: newComment,
      })

      this.$emit('timelog:changed', { timelogId: this.id, changes: { comment: newComment } })
    },

    async changeTimespanStartTime(event) {
      const startTime = parseUnixTimestamp(this.timespan.lower, this.$timeZone),
        lowerBound = event.target.value
      startTime.setHours(...lowerBound.split(':').map(Number))
      const upperBound = parseUnixTimestamp(this.timespan.upper, this.$timeZone)
      const resetStartTime = () => {
        event.target.value = this.formatUnixTimestamp(this.timespan.lower, { format: 'time' })
      }

      if (startTime >= upperBound) {
        alert(`Timelog start time must be set earlier than ${formatTime(upperBound)}`)
        this.$nextTick(resetStartTime)
        return
      }

      let url = this.$route('timelogs.update', { id: this.id })
      const params = new URLSearchParams(location.search), userId = params.get('user_id')
      if (userId) url += `?user_id=${userId}`
      let resp
      try {
        resp = await axios.patch(url, {
          start_time: lowerBound,
          utc_offset: utcOffset(),
        })
      } catch(err) {
        alert(`Unable to change timelog start time due to:\n${stringifyValidationErrors(err)}`)
        this.$nextTick(resetStartTime)
        return
      }

      const { timelog } = resp.data
      const time = secondsToTimeStr(timelog.timespan.upper - timelog.timespan.lower),
            [hrs, mins] = timeStrToHrsAndMins(time)
      this.hours = String(hrs).padStart(2, '0')
      this.minutes = String(mins).padStart(2, '0')

      this.$emit('timelog:changed', { timelogId: this.id, changes: { timespan: timelog.timespan } })
    },

    async changeTimespanEndTime(event) {
      const resetEndTime = () => {
        event.target.value = this.formatUnixTimestamp(this.timespan.upper, { format: 'time' })
      }

      let warn = ''
      const upperBound = event.target.value
      const endTime = parseUnixTimestamp(this.timespan.upper, this.$timeZone)
      endTime.setHours(...upperBound.split(':').map(Number))
      const now = new TZDateMini(Date.now(), this.$timeZone.value)
      if(endTime > now) {
        warn = 'End time cannot be in the future'
      } else {
        const lowerBound = parseUnixTimestamp(this.timespan.lower, this.$timeZone)
        if (formatTime(lowerBound, { format: 'EU' }) >= upperBound) {
          warn = `Timelog end time must be set later than ${formatTime(lowerBound)}`
        }
      }

      if (warn) {
        alert(warn)
        this.$nextTick(resetEndTime)
        return
      }

      let url = this.$route('timelogs.update', { id: this.id })
      const params = new URLSearchParams(location.search), userId = params.get('user_id')
      if (userId) url += `?user_id=${userId}`
      let resp
      try {
        resp = await axios.patch(url, {
          end_time: upperBound,
          utc_offset: utcOffset(),
        })
      } catch(err) {
        alert(`Unable to change timelog end time due to:\n${stringifyValidationErrors(err)}`)
        this.$nextTick(resetEndTime)
        return
      }

      const { timelog } = resp.data
      const time = secondsToTimeStr(timelog.timespan.upper - timelog.timespan.lower),
            [hrs, mins] = timeStrToHrsAndMins(time)
      this.hours = String(hrs).padStart(2, '0')
      this.minutes = String(mins).padStart(2, '0')

      this.$emit('timelog:changed', { timelogId: this.id, changes: { timespan: timelog.timespan } })
    },

    async changeTimespanDuration(event) {
      if (!event.target.checkValidity()) {
        this.resetDuration()
        return
      }

      const [hours, minutes] = event.target.value.split(':', 2).map(Number),
        numSecs = hours * 3_600 + minutes * 60 + this.seconds
      if (numSecs > this.maxNumSecs) {
        alert(`Timelog duration cannot exceed: ${secondsToTimeStr(this.maxNumSecs)}`)
        this.$nextTick(this.resetDuration)
        return
      }

      const upper = this.timespan.lower,
        lower = upper - hours * 3_600 - minutes * 60,
        newTimespan = { lower, upper }
      if (newTimespan.upper - newTimespan.lower === this.timespan.upper - this.timespan.lower) {
        return
      }

      const hrs = String(hours).padStart(2, '0'),
        mins = String(minutes).padStart(2, '0'),
        secs = String(this.seconds).padStart(2, '0')

      let url = this.$route('timelogs.update', { id: this.id })
      const params = new URLSearchParams(location.search), userId = params.get('user_id')
      if (userId) url += `?user_id=${userId}`
      let resp
      try {
        resp = await axios.patch(url, {
          time: `${hrs}:${mins}:${secs}`,
          utc_offset: utcOffset(),
        })
      } catch(err) {
        alert(`Unable to change timelog duration due to:\n${stringifyValidationErrors(err)}`)
        this.$nextTick(this.resetDuration)
        return
      }

      const { timelog } = resp.data
      this.$refs.hhMM.value = secondsToTimeStr(timelog.timespan.upper - timelog.timespan.upper)
      this.$emit('timelog:changed', { timelogId: this.id, changes: { timespan: timelog.timespan } })
    },

    async confirmDeletion() {
      if (!confirm('Are you sure you want to delete this timelog?')) return

      try {
        await axios.delete(this.$route('timelogs.destroy', { id: this.id }), {
          data: {
            delete_on_wrike: this.synced_to_wrike,
          },
        })
      } catch(err) {
        alert(`Unable to delete timelog due to:\n${stringifyValidationErrors(err)}`)
        return
      }

      new Modal(this.$refs.timelogOpts).destroy()
      this.$emit('timelog:deleted', { timelogId: this.id })
    },
  },

  emits: ['timelog:changed', 'timelog:deleted'],
}
</script>

<template>
  <AnonLayout>
    <div class="module-content padding-large-y">
      <form @submit.prevent="form.post($route('password_resets.create'))">
        <div class="input-wrapper input-wrapper-block input-wrapper-vertical">
          <label for="email" class="input-label font-weight-700 padding-small-bottom">
            Please enter your email
          </label>
          <span class="input-label no-padding-top padding-large-bottom">
            We will email you a link to reset your password.
          </span>

          <input type="email" id="email" class="input input-single-line" autocomplete="username"
                 v-model="form.email" ref="email" required>
        </div>

        <div class="margin-large-top">
          <button class="btn btn-theme btn-block" :disabled="form.processing">Send</button>
        </div>
      </form>
    </div>
  </AnonLayout>
</template>

<script>
import { useForm } from '@inertiajs/vue3'

import AnonLayout from '../AnonLayout'

export default {
  name: 'PasswordResetRequest',

  setup(props) {
    const form = useForm({
      email: '',
    })

    return {
      form,
    }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.email.focus()
  },*/

  components: {
    AnonLayout,
  },
}
</script>

<template>
  <div class="h4 margin-bottom">Time Distribution</div>

  <div class="module p">
    <div class="module-content no-padding">
      <div class="table-wrapper" v-if="timeDist.length">
        <table class="timetracker-table">
          <thead>
            <tr class="background-transparent">
              <th class="background-transparent no-border-top">Task Category</th>
              <th class="background-transparent no-border-top text-align-center">Hours</th>
              <th class="background-transparent no-border-top text-align-center">Hours Rank</th>
              <th class="background-transparent no-border-top text-align-center">% Time</th>
              <th class="background-transparent no-border-top text-align-center">% Time Rank</th>
              <th class="background-transparent no-border-top">Time Distribution</th>
              <th class="background-transparent no-border-top text-align-right">Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row in timeDist" :key="row.task_category.id">
              <td class="timetracker-td timetracker-td-w timetracker-td-w-250">
                <span class="font-weight-600">
                  {{ row.task_category.name }}
                </span>
              </td>

              <td class="text-align-center timetracker-td">
                <h6 class="hide-nonmobile no-margin-top">Hours</h6>
                {{ secsToTimeStr(row.hours) }}
              </td>

              <td class="text-align-center timetracker-td">
                <h6 class="hide-nonmobile no-margin-top">Hours Rank</h6>
                {{ formatRank(row.hours_rank) }}
              </td>

              <td class="text-align-center timetracker-td">
                <h6 class="hide-nonmobile no-margin-top">% Time</h6>
                {{ formatPct(row.pct_time) }}
              </td>

              <td class="text-align-center timetracker-td">
                <h6 class="hide-nonmobile no-margin-top">% Time Rank</h6>
                {{ formatRank(row.pct_time_rank) }}
              </td>

              <td class=" timetracker-td">
                <h6 class="hide-nonmobile no-margin-top">Time Distribution</h6>

                <span :data-td-val="formatNum(row.pct_time)"
                      :data-td-totalMin="row.pct_time_min" :data-td-totalMax="row.pct_time_max"
                      ref="timeDistChartContainers" v-if="row.pct_time">
                </span>
              </td>

              <td class="timetracker-td-actions text-align-right">
                <Link :href="$linkTo('main.time', { qryStrParams: { user_id: user.id, task_category: row.task_category.id, date_from: dateFrom, date_to: dateTo } })"
                      class="btn btn-link btn-symbol" title="View">

                  <i class="symbol symbol-visible"></i>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="font-style-italic color-neutral padding" v-else>
        No time distribution information matches the specified date range.
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import Tooltip from '../imports/tooltip'
import timeDistChart from '../lib/d3insightChart'
import { secondsToTimeStr  } from '../lib/date'
import { formatNumber, formatPercent } from '../lib/number'

export default {
  name: 'TaskCatTimeDist',

  props: {
    timeDist: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
  },

  watch: {
    timeDist() {
      if (!this.$refs.timeDistChartContainers) return

      this.$refs.timeDistChartContainers.forEach(container => {
        new timeDistChart(container).render()
      })

      this.$nextTick(() => {
        document.querySelectorAll('circle[data-tooltip-content]').forEach(el => {
          new Tooltip(el)
        })
      })
    },
  },

  methods: {
    formatNum(number) {
      return formatNumber(number)
    },

    formatPct(number) {
      return formatPercent(number)
    },

    formatRank([rank, total]) {
      return `${rank} of ${total}`
    },

    secsToTimeStr(seconds) {
      return secondsToTimeStr(seconds)
    },
  },

  components: {
    Link,
  },
}
</script>

<template>
  <tr>
    <td class="no-border-bottom timetracker-td">
        <Link :href="$route('admin.groups.edit', { id })"
              class="h4 color-inherit font-weight-400 no-margin-y display-inline-block">

          {{ name }}
        </Link>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">
        Date created
      </span>
      <i class="symbol symbol-file"></i>
      {{ createdOn }}
    </td>
  </tr>

</template>

<script>
import { Link } from '@inertiajs/vue3'
import { formatDate, parseISODate } from '../../lib/date'

export default {
  name: 'GroupRow',

  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    created: {
      type: String,
      required: true,
    },
  },

  computed: {
    createdOn() {
      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },
  },

  components: {
    Link,
  },
}
</script>

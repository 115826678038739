<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-col-xs-12 flex-col-md-5 flex-col-lg-6">
        <MyAnalyticsNavTabs />
      </div>

      <div class="flex-col-xs-12 flex-col-md-7 flex-col-lg-6 text-align-right">
        <a href="#" @click.prevent="exportOneOnOneCmpToCsvPath" class="btn btn-theme btn-block-mobile"
           :class="{ disabled: !user2Id }">

          Export
        </a>
      </div>
    </div>

    <div class="module module-expanded">
      <div class="module-header module-header-break">
        <h2 class="no-margin">How do you compare?</h2>
        <div class="module-functions">
          <select class="module-function input input-select margin-right" v-model="_cmp">
            <option value="task_category">Task categories</option>
            <option value="project">Projects</option>
            <option value="client">Clients</option>
          </select>

          <div class="module-function">
            <DateRangePicker :dateFrom="date_from" :dateTo="date_to" />
          </div>
        </div>
      </div>

      <div class="module-content">
        <div class="flex-grid flex-grid-fixed flex-grid-expanded">
          <div class="flex-col-sm-6 flex-col-xs-12">
            <div class="position-relative">
              <a href="#"
                 class="flex-xs align-items-center justify-content-center border-color-neutral-alpha-2 radius-small border-style-solid border-width-thin padding text-decoration-none color-inherit"
                 data-toggle-dropdown>

                <div class="flex-0-0">
                  <UserThumb v-bind="this.users[this.user1Id]" v-if="user1Id" />
                  <div class="thumbnail thumbnail-small" v-else>
                    <div class="thumbnail-text">
                      <i class="symbol symbol-search"></i>
                    </div>
                  </div>
                </div>

                <div class="flex-1-1 padding-small-x">
                  <template v-if="user1Id">
                    <p class="h4 no-margin">
                      {{ users[user1Id].first_name }} {{ users[user1Id].last_name }}
                    </p>
                    <p class="no-margin">{{ job_title_by_id[users[user1Id].job_id] || '' }}</p>
                  </template>
                  <p class="h4 no-margin font-weight-400" v-else>Search users</p>
                </div>
                <div class="flex-0-0">
                  <i class="font-size-large symbol symbol-arrow-down color-neutral"></i>
                </div>
              </a>

              <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                   data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                <div class="padding-small position-sticky background-theme z-index-1 offset-0-x offset-0-top">
                  <div class="input-group input-block input-group-horizontal">
                    <input placeholder="Type in user name" @input="filterUser1Opts"
                           class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right">

                    <label class="font-size-normalize btn btn-symbol btn-small btn-default">
                      <span class="sr-only">Search</span>
                      <i class="symbol symbol-search"></i>
                    </label>
                  </div>
                </div>

                <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active">
                  <label class="list-group-item pointer-reference dropdown-purger"
                          :class="{ active: user1Id === id }"
                          @click.prevent="user1Id = id"
                          v-show="!user1Regex || user1Regex.test(name)"
                          v-for="[id, name] in sortedUsersExcept(user1Id)" :key="`user1_${id}`">

                    <span class=" flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                      <div class="flex-0-0">
                        <UserThumb v-bind="users[id]" :small="true" />
                      </div>

                      <span class="flex-child flex-1-1 padding-small-left">
                        <input type="radio" class="sr-only">
                        <p class="no-margin text-wrap-ellipsis">
                          {{ users[id].first_name }} {{ users[id].last_name }}
                        </p>
                        <p class="no-margin text-wrap-ellipsis color-neutral">
                          {{ job_title_by_id[users[id].job_id] || '' }}
                        </p>
                      </span>
                      <span class="flex-child flex-0-0">
                        <i class="symbol symbol-check only-toggle flex-0-0"></i>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-col-sm-6 flex-col-xs-12">
            <div class="position-relative">
              <a href="#"
                 class="flex-xs align-items-center justify-content-center border-color-neutral-alpha-2 radius-small border-style-solid border-width-thin padding text-decoration-none color-inherit"
                 data-toggle-dropdown>

                <div class="flex-0-0">
                  <UserThumb v-bind="this.users[this.user2Id]" v-if="user2Id" />
                  <div class="thumbnail thumbnail-small" v-else>
                    <div class="thumbnail-text">
                      <i class="symbol symbol-search"></i>
                    </div>
                  </div>
                </div>

                <div class="flex-1-1 padding-small-x">
                  <template v-if="user2Id">
                    <p class="h4 no-margin">
                      {{ users[user2Id].first_name }} {{ users[user2Id].last_name }}
                    </p>
                    <p class="no-margin">{{ job_title_by_id[users[user2Id].job_id] || '' }}</p>
                  </template>
                  <p class="h4 no-margin font-weight-400" v-else>Search users</p>
                </div>
                <div class="flex-0-0">
                  <i class="font-size-large symbol symbol-arrow-down color-neutral"></i>
                </div>
              </a>

              <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                   data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                <div class="padding-small position-sticky background-theme z-index-1 offset-0-x offset-0-top">
                  <div class="input-group input-block input-group-horizontal">
                    <input placeholder="Type in user name" @input="filterUser2Opts"
                           class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right">

                    <label class="font-size-normalize btn btn-symbol btn-small btn-default">
                      <span class="sr-only">Search</span>
                      <i class="symbol symbol-search"></i>
                    </label>
                  </div>
                </div>

                <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active">
                  <label class="list-group-item pointer-reference dropdown-purger"
                         :class="{ active: user2Id === id }"
                         @click.prevent="user2Id = id"
                         v-show="!user2Regex || user2Regex.test(name)"
                         v-for="[id, name] in sortedUsersExcept(user2Id)" :key="`user2_${id}`">

                    <span class=" flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                      <div class="flex-0-0">
                        <UserThumb v-bind="users[id]" :small="true" />
                      </div>

                      <span class="flex-child flex-1-1 padding-small-left">
                        <input type="radio" class="sr-only">
                        <p class="no-margin text-wrap-ellipsis">
                          {{ users[id].first_name }} {{ users[id].last_name }}
                        </p>
                        <p class="no-margin text-wrap-ellipsis color-neutral">
                          {{ job_title_by_id[users[id].job_id] || '' }}
                        </p>
                      </span>
                      <span class="flex-child flex-0-0">
                        <i class="symbol symbol-check only-toggle flex-0-0"></i>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref="oneOnOneCmpChart"></div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Tooltip from '../imports/tooltip'
import OneOnOneCmpChart from '../lib/charts/insights/d3Compare'

import Layout from '../Layout'
import MyAnalyticsNavTabs from '../components/MyAnalyticsNavTabs'
import DateRangePicker from '../components/DateRangePicker'
import UserThumb from '../components/UserThumb'
import { humanize } from '../lib/string'

export default {
  name: 'OneOnOneCmp',

  props: {
    cmp: {
      type: String,
      required: true,
    },
    date_from: {
      type: String,
      required: true,
    },
    date_to: {
      type: String,
      required: true,
    },
    user1_id: {
      type: Number,
      required: true,
    },
    user2_id: {
      type: Number,
    },
    users: {
      type: Object,
      required: true,
    },
    job_title_by_id: {
      type: Object,
      required: true,
    },
    rows: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      _cmp: this.cmp,
      user1Id: this.user1_id,
      user1Regex: null,
      user2Id: this.user2_id,
      user2Regex: null,
      chart: null,
    }
  },

  mounted() {
    this.chart = new OneOnOneCmpChart(this.$refs.oneOnOneCmpChart)
    if (!Object.keys(this.rows).length) return

    this.chart.render({ data: this.rows })
  },

  watch: {
    _cmp: 'redrawChart',
    user1Id: 'redrawChart',
    user2Id: 'redrawChart',
  },

  computed: {
    sortedUsers() {
      return Object.entries(this.users).map(([id, { first_name, last_name }]) => {
        return [Number(id), `${first_name} ${last_name}`]
      }).sort((u1, u2) => u1[1].localeCompare(u2[1]))
    },
  },

  methods: {
    redrawChart() {
      const params = new URLSearchParams(location.search)
      params.set('cmp', this._cmp)
      params.set('user1', this.user1Id)
      params.set('user2', this.user2Id)

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['rows'],
        onSuccess: () => this.chart.render({ data: this.rows })
      })
    },

    exportOneOnOneCmpToCsvPath() {
      if (!this.user2Id) return

      const params = new URLSearchParams(location.search)
      params.set('csv', 1)

      location.href = `${location.pathname}?${params}`
    },

    sortedUsersExcept(userId) {
      return this.sortedUsers.filter(([id, _]) => id !== userId)
    },

    filterUser1Opts(e) {
      const userQuery = e.target.value.trim()
      if (!userQuery) {
        this.user1Regex = null
        return
      }

      this.user1Regex = new RegExp(userQuery, 'i')
    },

    filterUser2Opts(e) {
      const userQuery = e.target.value.trim()
      if (!userQuery) {
        this.user2Regex = null
        return
      }

      this.user2Regex = new RegExp(userQuery, 'i')
    },
  },

  components: {
    Layout,
    MyAnalyticsNavTabs,
    DateRangePicker,
    UserThumb,
  },
}
</script>

<template>
  <div class="module margin-large-top margin-bottom">
    <div class="module-header module-header-break padding-top no-margin-bottom padding-bottom align-items-center">
      <div class="flex-1-1 padding-right"> <!-- flex-xs -->
        <p class="h3 color-theme flex-1-1 no-margin-y">
          {{ title }}
        </p>
      </div>

      <div class="module-functions flex-0-0 flex-nowrap flex-xs align-items-center justyify-content-space-between">
        <div class="module-function">
          <div class="color-neutral text-nowrap">Created on {{ createdOn }}</div>
        </div>
      </div>
    </div>

    <div class="module-content no-padding-x no-padding-bottom">
      <hr class="no-margin-y">

      <table class="margin-top" v-for="(timelogs, date) in timelogsByDate" :key="date">
        <tr>
          <th class="padding-x no-border-top" colspan="2">
            <div class="h4 no-margin-y">{{ fmtDate(date) }}</div>
          </th>

          <th class="padding-x no-border-top text-align-right">
            <div class="h4 no-margin-y">{{ calculateTotalTimeFor(timelogs) }}</div>
          </th>
        </tr>

        <tr v-for="timelog in timelogs" :key="timelog.id">
          <td class="padding-left no-padding-right timetracker-td timetracker-td-w timetracker-td-w-60">
            <div class="position-relative margin-small-right">
              <div class="thumbnail thumbnail-micro">
                <span class="thumbnail-text" :title="users[timelog.user_id]">
                  {{ userInitialsById[timelog.user_id] }}
                </span>
              </div>
            </div>
          </td>

          <td class="padding-x">
            {{ fmtTime(timelog.timespan.lower) }} - {{ fmtTime(timelog.timespan.upper) }}
          </td>

          <td class="padding-x text-align-right">
            <span class="entry-total font-weight-700">
              {{ calculateTotalTimeFor([timelog]) }}
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'

import { formatDate, formatTime, parseISODate, parseUnixTimestamp, secondsToTimeStr } from '../lib/date'

export default {
  name: 'TaskTimelogs',

  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    created: {
      type: String,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      timelogs: [],
    }
  },

  async created() {
    const includeTimelogsNotOwnedByUser = this.$page.props.current_user.is_superuser ||
                                                this.$page.props.current_user.is_staff
    try {
      const resp = await axios.get(reverseUrl('timelogs.index'), {
        params: {
          task_id: this.id,
          include_timelogs_not_owned_by_user: includeTimelogsNotOwnedByUser,
        },
      })

      const { timelogs } = resp.data
      this.timelogs.splice(0, this.timelogs.length, ...timelogs)
    } catch(err) {
      console.error(`Unable to load timelogs for task #${this.id} due to: ${err}`)
    }
  },

  computed: {
    createdOn() {
      if (!this.created) return ''

      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },

    timelogsByDate() {
      return _.groupBy(this.timelogs, timelog => {
        return formatDate(parseUnixTimestamp(timelog.timespan.lower), {
          format: 'ISO',
          timeZone: this.$timeZone,
        })
      })
    },

    userInitialsById() {
      return Object.fromEntries(
        Object.entries(this.users).map(([id, name]) => [id, [...name.matchAll(/\b[A-Z]/g)].join('')])
      )
    },
  },

  methods: {
    fmtDate(dateStr) {
      return formatDate(parseISODate(dateStr), { timeZone: this.$timeZone })
    },

    fmtTime(timestamp) {
      return formatTime(new Date(timestamp * 1_000))
    },

    calculateTotalTimeFor(timelogs) {
      const totalSecs = timelogs.reduce((accu, tl) => accu + (tl.timespan.upper - tl.timespan.lower), 0)
      return secondsToTimeStr(totalSecs)
    },
  },
}
</script>

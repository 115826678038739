<template>
  <Layout>
    <div class="h2 font-weight-300 no-margin-y">
      {{ job.id ? 'Edit' : 'Add' }} Job
    </div>

    <div class="flex-grid flex-grid-fixed">
      <div class="flex-col-xs-12 flex-col-sm-9 flex-col-md-7">
        <form @submit.prevent="submitForm">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="title" class="input-label font-weight-700">Title</label>
            <input id="title" class="input input-single-line"
                   :class="{ 'input-error': form.errors.title }"
                   minlength="2" maxlength="100"
                   ref="jobTitle" v-model.trim="form.title" required>
            <div class="input-info color-error font-weight-700"
                 v-if="form.errors.title || form.errors.__root__">

              {{ (form.errors.title || form.errors.__root__).join(', ') }}
            </div>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="description" class="input-label font-weight-700">Description</label>
            <textarea id="description" class="input input-multiple-line input-block"
                      v-model.trim="form.description"></textarea>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="department_id" class="input-label font-weight-700">Department</label>
            <select id="department_id" class="input input-select"
                    :class="{ 'input-error': form.errors.department_id }"
                    v-model.number="form.department_id" required>

              <option :value="0">Select department</option>
              <option :value="id" v-for="(name, id) in all_departments" :key="id">
                {{ name }}
              </option>
            </select>
            <div class="input-info color-error font-weight-700" v-if="form.errors.department_id">
              {{ form.errors.department_id.join(', ') }}
            </div>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="task_status_group_id" class="input-label font-weight-700">
              Default task status group
            </label>
            <select id="task_status_group_id" class="input input-select"
                    :class="{ 'input-error': form.errors.task_status_group_id }"
                    v-model.number="form.task_status_group_id" required>

              <option :value="0">Select task status group</option>
              <option :value="id" v-for="(name, id) in all_task_status_groups" :key="id">
                {{ name }}
              </option>
            </select>
            <div class="input-info color-error font-weight-700"
                 v-if="form.errors.task_status_group_id">

              {{ form.errors.task_status_group_id.join(', ') }}
            </div>
          </div>

          <template v-if="Object.keys(all_groups).length">
            <label class="input-label font-weight-700">Groups</label>
            <datalist id="group-options">
              <template v-for="(name, id) in all_groups" :key="id">
                <option :value="name" v-if="findGroupIndex(id) === -1" />
              </template>
            </datalist>

            <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap"
                 v-for="[id, name] in form.groups" :key="id">

              <div class="flex-child flex-1-1 no-margin-top">
                <label :for="`group-${id}`" class="sr-only">{{ name }}</label>
                <input :id="`group-${id}`" :value="name"
                       class="input input-single-line input-block"
                       list="group-options" @change="changeGroup(id, $event)">
              </div>

              <div class="flex-child flex-0-0 no-margin-top flex-xs">
                <a href="#" class="btn btn-error-glassy btn-symbol"
                   @click.prevent="removeGroup(id)">

                  <i class="symbol symbol-minus"></i>
                </a>
              </div>
            </div>

            <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap">
              <div class="flex-child flex-1-1 no-margin-top">
                <input list="group-options" class="input input-single-line input-block"
                       placeholder="Add group.." @change="addGroup($event)">
              </div>
            </div>
          </template>

          <label class="input-label font-weight-700">Task Categories</label>
          <datalist id="task-category-options">
            <template v-for="[id, name] in allTaskCategories" :key="id">
              <option :value="name" v-if="findTaskCategoryIndex({ id }) === -1" />
            </template>
          </datalist>

          <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap"
               v-for="[id, name] in form.task_categories" :key="id">

            <div class="flex-child flex-1-1 no-margin-top">
              <label :for="`task-category-${id}`" class="sr-only">{{ name }}</label>
              <input :id="`task-category-${id}`" :value="name"
                     class="input input-single-line input-block"
                     list="task-category-options" @change="changeTaskCategory(id, $event)">
            </div>

            <div class="flex-child flex-0-0 no-margin-top flex-xs">
              <a href="#" class="btn btn-error-glassy btn-symbol"
                 @click.prevent="removeTaskCategory({ id, name })">

                <i class="symbol symbol-minus"></i>
              </a>
            </div>
          </div>

          <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap">
            <div class="flex-child flex-1-1 no-margin-top">
              <input list="task-category-options" class="input input-single-line input-block"
                     placeholder="Add task category.." @change="addTaskCategory($event)">
            </div>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="report_id" class="input-label font-weight-700">Reports To</label>
            <select id="report_id" class="input input-select"
                    :class="{ 'input-error': form.errors.report_id }"
                    v-model.number="form.report_id">

              <option :value="0">Select job</option>
              <option :value="id" v-for="[id, title] in allJobs" :key="id">
                {{ title }}
              </option>
            </select>
            <div class="input-info color-error font-weight-700" v-if="form.errors.report_id">
              {{ form.errors.report_id.join(', ') }}
            </div>
          </div>

          <hr class="margin-large-y">

          <div class="margin-large-top">
            <button class="btn btn-theme" :disabled="form.processing">Save</button>
            <Link :href="$returnBack() || $route('admin.jobs.index')"
                  class="btn btn-neutral-glassy margin-left">

                Go Back
              </Link>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link, useForm } from '@inertiajs/vue3'
import * as _ from 'lodash-es'

import Layout from '../Layout'

export default {
  name: 'JobForm',

  props: {
    job: {
      type: Object,
      required: true,
    },
    all_departments: {
      type: Object,
      required: true,
    },
    all_task_status_groups: {
      type: Object,
      required: true,
    },
    all_groups: {
      type: Object,
      required: true,
    },
    all_task_categories: {
      type: Object,
      required: true,
    },
    all_jobs: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      title: props.job.title,
      description: props.job.description,
      department_id: props.job.department_id,
      task_status_group_id: props.job.task_status_group_id,
      groups: Object.entries(props.job.groups).sort((g1, g2) => g1[1].localeCompare(g2[1])),
      task_categories: Object.entries(props.job.task_categories).sort((c1, c2) => c1[1].localeCompare(c2[1])),
      report_id: props.job.report_id,
    })

    return { form }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.jobTitle.focus()
  },*/

  computed: {
    allTaskCategories() {
      return Object.entries(this.all_task_categories).sort((c1, c2) => c1[1].localeCompare(c2[1]))
    },

    allJobs() {
      return Object.entries(this.all_jobs).sort((c1, c2) => c1[1].localeCompare(c2[1]))
    },
  },

  methods: {
    findGroupIndex(groupId) {
      const gId = String(groupId)
      return this.form.groups.findIndex(([id, _]) => id === gId)
    },

    findGroupEntryFor(name) {
      const n = name.trim().toLowerCase()
      return Object.entries(this.all_groups).find(([_, name]) => name.toLowerCase() === n)
    },

    addGroup(event) {
      const entry = this.findGroupEntryFor(event.target.value)
      if (entry && this.findGroupIndex(entry[0]) === -1) {
        this.form.groups.push(entry)
      }

      event.target.value = ''
    },

    changeGroup(id, event) {
      const entry = this.findGroupEntryFor(event.target.value)

      if (entry) {
        const index = this.findGroupIndex(entry[0])
        if (index === -1) this.form.groups[index][1] = entry[1]
      } else {
        event.target.value = this.all_groups[id]
      }
    },

    removeGroup(groupId) {
      const index = this.findGroupIndex(groupId)
      if (index === -1) return

      this.form.groups.splice(index, 1)
    },

    findTaskCategoryIndex({ id = 0, name = '' } = {}) {
      if (id) {
        const catId = String(id)
        return this.form.task_categories.findIndex(([taskCatId, _]) => taskCatId === catId)
      } else if (name) {
        const n = name.trim().toLowerCase()
        return this.form.task_categories.findIndex(([_, taskCatName]) => taskCatName.toLowerCase() === name)
      } else {
        return -1
      }
    },

    findTaskCategoryEntryFor(name) {
      const taskCatName = name.trim().toLowerCase()
      return Object.entries(this.all_task_categories).find(([_, name]) => name.toLowerCase() === taskCatName)
    },

    addTaskCategory(event) {
      const newTaskCatName = event.target.value,
        entry = this.findTaskCategoryEntryFor(newTaskCatName);

      if (entry) {
        if (this.findTaskCategoryIndex({ id: entry[0] }) === -1) {
          this.form.task_categories.push(entry)
        }
      } else {
        this.form.task_categories.push([0, newTaskCatName.trim()])
      }

      event.target.value = ''
    },

    changeTaskCategory(id, event) {
      const entry = this.findTaskCategoryEntryFor(event.target.value)

      if (entry) {
        const index = this.findTaskCategoryIndex({ id: entry[0] })
        if (index !== -1) this.form.task_categories[index][1] = entry[1]
      } else {
        event.target.value = this.all_task_categories[id]
      }
    },

    removeTaskCategory(taskCategory) {
      const index = this.findTaskCategoryIndex(taskCategory)
      if (index === -1) return

      this.form.task_categories.splice(index, 1)
    },

    submitForm() {
      const newTaskCatCount = this.form.task_categories.filter(([id, _]) => !id).length
      if (newTaskCatCount) {
        let msg = `Are you sure you want to add ${newTaskCatCount} task `
        msg += newTaskCatCount ? 'categories' : 'category'
        msg += '?'
        if (!confirm(msg)) return
      }

      const frm = this.form.transform(data => {
        const [existingTaskCats, newTaskCats] = _.partition(data.task_categories, ([id, _]) => Number(id))

        return {
          ...data,
          department_id: Number(data.department_id) || null,
          task_status_group_id: Number(data.task_status_group_id) || null,
          groups: data.groups.map(([id, _]) => Number(id)),
          task_categories: existingTaskCats.map(([id, _]) => Number(id)),
          new_task_category_names: newTaskCats.map(([_, name]) => name),
          report_id: Number(data.report_id) || null,
        }
      })

      if (this.job.id) {
        frm.patch(reverseUrl('admin.jobs.update', { id: this.job.id }))
      } else {
        frm.post(reverseUrl('admin.jobs.create'))
      }
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

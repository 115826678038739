<template>
  <div class="module" data-grid-area="allocation">
    <div class="module-header">
      <h5 class="no-margin-y">Time allocation</h5>
    </div>

    <div class="module-content align-self-stretch flex-xs flex-direction-column">
      <div class="flex-1-1 flex-xs flex-direction-column justify-content-center">
        <div ref="taskCatTimeAllocChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskCatTimeAllocChart from '../lib/charts/insights/d3Allocation'

export default {
  name: 'TaskCatTimeAllocChart',

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      chart: null,
    }
  },

  watch: {
    data(newData) {
      this.chart.render({
        data: newData,
      })
    },
  },

  mounted() {
    this.chart = new TaskCatTimeAllocChart(this.$refs.taskCatTimeAllocChart)
    this.chart.render({
      data: this.data,
    })
  },
}
</script>

<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="h2 font-weight-300 no-margin-y">Team members</div>
      </div>

      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="user-name-or-email" class="sr-only">User name or email</label>
              <input id="user-name-or-email" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="userNameOrEmail"
                     placeholder="User name or email" @input="debouncedFilterUsers">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.users.new')" class="btn btn-theme btn-block-mobile">
              Add new +
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div class="module">
      <div class="module-content no-padding">
        <div class="table-wrapper" v-if="users.length">
          <table class="timetracker-table">
            <thead>
              <tr class="background-transparent">
                <th class="background-transparent no-border-top">First name</th>
                <th class="background-transparent no-border-top">Last name</th>
                <th class="background-transparent no-border-top">Email</th>
                <th class="background-transparent no-border-top">Job title</th>
                <th class="background-transparent no-border-top text-align-center">Is active?</th>
                <th class="background-transparent no-border-top text-align-center">Is superuser?</th>
                <th class="background-transparent no-border-top text-align-center">Viewers</th>
                <th class="background-transparent no-border-top text-align-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td class="only-mobile">
                  <Link :href="$route('admin.users.edit', { id: user.id })">
                    {{ user.first_name }} {{ user.last_name }}
                  </Link>
                </td>

                <td class="hide-mobile">
                  <Link :href="$route('admin.users.edit', { id: user.id })">
                    {{ user.first_name }}
                  </Link>
                </td>

                <td class="hide-mobile">
                  <Link :href="$route('admin.users.edit', { id: user.id })">
                    {{ user.last_name }}
                  </Link>
                </td>

                <td class="timetracker-td timetracker-td-w timetracker-td-w-300">
                  <h6 class="hide-nonmobile no-margin-top">Email</h6>
                  <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                </td>

                <td class="timetracker-td timetracker-td-w timetracker-td-w-200">
                  <h6 class="hide-nonmobile no-margin-top">Job Title</h6>

                  <Link :href="$route('admin.jobs.edit', { id: user.job.id })"
                        v-if="user.job">

                    {{ user.job.title }}
                  </Link>
                  <span class="font-style-italic color-neutral" v-else>N/A</span>
                </td>

                <td class="text-align-center">
                  <h6 class="hide-nonmobile no-margin-top">Is Active?</h6>

                  <i class="symbol symbol-check color-success" v-if="user.is_active"></i>
                  <span class="disabled color-neutral" v-else>&ndash;</span>
                </td>

                <td class="text-align-center">
                  <h6 class="hide-nonmobile no-margin-top">Is Superuser?</h6>

                  <i class="symbol symbol-check color-success" v-if="user.is_superuser"></i>
                  <span class="disabled color-neutral" v-else>&ndash;</span>
                </td>

                <td class="text-align-center">
                  <h6 class="hide-nonmobile no-margin-top">Viewers</h6>
                  {{ user.viewers_count }}
                </td>

                <td class="timetracker-td-actions text-align-right">
                  <Link :href="$route('insights.index') + `?user_id=${user.id}`"
                        class="btn btn-neutral-glassy">

                    Insights
                  </Link>

                  <a href="#" class="btn btn-link btn-symbol margin-left" title="Mark inactive"
                     @click.prevent="confirmInactive(user)">

                    <i class="symbol symbol-delete"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="font-style-italic color-neutral padding" v-else>
          There are no team members yet.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import * as _ from 'lodash-es'

import Layout from '../Layout'

export default {
  name: 'UserList',

  props: {
    users: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
  },

  created() {
    this.debouncedFilterUsers = _.debounce(this.filterUsers,
                                           this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.userNameOrEmail.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterUsers.cancel()
  },

  methods: {
    filterUsers(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
    },

    confirmInactive({ id, first_name: firstName, last_name: lastName }) {
      if (!confirm(`Are you sure you want to delete user '${firstName} ${lastName}'?`)) return

      this.$inertia.delete(reverseUrl('admin.users.destroy', { id }))
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

export function boolToStr(bool) {
  return bool ? 'Y' : 'F';
}

export function stringifyErrors({
  response: {
    data: { errors },
  },
}) {
  return Object.entries(errors)
    .map(([field, errs]) => `${field}: ${errs.join('. ')}`)
    .join('\n');
}

export function stringifyValidationErrors({
  response: {
    data: {
      validation_error: { body_params: errors },
    },
  },
}) {
  return errors.map((err) => `${err.loc.join(', ')}: ${err.msg}`).join('\n');
}

export function humanize(string) {
  return string.replaceAll('_', ' ').replace(/^[a-z]/, (match) => match.toUpperCase());
}

export function titlecase(string) {
  return string
    .replaceAll(/_/g, ' ')
    .toLowerCase()
    .replaceAll(/\b[a-z]/g, (match) => match.toUpperCase());
}

<template>
  <div class="table-wrapper">
    <table class="timetracker-table">
      <!--
        @loop template / tr and tbody
        :NOTE: tag is commented out for front end demo to work because tables are weird
      -->
      <tr>
        <td class="hide-mobile no-border-bottom text-align-right timetracker-td timetracker-td-w timetracker-td-w-60"
            v-if="task.timelogs.length">

          <a :href="`#task-${task.id}-${dateOrStatus}-timelogs`" class="btn btn-small btn-primary btn-symbol btn-round"
             title="Toggle timelogs" data-toggle-accordion>

            <i class="symbol symbol-arrow-down symbol-arrow-up-toggle"></i>
          </a>
          <!--span class="spacer" v-else>&nbsp;</span-->
        </td>

        <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-40 text-align-center no-padding-right">
          <div class="btn cursor-grab cursor-grabbing font-size-large no-padding color-neutral align-items-flex-start color-theme-hover color-theme-active color-theme-focus no-shadow"
               :class="{ 'draggable-task': !$impersonatingUser.value }">

            <i class="symbol symbol-drag"></i>
          </div>
        </td>

        <td class="no-border-bottom timetracker-td" colspan="2">
          <form class="h5 color-inherit no-margin-y flex-xs align-items-center">
            <a href="#" @click.prevent="toggleTaskFavStatus"
               :class="{ disabled: $impersonatingUser.value }">

              <i class="symbol color-primary" :class="taskFavStatusCssClass"></i>
            </a>
            &nbsp;
            <Link :href="$linkTo('admin.tasks.edit', { viewArgs: [task.id], returnBack: true })"
                  class="color-inherit font-weight-400 no-margin-y display-inline-block"
                  v-if="userCanChangeOrDeleteTask">

              {{ description }}
            </Link>
            <!--input class="no-margin input input-blend input-block flex-1-1"
                   :value="description"
                   :title="task.root_project ? `(${task.root_project.name})` : ''" required
                   @focus="onTitleFocused"
                   @blur="$event.target.value = description"
                   @change="changeTitle"
                   v-if="userCanChangeOrDeleteTask"-->
            <span v-else>{{ description }}</span>
          </form>

          <!-- @if has tags -->
          <!--div class="margin-small-top"-->
            <!-- @loop .tag -->
            <!--span class="tag tag-neutral font-weight-400">
              <span class="REPLACE">Tag Text</span>
            </span>
          </div-->
        </td>

        <td class="no-border-bottom text-align-right timetracker-td timetracker-td-w timetracker-td-w-300">
          <h5 class="hide-nonmobile no-margin-top">Status:</h5>

          <template v-if="!$impersonatingUserHasROAccess.value">
            <a href="#" @click.prevent="onStatusNameClicked"
               class="tag no-margin-y" :class="taskStatusTagCssClass">

              {{ task.status?.name || 'Select status' }}
            </a>
          </template>
          <span class="tag no-margin-y" :class="taskStatusTagCssClass" v-else>
            {{ task.status?.name || 'Select status' }}
          </span>
        </td>

        <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-100">
          <h5 class="hide-nonmobile no-margin-top">
            Total time:
          </h5>
          <span class="entry-total font-weight-700">
            {{ taskTotalTime }}
          </span>
        </td>

        <td class="no-border-bottom timetracker-td timetracker-td-w-100 timetracker-td-actions text-align-right">
          <form class="display-inline margin-right">
            <a href="#" class="btn btn-link no-padding font-size-large"
               :class="{ disabled: $impersonatingUser.value }" title="Toggle timer"
               @click.prevent="toggleTimer">

              <i class="symbol" :class="timerBtnCssClasses"></i>
              <span class="sr-only">Toggle timer</span>
            </a>
          </form>

          <span class="position-relative" v-if="userCanChangeOrDeleteTask">
            <a :href="`#task-${task.id}-options`" class="btn btn-link no-padding font-size-large"
               title="More options" data-modal-change-hash="false" data-modal-disable-overlay="false"
               data-toggle-modal-default>

              <i class="symbol symbol-kebab-horizontal"></i>
              <span class="sr-only">More options</span>
            </a>

            <div class="modal modal-default" :id="`task-${task.id}-options`" ref="taskOpts"
                 data-modal-width="400px">

                <div class="text-align-center">
                  <h1 class="no-margin-top color-inherit">Options</h1>
                  <ul class="list-group list-group-compact">
                    <li>
                      <Link :href="$linkTo('admin.tasks.edit', { viewArgs: [task.id], returnBack: true })">
                        Edit task
                      </Link>
                    </li>

                    <li>
                      <a href="#" @click.prevent="confirmDeletion">Delete task</a>
                    </li>
                  </ul>
                </div>
            </div>
          </span>
        </td>

        <td class="no-border-bottom timetracker-td only-mobile" v-if="task.timelogs.length">
          <a :href="`#task-${task.id}-${dateOrStatus}-timelogs`" class="hide-nonmobile btn btn-small btn-link no-padding-x"
             title="Toggle timelogs" data-toggle-accordion>

            <i class="symbol symbol-arrow-down symbol-arrow-up-toggle"></i>
            &nbsp;Toggle timelogs
          </a>
        </td>
      </tr>

      <tbody class="accordion" :id="`task-${task.id}-${dateOrStatus}-timelogs`" data-accordion-change-hash="false"
             v-if="task.timelogs.length">

        <TimelogRow v-bind="timelog"
                    @timelog:changed="$emit('timelog:changed', Object.assign($event, { taskId: task.id }))"
                    @timelog:deleted="$emit('timelog:deleted', Object.assign($event, { taskId: task.id }))"
                    v-for="timelog in task.timelogs" :key="timelog.id" />
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import { Link } from '@inertiajs/vue3'

import { secondsToTimeStr } from '../lib/date'
import Modal from '../imports/modal'
import { taskStatusCSSClass } from '../lib/task'

import TimelogRow from './TimelogRow'

export default {
  name: 'TaskRow',

  props: {
    task: {
      type: Object,
      required: true
    },
    date: {
      type: String,
    },
    status: {
      type: String,
    },
    taskStatusOptions: {
      type: Object,
      required: true
    },
  },

  data() {
    let timerStarted = false
    const timer = localStorage.getItem('timer')
    if (timer) timerStarted = JSON.parse(timer).taskId === this.task.id

    return {
      timerStarted,
    }
  },

  mounted() {
    this.$emitter.on('task:changed', this.onTaskChanged)
    this.$emitter.on('timer:started', this.onTimerStarted)
    this.$emitter.on('timer:stopped', this.onTimerStopped)
  },

  unmounted() {
    this.$emitter.off('timer:stopped', this.onTimerStopped)
    this.$emitter.off('timer:started', this.onTimerStarted)
    this.$emitter.off('task:changed', this.onTaskChanged)
  },

  computed: {
    description() {
      return `${this.task.title} [${this.task.project.name}]`
    },

    userCanChangeOrDeleteTask() {
      if (this.$page.props.current_user.is_superuser) return true
      if (this.task.authors.includes(this.$page.props.current_user.id)) return true
      if (!this.$impersonatingUserHasROAccess.value) return false

      return false
    },

    dateOrStatus() {
      return this.date || this.status
    },

    taskFavStatusCssClass() {
      return this.task.is_favorite ? 'symbol-star' : 'symbol-star-stroke'
    },

    taskStatusTagCssClass() {
      return `tag-${taskStatusCSSClass(this.task.status?.name)}`
    },

    taskTotalTime() {
      let totalSecs
      if ('total_time' in this.task) {
        totalSecs = this.task.total_time
      } else {
        totalSecs = this.task.timelogs.reduce((accu, tl) => accu + tl.timespan.upper - tl.timespan.lower, 0)
      }

      return secondsToTimeStr(totalSecs)
    },

    timerBtnCssClasses() {
      return this.timerStarted ? ['symbol-pause', 'symbol-play-toggle'] : ['symbol-play', 'symbol-pause-toggle']
    },
  },

  methods: {
    onTitleFocused(event) {
      event.target.value = this.task.title
    },

    onStatusNameClicked(_event) {
      this.$emit('open:taskStatusModal', {
        id: this.task.id,
        statusId: this.task.status?.id || null
      })
    },

    onTaskChanged({ id, changes }) {
      if (id != this.task.id) return

      Object.assign(this.task, changes)
    },

    async changeTitle(event) {
      const newTitle = event.target.value

      try {
        await axios.patch(this.$route('tasks.update', { id: this.task.id }), {
          title: newTitle,
        })
      } catch {
        console.error(`Not allowed to change title for task #${this.task.id} to: '${newTitle}'`)
        return
      }

      event.target.value = `${newTitle} [${this.task.project.name}]`
      this.$emit('task:changed', { id: this.task.id, changes: { title: newTitle } })
    },

    async changeStatus(event) {
      const newStatus = Number(event.target.value) || null
      const resp = await axios.patch(this.$route('tasks.update', { id: this.task.id }), {
        status_id: newStatus,
      })
      const { task: { status } } = resp.data

      this.$emit('task:changed', { id: this.task.id, changes: { status } })
    },

    async toggleTaskFavStatus() {
      if (this.$impersonatingUser.value) return

      const isFavorite = !this.task.is_favorite

      await axios.patch(this.$route('tasks.update', { id: this.task.id }), {
        is_favorite: isFavorite,
      })

      this.$emit('task:changed', { id: this.task.id, changes: { is_favorite: isFavorite } })
    },

    toggleTimer() {
      if (this.$impersonatingUser.value) return

      const event = this.timerStarted ? 'stop:timer' : 'start:timer'
      this.timerStarted = !this.timerStarted

      if (this.timerStarted) {
        this.$emitter.emit(event, this.task.id)
      } else {
        this.$emitter.emit(event, this.task.id)
      }
    },

    onTimerStarted(taskId) {
      if (taskId !== this.task.id) return

      this.timerStarted = true
    },

    onTimerStopped(taskId) {
      if (taskId !== this.task.id) return

      this.timerStarted = false
    },

    async confirmDeletion() {
      if (!confirm('Are you sure you want to delete this task?')) return

      try {
        await axios.delete(this.$route('tasks.destroy', { id: this.task.id }), {
          data: {
            delete_on_wrike: this.task.synced_to_wrike,
          },
        })
        this.$emit('task:deleted', this.task.id)
      } catch(error) {
        console.error(`Not allowed to delete task #${this.task.id}'`)
        return
        // alert(error.response.data.errors.id.join(', '))
      }

      new Modal(this.$refs.taskOpts).destroy()
    },
  },

  components: {
    Link,
    TimelogRow,
  },
  emits: [
    'open:taskStatusModal',
    'task:changed',
    'task:deleted',
    'timelog:changed',
    'timelog:deleted',
  ],
}
</script>

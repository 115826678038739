<template>
  <tr>
    <td class="timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Date</span>
      {{ date }}
    </td>

    <td class="timetracker-td timetracker-td-w timetracker-td-w-100">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Start</span>
      {{ fmtTime(timespan.lower) }}
    </td>

    <td class="timetracker-td timetracker-td-w timetracker-td-w-100">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">End</span>
      {{ fmtTime(timespan.upper) }}
    </td>

    <td class="timetracker-td timetracker-td-w timetracker-td-w-100">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Total</span>
      {{ duration }}
    </td>

    <td class="timetracker-td timetracker-td-w timetracker-td-w-200">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Task</span>
      <Link :href="$linkTo('admin.tasks.edit', { viewArgs: [task.id], returnBack: true })"
            class="color-inherit">

        {{ task.title }}
      </Link>
    </td>

    <td>
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Category</span>
      {{ task.category?.name || '' }}
    </td>

    <td class="text-align-center">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Status</span>
      <div class="tag" :class=taskStatusTagCssClass>
        <span class="REPLACE">Backlog</span>
      </div>
    </td>

    <td class="timetracker-td timetracker-td-w timetracker-td-w-200">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">
        Project / client
      </span>

      <div class="text-wrap-ellipsis font-weight-700">
        <Link :href="$linkTo('admin.clients.edit', { viewArgs: [task.project.client.id], returnBack: true})"
              class="color-inherit">

          {{ task.project.client.name }}
        </Link>
      </div>

      <Link :href="$linkTo('admin.projects.edit', { viewArgs: [task.project.id], returnBack: true })"
            class="color-inherit">

        {{ task.project.name }}
      </Link>
    </td>

    <td class="text-align-center">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Comment</span>
      <a href="#" class="btn btn-symbol btn-link" :title="comment.slice(0, 100)" data-toggle-modal-default>
        <i class="symbol symbol-file"></i>
      </a>

      <div class="modal modal-default" data-modal-width="500px" data-modal-change-hash="false"
           data-modal-disable-overlay="false">

        {{ comment }}
      </div>
    </td>
  </tr>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import { formatDate, formatTime, parseUnixTimestamp, secondsToTimeStr } from '../lib/date'
import { taskStatusCSSClass } from '../lib/task'

export default {
  name: 'MyAnalyticsTimelog',

  props: {
    timespan: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
  },

  computed: {
    date() {
      return formatDate(parseUnixTimestamp(this.timespan.lower), {
        format: 'tiny',
        timeZone: this.$timeZone,
      })
    },
    duration() {
      return secondsToTimeStr(this.timespan.upper - this.timespan.lower)
    },
    taskStatusTagCssClass() {
      return `tag-${taskStatusCSSClass(this.task.status?.name)}`
    },
  },

  methods: {
    fmtTime(unixTimestamp) {
      return formatTime(parseUnixTimestamp(unixTimestamp))
    },
  },

  components: {
    Link,
  },
}
</script>

<template>
  <form @submit.prevent="saveNewTask">
    <div class="module margin-large-top margin-bottom">
      <div class="module-header module-header-break padding-top no-margin-bottom padding-bottom align-items-center">
        <div class="flex-1-1 padding-right">
          <div class="h3 color-theme flex-1-1 no-margin-y">
            <label for="project-name" class="sr-only">Task title</label>
            <input id="task-title" class="input input-blend input-block"
                  placeholder="Task title" v-model.trim="_title" @change="onTitleChanged"
                  required>
          </div>

          <div class="position-relative margin-small-top display-inline-block" v-if="!clientId">
            <div class="tag tag-neutral font-weight-400" data-toggle-dropdown>
              <span>{{ clientName || 'Add client +' }}</span>
            </div>
            <span>&nbsp;</span>

            <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                 data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

              <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                <div class="input-group input-block input-group-horizontal">
                  <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                         id="client-name" placeholder="Search clients"
                         @input="debouncedFetchClients">

                  <label for="client-name"
                         class="font-size-normalize btn btn-symbol btn-small btn-default">

                    <span class="sr-only">Search</span>
                    <i class="symbol symbol-search"></i>
                  </label>
                </div>
              </div>

              <div class="list-group list-group-interactive">
                <!--
                  @NOTE

                  .list-group-item
                    classes to add:
                      `active` => when the contained input field is checked or has value
                -->
                <label class="list-group-item pointer-reference"
                       :class="{ active: _clientId === id }"
                       @click.prevent="onClientChanged(id, name)"
                       v-for="{ id, name } in clientOptions" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="radio" name="client-id" :value="id" class="sr-only">
                      <span class="text-wrap-ellipsis">{{ name }}</span>
                    </span>
                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="position-relative margin-small-top display-inline-block">
            <!-- @NOTE
              .tag

                attributes to add
                  `data-toggle-dropdown` => if can edit info
            -->
            <div class="tag tag-neutral font-weight-400" data-toggle-dropdown>
              <span>{{ projectName || project?.name || 'Add project +' }}</span>
            </div>

            <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

              <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                <div class="input-group input-block input-group-horizontal">
                  <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                         id="project-name" placeholder="Search projects" ref="projectName"
                         @input="filterProjects">

                  <label for="project-name"
                         class="font-size-normalize btn btn-symbol btn-small btn-default">

                    <span class="sr-only">Search</span>
                    <i class="symbol symbol-search"></i>
                  </label>
                </div>
              </div>

              <div class="list-group list-group-interactive">
                <!--
                  @NOTE

                  .list-group-item
                    classes to add:
                      `active` => when the contained input field is checked or has value
                -->
                <label class="list-group-item pointer-reference"
                      :class="{ active: _projectId === id }"
                      @click.prevent="onProjectChanged(id, name)"
                      v-show="!projectRegex || projectRegex.test(name)"
                      v-for="{ id, name } in projectOptions" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="radio" name="project-id" :value="id" class="sr-only">
                      <span class="text-wrap-ellipsis">{{ name }}</span>
                    </span>
                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="module-functions flex-0-0 flex-nowrap flex-xs align-items-center justyify-content-space-between">
          <div class="module-function" v-if="id">
            <div class="color-neutral text-nowrap">Created on {{ createdOn }}</div>
          </div>

          <div class="module-function text-align-right" v-if="id">
            <div class="display-inline-block position-relative">
              <a href="#" class="btn padding-small font-size-large btn-link"
                 title="More options" data-toggle-dropdown>

                <i class="symbol symbol-kebab-horizontal"></i>
              </a>

              <div class="dropdown dropdown-right dropdown-top-flush text-align-left"
                   data-dropdown-width="200px">

                <ul class="list-group list-group-small no-margin-y">
                  <li class="no-border">
                    <a href="#" class="display-block dropdown-purger"
                       @click.prevent="$copyURLToClipboard">

                      <i class="symbol symbol-hyperlink"></i> Copy link
                    </a>
                  </li>

                  <li class="no-border" v-if="userCanDeleteTask">
                    <a href="#task-del-confirm-modal" @click.prevent
                       class="display-block dropdown-purger" data-modal-disable-overlay="false"
                       data-toggle-modal-default>

                      <i class="symbol symbol-delete"></i> Delete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="module-content no-padding-x no-padding-bottom">
        <div class="timetracker-module-subheader flex-md flex-wrap align-items-center">
          <div class="flex-0-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs"
            v-if="sortedTaskStatuses.length">

            <a href="#" class="btn btn-block-mobile no-radius"
               :class="taskStatusBtnCSSClass" data-toggle-dropdown>

              <span>
                &bull;
                <span class="color-theme">
                  &nbsp;{{ taskStatusName || 'Status' }}&nbsp;
                </span>
                <i class="symbol symbol-arrow-down"></i>
              </span>
            </a>

            <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

              <div class="list-group">
                <!--
                  @NOTE

                  .list-group-item
                    classes to add:
                      `active` => when the contained input for status is checked
                -->
                <label class="list-group-item pointer-reference"
                      :class="{ active: _statusId === id }"
                      @click.prevent="onStatusChanged(id)"
                      v-for="[id, name] in sortedTaskStatuses" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="radio" name="status-id" :value="id" class="sr-only">

                      <span class="text-wrap-ellipsis">
                        <span :class="taskStatusBulletCSSClass(id)">&bull;&nbsp;</span>
                        {{ name }}
                      </span>
                    </span>

                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="flex-0-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top  position-relative"
               v-if="categoryName || taskCategoryOptions.length">

            <div class="btn btn-block-mobile no-radius font-weight-400" v-bind="categoryDataAttrs">
              <span>
                <i class="symbol symbol-grid"></i>
                {{ categoryName ? ` ${categoryName}` : ' Category' }}
              </span>
            </div>

            <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                 data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px"
                 v-if="taskCategoryOptions.length">

              <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                <div class="input-group input-block input-group-horizontal">
                  <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                         id="task-category" placeholder="Search categories"
                         @input="filterCategories">

                  <label for="task-category"
                         class="font-size-normalize btn btn-symbol btn-small btn-default">

                    <span class="sr-only">Search</span>
                    <i class="symbol symbol-search"></i>
                  </label>
                </div>
              </div>

              <div class="list-group list-group-interactive">
                <!--
                  @NOTE

                  .list-group-item
                    classes to add:
                      `active` => when the contained input field is checked or has value
                -->
                <label class="list-group-item pointer-reference"
                       :class="{ active: _categoryId === id }"
                       @click.prevent="onCategoryChanged(id, name)"
                       v-show="!categoryRegex || categoryRegex.test(name)"
                       v-for="[id, name] in taskCategoryOptions" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="radio" name="category-id" :value="id" class="sr-only">
                      <span class="text-wrap-ellipsis">{{ name }}</span>
                    </span>
                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="flex-0-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top  position-relative"
            v-if="Object.keys(users).length">

            <div class="btn btn-block-mobile no-radius font-weight-400" v-bind="usersDataAttrs">
              <span>
                <i class="symbol symbol-users"></i>
                <span v-if="_authors.size">{{ authorNames }}</span>
                <span v-else>&nbsp;Authors +</span>
              </span>
            </div>

            <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

              <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                <div class="input-group input-block input-group-horizontal">
                  <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                        id="author-name" placeholder="Toggle task authors"
                        @input="filterAuthors">
                  <label for="author-name"
                        class="font-size-normalize btn btn-symbol btn-small btn-default">

                    <span class="sr-only">Search</span>
                    <i class="symbol symbol-search"></i>
                  </label>
                </div>
              </div>

              <div class="list-group list-group-interactive">
                <!--
                  @NOTE

                  .list-group-item
                    classes to add:
                      `active` => when the contained input field is checked or has value
                -->
                <label class="list-group-item pointer-reference"
                      :class="{ active: _authors.has(id) }"
                      @click.prevent="onAuthorToggled(id)"
                      v-show="!authorRegex || authorRegex.test(name)"
                      v-for="(name, id) in users" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="radio" name="author-id" :value="id" class="sr-only">
                      <span class="text-wrap-ellipsis">{{ name }}</span>
                    </span>
                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="flex-0-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top  position-relative"
            v-if="Object.keys(users).length">

            <div class="btn btn-block-mobile no-radius font-weight-400" v-bind="usersDataAttrs">
              <span>
                <i class="symbol symbol-plus"></i>
                <span v-if="_responsibles.size">{{ responsibleNames }}</span>
                <span v-else>&nbsp;Assignees</span>
              </span>
            </div>

            <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                 data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

              <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                <div class="input-group input-block input-group-horizontal">
                  <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                         id="responsible-name" placeholder="Toggle task assignees"
                         @input="filterResponsibles">
                  <label for="responsible-name"
                         class="font-size-normalize btn btn-symbol btn-small btn-default">

                    <span class="sr-only">Search</span>
                    <i class="symbol symbol-search"></i>
                  </label>
                </div>
              </div>

              <div class="list-group list-group-interactive">
                <!--
                  @NOTE

                  .list-group-item
                    classes to add:
                      `active` => when the contained input field is checked or has value
                -->
                <label class="list-group-item pointer-reference"
                      :class="{ active: _responsibles.has(id) }"
                      @click.prevent="onResponsibleToggled(id)"
                      v-show="!responsibleRegex || responsibleRegex.test(name)"
                      v-for="(name, id) in users" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="radio" name="responsible-id" :value="id" class="sr-only">
                      <span class="text-wrap-ellipsis">{{ name }}</span>
                    </span>
                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="flex-0-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top  position-relative"
                v-if="parent || parentCandidates.length">

            <div class="btn btn-block-mobile no-radius font-weight-400" v-bind="parentDataAttrs">
              <span>
                <i class="symbol symbol-folder"></i>
                {{ parentTitle ? ` ${parentTitle}` : (parentCandidates.length ? ' Select parent +' : '') }}
              </span>
            </div>

            <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px"
                v-if="parentCandidates.length">

              <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                <div class="input-group input-block input-group-horizontal">
                  <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                        id="parent-task" placeholder="Search tasks"
                        @input="filterTasks">

                  <label for="parent-task"
                        class="font-size-normalize btn btn-symbol btn-small btn-default">

                    <span class="sr-only">Search</span>
                    <i class="symbol symbol-search"></i>
                  </label>
                </div>
              </div>

              <div class="list-group list-group-interactive">
                <!--
                  @NOTE

                  .list-group-item
                    classes to add:
                      `active` => when the contained input field is checked or has value
                -->
                <label class="list-group-item pointer-reference"
                      :class="{ active: _parentId === id }"
                      @click.prevent="onParentToogled(id, title)"
                      v-show="!parentRegex || parentRegex.test(title)"
                      v-for="{ id, title } in parentCandidates" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="radio" name="parent-id" :value="id" class="sr-only">
                      <span class="text-wrap-ellipsis">{{ title }}</span>
                    </span>
                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="flex-0-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative align-self-stretch flex-xs align-items-center"
               v-if="nonBillableTasksEnabled">

            <label class="input input-toggle">
              <input type="checkbox" class="input" :disabled="!userCanChangeTask"
                     v-model.trim="_billable" @change="onBillableChanged">
              <span class="input-toggle-label">Billable?</span>
            </label>
          </div>

          <div class="flex-1-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top align-self-stretch flex-md align-items-center justify-content-flex-end">
            <div class="flex-0-0 position-relative text-align-center">
              <div class="btn btn-block-mobile no-radius font-weight-400 padding-small-x"
                   v-bind="dueDateDataAttrs">

                <span>
                  <i class="symbol symbol-calendar"></i>

                  <span class="timetracker-text-wrap-ellipsis-inline text-wrap-ellipsis"
                        v-if="dueDateStr">

                    <span>&nbsp;{{ dueDateStr }}</span>
                  </span>
                  <span v-else>&nbsp;Add due date +</span>
                </span>
              </div>

              <div class="dropdown dropdown-right dropdown-top-flush padding-x padding-small-y no-padding-top" data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">
                <label class="input-wrapper input-wrapper-vertical input-wrapper-block p">
                  <span class="input-label font-weight-700">Due date</span>
                  <input type="date" class="input input-single-line"
                         v-model="dueOn" @change="onDueOnChanged">
                </label>
                <label class="input-wrapper input-wrapper-vertical input-wrapper-block p">
                  <span class="input-label font-weight-700">Due time</span>
                  <input type="time" class="input input-single-line"
                         v-model="dueAt" @change="onDueAtChanged">
                </label>
              </div>
            </div>

            <div class="flex-0-0 padding-x">
              <div class="input-wrapper input-wrapper-horizontal input-wrapper-block justify-content-flex-end">
                <label class="input-toggle input-toggle-primary input-toggle-reverse padding-small-y">
                  <input type="checkbox" class="input" :disabled="!userCanChangeTask"
                         v-model.trim="_syncedToWrike" @change="onSyncedOnWrikeChanged">
                  <span class="input-label input-toggle-label font-weight-700">
                    Synced to Wrike
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <Editor :api-key="$page.props.tinymce_api_key"
                :init="$tinymceInitOpts"
                :plugins="$page.props.tinymce_plugins"
                :toolbar="$page.props.tinymce_toolbar"
                v-model.trim="_description"
                @change="onDescriptionChanged"
                :disabled="!userCanChangeTask" />
      </div>
    </div>

    <div class="margin-top text-align-right">
      <Link :href="$returnBack() || $route('admin.tasks.index')" class="btn btn-theme-outline">
        {{ id ? 'Back' : 'Cancel' }}
      </Link>
      &nbsp;
      <button href="#" class="btn btn-theme" v-if="!id">
        {{ id ? 'Update' : 'Create' }} task
      </button>
    </div>
  </form>

  <ModelDelConfirmModal modelCls="task"
    :action="$route('tasks.destroy', { id })"
    :successPath="$returnBack() || $route('admin.tasks.index')"
    :syncedToWrike="syncedToWrike"
    v-if="id" />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'
import { ref, watch } from 'vue'

import { Link, usePage } from '@inertiajs/vue3'
import Editor from '@tinymce/tinymce-vue';

import ModelDelConfirmModal from './ModelDelConfirmModal'

import { formatDate, formatDateTime, parseISODate } from '../lib/date'
import { stringifyValidationErrors } from '../lib/string'
import { taskStatusCSSClass } from '../lib/task'

export default {
  name: 'TaskDetails',

  props: {
    id: {
      type: Number,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    created: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    billable: {
      type: Boolean,
    },
    nonBillableTasksEnabled: {
      type: Boolean,
      required: true,
    },
    syncedToWrike: {
      type: Boolean,
    },
    statusId: {
      type: Number,
    },
    categoryId: {
      type: Number,
    },
    parentId: {
      type: Number,
    },
    parent: {
      type: Object,
    },
    parentTaskCandidates: {
      type: Array,
      required: true,
    },
    projectId: {
      type: Number,
    },
    project: {
      type: Object,
    },
    taskStatuses: {
      type: Object,
      required: true,
    },
    taskCategories: {
      type: Object,
      required: true,
    },
    authors: {
      type: Array,
      required: true,
    },
    responsibles: {
      type: Array,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let dueAt = ref(props.dueDate ? formatDateTime(parseISODate(props.dueDate), { format: 'clock' }) : '')

    const onDueDateChanged = async newDueDate => {
      const dueDateMillis = newDueDate?.getTime()
      if (!dueDateMillis) dueAt.value = ''

      if (!props.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: props.id }), {
          due_date: dueDateMillis ? dueDateMillis / 1_000 : null,
        })
      } catch(err) {
        alert(`Unable to change task due date due to:\n${stringifyValidationErrors(err)}`)
      }
    }

    const page = usePage()
    const debouncedOnDueDateChanged = _.debounce(onDueDateChanged,
                                                 page.props.app_config.autocomplete_debounce_min)
    const dueDate = ref(props.dueDate ? parseISODate(props.dueDate) : null)
    watch(dueDate, debouncedOnDueDateChanged)

    return {
      dueDate_: dueDate,
      dueAt,
    }
  },

  data() {
    const _authors = new Set(this.authors.map(String))
    if (location.pathname === reverseUrl('admin.tasks.new') && !_authors.size) {
      _authors.add(String(this.$page.props.current_user.id))
    }
    // const dueDate = this.dueDate ? parseISODate(this.dueDate) : null

    return {
      _title: this.title,
      _description: this.description,
      _billable: this.billable,
      _syncedToWrike: this.syncedToWrike || false,
      _statusId: this.statusId,
      _categoryId: this.categoryId,
      categoryName: this.taskCategories[this.categoryId] || '',
      categoryRegex: null,
      _parentId: this.parentId,
      parentTitle: this.parent?.title || '',
      parentRegex: null,
      _projectId: this.projectId,
      projectOptions: [],
      projectName: '',
      projectRegex: null,
      _authors,
      authorRegex: null,
      _responsibles: new Set(this.responsibles.map(String)),
      responsibleRegex: null,
      clientId: this.project?.client_id,
      _clientId: this.project?.client_id || 0,
      clientOptions: [],
      clientName: '',
      dueOn: this.dueDate ? formatDate(parseISODate(this.dueDate), { format: 'ISO' }) : '',
    }
  },

  async created() {
    this.debouncedFetchClients = _.debounce(this.fetchClients,
                                            this.$page.props.app_config.autocomplete_debounce_min)

    if (this.clientId || !this.projectId) {
      await this.fetchClients()
      return
    }

    try {
      const resp = await axios.get(reverseUrl('projects.show', {
        id: this.projectId,
      }))
      const { project: { client_id: clientId } } = resp.data
      this._clientId = clientId
    } catch(err) {
      console.error(`Unable to load client ID for project #${this.projectId} due to: ${err}`)
    }
  },

  beforeUnmount() {
    this.debouncedFetchClients.cancel()
  },

  computed: {
    userCanChangeTask() {
      return this.$page.props.current_user.is_staff ||
        this.authors.includes(this.$page.props.current_user.id)
    },

    userCanDeleteTask() {
      return this.userCanChangeTask
    },

    categoryDataAttrs() {
      const res = {}
      if (this.taskCategoryOptions.length) res['data-toggle-dropdown'] = ''
      return res
    },

    usersDataAttrs() {
      const res = {}
      if (Object.keys(this.users).length && this.userCanChangeTask) {
        res['data-toggle-dropdown'] = ''
      }
      return res
    },

    parentDataAttrs() {
      const res = {}
      if (this.parentCandidates.length && this.userCanChangeTask) {
        res['data-toggle-dropdown'] = ''
      }
      return res
    },

    dueDateDataAttrs() {
      const res = {}
      if (this.userCanChangeTask) res['data-toggle-dropdown'] = ''
      return res
    },

    createdOn() {
      if (!this.created) return ''

      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },

    dueDateStr() {
      if (!this.dueDate_ || Number.isNaN(this.dueDate_.getTime())) return ''

      return formatDateTime(this.dueDate_)
    },

    sortedTaskStatuses() {
      return Object.entries(this.taskStatuses)
                   .map(([id, name]) => [Number(id), name])
                   .sort((a1, a2) => a1[1].localeCompare(a2[1]))
    },

    taskStatusName() {
      return this.taskStatuses[this._statusId]
    },

    taskStatusBtnCSSClass() {
      return `btn-${taskStatusCSSClass(this.taskStatusName)}-glassy`
    },

    taskCategoryOptions() {
      return Object.entries(this.taskCategories)
                   .map(([id, name]) => [Number(id), name])
                   .sort((c1, c2) => c1[1].localeCompare(c2[1]))
    },

    parentCandidates() {
      return this.parentTaskCandidates.filter(task => task.synced_to_wrike === this._syncedToWrike)
    },

    authorNames() {
      return ' ' + [...this._authors].map(id => {
        return this.users[id].split(' ', 2).map((n, i) => [i ? `${n[0]}.` : n]).join(' ')
      }).sort().join(', ')
    },

    responsibleNames() {
      return ' ' + [...this._responsibles].map(id => {
        return this.users[id].split(' ', 2).map((n, i) => [i ? `${n[0]}.` : n]).join(' ')
      }).sort().join(', ')
    },
  },

  methods: {
    resetClientOptions() {
      this.clientOptions.splice(0, this.clientOptions.length)
    },

    async fetchClients(e = undefined) {
      const q = e ? e.target.value.trim() : ''

      // if (q.length < 3) {
      //   this.resetClientOptions()
      //   return
      // }

      try {
        const resp = await axios.get(reverseUrl('clients.index'), {
          params: {
            q,
          },
        })

        const { clients } = resp.data
        this.clientOptions.splice(0, this.clientOptions.length, ...clients)
      } catch(err) {
        console.error(`Unable to load client suggestions matching '${q}' due to: ${err}`)
      }
    },

    async onClientChanged(id, name) {
      if (this._clientId && id === this._clientId) {
        this._clientId = 0
        this.clientName = ''
        this.resetProjectOptions()
      } else {
        this._clientId = id
        this.clientName = name

        try {
          const resp = await axios.get(reverseUrl('projects.index'), {
            params: {
              client_id: this._clientId,
            },
          })

          const { projects } = resp.data
          this.projectOptions.splice(0, this.projectOptions.length, ...projects)
        } catch(err) {
          console.error(`Unable to load project suggestions for client with ID: '${id}' due to: ${err}`)
        }
      }

      await this.onProjectChanged(0, '')
      this.$refs.projectName.value = ''
      this.projectRegex = null
    },

    resetProjectOptions() {
      this.projectOptions.splice(0, this.projectOptions.length)
    },

    async onTitleChanged(_e) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          title: this._title,
        })
      } catch(err) {
        alert(`Unable to change task title due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    async onDescriptionChanged(_e, _editor) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          description: this._description.trim(),
        })
      } catch(err) {
        let errMsg = 'Unable to change task description due to:'
        errMsg += `\n${stringifyValidationErrors(err)}`
        alert(errMsg)
      }
    },

    async onProjectChanged(id, name) {
      if (this._projectId && id === this._projectId) {
        this._projectId = 0
        this.projectName = ''
        if (!this.id) this._syncedToWrike = false
      } else {
        this._projectId = id
        this.projectName = name
        if (!this.id) {
          this._syncedToWrike = this.projectOptions.find(po => po.id === id)?.synced_to_wrike || false
        }
      }

      this._parentId = 0
      this.parentTitle = ''
      this.$inertia.reload({
        data: {
          project_id: id,
        },
        only: ['parent_task_candidates'],
      })

      if (!this.id || !this._projectId) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          project_id: this._projectId,
        })
      } catch(err) {
        let errMsg = `Unable to move task to project '${projectName}' due to:`
        errMsg += `\n${stringifyValidationErrors(err)}`
        alert(errMsg)
      }
    },

    filterProjects(e) {
      const projectQuery = e.target.value.trim()
      if (!projectQuery) {
        this.projectRegex = null
        return
      }

      this.projectRegex = new RegExp(projectQuery, 'i')
    },

    async onStatusChanged(newStatusId) {
      this._statusId = newStatusId
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          status_id: this._statusId || null,
        })
      } catch(err) {
        alert(`Unable to change task status due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    filterCategories(e) {
      const categoryQuery = e.target.value.trim()
      if (!categoryQuery) {
        this.categoryRegex = null
        return
      }

      this.categoryRegex = new RegExp(categoryQuery, 'i')
    },

    async onCategoryChanged(id, name) {
      if (this._categoryId && id === this._categoryId) {
        this._categoryId = 0
        this.categoryName = ''
      } else {
        this._categoryId = id
        this.categoryName = name
      }
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          category_id: this._categoryId || null,
        })
      } catch(err) {
        alert(`Unable to change task category due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    filterAuthors(e) {
      const authorQuery = e.target.value.trim()
      if (!authorQuery) {
        this.authorRegex = null
        return
      }

      this.authorRegex = new RegExp(authorQuery, 'i')
    },

    async onAuthorToggled(id) {
      if (this._authors.has(id)) {
        this._authors.delete(id)
      } else {
        this._authors.add(id)
      }
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          author_ids: [...this._authors],
        })
      } catch(err) {
        alert(`Unable to change task authors due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    filterResponsibles(e) {
      const responsibleQuery = e.target.value.trim()
      if (!responsibleQuery) {
        this.responsibleRegex = null
        return
      }

      this.responsibleRegex = new RegExp(responsibleQuery, 'i')
    },

    async onResponsibleToggled(id) {
      if (this._responsibles.has(id)) {
        this._responsibles.delete(id)
      } else {
        this._responsibles.add(id)
      }
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          responsible_ids: [...this._responsibles],
        })
      } catch(err) {
        alert(`Unable to change task assignees due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    filterTasks(e) {
      const parentQuery = e.target.value.trim()
      if (!parentQuery) {
        this.parentRegex = null
        return
      }

      this.parentRegex = new RegExp(parentQuery, 'i')
    },

    async onParentToogled(id, title) {
      if (this._parentId && id === this._parentId) {
        this._parentId = 0
        this.parentTitle = ''
      } else {
        this._parentId = id
        this.parentTitle = title
      }
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          parent_id: this._parentId || null,
        })
      } catch(err) {
        alert(`Unable to change task parent due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    async onBillableChanged(_e) {
      if (!this.id || !this.nonBillableTasksEnabled) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          billable: this._billable,
        })
      } catch(err) {
        let errMsg = `Unable to mark task as ${this._billable ? '' : 'non-'}billable due to:`
        errMsg += `\n${stringifyValidationErrors(err)}`
        alert(errMsg)
      }
    },

    async onSyncedOnWrikeChanged(_e) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('tasks.update', { id: this.id }), {
          synced_to_wrike: this._syncedToWrike,
        })
      } catch(err) {
        let errMsg = 'Unable to change task Wrike sync pref due to:'
        errMsg += `\n${stringifyValidationErrors(err)}`
        alert(errMsg)
      }
    },

    onDueOnChanged() {
      const dueDate = parseISODate(this.dueOn)
      if (Number.isNaN(dueDate.getTime())) {
        this.dueDate_ = null
        this.dueAt = ''
        return
      }

      if (this.dueAt) {
        const [hrs, mins] = this.dueAt.split(':', 2).map(Number)
        dueDate.setHours(hrs, mins)
      }
      this.dueDate_ = dueDate
    },

    onDueAtChanged() {
      if (!this.dueOn) return

      const dueDate = parseISODate(this.dueOn)
      const [hrs, mins] = this.dueAt.split(':', 2).map(Number)
      dueDate.setHours(hrs, mins)
      this.dueDate_ = dueDate
    },

    async saveNewTask() {
      if (!this._projectId) {
        alert('Please select a project to proceed')
        return
      }

      if (!this._authors.size) {
        alert('Please select at least one author')
        return
      }

      const data = {
        title: this._title,
        description: this._description,
        synced_to_wrike: this._syncedToWrike,
        project_id: this._projectId,
      }
      if (this.nonBillableTasksEnabled) data.billable = this._billable
      if (this._statusId) data.status_id = this._statusId
      if (this._categoryId) data.category_id = this._categoryId
      if (this._authors) data.author_ids = [...this._authors]
      if (this._responsibles) data.responsible_ids = [...this._responsibles]
      if (this._parentId) data.parent_id = this._parentId
      if (this.dueDate_) data.due_date = this.dueDate_.getTime() / 1_000

      try {
        const resp = await axios.post(reverseUrl('tasks.create'), data)

        const { id } = resp.data
        this.$inertia.get(reverseUrl('admin.tasks.edit', { id }))
      } catch(err) {
        alert(`Unable to save new task due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    taskStatusBulletCSSClass(taskStatusId) {
      const taskStatusName = this.taskStatuses[taskStatusId]
      return `color-${taskStatusCSSClass(taskStatusName)}`
    },
  },

  components: {
    Link,
    Editor,
    ModelDelConfirmModal,
  },
}
</script>

<template>
  <!-- .@NOTE
    classes to add
      .module

        when timer is in default state/stopped => `background-gradient-90-from-secondary-to-primary`
        when timer is running => `background-gradient-90-from-secondary-to-success`
  -->
  <form id="timetracker-timer"
        class="module no-border module-expanded theme-inverse margin-large-y" :class="formCssClass"
        @submit.prevent="toggleTimer" v-if="onTimePage">

    <div class="module-content no-padding">
      <div class="flex-grid flex-grid-fixed flex-grid-no-gutter flex-direction-row-reverse">
        <div class="flex-col-xs-12 flex-col-md-8  flex-col-lg-9 flex-md  flex-direction-column">
          <div class="flex-grid flex-grid-no-gutter">
            <div class="flex-col-xs-12 flex-col-md-4 flex-0-0">
              <div class="padding-y padding-large-x border-style-solid-bottom border-width-thin-bottom border-color-background-alpha-2 h4 color-inherit no-margin">
                <label for="timer-project-name" class="sr-only">Project</label>
                <input class="input input-blend input-block no-padding" id="timer-project-name"
                       placeholder="Enter project name" list="timer-project-options"
                       autocomplete="off" :disabled="!!timerStartedAt || $impersonatingUser.value"
                       tabindex="1"
                       ref="projectName" v-model="projectName"
                       @input="debouncedAutocompleteProjectName" @change="setProjectId" required>

                <datalist id="timer-project-options" ref="projectOptions">
                  <option :value="project.name"
                          v-for="project in projectOptions" :key="project.id" />
                </datalist>
              </div>
            </div>

            <div class="flex-child flex-0-0 hide-mobile spacer border-style-solid-right border-width-thin-right border-color-background-alpha-2"></div>

            <div class="flex-child flex-1-1">
              <div class="padding-y padding-large-x border-style-solid-bottom border-width-thin-bottom border-color-background-alpha-2 h4 font-weight-400 color-inherit no-margin">

                <label for="timer-client-name" class="sr-only">Client</label>

                <input class="input input-blend input-block no-padding" id="timer-client-name"
                       placeholder="Enter client name" list="timer-client-options" autocomplete="off"
                       :disabled="!!timerStartedAt || $impersonatingUser.value" tabindex="2"
                       v-model="clientName" @change="setClientId" required>

                <datalist id="timer-client-options" ref="clientOptions">
                  <option :value="client.name"
                          v-for="client in clientOptions" :key="client.id" />
                </datalist>
              </div>
            </div>

            <div class="flex-col-xs-12 flex-col-lg-2 flex-0-0 align-self-stretch flex-xs align-items-stretch">
              <label class="padding-right border-style-solid-bottom border-width-thin-bottom border-color-background-alpha-2 flex-xs align-items-center justify-content-flex-end padding-y padding-right flex-1-0">
                <span class="font-weight-700">Sync to Wrike</span>
                <input type="checkbox" class="margin-small-left font-size-large input input-inline"
                       style="width: 1em" v-model="syncedToWrike">
              </label>
            </div>
          </div>

          <div class="padding-large flex-1-1 flex-md flex-direction-column">
            <div class="h3 no-margin-y">
                <label for="timer-task-title" class="sr-only">Task title</label>
                <input class="input input-blend input-block no-padding" id="timer-task-title"
                      placeholder="Click here to enter task title" list="timer-task-options"
                      autocomplete="off" :disabled="!!timerStartedAt || $impersonatingUser.value"
                      tabindex="3" v-model="taskTitle"
                      @input="debouncedAutocompleteTaskTitle" @change="setTaskIdOrTitle" required>

                <datalist id="timer-task-options">
                  <option :value="task.title"
                          v-for="task in taskOptions" :key="task.id" />
                </datalist>
              </div>
              <div class="h3 font-weight-400 no-margin-bottom margin-small-top flex-1-1 flex-md  flex-direction-column">
              <label for="timer-comment" class="sr-only">Timelog comment</label>
              <textarea id="timer-comment" rows="4"
                        class="input input-blend input-block no-border-bottom flex-1-1"
                        :disabled="$impersonatingUser.value"
                        placeholder="Add your time entry comment here." tabindex="6"
                        v-model.trim="comment" @input="debouncedOnCommentChanged"
                        @focus="commentHasFocus = true" @blur="commentHasFocus = false"></textarea>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-md-4 flex-col-lg-3 background-background-alpha-2 flex-md">
          <div class="padding-small-y">
            <div class="padding-large-y text-align-center flex-1-1 flex-md flex-direction-column-reverse align-items-center justify-content-center">
              <div class="padding-large-x">
                <div class="flex-grid flex-grid-compact flex-grid-no-gutter-y flex-grid-fixed justify-content-center">
                  <div class="flex-col-xs-12 flex-col-sm-5 flex-col-md-12 flex-col-lg-10">
                    <label for="timer-task-category" class="sr-only">Task Category</label>

                    <select id="timer-task-category"
                            class="input input-single-line input-select input-block background-transparent border-color-theme input-round margin-small-bottom"
                            :disabled="$impersonatingUser.value" tabindex="4"
                            v-model.number="taskCategoryId">

                      <option :value="0">Select task category</option>
                      <option :value="id" v-for="[id, name] in taskCategoryOptions" :key="id">
                        {{ name }}
                      </option>
                    </select>
                  </div>

                  <div class="flex-col-xs-12 flex-col-sm-5 flex-col-md-12 flex-col-lg-10">
                    <label class="sr-only">Task status</label>

                    <div class="position-relative">
                      <a href="#"
                         class="input input-single-line input-select input-block background-transparent border-color-theme input-round text-decoration-none justify-content-space-between"
                         data-toggle-dropdown>

                        <div class="text-wrap-ellipsis">
                          {{ taskStatusName || 'Select task status' }}
                        </div>
                      </a>

                      <div class="dropdown dropdown-top-flush dropdown-left theme-default color-theme no-padding-x overflow-visible"
                           data-dropdown-width="100%">

                        <div class="list-group list-group-interactive list-group-compact">
                          <label class="list-group-item pointer-reference no-border-y flex-xs justify-content-space-between flex-wrap align-items-center dropdown-purger"
                                 :class="{ active: taskStatusId === id }"
                                 @click="taskStatusName = name"
                                 v-for="(id, name) in taskStatusOptions[defaultWrikeWorkflow]" :key="id"
                                 v-if="defaultWrikeWorkflow">

                            <input type="radio" name="timer-task-status" class="sr-only">
                            <span class="flex-1-1">{{ name }}</span>
                            <i class="symbol symbol-check only-toggle flex-0-0"></i>
                          </label>

                          <div class="position-relative"
                               v-for="[wrikeWf, statusIdByName] in taskStatusOptionsWithoutDefaultWf"
                               :key="wrikeWf">

                            <a href="#"
                               class="list-group-item pointer-reference no-border-bottom flex-xs justify-content-space-between flex-wrap align-items-center"
                               data-toggle-dropdown>

                              <span class="flex-1-1">{{ wrikeWf }}</span>
                              <i class="symbol symbol-arrow-right"></i>
                            </a>

                            <div class="dropdown dropdown-bottom dropdown-left-flush theme-default color-theme no-padding-x overflow-visible"
                                 data-dropdown-width="100%">

                              <div class="list-group list-group-interactive list-group-compact">
                                <div class="position-relative">
                                  <label class="list-group-item pointer-reference no-border-y flex-xs justify-content-space-between flex-wrap align-items-center dropdown-purger"
                                         :class="{ active: taskStatusId === id }"
                                         @click="taskStatusName = name"
                                         v-for="(id, name) in statusIdByName" :key="id">

                                    <input type="radio" name="timer-task-status" class="sr-only">
                                    <span class="flex-1-1">{{ name }}</span>
                                    <i class="symbol symbol-check only-toggle flex-0-0"></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="special-primary text-leading-whole inline-flex margin-large-y">
                <div>{{ hours }}</div>:<div>{{ minutes }}</div>:<div>{{ seconds }}</div>
              </div>

              <div class="margin-small-top">
                <button class="timetracker-timer-toggle btn btn-round btn-symbol color-theme color-theme-polar-hover color-theme-polar-focus border-color-background background-background-alpha-2 background-background-focus background-background-hover background-theme-polar-active color-accent-alpha-8-active"
                        :class="{ active: !!timerStartedAt }"
                        :disabled="!timelogFormComplete || $impersonatingUser.value || timerWasRecentlyPaused"
                        tabindex="7">

                  <i class="symbol symbol-play symbol-pause-toggle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <RougeTimerModal :clientName="clientName" :projectName="projectName"
                   :taskTitle="taskTitle" :comment="comment"
                   :timerStartedAt="timerStartedAt" :timelogId="timelogId"
                   :modalOpen="rougeTimerModalOpen"
                   @timelog:added="toggleTimer(undefined, { timelog: $event })"
                   @after_destroy_fw_modal="rougeTimerModalOpen = false" />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'
import ReconnectingEventSource from 'reconnecting-eventsource'

import RougeTimerModal from './RougeTimerModal'
import { parseISODate, parseUnixTimestamp, utcOffset } from '../lib/date'
import { stringifyValidationErrors  } from '../lib/string'

export default {
  name: 'Timer',

  props: {
    clients: {
      type: Array,
      required: true
    },
    taskCategoryOptions: {
      type: Array,
      required: true
    },
    taskStatusOptions: {
      type: Object,
      required: true
    },
    active_timelog: {
      type: Object,
    },
  },

  data() {
    let clientId = 0, clientName = ''
    let projectId = 0, projectName = ''
    let taskId = 0, taskTitle = ''
    let taskCategoryId = 0, taskCategoryName = ''
    let taskStatusId = 0, taskStatusName = ''
    let timelogId = 0, comment = '', syncedToWrike = false
    let timerStartedAt = null

    if (this.active_timelog) {
      clientId = this.active_timelog.task.project.client.id
      clientName = this.active_timelog.task.project.client.name
      projectId = this.active_timelog.task.project.id
      projectName = this.active_timelog.task.project.name
      taskId = this.active_timelog.task.id
      taskTitle = this.active_timelog.task.title
      if (this.active_timelog.task.category) {
        taskCategoryId = this.active_timelog.task.category.id
        taskCategoryName = this.active_timelog.task.category.name
      }
      if (this.active_timelog.task.status) {
        taskStatusId = this.active_timelog.task.status.id
        taskStatusName = this.active_timelog.task.status.name
      }
      timelogId = this.active_timelog.id
      comment = this.active_timelog.comment
      syncedToWrike = this.active_timelog.synced_to_wrike
      timerStartedAt = new Date(this.active_timelog.timespan.lower * 1_000)
    }

    return {
      clientOptions: [],
      clientId,
      clientName,
      projectOptions: [],
      projectId,
      projectName,
      taskOptions: [],
      taskId,
      taskTitle,
      _taskCategoryName: taskCategoryName,
      taskCategoryId,
      _taskStatusName: taskStatusName,
      taskStatusId,
      timelogId,
      comment,
      commentHasFocus: false,
      syncedToWrike,
      timerStartedAt,
      timerPausedAt: null,
      intervalId: null,
      eventSource: null,
      hours: '00',
      minutes: '00',
      seconds: '00',
      rougeTimerModalOpen: false,
    }
  },

  async created() {
    this.$watch(
      () => this.clients.length,
      (_length) => {
        this.clientOptions.splice(0, this.clientOptions.length, ...this.clients)
      }
    )

    if (this.active_timelog) {
      this.$nextTick(() => {
        this.persistTimerState()
        this.startTimer()
      })
    }

    // this.debouncedAutocompleteClientName = _.debounce(this.autocompleteClientName,
    //                                                   this.$page.props.app_config.autocomplete_debounce_min)
    this.debouncedAutocompleteProjectName = _.debounce(this.autocompleteProjectName,
                                                       this.$page.props.app_config.autocomplete_debounce_min)
    this.debouncedAutocompleteTaskTitle = _.debounce(this.autocompleteTaskTitle,
                                                     this.$page.props.app_config.autocomplete_debounce_min)
    this.debouncedOnCommentChanged = _.debounce(this.onCommentChanged,
                                                this.$page.props.app_config.input_on_change_min_delay)
  },

  mounted() {
    if (localStorage.getItem('timer') && !this.active_timelog) {
      localStorage.removeItem('timer')
      // location.reload()
      // return
    }

    /*if (!this.$isMobile && this.onTimePage) {
      this.$refs.projectName.focus()
    }*/

    this.$emitter.on('start:timer', this.onStartTimer)
    this.$emitter.on('stop:timer', this.onStopTimer)
    this.$emitter.on('timelog:changed', this.onTimelogChanged)

    if (this.timelogId) this.connectEventSource()
  },

  beforeUnmount() {
    if (this.timelogId) this.disconnectEventSource()

    this.debouncedOnCommentChanged.cancel()
    this.debouncedAutocompleteTaskTitle.cancel()
    this.debouncedAutocompleteProjectName.cancel()
    // this.debouncedAutocompleteClientName.cancel()
  },

  unmounted() {
    this.$emitter.off('stop:timer', this.onStopTimer)
    this.$emitter.off('start:timer', this.onStartTimer)
    this.$emitter.off('timelog:changed', this.onTimelogChanged)

    if (!this.intervalId) return

    clearInterval(this.intervalId)
    this.intervalId = null

    this.persistTimerState()
  },

  computed: {
    defaultWrikeWorkflow() {
      if (this.taskStatusName) {
        for(const [wrikeWf, statusIdByName] of Object.entries(this.taskStatusOptions)) {
          if (this.taskStatusName in statusIdByName) return wrikeWf
        }
      }

      if (this.$page.props.current_user?.job?.task_status_group) {
        return this.$page.props.current_user.job.task_status_group.name
      } else {
        return this.$page.props.app_settings.wrike_task_custom_stat_wf_names?.[0]
      }
    },

    taskStatusOptionsWithoutDefaultWf() {
      return Object.entries(this.taskStatusOptions).filter(([wrikeWf, statusIdByName]) => {
        return wrikeWf !== this.defaultWrikeWorkflow
      })
    },

    onTimePage() {
      return location.pathname.endsWith(reverseUrl('main.time'))
    },

    formCssClass() {
      if (this.timerStartedAt) {
        return 'background-gradient-90-from-secondary-to-success'
      } else {
        return 'background-gradient-90-from-secondary-to-primary'
      }
    },

    taskStatusName: {
      get() {
        return this._taskStatusName
      },

      set(newValue) {
        const status = newValue.trim(), st = status.toLowerCase()
        let entry
        for (const [_, statusIdByName] of Object.entries(this.taskStatusOptions)) {
          entry = Object.entries(statusIdByName).find(([s, _]) => s.toLowerCase() === st)
          if (entry) break
        }

        if (entry) {
          this._taskStatusName = entry[0]
          this.taskStatusId = Number(entry[1])
        } else {
          this._taskStatusName = ''
          this.taskStatusId = 0
        }
      },
    },

    taskCategoryName: {
      get() {
        return this._taskCategoryName
      },

      set(newValue) {
        const category = newValue.trim(), cat = category.toLowerCase()
        const entry = this.taskCategoryOptions.find(([_, c]) => c.toLowerCase() === cat)

        if (entry) {
          this.taskCategoryId = Number(entry[0])
          this._taskCategoryName = entry[1]
        } else {
          this.taskCategoryId = 0
          this._taskCategoryName = ''
        }
      },
    },

    timerWasRecentlyPaused() {
      if (!this.timerPausedAt) return false

      return new Date().getTime() - this.timerPausedAt.getTime() < this.$page.props.app_config.timer_state_reset_delay_millis
    },

    timelogFormComplete() {
      return this.projectId && (this.taskId || (!this.$onlyWorksOnFixedTasks.value && this.taskTitle))
    },

    maxNumSecsWorkedPerTimelog() {
      return this.$page.props.app_settings.max_num_hours_worked_per_timelog * 3_600
    },
  },

  methods: {
    /*async autocompleteClientName(event) {
      const q = event.target.value

      const params = new URLSearchParams()
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) {
        params.set('q', q)
      }
      const resp = await axios.get(`${this.$route('clients.index')}?${params}`)
      const { clients } = resp.data
      this.clientOptions.splice(0, this.clientOptions.length, ...clients)
    },*/

    // clearClientOptions() {
    //   this.clientOptions.splice(0, this.clientOptions.length)
    //   this.clearProjectOptions()
    // },

    clearTaskOptions() {
      this.taskOptions.splice(0, this.taskOptions.length)
    },

    clearProjectOptions() {
      this.projectOptions.splice(0, this.projectOptions.length)
      this.clearTaskOptions()
    },

    async setClientId(event) {
      const clientName = event.target.value.trim().toLowerCase()
      const client = this.clientOptions.find(c => c.name.toLowerCase() === clientName)
      this.clientId = client?.id || 0
      if (!this.clientId) {
        setTimeout(() => {
          this.clientName = ''

          this.projectId = 0
          this.projectName = ''
          this.taskId = 0
          this.taskTitle = ''
          this.taskCategoryName = ''
          this.taskStatusName = ''
          this.comment = ''
        },
        this.$page.props.app_config.autocomplete_field_reset_delay)
      }

      this.clearProjectOptions()
      await this.autocompleteProjectName()
    },

    async autocompleteProjectName(event) {
      if (!this.clientId) this.clearProjectOptions()

      const q = event?.target?.value || ''
      const params = new URLSearchParams()
      if (this.clientId) params.set('client_id', this.clientId)
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) {
        params.set('q', q)
      }
      const resp = await axios.get(`${this.$route('projects.index')}?${params}`)
      const { projects } = resp.data
      const dupeProjects = Object.values(Object.entries(Object.groupBy(projects, p => p.name)).flatMap(([_, arr]) => arr.length > 1 ? arr : []))
      for (const dupeProj of dupeProjects) {
        const idx = projects.findIndex(proj => proj.id === dupeProj.id)
        if (idx === -1) continue

        const replaceProj = Object.assign(dupeProj, { name: `${dupeProj.name} [${dupeProj.id}]` })
        projects.splice(idx, 1, replaceProj)
      }
      this.projectOptions.splice(0, this.projectOptions.length, ...projects)
    },

    async setProjectId(event) {
      const projectName = event.target.value.trim().toLowerCase(),
        match = / \[(\d+)\]$/.exec(projectName)
      let project
      if(match) {
        const projId = Number(match[1])
        project = this.projectOptions.find(p => p.id === projId)
      } else {
        project = this.projectOptions.find(p => p.name.toLowerCase() === projectName)
      }
      this.projectId = project?.id || 0
      if (!this.projectId) {
        setTimeout(() => {
          this.projectName = ''

          this._taskTitle = ''
          this.taskId = 0
          this.taskTitle = ''
          this.taskCategoryName = ''
          this.taskStatusName = ''
          this.comment = ''
        },
        this.$page.props.app_config.autocomplete_field_reset_delay)
      } else if (!this.clientId) {
        let client = this.clientOptions.find(c => c.id === project.client_id)
        if (!client) {
          try {
            const resp = await axios.get(reverseUrl('clients.show', { id: project.client_id }))
            client = resp.data.client
            this.clientOptions.push(client)
          } catch {
            console.error(`Client #${project.client_id} not found!`)
          }
        }

        if (client) {
          this.clientId = client.id
          this.clientName = client.name
        }
      }

      this.clearTaskOptions()
      await this.autocompleteTaskTitle()
    },

    async autocompleteTaskTitle(event) {
      if (!this.projectId) {
        this.clearTaskOptions()
        return
      }

      const q = event?.target?.value || ''
      const params = new URLSearchParams()
      params.set('project', this.projectId)
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) params.set('q', q)
      if (!this.$onlyWorksOnFixedTasks.value) params.set('include_tasks_not_assigned_to_user', 1)
      const resp = await axios.get(`${this.$route('tasks.index')}?${params}`)
      const { tasks } = resp.data
      this.taskOptions.splice(0, this.taskOptions.length, ...tasks)
    },

    async setTaskIdOrTitle(event) {
      const title = event.target.value.trim(), tit = title.toLowerCase()
      const task = this.taskOptions.find(t => t.title.toLowerCase() === tit)
      this.taskId = task?.id || 0

      if (this.taskId) {
        const resp = await axios.get(this.$route('tasks.show', { id: this.taskId }))
        const { task } = resp.data
        this.taskCategoryName = task.category?.name || ''
        this.taskStatusName = task.status?.name || ''
        this.syncedToWrike = task.synced_to_wrike
      } else {
        this.syncedToWrike = false
        this.taskCategoryName = ''
        this.taskStatusName = ''
      }

      if (this.$onlyWorksOnFixedTasks.value) {
        this.taskTitle = task?.title || ''
        // if (!this.taskId) {
        //   setTimeout(() => {
        //     this.taskTitle = ''
        //     event.target.value = ''
        //   },
        //   this.$page.props.app_config.autocomplete_field_reset_delay)
        // }
      } else {
        this.taskTitle = task?.title || title
      }

      if (!this.taskTitle) {
        this.taskCategoryName = ''
        this.taskStatusName = ''
        this.comment = ''
      }
    },

    async onCommentChanged() {
      if (!this.timelogId) return

      try {
        await axios.patch(this.$route('timelogs.update', { id: this.timelogId }), {
          comment: this.comment,
        })
      } catch(err) {
        alert(`Unable to sync timelog comment to server due to:\n${stringifyValidationErrors(err)}`)
        return
      }

      this.persistTimerState()
    },

    resetTimer(elapsedSecs = 0) {
      this.hours = String(Math.floor(elapsedSecs / 3_600)).padStart(2, '0')
      this.minutes = String(Math.floor((elapsedSecs % 3_600) / 60)).padStart(2, '0')
      this.seconds = String(Math.floor((elapsedSecs % 3_600) % 60)).padStart(2, '0')
    },

    resetTimerState() {
        this.clientId = 0
        this.clientName = ''
        // this.clearClientOptions()
        this.projectId = 0
        this.projectName = ''
        this.taskId = 0
        this.taskTitle = ''
        this.taskCategoryName = ''
        this.taskStatusName = ''
        this.comment = ''
        this.syncedToWrike = false
        this.timerPausedAt = null
        this.timelogId = 0

        this.resetTimer()
    },

    startTimer() {
      this.intervalId = setInterval(() => {
        const elapsedSecs = (new Date() - this.timerStartedAt) / 1_000
        this.resetTimer(elapsedSecs)

        if (elapsedSecs > this.maxNumSecsWorkedPerTimelog && !this.rougeTimerModalOpen) {
          this.rougeTimerModalOpen = true
        }
      }, 1_000)
    },

    persistTimerState() {
      localStorage.setItem('timer', JSON.stringify({
        timerStartedAt: this.timerStartedAt.toISOString(),
        clientId: Number(this.clientId),
        clientName: String(this.clientName),
        projectId: Number(this.projectId),
        projectName: String(this.projectName),
        taskId: Number(this.taskId),
        taskTitle: String(this.taskTitle),
        taskCategoryName: String(this._taskCategoryName),
        // taskCategoryId: Number(this.taskCategoryId),
        taskStatusName: String(this.taskStatusName),
        // taskStatusId: String(this.taskStatusId),
        timelogId: Number(this.timelogId),
        comment: String(this.comment),
        syncedToWrike: Boolean(this.syncedToWrike),
      }))
    },

    async toggleTimer(event = undefined, { timelog = undefined } = {}) {
      if (this.timerStartedAt) {
        if (this.intervalId) clearInterval(this.intervalId)
        this.intervalId = null
        let elapsedSecs = Math.floor((new Date() - this.timerStartedAt) / 1_000)
        this.timerStartedAt = null
        localStorage.removeItem('timer')
        this.$emitter.emit('timer:stopped', this.taskId)

        if (timelog === undefined) {
          if (elapsedSecs < 60) {
            let msg = 'Your current time entry is less than 1 minute long.'
            msg += '\nDo you want to round it up to a whole minute or discard it?'
            if (!confirm(msg)) {
              this.hours = this.minutes = this.seconds = '00'

              try {
                await axios.delete(this.$route('timelogs.destroy', { id: this.timelogId }), {
                  data: {
                    delete_on_wrike: false,
                  },
                })
              } catch(err) {
                alert(`Unable to remove timer on server due to:\n${stringifyValidationErrors(err)}`)
              }

              this.timelogId = 0
              return
            } else {
              elapsedSecs = 60
            }
          }

          let hrs = 0, mins = 0, secs = 0
          if (elapsedSecs >= 3_600) {
            hrs = Math.floor(elapsedSecs / 3_600)
            mins = Math.floor((elapsedSecs % 3_600) / 60)
            secs = Math.floor((elapsedSecs % 3_600) % 60)
          } else {
            mins = Math.floor(elapsedSecs / 60)
            secs = Math.floor(elapsedSecs % 60)
          }
          hrs = String(hrs).padStart(2, '0')
          mins = String(mins).padStart(2, '0')
          secs = String(secs).padStart(2, '0')

          const taskCategoryId = this.taskCategoryId || null,
                taskStatusId = this.taskStatusId || null

          try {
            const resp = await axios.patch(this.$route('timelogs.update', { id: this.timelogId }), {
              time: `${hrs}:${mins}:${secs}`,
              utc_offset: utcOffset(),
              comment: this.comment,
              synced_to_wrike: this.syncedToWrike,
              task_category: taskCategoryId,
              task_status: taskStatusId,
            })
            timelog = resp.data.timelog
          } catch(err) {
            alert(`Unable to stop timer on server due to:\n${stringifyValidationErrors(err)}`)
            return
          }
        } else {
          this.comment = timelog.comment
        }

        elapsedSecs = timelog.timespan.upper - timelog.timespan.lower
        this.resetTimer(elapsedSecs)
        this.timerPausedAt = parseUnixTimestamp(timelog.timespan.upper)
        this.disconnectEventSource()

        if (this.onTimePage) {
          setTimeout(() => this.resetTimerState(),
                     this.$page.props.app_config.timer_state_reset_delay_millis)
        }

        this.$emitter.emit('timelog:added', { timelog })
      } else {
        const taskId = this.taskId || null,
              taskTitle = this.taskId ? null : this.taskTitle,
              taskCategoryId = this.taskCategoryId || null,
              taskStatusId = this.taskStatusId || null

        let resp
        try {
          resp = await axios.post(this.$route('time_entries.create'), {
            time: null,
            utc_offset: utcOffset(),
            task_id: taskId,
            project_id: this.projectId,
            task_title: taskTitle,
            task_status: taskStatusId,
            task_category: taskCategoryId,
            comment: this.comment,
            synced_to_wrike: this.syncedToWrike,
          })
        } catch(err) {
          alert(`Unable to start timer on server due to:\n${stringifyValidationErrors(err)}`)
          return
        }

        const { timelog } = resp.data
        this.taskId = timelog.task.id
        this.timelogId = timelog.id
        this.timerStartedAt = new Date()
        this.persistTimerState()
        this.startTimer()
        this.connectEventSource()

        this.$emitter.emit('timer:started', this.taskId)
      }
    },

    async onStartTimer(taskId) {
      if (this.timerStartedAt) await this.toggleTimer()

      const resp = await axios.get(this.$route('tasks.show', { id: taskId }))
      const { task } = resp.data
      this.clientId = task.project.client.id
      this.clientName = task.project.client.name
      this.projectId = task.project.id
      this.projectName = task.project.name
      this.taskId = taskId
      this.taskTitle = task.title
      this.taskCategoryName = task.category?.name || ''
      this.taskStatusName = task.status?.name || ''
      this.syncedToWrike = task.synced_to_wrike

      await this.toggleTimer()
    },

    async onStopTimer(_taskId) {
      await this.toggleTimer()
    },

    onTimelogChanged({ taskId, changes }) {
      if (taskId !== this.taskId || !('comment' in changes)) return

      this.comment = changes.comment
    },

    connectEventSource() {
      const url = reverseUrl('timelogs.event_source', { id: this.timelogId })
      this.eventSource = new ReconnectingEventSource(url)
      this.eventSource.addEventListener('message', this.onActiveTlStatusUpdReceived);
      // console.log('[', Date.now(), '] EVT SRC: CONNECTED')
    },

    disconnectEventSource() {
      if (!this.eventSource) return

      this.eventSource.removeEventListener('message', this.onActiveTlStatusUpdReceived)
      this.eventSource.close()
      this.eventSource = null
      // console.log('[', Date.now(), '] EVT SRC: DISCONNECTED')
    },

    async onActiveTlStatusUpdReceived(event) {
      const { timespan: { upper }, comment } = JSON.parse(event.data)
      // console.log('[', Date.now(), '] UPPER:', upper)
      if (!this.commentHasFocus) this.comment = comment
      if (upper === null) return

      this.disconnectEventSource()

      if (this.intervalId) clearInterval(this.intervalId)
      this.intervalId = null
      this.timerStartedAt = null
      localStorage.removeItem('timer')
      this.$emitter.emit('timer:stopped', this.taskId)

      if (parseISODate(upper).getFullYear() === 1970) {
        this.resetTimerState()
        // this.resetTimer()
        // this.timelogId = 0
        return
      }

      let resp
      try {
        resp = await axios.get(this.$route('timelogs.show', { id: this.timelogId }))
      } catch(err) {
        alert(`Unable to fetch stopped timelog from server due to:\n${stringifyValidationErrors(err)}`)
        return
      }

      const { timelog } = resp.data
      this.resetTimerState()
      // const elapsedSecs = timelog.timespan.upper - timelog.timespan.lower
      // this.resetTimer(elapsedSecs)

      this.$emitter.emit('timelog:added', { timelog })
    },
  },

  components: {
    RougeTimerModal,
  },
}
</script>

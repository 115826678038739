<template>
  <Layout>
    <Timer :clients="clients" :active_timelog="active_timelog"
           :taskCategoryOptions="taskCategoryOptions"
           :taskStatusOptions="task_statuses" />

    <template v-if="onTimePage">
      <WeeklyOverview :thisWeeksTotals="this_weeks_totals" />
    </template>

    <TimelogFilters :clients="clients" :taskStatusOptions="task_statuses"
                    :taskCategoryOptions="taskCategoryOptions"
                    :dateFrom="date_from" :dateTo="date_to"
                    @filter:timelogs="filterTimelogs" />

    <DateTasks :date="date" :tasks="tasks" :taskStatusOptions="task_statuses"
               @open:taskStatusModal="openTaskStatusModal"
               @timelog:changed="changeTimelog($event)"
               @timelog:deleted="deleteTimelog($event)"
               v-for="[date, tasks] in tasksByDate" :key="date" v-if="onTimePage" />

    <StatusTasks :status="status" :tasks="tasks" :taskStatusOptions="task_statuses"
                 v-for="(tasks, status) in tasks_by_status" :key="status"
                 @open:taskStatusModal="openTaskStatusModal"
                 @taskStatus:changed="$inertia.reload({ only: ['tasks_by_status'] })"
                 v-else />

    <TaskStatusModal v-bind="task" :options="task_statuses" :modalOpen="taskStatusModalOpen"
                     @after_destroy_fw_modal="resetTaskStatusModal" />
  </Layout>
</template>

<script>
import axios from 'axios'
import { startOfWeek, endOfWeek } from 'date-fns'
import { TZDateMini } from '@date-fns/tz';

import Layout from '../Layout'
import Timer from '../components/Timer'
import WeeklyOverview from '../components/WeeklyOverview'
import TimelogFilters from '../components/TimelogFilters'
import DateTasks from '../components/DateTasks'
import StatusTasks from '../components/StatusTasks'
import TaskStatusModal from '../components/TaskStatusModal'

import { formatDate, parseUnixTimestamp  } from '../lib/date'

export default {
  name: 'Time',

  props: {
    date_from: {
      type: String,
    },
    date_to: {
      type: String,
    },
    task_statuses: {
      type: Object,
      required: true
    },
    task_categories: {
      type: Object,
      required: true
    },
    this_weeks_totals: {
      type: Object,
    },
    tasks_by_date: {
      type: Object,
    },
    tasks_by_status: {
      type: Object,
    },
    active_timelog: {
      type: Object,
    },
  },

  data() {
    return {
      clients: [],
      task: {
        id: 0,
        statusId: null,
      },
      taskStatusModalOpen: false,
    }
  },

  async created() {
    try {
      const resp = await axios.get(this.$route('clients.index'))
      const { clients } = resp.data

      this.clients.splice(0, this.clients.length, ...clients)
    } catch {
      console.error('Unable to load client list')
    }
  },

  mounted() {
    this.$emitter.on('timelog:added', this.onTimelogAdded)
  },

  unmounted() {
    this.$emitter.off('timelog:added', this.onTimelogAdded)
  },

  computed: {
    taskCategoryOptions() {
      return Object.entries(this.task_categories).sort((c1, c2) => c1[1].localeCompare(c2[1]))
    },

    onTimePage() {
      return location.pathname.endsWith(this.$route('main.time'))
    },

    tasksByDate() {
      return Object.entries(this.tasks_by_date).sort((a1, a2) => a2[0].localeCompare(a1[0]))
    },

    tasksByDateOrStatus() {
      return this.tasks_by_date || this.tasks_by_status
    },

    now() {
      return new TZDateMini(Date.now(), this.$timeZone.value)
    },

    today() {
      return formatDate(this.now, { format: 'ISO' })
    },

    beginningOfTheWeek() {
      return startOfWeek(this.now)
    },

    endOfTheWeek() {
      return endOfWeek(this.now)
    },
  },

  methods: {
    happenedThisWeek(unixTime) {
      const date = parseUnixTimestamp(unixTime)
      return date >= this.beginningOfTheWeek && date <= this.endOfTheWeek
    },

    filterTimelogs({ filters, onBefore, onFinish }) {
      this.$inertia.get(location.pathname, filters, {
        onBefore,
        onFinish: visit => {
          onFinish()
          this.$emitter.emit('timelogs:filtered', this.taskId)
        },
      })
    },

    openTaskStatusModal(task) {
      Object.assign(this.task, task)
      this.taskStatusModalOpen = true
    },

    resetTaskStatusModal(task) {
      this.task.id = 0
      this.task.statusId = null
      this.taskStatusModalOpen = false
    },

    changeTimelog({ changes }) {
      if (!('timespan' in changes) || !this.happenedThisWeek(changes.timespan.lower)) return

      this.$inertia.reload({ only: ['this_weeks_totals'] })
    },

    deleteTimelog({ timelog, date }) {
      if (!(date in this.this_weeks_totals)) return

      this.this_weeks_totals[date] -= timelog.timespan.upper - timelog.timespan.lower
    },

    onTimelogAdded({ timelog }) {
      if (!this.tasks_by_date) return

      const date = formatDate(new Date(timelog.timespan.lower * 1_000), {
        format: 'ISO',
        timeZone: this.$timeZone,
      })
      this.this_weeks_totals[date] += timelog.timespan.upper - timelog.timespan.lower
    },
  },

  components: {
    Layout,
    Timer,
    WeeklyOverview,
    TimelogFilters,
    DateTasks,
    StatusTasks,
    TaskStatusModal,
  },
}
</script>

<template>
  <div class="module-function position-relative margin-auto-right">
    <div class="input-group input-small btn-small input-block-mobile input-group-horizontal btn-group-unite">
      <div class="btn btn-default btn-no-interaction color-neutral">Per page</div>
      <select class="input input-select input-single-line"
              @change="$inertia.reload({ data: { per_page: $event.target.value } })">

        <template v-for="(pageSize, i) in pageSizes" :key="pageSize">
          <option :value="pageSize" v-if="!i || recordCount > pageSize"
                  :selected="pageSize === perPage">

            {{ pageSize }}
          </option>
        </template>
      </select>
    </div>
  </div>

  <div class="module-function">
    <div class="btn-small btn-group btn-block-mobile btn-group-horizontal">
      <a href="#" @click.prevent="visitPage(page === 1 ? 0 : page - 1)"
         class="btn btn-default btn-symbol" :class="{ 'btn-disabled': page === 1 }"
         title="Previous page">

        <i class="symbol symbol-arrow-left"></i>
      </a>

      <a href="#" @click.prevent="visitPage(page - 1)" v-if="page > 1"
         class="btn btn-default page-number background-primary-hover color-background-hover background-primary-focus color-background-focus">

         {{ formatPageNum(page - 1) }}
      </a>

      <a href="#" @click.prevent
         class="btn btn-default page-number background-primary-hover color-background-hover background-primary-focus color-background-focus color-primary btn-no-interaction">

         {{ formatPageNum(page) }}
      </a>

      <a href="#" @click.prevent="visitPage(page + 1)" v-if="page < pageCount"
         class="btn btn-default page-number background-primary-hover color-background-hover background-primary-focus color-background-focus">

         {{ formatPageNum(page + 1) }}
      </a>

      <a href="#" @click.prevent="visitPage(page === pageCount ? 0 : page + 1)"
         class="btn btn-default btn-symbol" :class="{ 'btn-disabled': page === pageCount }"
         title="Next page">

        <i class="symbol symbol-arrow-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
  name: 'Pagination',

  props: {
    recordCount: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    pageSizes: {
      type: Array,
      default: [100, 250, 500],
    },
    onPaginationLinkClicked: {
      type: Function,
    },
  },

  methods: {
    formatPageNum(pageNum) {
      return pageNum < 10 ? `0${pageNum}` : pageNum;
    },

    visitPage(page) {
      if (!page) return

      if (this.onPaginationLinkClicked) {
        this.onPaginationLinkClicked(page)
      } else {
        const params = new URLSearchParams(location.search)
        params.set('page', page)
        this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
      }
    },
  },
}
</script>

<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="h2 font-weight-300 no-margin-y">Task categories</div>
      </div>

      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <label for="category-name" class="sr-only">Category name</label>
            <input id="category-name" type="search" :value="q"
                   class="input input-single-line font-weight-600 no-border-right"
                   ref="categoryName"
                   placeholder="Category name" @change="debouncedFilterCategories">
          </div>

          <div class="module-function">
            <Link :href="$route('admin.task_categories.new')"
                  class="btn btn-theme btn-block-mobile">

              Add new +
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div class="module">
      <div class="module-content no-padding">
        <div class="table-wrapper" v-if="task_categories.length">
          <table class="timetracker-table">
            <thead>
              <tr class="background-transparent">
                <th class="background-transparent no-border-top">Task category</th>
                <th class="background-transparent no-border-top text-align-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="task_cat in task_categories" :key="task_cat.id">
                <td>
                  <Link :href="$route('admin.task_categories.edit', { id: task_cat.id })">
                    {{ task_cat.name }}
                  </Link>
                </td>

                <td class="timetracker-td-actions text-align-right">
                  <a href="#" class="btn btn-link btn-symbol" title="Delete task category"
                     @click.prevent="confirmDeletion(task_cat)">

                    <i class="symbol symbol-delete"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="font-style-italic color-neutral padding" v-else>
          There are no task categories yet.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import * as _ from 'lodash-es'

import Layout from '../Layout'

export default {
  name: 'TaskCategoryList',

  props: {
    task_categories: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
  },

  created() {
    this.debouncedFilterCategories = _.debounce(this.filterCategories,
                                                this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.categoryName.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterCategories.cancel()
  },

  methods: {
    filterCategories(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
    },

    confirmDeletion({ id, name }) {
      if (!confirm(`Are you sure you to delete the '${name}' task category?`)) return

      this.$inertia.delete(reverseUrl('admin.task_categories.destroy', { id }))
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

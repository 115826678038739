<template>
  <Layout>
    <div class="h6 color-neutral-dark font-weight-400">
      <Link :href="$route('admin.tasks.index')" class="color-inherit">
        Tasks
      </Link>

      <span class="separator">&gt;&nbsp;</span>

      <Link :href="id ? $route('admin.tasks.edit', { id }) : $route('admin.tasks.new')"
            class="color-inherit font-weight-700">

        {{ id ? title : 'New task' }}
      </Link>
    </div>

    <ul class="tabs">
      <!-- @NOTE
        li
          .classes to add:
            'active' => 'if current template matches the contained list item'
      -->

      <li :class="{ active: currentTab === 'TaskDetails' }">
        <a href="#" @click.prevent="currentTab = 'TaskDetails'">Details</a>
      </li>
      <li :class="{ active: currentTab === 'TaskTimelogs' }" v-if="id">
        <a href="#" @click.prevent="currentTab = 'TaskTimelogs'">Timelogs</a>
      </li>
    </ul>

    <KeepAlive>
      <component :is="currentTab" v-bind="currentTabProps" />
    </KeepAlive>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import Layout from '../Layout'
import TaskDetails from './TaskDetails'
import TaskTimelogs from './TaskTimelogs'

export default {
  name: 'TaskForm',

  props: {
    id: {
      type: Number,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    created: {
      type: String,
    },
    due_date: {
      type: String,
    },
    billable: {
      type: Boolean,
    },
    non_billable_tasks_enabled: {
      type: Boolean,
      required: true,
    },
    synced_to_wrike: {
      type: Boolean,
    },
    status_id: {
      type: Number,
    },
    category_id: {
      type: Number,
    },
    parent_id: {
      type: Number,
    },
    parent: {
      type: Object,
    },
    parent_task_candidates: {
      type: Array,
      required: true,
    },
    project_id: {
      type: Number,
    },
    project: {
      type: Object,
    },
    task_statuses: {
      type: Object,
      required: true,
    },
    task_categories: {
      type: Object,
      required: true,
    },
    authors: {
      type: Array,
      required: true,
    },
    responsibles: {
      type: Array,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
  },

  data() {
    const currentTab = location.hash.substring(1) === 'timelogs' ? 'TaskTimelogs' : 'TaskDetails'

    return {
      currentTab,
    }
  },

  computed: {
    currentTabProps() {
      if (this.currentTab === 'TaskDetails') {
        return {
          id: this.id,
          title: this.title,
          description: this.description,
          created: this.created,
          dueDate: this.due_date,
          billable: this.billable,
          nonBillableTasksEnabled: this.non_billable_tasks_enabled,
          syncedToWrike: this.synced_to_wrike,
          statusId: this.status_id,
          categoryId: this.category_id,
          parentId: this.parent_id,
          parentTaskCandidates: this.parent_task_candidates,
          parent: this.parent,
          projectId: this.project_id,
          project: this.project,
          taskStatuses: this.task_statuses,
          taskCategories: this.task_categories,
          authors: this.authors,
          responsibles: this.responsibles,
          users: this.users,
        }
      } else {
        return {
          id: this.id,
          title: this.title,
          created: this.created,
          users: this.users,
        }
      }
    },
  },

  watch: {
    currentTab(newTab) {
      if (newTab === 'TaskTimelogs') {
        location.hash = '#timelogs'
      } else {
        location.hash = ''
      }
    },
  },

  components: {
    Layout,
    Link,
    TaskDetails,
    TaskTimelogs,
  },
}
</script>

<template>
  <Layout>
    <div id="timetracker-heading"
         class="flex-grid justify-content-space-between align-items-center">

      <div class="flex-col-xs-12  flex-col-md-6">
        <h1 class="no-margin">Clients</h1>
      </div>

      <div class="flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="client-name" class="sr-only">Client name</label>
              <input id="client-name" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="clientName"
                     placeholder="Client name" @input="debouncedFilterClients">
            </div>
          </div>

          <div class="module-function" v-if="$page.props.current_user.is_superuser">
            <Link :href="$route('admin.clients.new')" class="btn btn-block-mobile btn-theme">
              Add new +
            </Link>
          </div>
        </div>
      </div>

      <div class="flex-col-xs-12">
        <ClientFilters :accountManagerById="account_manager_by_id" :salesRepById="sales_rep_by_id" />
      </div>
    </div>

    <div id="timetracker-clients" class="module">
      <div class="module-header">
        <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
          <Pagination :recordCount="record_count"  :page="page" :pageCount="page_count"
                      :perPage="per_page" />
        </div>
        <hr class="hide-nonmobile no-margin-bottom">
      </div>

      <div class="module-content no-padding-x">
        <div class="table-wrapper">
         <table class="timetracker-table border-style-solid-bottom border-width-thin-bottom border-color-neutral-alpha-3 text-vertical-align-middle">

          <tr class="hide-mobile">
            <th class="font-weight-700 color-theme text-align-center color-theme timetracker-td timetracker-td-w timetracker-td-w-50"
                v-if="$page.props.current_user.is_superuser">

              <label class="input-label no-padding">
                <input type="checkbox" class="input-inline" v-model="allClientsSelected">
                <span class="hide-nonmobile">Select all clients</span>
              </label>
            </th>

            <th class="font-weight-700 color-theme">
              Client
              <a href="#" @click.prevent="sortClients"
                 class="color-inherit text-decoration-none text-vertical-align-middle display-inline-flex"
                 v-html="dir === 'asc' ? ascDirSvgIcon : descDirSvgIcon">
              </a>
            </th>

            <th class="font-weight-700 color-theme text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
              Account manager
            </th>

            <th class="font-weight-700 color-theme text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
              Sales
            </th>

            <th class="font-weight-700 color-theme text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
              Project Manager
            </th>

            <th class="font-weight-700 color-theme text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
              SEO
            </th>

            <th class="font-weight-700 color-theme">Projects</th>

            <th class="no-border-bottom timetracker-td-actions text-align-center" width="50"
                v-if="$page.props.current_user.is_superuser">

              <div class="bulk-actions position-relative">
                <button class="btn no-padding font-size-large" data-modal-disable-overlay="false"
                        data-toggle-modal-default>

                  <i class="symbol symbol-kebab-horizontal"></i>
                  <span class="sr-only">More options</span>
                </button>

                <div class="modal modal-default" data-modal-width="400px">
                  <div class="text-align-center">
                    <h1 class="no-margin-top">More options</h1>
                    <ul class='list-group list-group-small no-margin-y'>
                      <li>
                        <a href="#" @click.prevent="confirmDeleteSelected">Delete selected</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </th>
          </tr>

          <template v-for="client in clients" :key="client.id">
            <ClientRow v-bind="client" :allClientsSelected="allClientsSelected"
                       :accountManagerById="account_manager_by_id" :salesRepById="sales_rep_by_id"
                       @client:selected="onClientSelectionChanged"
                       @client:deleted="onClientDeleted" />

            <tbody class="accordion" :id="`client-${client.id}-projects`"
                   data-accordion-change-hash="false" v-if="client.projects.length">

              <ClientProject v-bind="project" :clientSelected="isClientSelected(client.id)"
                             @project:selected="onProjectSelectionChanged"
                             @project:deleted="onProjectDeleted"
                             v-for="project in client.projects" :key="project.id" />
            </tbody>
          </template>
         </table>
       </div>
      </div>

      <div class="module-footer">
        <hr class="hide-nonmobile">
        <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
          <Pagination :recordCount="record_count" :page="page" :pageCount="page_count"
                      :perPage="per_page" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'
import { computed } from 'vue'

import Layout from '../Layout'
import { Link } from '@inertiajs/vue3'

import Pagination from './Pagination'
import ClientFilters from '../components/admin/ClientFilters'
import ClientRow from '../components/admin/ClientRow'
import ClientProject from '../components/admin/ClientProject'
import ascDirIcon from '../img/icon-dir-asc.svg?raw'
import descDirIcon from '../img/icon-dir-desc.svg?raw'

import { stringifyValidationErrors } from '../lib/string'

export default {
  name: 'ClientList',

  props: {
    clients: {
      type: Array,
      required: true,
    },
    account_manager_by_id: {
      type: Object,
      required: true,
    },
    sales_rep_by_id: {
      type: Object,
      required: true,
    },
    project_manager_by_id: {
      type: Object,
      required: true,
    },
    seo_by_id: {
      type: Object,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      default: 'name',
    },
    dir: {
      type: String,
      default: 'asc',
    },
    record_count: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    page_count: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      required: true,
    },
  },

  provide() {
    return {
      projectManagerById: this.project_manager_by_id,
      seoById: this.seo_by_id,
    }
  },

  data() {
    return {
      selectedClients: new Set([]),
      allClientsSelected: false,
      selectedProjects: new Set([]),
    }
  },

  created() {
    this.debouncedFilterClients = _.debounce(this.filterClients,
                                             this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.clientName.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterClients.cancel()
  },

  computed: {
    ascDirSvgIcon() {
      return ascDirIcon
    },
    descDirSvgIcon() {
      return descDirIcon
    },
  },

  methods: {
    filterClients(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      params.delete('page')

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['clients', 'q', 'page', 'page_count'],
      })
    },

    sortClients() {
      const params = new URLSearchParams(location.search)
      const dir = this.dir === 'desc' ? 'asc' : 'desc'
      params.set('dir', dir)

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['clients', 'dir'],
      })
    },

    onClientSelectionChanged({ id, checked }) {
      if (checked) {
        this.selectedClients.add(id)
      } else {
        this.selectedClients.delete(id)
      }
    },

    isClientSelected(id) {
      return computed(() => this.selectedClients.has(id))
    },

    onProjectSelectionChanged({ id, checked }) {
      if (checked) {
        this.selectedProjects.add(id)
      } else {
        this.selectedProjects.delete(id)
      }
    },

    async confirmDeleteSelected() {
      if (!this.selectedClients.size && !this.selectedProjects.size) {
        alert('Please select at least 1 client or project to proceed')
        return
      }

      let warning = 'Are you sure you want to delete'
      if (this.selectedClients.size) {
        let clientCount = 'client'
        if (this.selectedClients.size !== 1) clientCount += 's'
        warning += ` ${this.selectedClients.size} ${clientCount}`

        if (this.selectedProjects.size) warning += ' and'
      }
      if (this.selectedProjects.size) {
        let projectCount = 'project'
        if (this.selectedProjects.size !== 1) projectCount += 's'
        warning += ` ${this.selectedProjects.size} ${projectCount}`
      }
      warning += '?'
      if (!confirm(warning)) return

      const data = {}
      if (this.selectedClients.size) data.ids = [...this.selectedClients]
      if (this.selectedProjects.size) data.project_ids = [...this.selectedProjects]

      try {
        await axios.delete(reverseUrl('clients.destroy_many'), {
          data,
        })
      } catch(err) {
        alert(`Unable to delete client(s) and/or project(s) due to:\n${stringifyValidationErrors(err)}`)
        return
      }

      this.$inertia.reload({ only: ['clients'] })
    },

    onClientDeleted(id) {
      const index = this.clients.findIndex(c => c.id === id)
      if (index === -1) return

      this.clients.splice(index, 1)
    },

    onProjectDeleted({ clientId, projectId }) {
      const client = this.clients.find(c => c.id === clientId)
      if (!client) return

      const index = client.projects.findIndex(p => p.id === projectId)
      if (index === -1) return

      client.projects.splice(index, 1)
    },
  },

  components: {
    Layout,
    Link,
    Pagination,
    ClientFilters,
    ClientRow,
    ClientProject,
  },
}
</script>

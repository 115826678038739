<template>
  <Layout>
    <h1>Settings</h1>

    <div class="module">
      <div class="module-content">
        <div class="flex-grid flex-grid-fixed">
          <div class="flex-col-xs-12 flex-col-md-8">
            <form>
              <h2 class="margin-small-top">Global settings</h2>

              <div class="p" v-if="defaultClientJobsSetting">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(defaultClientJobsSetting) }}
                </label>

                <template v-for="job in defaultClientJobsSetting.value" :key="job">
                  <span class="color-inherit highlight highlight-neutral display-inline-block">
                    {{ job }}
                  </span>
                  <span>&nbsp;</span>
                </template>

                <div class="inline-flex-xs align-items-stretch position-relative">
                  <a href="#" class="font-size-large color-neutral-dark" title="Add job"
                     data-toggle-dropdown>

                    <span class="sr-only">Add job</span> <i class="symbol symbol-plus"></i>
                  </a>

                  <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                       data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                    <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                      <div class="input-group input-block input-group-horizontal">
                        <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                               placeholder="Search jobs" @input="filterClientJobs">

                        <label class="font-size-normalize btn btn-symbol btn-small btn-default">
                          <span class="sr-only">Search</span>
                          <i class="symbol symbol-search"></i>
                        </label>
                      </div>
                    </div>

                    <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active list-group-toggle-multiple">
                      <label class="list-group-item pointer-reference"
                             :class="{ active: defaultClientJobsSetting.value.includes(job) }"
                             @click.prevent="onClientJobToggled(job)"
                             v-show="!cliJobRegex || cliJobRegex.test(job)"
                             v-for="job in jobs" :key="job">

                        <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                          <span class="flex-child flex-1-1">
                            <input type="checkbox" class="sr-only">
                            <span class="text-wrap-ellipsis">{{ job }}</span>
                          </span>
                          <span class="flex-child flex-0-0">
                            <i class="symbol symbol-check only-toggle flex-0-0"></i>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${defaultClientJobsSetting.key}' setting`"
                             v-model="defaultClientJobsSetting.description"
                             @change="onSettingDescChanged(defaultClientJobsSetting)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="defaultProjectJobsSetting">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(defaultProjectJobsSetting) }}
                </label>

                <template v-for="job in defaultProjectJobsSetting.value" :key="job">
                  <span class="color-inherit highlight highlight-neutral display-inline-block">
                    {{ job }}
                  </span>
                  <span>&nbsp;</span>
                </template>

                <div class="inline-flex-xs align-items-stretch position-relative">
                  <a href="#" class="font-size-large color-neutral-dark" title="Add job"
                     data-toggle-dropdown>

                    <span class="sr-only">Add job</span> <i class="symbol symbol-plus"></i>
                  </a>

                  <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                       data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                    <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                      <div class="input-group input-block input-group-horizontal">
                        <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                               placeholder="Search jobs" @input="filterProjectJobs">

                        <label class="font-size-normalize btn btn-symbol btn-small btn-default">
                          <span class="sr-only">Search</span>
                          <i class="symbol symbol-search"></i>
                        </label>
                      </div>
                    </div>

                    <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active list-group-toggle-multiple">
                      <label class="list-group-item pointer-reference"
                             :class="{ active: defaultProjectJobsSetting.value.includes(job) }"
                             @click.prevent="onProjectJobToggled(job)"
                             v-show="!projJobRegex || projJobRegex.test(job)"
                             v-for="job in jobs" :key="job">

                        <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                          <span class="flex-child flex-1-1">
                            <input type="checkbox" class="sr-only">
                            <span class="text-wrap-ellipsis">{{ job }}</span>
                          </span>
                          <span class="flex-child flex-0-0">
                            <i class="symbol symbol-check only-toggle flex-0-0"></i>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${defaultProjectJobsSetting.key}' setting`"
                             v-model="defaultProjectJobsSetting.description"
                             @change="onSettingDescChanged(defaultProjectJobsSetting)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="groupsForUsersWithFixedTasks">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(groupsForUsersWithFixedTasks) }}
                </label>

                <template v-for="group in groupsForUsersWithFixedTasks.value" :key="group">
                  <span class="color-inherit highlight highlight-neutral display-inline-block">
                    {{ group }}
                  </span>
                  <span>&nbsp;</span>
                </template>

                <div class="inline-flex-xs align-items-stretch position-relative">
                  <a href="#" class="font-size-large color-neutral-dark" title="Add group"
                     data-toggle-dropdown>

                    <span class="sr-only">Add group</span> <i class="symbol symbol-plus"></i>
                  </a>

                  <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                       data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                    <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                      <div class="input-group input-block input-group-horizontal">
                        <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                               placeholder="Search groups" @input="filterGroups">

                        <label class="font-size-normalize btn btn-symbol btn-small btn-default">
                          <span class="sr-only">Search</span>
                          <i class="symbol symbol-search"></i>
                        </label>
                      </div>
                    </div>

                    <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active list-group-toggle-multiple">
                      <label class="list-group-item pointer-reference"
                             :class="{ active: groupsForUsersWithFixedTasks.value.includes(group) }" @click.prevent="onGroupToggled(group)"
                             v-show="!groupRegex || groupRegex.test(group)"
                             v-for="group in groups" :key="group">

                        <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                          <span class="flex-child flex-1-1">
                            <input type="checkbox" class="sr-only">
                            <span class="text-wrap-ellipsis">{{ group }}</span>
                          </span>
                          <span class="flex-child flex-0-0">
                            <i class="symbol symbol-check only-toggle flex-0-0"></i>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${groupsForUsersWithFixedTasks.key}' setting`"
                             v-model="groupsForUsersWithFixedTasks.description"
                             @change="onSettingDescChanged(groupsForUsersWithFixedTasks)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="hiddenOverdueProjDaysThreshold">
                <label :for="hiddenOverdueProjDaysThreshold.key"
                  class="input-label display-block padding-small-bottom">

                  {{ labelFor(hiddenOverdueProjDaysThreshold) }}
                </label>
                <input :id="hiddenOverdueProjDaysThreshold.key"
                       type="number" step="1" min="0"
                       class="input input-single-line" width="2"
                       v-model.number="hiddenOverdueProjDaysThreshold.value"
                       @change="onSettingValueChanged(hiddenOverdueProjDaysThreshold)">

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${hiddenOverdueProjDaysThreshold.key}' setting`"
                             v-model="hiddenOverdueProjDaysThreshold.description"
                             @change="onSettingDescChanged(hiddenOverdueProjDaysThreshold)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="hiddenOverdueTaskDaysThreshold">
                <label :for="hiddenOverdueTaskDaysThreshold.key"
                  class="input-label display-block padding-small-bottom">

                  {{ labelFor(hiddenOverdueTaskDaysThreshold) }}
                </label>
                <input :id="hiddenOverdueTaskDaysThreshold.key"
                       type="number" step="1" min="0"
                       class="input input-single-line" width="2"
                       v-model.number="hiddenOverdueTaskDaysThreshold.value"
                       @change="onSettingValueChanged(hiddenOverdueTaskDaysThreshold)">

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${hiddenOverdueTaskDaysThreshold.key}' setting`"
                             v-model="hiddenOverdueTaskDaysThreshold.description"
                             @change="onSettingDescChanged(hiddenOverdueTaskDaysThreshold)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="maxNumHoursWorkedPerTimelog">
                <label :for="maxNumHoursWorkedPerTimelog.key"
                  class="input-label display-block padding-small-bottom">

                  {{ labelFor(maxNumHoursWorkedPerTimelog) }}
                </label>
                <input :id="maxNumHoursWorkedPerTimelog.key"
                       type="number" step="0.1" min="0" max="6"
                       class="input input-single-line" width="2"
                       v-model.number="maxNumHoursWorkedPerTimelog.value"
                       @change="onSettingValueChanged(maxNumHoursWorkedPerTimelog)">

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${maxNumHoursWorkedPerTimelog.key}' setting`"
                             v-model="maxNumHoursWorkedPerTimelog.description"
                             @change="onSettingDescChanged(maxNumHoursWorkedPerTimelog)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="maxNumHoursWorkedPerDay">
                <label :for="maxNumHoursWorkedPerDay.key"
                  class="input-label display-block padding-small-bottom">

                  {{ labelFor(maxNumHoursWorkedPerDay) }}
                </label>
                <input :id="maxNumHoursWorkedPerDay.key"
                       type="number" step="0.1" min="0" max="12"
                       class="input input-single-line" width="2"
                       v-model.number="maxNumHoursWorkedPerDay.value"
                       @change="onSettingValueChanged(maxNumHoursWorkedPerDay)">

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${maxNumHoursWorkedPerDay.key}' setting`"
                             v-model="maxNumHoursWorkedPerDay.description"
                             @change="onSettingDescChanged(maxNumHoursWorkedPerDay)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="maxNumHoursWorkedPerWeek">
                <label :for="maxNumHoursWorkedPerWeek.key"
                  class="input-label display-block padding-small-bottom">

                  {{ labelFor(maxNumHoursWorkedPerWeek) }}
                </label>
                <input :id="maxNumHoursWorkedPerWeek.key"
                       type="number" step="0.1" min="0" max="48"
                       class="input input-single-line" width="2"
                       v-model.number="maxNumHoursWorkedPerWeek.value"
                       @change="onSettingValueChanged(maxNumHoursWorkedPerWeek)">

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${maxNumHoursWorkedPerWeek.key}' setting`"
                             v-model="maxNumHoursWorkedPerWeek.description"
                             @change="onSettingDescChanged(maxNumHoursWorkedPerWeek)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="nonBillableTasksEnabled">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(nonBillableTasksEnabled) }}
                </label>

                <div class="btn btn-group btn-group-horizontal btn-group-toggle btn-group-interactive">
                  <a href="#" @click.prevent="onSettingValueToggled(nonBillableTasksEnabled)"
                     :class="{ active: nonBillableTasksEnabled.value }"
                     class="btn border-color-neutral-alpha-3 background-theme-contrast color-neutral font-weight-inherit background-theme-active color-theme-active">

                    On
                  </a>
                  <a href="#" @click.prevent="onSettingValueToggled(nonBillableTasksEnabled)"
                     :class="{ active: !nonBillableTasksEnabled.value }"
                     class="btn border-color-neutral-alpha-3 background-theme-contrast color-neutral font-weight-inherit background-theme-active color-theme-active">

                    Off
                  </a>
                </div>

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${nonBillableTasksEnabled.key}' setting`"
                             v-model="nonBillableTasksEnabled.description"
                             @change="onSettingDescChanged(nonBillableTasksEnabled)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="usersCanAdjustTimelogDuration">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(usersCanAdjustTimelogDuration) }}
                </label>

                <div class="btn btn-group btn-group-horizontal btn-group-toggle btn-group-interactive">
                  <a href="#" @click.prevent="onSettingValueToggled(usersCanAdjustTimelogDuration)"
                     :class="{ active: usersCanAdjustTimelogDuration.value }"
                     class="btn border-color-neutral-alpha-3 background-theme-contrast color-neutral font-weight-inherit background-theme-active color-theme-active">

                    On
                  </a>
                  <a href="#" @click.prevent="onSettingValueToggled(usersCanAdjustTimelogDuration)"
                     :class="{ active: !usersCanAdjustTimelogDuration.value }"
                     class="btn border-color-neutral-alpha-3 background-theme-contrast color-neutral font-weight-inherit background-theme-active color-theme-active">

                    Off
                  </a>
                </div>

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${usersCanAdjustTimelogDuration.key}' setting`"
                             v-model="usersCanAdjustTimelogDuration.description"
                             @change="onSettingDescChanged(usersCanAdjustTimelogDuration)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="usersCanAdjustTimelogStartTime">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(usersCanAdjustTimelogStartTime) }}
                </label>

                <div class="btn btn-group btn-group-horizontal btn-group-toggle btn-group-interactive">
                  <a href="#" @click.prevent="onSettingValueToggled(usersCanAdjustTimelogStartTime)"
                     :class="{ active: usersCanAdjustTimelogStartTime.value }"
                     class="btn border-color-neutral-alpha-3 background-theme-contrast color-neutral font-weight-inherit background-theme-active color-theme-active">

                    On
                  </a>
                  <a href="#" @click.prevent="onSettingValueToggled(usersCanAdjustTimelogStartTime)"
                     :class="{ active: !usersCanAdjustTimelogStartTime.value }"
                     class="btn border-color-neutral-alpha-3 background-theme-contrast color-neutral font-weight-inherit background-theme-active color-theme-active">

                    Off
                  </a>
                </div>

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${usersCanAdjustTimelogStartTime.key}' setting`"
                             v-model="usersCanAdjustTimelogStartTime.description"
                             @change="onSettingDescChanged(usersCanAdjustTimelogStartTime)">
                    </span>
                  </div>
                </div>
              </div>

              <h2>Wrike settings</h2>

              <div class="p" v-if="wrikeSyncEnabled">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(wrikeSyncEnabled) }}
                </label>

                <div class="btn btn-group btn-group-horizontal btn-group-toggle btn-group-interactive">
                  <a href="#" @click.prevent="onSettingValueToggled(wrikeSyncEnabled)"
                     :class="{ active: wrikeSyncEnabled.value }"
                     class="btn border-color-neutral-alpha-3 background-theme-contrast color-neutral font-weight-inherit background-theme-active color-theme-active">

                    On
                  </a>
                  <a href="#" @click.prevent="onSettingValueToggled(wrikeSyncEnabled)"
                     :class="{ active: !wrikeSyncEnabled.value }"
                     class="btn border-color-neutral-alpha-3 background-theme-contrast color-neutral font-weight-inherit background-theme-active color-theme-active">

                    Off
                  </a>
                </div>

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                             :placeholder="`Click here to enter a description for the '${wrikeSyncEnabled.key}' setting`"
                             v-model="wrikeSyncEnabled.description"
                             @change="onSettingDescChanged(wrikeSyncEnabled)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="wrikeProjCustomStatWfNames">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(wrikeProjCustomStatWfNames) }}
                </label>

                <template v-for="workflow in wrikeProjCustomStatWfNames.value" :key="workflow">
                  <span class="color-inherit highlight highlight-neutral display-inline-block">
                    {{ workflow }}
                  </span>
                  <span>&nbsp;</span>
                </template>

                <div class="inline-flex-xs align-items-stretch position-relative">
                  <a href="#" class="font-size-large color-neutral-dark" title="Add workflow"
                      data-toggle-dropdown>

                    <span class="sr-only">Add workflow</span> <i class="symbol symbol-plus"></i>
                  </a>

                  <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                        data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                    <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                      <div class="input-group input-block input-group-horizontal">
                        <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                                placeholder="Search workflows" @input="filterProjWorkflows">

                        <label class="font-size-normalize btn btn-symbol btn-small btn-default">
                          <span class="sr-only">Search</span>
                          <i class="symbol symbol-search"></i>
                        </label>
                      </div>
                    </div>

                    <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active list-group-toggle-multiple">
                      <label class="list-group-item pointer-reference"
                              :class="{ active: wrikeProjCustomStatWfNames.value.includes(workflow) }"
                              @click.prevent="onProjWorkflowToggled(workflow)"
                              v-show="!projWorkflowRegex || projWorkflowRegex.test(workflow)"
                              v-for="workflow in wrike_workflows" :key="workflow">

                        <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                          <span class="flex-child flex-1-1">
                            <input type="checkbox" class="sr-only">
                            <span class="text-wrap-ellipsis">{{ workflow }}</span>
                          </span>
                          <span class="flex-child flex-0-0">
                            <i class="symbol symbol-check only-toggle flex-0-0"></i>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="margin-small-top color-neutral-dark">
                  <div class="flex-xs pointer-reference">
                    <div class="flex-0-0 margin-small-right">
                      <i class="symbol symbol-pencil"></i>
                    </div>
                    <span class="font-style-italic flex-1-1">
                      <input class="input input-blend input-block"
                              :placeholder="`Click here to enter a description for the '${wrikeProjCustomStatWfNames.key}' setting`"
                              v-model="wrikeProjCustomStatWfNames.description"
                              @change="onSettingDescChanged(wrikeProjCustomStatWfNames)">
                    </span>
                  </div>
                </div>
              </div>

              <div class="p" v-if="wrikeTaskCustomStatWfNames">
                <label class="input-label display-block padding-small-bottom">
                  {{ labelFor(wrikeTaskCustomStatWfNames) }}
                </label>

                <div class="p">
                  <template v-for="workflow in wrikeTaskCustomStatWfNames.value" :key="workflow">
                    <span class="color-inherit highlight highlight-neutral display-inline-block">
                      {{ workflow }}
                    </span>
                    <span>&nbsp;</span>
                  </template>

                  <div class="inline-flex-xs align-items-stretch position-relative">
                    <a href="#" class="font-size-large color-neutral-dark" title="Add workflow"
                       data-toggle-dropdown>

                      <span class="sr-only">Add workflow</span> <i class="symbol symbol-plus"></i>
                    </a>

                    <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                         data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                      <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                        <div class="input-group input-block input-group-horizontal">
                          <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                                 placeholder="Search workflows" @input="filterTaskWorkflows">

                          <label class="font-size-normalize btn btn-symbol btn-small btn-default">
                            <span class="sr-only">Search</span>
                            <i class="symbol symbol-search"></i>
                          </label>
                        </div>
                      </div>

                      <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active list-group-toggle-multiple">
                        <label class="list-group-item pointer-reference"
                               :class="{ active: wrikeTaskCustomStatWfNames.value.includes(workflow) }"
                               @click.prevent="onTaskWorkflowToggled(workflow)"
                               v-show="!taskWorkflowRegex || taskWorkflowRegex.test(workflow)"
                               v-for="workflow in wrike_workflows" :key="workflow">

                          <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                            <span class="flex-child flex-1-1">
                              <input type="checkbox" class="sr-only">
                              <span class="text-wrap-ellipsis">{{ workflow }}</span>
                            </span>
                            <span class="flex-child flex-0-0">
                              <i class="symbol symbol-check only-toggle flex-0-0"></i>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="margin-small-top color-neutral-dark">
                    <div class="flex-xs pointer-reference">
                      <div class="flex-0-0 margin-small-right">
                        <i class="symbol symbol-pencil"></i>
                      </div>
                      <span class="font-style-italic flex-1-1">
                        <input class="input input-blend input-block"
                               :placeholder="`Click here to enter a description for the '${wrikeTaskCustomStatWfNames.key}' setting`"
                               v-model="wrikeTaskCustomStatWfNames.description"
                               @change="onSettingDescChanged(wrikeTaskCustomStatWfNames)">
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<script>
import axios from 'axios'
import * as _ from 'lodash-es'

import Layout from '../Layout'
import { stringifyValidationErrors, titlecase } from '../lib/string'

export default {
  name: 'SettingsList',

  props: {
    settings: {
      type: Array,
      required: true,
    },
    jobs: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    wrike_workflows: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      _settings: _.cloneDeep(this.settings),
      cliJobRegex: null,
      projJobRegex: null,
      groupRegex: null,
      projWorkflowRegex: null,
      taskWorkflowRegex: null,
    }
  },

  computed: {
    defaultClientJobsSetting() {
      return this._settings.find(s => s.key === 'DEFAULT_CLIENT_JOBS')
    },
    defaultProjectJobsSetting() {
      return this._settings.find(s => s.key === 'DEFAULT_PROJECT_JOBS')
    },
    groupsForUsersWithFixedTasks() {
      return this._settings.find(s => s.key === 'GROUPS_FOR_USERS_WITH_FIXED_TASKS')
    },
    hiddenOverdueProjDaysThreshold() {
      return this._settings.find(s => s.key === 'HIDDEN_OVERDUE_PROJ_DAYS_THRESHOLD')
    },
    hiddenOverdueTaskDaysThreshold() {
      return this._settings.find(s => s.key === 'HIDDEN_OVERDUE_TASK_DAYS_THRESHOLD')
    },
    maxNumHoursWorkedPerTimelog() {
      return this._settings.find(s => s.key === 'MAX_NUM_HOURS_WORKED_PER_TIMELOG')
    },
    maxNumHoursWorkedPerDay() {
      return this._settings.find(s => s.key === 'MAX_NUM_HOURS_WORKED_PER_DAY')
    },
    maxNumHoursWorkedPerWeek() {
      return this._settings.find(s => s.key === 'MAX_NUM_HOURS_WORKED_PER_WEEK')
    },
    nonBillableTasksEnabled() {
      return this._settings.find(s => s.key === 'NON_BILLABLE_TASKS_ENABLED')
    },
    usersCanAdjustTimelogDuration() {
      return this._settings.find(s => s.key === 'USERS_CAN_ADJUST_TIMELOG_DURATION')
    },
    usersCanAdjustTimelogStartTime() {
      return this._settings.find(s => s.key === 'USERS_CAN_ADJUST_TIMELOG_START_TIME')
    },
    wrikeSyncEnabled() {
      return this._settings.find(s => s.key === 'WRIKE_SYNC_ENABLED')
    },
    wrikeProjCustomStatWfNames() {
      return this._settings.find(s => s.key === 'WRIKE_PROJ_CUSTOM_STAT_WF_NAMES')
    },
    wrikeTaskCustomStatWfNames() {
      return this._settings.find(s => s.key === 'WRIKE_TASK_CUSTOM_STAT_WF_NAMES')
    },
  },

  methods: {
    labelFor(setting) {
      return titlecase(setting.key)
    },

    filterClientJobs(e) {
      const jobQuery = e.target.value.trim()
      if (!jobQuery) {
        this.cliJobRegex = null
        return
      }

      this.cliJobRegex = new RegExp(jobQuery, 'i')
    },

    async onClientJobToggled(job) {
      const index = this.defaultClientJobsSetting.value.indexOf(job)
      let value = [...this.defaultClientJobsSetting.value]
      if (index === -1) {
        value.push(job)
        value.sort()
      } else {
        value.splice(index, 1)
      }

      try {
        await axios.patch(reverseUrl('settings.update', this.defaultClientJobsSetting.key), {
          value: JSON.stringify(value),
        })
      } catch(error) {
        let warn = `Unable to change value of the '${this.defaultClientJobsSetting.key}' setting`
        warn += ` due to: ${stringifyValidationErrors(error)}`
        alert(warn)
        return
      }

      this.defaultClientJobsSetting.value.splice(0, this.defaultClientJobsSetting.value.length, ...value)
    },

    async onSettingDescChanged(setting) {
      try {
        await axios.patch(reverseUrl('settings.update', setting.key), {
          description: setting.description,
        })
      } catch(error) {
        let warn = `Unable to change description of the '${setting.key}' setting`
        warn += ` due to: ${stringifyValidationErrors(error)}`
        alert(warn)
      }
    },

    filterProjectJobs(e) {
      const jobQuery = e.target.value.trim()
      if (!jobQuery) {
        this.projJobRegex = null
        return
      }

      this.projJobRegex = new RegExp(jobQuery, 'i')
    },

    async onProjectJobToggled(job) {
      const index = this.defaultProjectJobsSetting.value.indexOf(job)
      let value = [...this.defaultProjectJobsSetting.value]
      if (index === -1) {
        value.push(job)
        value.sort()
      } else {
        value.splice(index, 1)
      }

      try {
        await axios.patch(reverseUrl('settings.update', this.defaultProjectJobsSetting.key), {
          value: JSON.stringify(value),
        })
      } catch(error) {
        let warn = `Unable to change value of the '${this.defaultProjectJobsSetting.key}' setting`
        warn += ` due to: ${stringifyValidationErrors(error)}`
        alert(warn)
        return
      }

      this.defaultProjectJobsSetting.value.splice(0, this.defaultProjectJobsSetting.value.length, ...value)
    },

    filterGroups(e) {
      const grpQuery = e.target.value.trim()
      if (!grpQuery) {
        this.groupRegex = null
        return
      }

      this.groupRegex = new RegExp(grpQuery, 'i')
    },

    async onGroupToggled(job) {
      const index = this.groupsForUsersWithFixedTasks.value.indexOf(job)
      let value = [...this.groupsForUsersWithFixedTasks.value]
      if (index === -1) {
        value.push(job)
        value.sort()
      } else {
        value.splice(index, 1)
      }

      try {
        await axios.patch(reverseUrl('settings.update', this.groupsForUsersWithFixedTasks.key), {
          value: JSON.stringify(value),
        })
      } catch(error) {
        let warn = `Unable to change value of the '${this.groupsForUsersWithFixedTasks.key}' setting`
        warn += ` due to: ${stringifyValidationErrors(error)}`
        alert(warn)
        return
      }

      this.groupsForUsersWithFixedTasks.value.splice(0, this.groupsForUsersWithFixedTasks.value.length, ...value)
    },

    async onSettingValueChanged(setting) {
      try {
        await axios.patch(reverseUrl('settings.update', setting.key), {
          value: JSON.stringify(setting.value),
        })
      } catch(error) {
        let warn = `Unable to change value of the '${setting.key}' setting`
        warn += ` due to: ${stringifyValidationErrors(error)}`
        alert(warn)
      }
    },

    async onSettingValueToggled(setting) {
      setting.value = !setting.value

      try {
        await axios.patch(reverseUrl('settings.update', setting.key), {
          value: JSON.stringify(setting.value),
        })
      } catch(error) {
        let warn = `Unable to change value of the '${setting.key}' setting`
        warn += ` due to: ${stringifyValidationErrors(error)}`
        alert(warn)
      }
    },

    filterProjWorkflows(e) {
      const workflowQuery = e.target.value.trim()
      if (!workflowQuery) {
        this.projWorkflowRegex = null
        return
      }

      this.projWorkflowRegex = new RegExp(workflowQuery, 'i')
    },

    async onProjWorkflowToggled(workflow) {
      const index = this.wrikeProjCustomStatWfNames.value.indexOf(workflow)
      let value = [...this.wrikeProjCustomStatWfNames.value]
      if (index === -1) {
        value.push(workflow)
        value.sort()
      } else {
        value.splice(index, 1)
      }

      try {
        await axios.patch(reverseUrl('settings.update', this.wrikeProjCustomStatWfNames.key), {
          value: JSON.stringify(value),
        })
      } catch(error) {
        let warn = `Unable to change value of the '${this.wrikeProjCustomStatWfNames.key}' setting`
        warn += ` due to: ${stringifyValidationErrors(error)}`
        alert(warn)
        return
      }

      this.wrikeProjCustomStatWfNames.value.splice(0, this.wrikeProjCustomStatWfNames.value.length, ...value)
    },

    filterTaskWorkflows(e) {
      const workflowQuery = e.target.value.trim()
      if (!workflowQuery) {
        this.taskWorkflowRegex = null
        return
      }

      this.taskWorkflowRegex = new RegExp(workflowQuery, 'i')
    },

    async onTaskWorkflowToggled(workflow) {
      const index = this.wrikeTaskCustomStatWfNames.value.indexOf(workflow)
      let value = [...this.wrikeTaskCustomStatWfNames.value]
      if (index === -1) {
        value.push(workflow)
        value.sort()
      } else {
        value.splice(index, 1)
      }

      try {
        await axios.patch(reverseUrl('settings.update', this.wrikeTaskCustomStatWfNames.key), {
          value: JSON.stringify(value),
        })
      } catch(error) {
        let warn = `Unable to change value of the '${this.wrikeTaskCustomStatWfNames.key}' setting`
        warn += ` due to: ${stringifyValidationErrors(error)}`
        alert(warn)
        return
      }

      this.wrikeTaskCustomStatWfNames.value.splice(0, this.wrikeTaskCustomStatWfNames.value.length, ...value)
    },
  },

  components: {
    Layout,
  },
}
</script>

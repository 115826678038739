<template>
  <tr class="background-primary-alpha-1">
    <td class="hide-mobile no-border-bottom timetracker-td text-align-center timetracker-td timetracker-td-w timetracker-td-w-50"
        v-if="$page.props.current_user.is_superuser">

      <span class="spacer">&nbsp;</span>
    </td>

    <td class="timetracker-td">
      <span class="flex-md">
        <template v-if="$page.props.current_user.is_superuser">
          <label class="input-label no-padding margin-right">
            <input type="checkbox" class="input-inline" v-model="selected"
                   @change="$emit('project:selected', { id, checked: $event.target.checked })">
            <span class="hide-nonmobile">Select project</span>
          </label>

          <br class="hide-nonmobile">
          <br class="hide-nonmobile">
        </template>

        <Link :href="$linkTo('admin.projects.edit', { viewArgs: [id], returnBack: true })"
              class="no-margin-y display-inline-block color-inherit"
              v-if="$page.props.current_user.is_superuser">

          {{ name }}
        </Link>
        <span v-else>{{ name }}</span>
      </span>
    </td>

    <td class="hide-mobile text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="spacer">&nbsp;</span>
    </td>

    <td class="hide-mobile text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="spacer">&nbsp;</span>
    </td>

    <td class="text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Project manager</span>
      <UserThumb v-bind="projectManager" v-if="projectManager" />
    </td>

    <td class="text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">SEO</span>
      <UserThumb v-bind="seo" v-if="seo" />
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-75 text-align-center">
      <span class="spacer">&nbsp;</span>
    </td>

    <td class="no-border-bottom timetracker-td-actions text-align-center" width="50"
        v-if="$page.props.current_user.is_superuser">

      <div class="bulk-actions position-relative">
        <button class="btn no-padding font-size-large" data-modal-disable-overlay="false"
                data-toggle-modal-default>

          <i class="symbol symbol-kebab-horizontal"></i>
          <span class="sr-only">More options</span>
        </button>

        <div class="modal modal-default" data-modal-width="400px">
          <div class="text-align-center">
            <h1 class="no-margin-top">More options</h1>
            <ul class='list-group list-group-small no-margin-y'>
              <li><a href="#" @click.prevent="confirmDeletion">Delete</a></li>
            </ul>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import { Link } from '@inertiajs/vue3'

import UserThumb from '../UserThumb'
import { stringifyErrors } from '../../lib/string'

export default {
  name: 'ClientProject',

  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    created: {
      type: String,
      required: true,
    },
    synced_to_wrike: {
      type: Boolean,
      required: true,
    },
    project_manager_id: {
      type: Number,
    },
    seo_id: {
      type: Number,
    },
    client_id: {
      type: Number,
      required: true,
    },
    clientSelected: {
      type: Object,
      required: true,
    },
  },

  inject: ['projectManagerById', 'seoById'],

  data() {
    return {
      selected: false,
    }
  },

  watch: {
    'clientSelected.value'(newValue) {
      this.selected = newValue
      this.$emit('project:selected', { id: this.id, checked: newValue })
    },
  },

  computed: {
    projectManager() {
      return this.projectManagerById[this.project_manager_id]
    },

    seo() {
      return this.seoById[this.seo_id]
    },
  },

  methods: {
    async confirmDeletion() {
      if (!confirm(`Are you sure you want to delete project "${this.name}"?`)) return

      try {
        await axios.delete(reverseUrl('projects.destroy', this.id), {
          data: {
            delete_on_wrike: this.synced_to_wrike,
          },
        })
      } catch(err) {
        alert(`Unable to delete project due to:\n${stringifyErrors(err)}`)
        return
      }

      this.$emit('project:deleted', { clientId: this.client_id, projectId: this.id })
    },
  },

  emits: ['project:selected', 'project:deleted'],
  components: {
    Link,
    UserThumb,
  },
}
</script>

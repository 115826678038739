<template>
  <div class="module align-items-stretch flex-xs flex-direction-column" data-grid-area="log">
    <div class="flex-0-0">
      <div class="flex-grid flex-grid-compact justify-content-space-between align-items-center">
        <div class="flex-child flex-1-1 margin-right-auto">
          <h5 class="no-margin-y">Time log</h5>
        </div>

        <div class="flex-child align-self-center">
          <select class="input input-select margin-right" v-model="_model"
                  @change="$emit('model:changed', $event.target.value)">

            <option value="task_category">Task categories</option>
            <option value="project">Projects</option>
            <option value="client">Clients</option>
          </select>
        </div>
      </div>
    </div>

    <div class="flex-1-1 flex-xs flex-direction-column justify-content-flex-start">
      <div ref="timeLogChart"></div>
    </div> </div>
</template>

<script>
import TimeLogChart from '../lib/charts/insights/d3Log'

export default {
  name: 'MyAnalyticsTimeLogChart',

  props: {
    model: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      _model: this.model,
      chart: null,
    }
  },

  mounted() {
    this.chart = new TimeLogChart(this.$refs.timeLogChart)
    this.chart.render({
      data: this.data,
    })
  },

  watch: {
    data(newData) {
      this.chart.render({
        data: newData,
      })
    },
  },

  emits: ['model:changed'],
}
</script>

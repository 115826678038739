<template>
  <div :class="wrapperCSSClasses">
    <div class="thumbnail-image" v-if="avatar">
      <img class="profile-image" :src="avatar" :alt="fullName">
    </div>

    <span class="thumbnail-text" :title="fullName" v-else>
      {{ initials }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'UserThumb',

  props: {
    avatar: {
      type: String,
    },
    first_name: {
      type: String,
    },
    last_name: {
      type: String,
    },
    small: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    fullName() {
      return `${this.first_name} ${this.last_name}`
    },

    initials() {
      return this.first_name.charAt(0) + this.last_name.charAt(0)
    },

    wrapperCSSClasses() {
      const classes = ['thumbnail']
      if (this.small) classes.push('thumbnail-small')

      return classes
    },
  },
}
</script>

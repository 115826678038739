<template>
  <div class="h4 margin-bottom">Projects</div>

  <div class="module p">
    <div class="module-content no-padding">
      <div class="table-wrapper" v-if="projects.length">
        <table class="timetracker-table">
          <thead>
            <tr class="background-transparent">
              <th class="background-transparent no-border-top">Name</th>
              <th class="background-transparent no-border-top text-align-center">Hours</th>
              <th class="background-transparent no-border-top text-align-center">
                &nbsp;<div class="sr-only">% Time Graph</div>
              </th>
              <th class="background-transparent no-border-top text-align-center">% Time</th>
              <th class="background-transparent no-border-top text-align-right">Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row in projects" :key="row.project.id">
              <td class="timetracker-td timetracker-td-w timetracker-td-w-250">
                <div class="font-weight-600 text-wrap-ellipsis">
                  {{ row.project.name }}
                </div>
              </td>

              <td class="text-align-center timetracker-td">
                <h6 class="hide-nonmobile no-margin-top">Hours</h6>
                {{ secsToTimeStr(row.hours) }}
              </td>

              <td class="text-align-center timetracker-td timetracker-td-w timetracker-td-w-250">
                <h6 class="hide-nonmobile no-margin-top sr-only">% Time Graph</h6>
                <div class="text-vertical-align-middle display-inline-block progress margin-auto-x">
                  <div class="progress-bar progress-bar-primary"
                       :style="{ width: `calc(${row.pct_time} * 1%)`}">

                    <span class="sr-only">{{ formatPct(row.pct_time) }}</span>
                  </div>
                </div>
              </td>

              <td class="text-align-center timetracker-td">
                <h6 class="hide-nonmobile no-margin-top">% Time</h6>
                {{ formatPct(row.pct_time) }}
              </td>

              <td class="timetracker-td-actions text-align-right">
                <Link :href="$linkTo('main.time', { qryStrParams: { user_id: user.id, project_id: row.project.id, date_from: dateFrom, date_to: dateTo } })"
                      class="btn btn-link btn-symbol" title="View">

                  <i class="symbol symbol-visible"></i>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="font-style-italic color-neutral padding" v-else>
        No projects match the specified date range.
      </div>
    </div>
  </div>

</template>

<script>
import { Link } from '@inertiajs/vue3'

import { secondsToTimeStr  } from '../lib/date'
import { formatPercent } from '../lib/number'

export default {
  name: 'ProjTimeDist',

  props: {
    projects: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
  },

  methods: {
    formatPct(number) {
      return formatPercent(number)
    },

    secsToTimeStr(seconds) {
      return secondsToTimeStr(seconds)
    },
  },

  components: {
    Link,
  },
}
</script>

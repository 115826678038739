<template>
  <div class="modal modal-default" id="manual-time-entry-modal"
       @after_destroy_fw_modal="$emit('manualTimeEntryModal:closed')">

    <h2 class="no-margin-top margin-small-bottom">Manual time entry</h2>
    <p class="no-margin-y">Add your time entry below</p>

    <form @submit.prevent="submitForm">
      <div class="flex-grid flex-grid-fixed flex-grid-compact">
        <div class="flex-col-xs-12 flex-col-md-6">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
            <label for="manual-entry-client-name" class="input-label font-weight-600">Client</label>

            <input class="input input-single-line" id="time-entry-client-name"
                   placeholder="Enter client name" list="timer-client-options" autocomplete="off"
                   v-model="clientName" @change="setClientId" required>
            <datalist id="manual-entry-client-options" ref="clientOptions">
              <option :value="client.name"
                      v-for="client in clientOptions" :key="client.id" />
            </datalist>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-md-6">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
            <label for="manual-entry-project-name" class="input-label font-weight-600">Project</label>
            <input class="input input-single-line" id="manual-entry-project-name"
                   placeholder="Enter project name" list="manual-entry-project-options"
                   autocomplete="off" v-model="projectName"
                   @input="debouncedAutocompleteProjectName" @change="setProjectId" required>

            <datalist id="manual-entry-project-options" ref="projectOptions">
              <option :value="project.name"
                      v-for="project in projectOptions" :key="project.id" />
            </datalist>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-md-8">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
            <label for="manual-entry-task-title" class="input-label font-weight-600">Task title</label>
            <input class="input input-single-line" id="manual-entry-task-title"
                   placeholder="Click here to enter task title" list="manual-entry-task-options"
                   autocomplete="off" v-model="taskTitle"
                   @input="debouncedAutocompleteTaskTitle" @change="setTaskIdOrTitle" required>

            <datalist id="manual-entry-task-options">
              <option :value="task.title"
                      v-for="task in taskOptions" :key="task.id" />
            </datalist>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-md-4">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
            <label for="manual-entry-category-id" class="input-label font-weight-600">Task category</label>
            <select id="manual-entry-category-id" class="input input-single-line"
                    v-model.number="taskCategoryId">

              <option :value="0">Select task category</option>
              <option :value="id" v-for="[id, name] in taskCategoryOptions" :key="id">
                {{ name }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex-col-xs-12">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
            <label for="manual-entry-comment" class="input-label font-weight-600">Comment</label>
            <textarea id="manual-entry-comment" class="input input-multiple-line" placeholder="Add your time entry comment here." v-model.trim="comment"></textarea>
          </div>
        </div>

        <div class="flex-col-xs-12">
          <div class="flex-grid flex-grid-fixed flex-grid-no-gutter-y">
            <div class="flex-col-xs-12 flex-col-md-5">
              <div class="flex-grid flex-grid-fixed flex-grid-compact">
                <div class="flex-col-xs-12 flex-col-sm-7">
                  <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
                    <label for="manual-entry-start-date" class="input-label font-weight-600">Start date</label>
                    <input type="date" id="manual-entry-start-date" class="input input-single-line"
                           :max="today" v-model="_startDate" @change="onStartTimeChanged" required>
                  </div>
                </div>

                <div class="flex-col-xs-12 flex-col-sm-5">
                  <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
                    <label for="manual-entry-start-time" class="input-label font-weight-600">Start time</label>
                    <input type="time" id="manual-entry-start-time" class="input input-single-line"
                           v-model="_startTime" @change="onStartTimeChanged" required>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-col-xs-12 flex-col-md-5">
              <div class="flex-grid flex-grid-fixed flex-grid-compact">
                <div class="flex-col-xs-12 flex-col-sm-7">
                  <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
                    <label for="manual-entry-end-date" class="input-label font-weight-600">End date</label>
                    <input type="date" id="manual-entry-end-date" class="input input-single-line"
                           :max="today" v-model="_endDate" @change="onEndTimeChanged" required>
                  </div>
                </div>
                <div class="flex-col-xs-12 flex-col-sm-5">
                  <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
                    <label for="manual-entry-end-time" class="input-label font-weight-600">End time</label>
                    <input type="time" id="manual-entry-end-time" class="input input-single-line"
                           v-model="_endTime" @change="onEndTimeChanged" required>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-col-xs-12 flex-col-md-2">
              <div class="flex-grid flex-grid-fixed flex-grid-compact">
                <div class="flex-col-xs-12">
                  <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
                    <label for="manual-total-time" class="input-label font-weight-600">Total time</label>
                    <input id="manual-total-time" class="input input-single-line"
                           pattern="\d{2}:\d{2}" maxlength="5" placeholder="HH:MM"
                           v-model="totalTime" ref="totalTime" @change="onTotalTimeChanged">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 hide-mobile">
          <span class="spacer"></span>
        </div>

        <div class="flex-col-xs-12 flex-col-md-8 align-self-center">
          <label class="input-toggle input-toggle-block-mobile">
            <input type="checkbox" class="input" v-model="syncedToWrike">
            <span class="input-label input-toggle-label">Sync to wrike</span>
          </label>

          <label class="input-toggle input-toggle-block-mobile">
            <input type="checkbox" class="input" v-model="billable">
            <span class="input-label input-toggle-label">Billable</span>
          </label>
        </div>

        <div class="flex-col-xs-12 hide-nonmobile">
          <span class="spacer"></span>
        </div>

        <div class="flex-col-xs-12 flex-col-md-4 text-align-right align-self-center">
          <button class="btn btn-theme btn-block-mobile" :disabled="!formIsValid">
            Add time entry
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'

import {
  formatDate,
  formatTime,
  hhMMToSecs,
  parseISODate,
  secondsToTimeStr,
  timeStrToHrsAndMins
} from '../lib/date'
import Modal from '../imports/modal'
import { stringifyValidationErrors  } from '../lib/string'

export default {
  name: 'ManualTimeEntry',

  props: {
    taskCategoryOptions: {
      type: Array,
      required: true,
    },
    modalOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      clientOptions: [],
      clientId: 0,
      clientName: '',
      projectOptions: [],
      projectId: 0,
      projectName: '',
      taskOptions: [],
      taskId: 0,
      taskTitle: '',
      taskCategoryId: 0,
      _startDate: '',
      _startTime: '',
      _endDate: '',
      _endTime: '',
      _totalTime: '',
      comment: '',
      syncedToWrike: false,
      billable: true,
      reqInProgress: false,
    }
  },

  async created() {
    let resp
    try {
      resp = await axios.get(this.$route('clients.index'))
      const { clients } = resp.data
      this.clientOptions.splice(0, this.clientOptions.length, ...clients)
    } catch {
      console.error('Unable to load client list')
    }

    // this.debouncedAutocompleteClientName = _.debounce(this.autocompleteClientName,
    //                                                   this.$page.props.app_config.autocomplete_debounce_min)
    this.debouncedAutocompleteProjectName = _.debounce(this.autocompleteProjectName,
                                                       this.$page.props.app_config.autocomplete_debounce_min)
    this.debouncedAutocompleteTaskTitle = _.debounce(this.autocompleteTaskTitle,
                                                     this.$page.props.app_config.autocomplete_debounce_min)
  },

  beforeUnmount() {
    this.debouncedAutocompleteTaskTitle.cancel()
    this.debouncedAutocompleteProjectName.cancel()
    // this.debouncedAutocompleteClientName.cancel()
  },

  computed: {
    today() {
      return formatDate(new Date(), { format: 'ISO' })
    },

    maxNumSecs() {
      return this.$page.props.app_settings.max_num_hours_worked_per_timelog * 3_600
    },

    startTime() {
      if (!this._startDate || !this._startTime) return

      const sTime = parseISODate(this._startDate)
      sTime.setHours(...timeStrToHrsAndMins(this._startTime))

      if (sTime > new Date()) this.$nextTick(() => this._startTime = '')

      return sTime
    },

    endTime() {
      if (!this._endDate || !this._endTime) return

      const eTime = parseISODate(this._endDate)
      eTime.setHours(...timeStrToHrsAndMins(this._endTime))

      if (eTime > new Date()) this.$nextTick(() => this._endTime = '')

      return eTime
    },

    totalTime: {
      get() {
        return this._totalTime
      },

      set(newValue) {
        if (!/^\d{1,2}:\d{2}$/.test(newValue)) return
        if (hhMMToSecs(newValue) > this.maxNumSecs) return

        this._totalTime = newValue.length === 4 ? `0${newValue}` : newValue
      },
    },

    formIsValid() {
      let res = this.projectId
      res &&= (this.taskId || (!this.$onlyWorksOnFixedTasks.value && this.taskTitle))
      res &&= (this.startTime && this.endTime)
      res &&= this.startTime < this.endTime

      return res
    },
  },

  watch: {
    modalOpen(modalIsOpen) {
      if (!modalIsOpen) return

      new Modal(this.$el, null, {
        width: '900px',
        changeHash: false,
        disableOverlay: false,
      }).create()
    },
  },

  methods: {
    /*async autocompleteClientName(event) {
      const q = event.target.value

      const params = new URLSearchParams()
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) {
        params.set('q', q)
      }
      const resp = await axios.get(`${this.$route('clients.index')}?${params}`)
      const { clients } = resp.data
      this.clientOptions.splice(0, this.clientOptions.length, ...clients)
    },*/

    clearTaskOptions() {
      this.taskOptions.splice(0, this.taskOptions.length)
    },

    clearProjectOptions() {
      this.projectOptions.splice(0, this.projectOptions.length)
      this.clearTaskOptions()
    },

    async setClientId(event) {
      const clientName = event.target.value.trim().toLowerCase()
      const client = this.clientOptions.find(c => c.name.toLowerCase() === clientName)
      this.clientId = client?.id || 0
      if (!this.clientId) {
        setTimeout(() => {
          this.clientName = ''

          this.projectId = 0
          this.projectName = ''
          this.taskId = 0
          this.taskTitle = ''
          this.comment = ''
        },
        this.$page.props.app_config.autocomplete_field_reset_delay)
      }

      this.clearProjectOptions()
      await this.autocompleteProjectName()
    },

    async autocompleteProjectName(event) {
      if (!this.clientId) this.clearProjectOptions()

      const q = event?.target?.value || ''
      const params = new URLSearchParams()
      if (this.clientId) params.set('client_id', this.clientId)
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) {
        params.set('q', q)
      }
      const resp = await axios.get(`${this.$route('projects.index')}?${params}`)
      const { projects } = resp.data
      const dupeProjects = Object.values(Object.entries(Object.groupBy(projects, p => p.name)).flatMap(([_, arr]) => arr.length > 1 ? arr : []))
      for (const dupeProj of dupeProjects) {
        const idx = projects.findIndex(proj => proj.id === dupeProj.id)
        if (idx === -1) continue

        const replaceProj = Object.assign(dupeProj, { name: `${dupeProj.name} [${dupeProj.id}]` })
        projects.splice(idx, 1, replaceProj)
      }
      this.projectOptions.splice(0, this.projectOptions.length, ...projects)
    },

    async setProjectId(event) {
      const projectName = event.target.value.trim().toLowerCase(),
        match = / \[(\d+)\]$/.exec(projectName)
      let project
      if(match) {
        const projId = Number(match[1])
        project = this.projectOptions.find(p => p.id === projId)
      } else {
        project = this.projectOptions.find(p => p.name.toLowerCase() === projectName)
      }
      this.projectId = project?.id || 0
      if (!this.projectId) {
        setTimeout(() => {
          this.projectName = ''

          this._taskTitle = ''
          this.taskId = 0
          this.taskTitle = ''
          this.comment = ''
        },
        this.$page.props.app_config.autocomplete_field_reset_delay)
      } else if (!this.clientId) {
        let client = this.clientOptions.find(c => c.id === project.client_id)
        if (!client) {
          try {
            const resp = await axios.get(reverseUrl('clients.show', { id: project.client_id }))
            client = resp.data.client
            this.clientOptions.push(client)
          } catch {
            console.error(`Client #${project.client_id} not found!`)
          }
        }

        if (client) {
          this.clientId = client.id
          this.clientName = client.name
        }
      }

      this.clearTaskOptions()
      await this.autocompleteTaskTitle()
    },

    async autocompleteTaskTitle(event) {
      if (!this.projectId) {
        this.clearTaskOptions()
        return
      }

      const q = event?.target?.value || ''
      const params = new URLSearchParams()
      params.set('project', this.projectId)
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) params.set('q', q)
      if (!this.$onlyWorksOnFixedTasks.value) params.set('include_tasks_not_assigned_to_user', 1)
      const resp = await axios.get(`${this.$route('tasks.index')}?${params}`)
      const { tasks } = resp.data
      this.taskOptions.splice(0, this.taskOptions.length, ...tasks)
    },

    async setTaskIdOrTitle(event) {
      const title = event.target.value.trim(), tit = title.toLowerCase()
      const task = this.taskOptions.find(t => t.title.toLowerCase() === tit)
      this.taskId = task?.id || 0

      if (this.taskId) {
        const resp = await axios.get(this.$route('tasks.show', { id: this.taskId }))
        const { task } = resp.data
        this.taskCategoryId = task.category?.id || 0
        this.syncedToWrike = task.synced_to_wrike
      } else {
        this.taskCategoryId = 0
        this.syncedToWrike = false
      }

      if (this.$onlyWorksOnFixedTasks.value) {
        this.taskTitle = task?.title || ''
      } else {
        this.taskTitle = task?.title || title
      }

      if (!this.taskTitle) {
        this.taskCategoryName = ''
        this.taskStatusName = ''
        this.comment = ''
      }
    },

    onStartTimeChanged() {
      if (!this.startTime) return
      if (!this.endTime && !this.totalTime) return

      if (this.endTime) {
        const totalSeconds = (this.endTime.getTime() - this.startTime.getTime()) / 1_000
        this._totalTime = secondsToTimeStr(totalSeconds)
      } else {
        const endTime = new Date(this.startTime.getTime() + hhMMToSecs(this.totalTime) * 1_000)
        this._endDate = formatDate(endTime, { format: 'ISO' })
        this._endTime = formatTime(endTime, { format: 'EU' })
      }
    },

    onEndTimeChanged() {
      if (!this.endTime) return
      if (!this.startTime && !this.totalTime) return

      if (this.startTime) {
        const totalSeconds = (this.endTime.getTime() - this.startTime.getTime()) / 1_000
        this._totalTime = secondsToTimeStr(totalSeconds)
      } else {
        const startTime = new Date(this.endTime.getTime() - hhMMToSecs(this.totalTime) * 1_000)
        this._startDate = formatDate(startTime, { format: 'ISO' })
        this._startTime = formatTime(startTime, { format: 'EU' })
      }
    },

    onTotalTimeChanged() {
      if (!this.totalTime) return
      if (!this.startTime && !this.endTime) return

      if (this.startTime) {
        const endTime = new Date(this.startTime.getTime() + hhMMToSecs(this.totalTime) * 1_000)
        this._endDate = formatDate(endTime, { format: 'ISO' })
        this._endTime = formatTime(endTime, { format: 'EU' })
        if (endTime > new Date()) this.$nextTick(() => this._startTime = '')
      } else if (this.endTime) {
        const startTime = new Date(this.endTime.getTime() - hhMMToSecs(this.totalTime) * 1_000)
        this._startDate = formatDate(startTime, { format: 'ISO' })
        this._startTime = formatTime(startTime, { format: 'EU' })
      }
    },

    resetModalState() {
      this.clientOptions.splice(0, this.clientOptions.length)
      this.clientId = 0
      this.clientName = ''
      this.projectOptions.splice(0, this.projectOptions.length)
      this.projectId = 0
      this.projectName = ''
      this.taskOptions.splice(0, this.taskOptions.length)
      this.taskId = 0
      this.taskTitle = ''
      this.taskCategoryId = 0
      this._startDate = ''
      this._startTime = ''
      this._endDate = ''
      this._endTime = ''
      this._totalTime = ''
      this.comment = ''
      this.syncedToWrike = false
      this.billable = true
    },

    async submitForm(event) {
      if (!event.target.checkValidity()) return

      const taskId = this.taskId || null,
            taskTitle = this.taskId ? null : this.taskTitle
      this.reqInProgress = true
      let resp
      try {
        resp = await axios.post(reverseUrl('time_entries.create'), {
          project_id: this.projectId,
          task_id: taskId,
          task_title: taskTitle,
          task_status: 0,
          task_category: this.taskCategoryId,
          comment: this.comment,
          start_time: this.startTime.toISOString(),
          end_time: this.endTime.toISOString(),
          synced_to_wrike: this.syncedToWrike,
          billable: this.billable,
        })
      } catch(err) {
        alert(`Unable to add manual time entry due to:\n${stringifyValidationErrors(err)}`)
        return
      } finally {
        this.reqInProgress = false
      }

      const { timelog } = resp.data
      this.resetModalState()
      new Modal(this.$el).destroy()

      this.$emitter.emit('timelog:added', { timelog })
    },
  },

  emits: ['manualTimeEntryModal:closed'],
}
</script>

const NUMBER_FORMAT = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

export function formatNumber(number) {
  return NUMBER_FORMAT.format(number);
}

const PERCENT_NUMBER_FORMAT = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
});

export function formatPercent(number) {
  return PERCENT_NUMBER_FORMAT.format(number / 100);
}

<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-col-xs-12 flex-col-md-5 flex-col-lg-6">
        <MyAnalyticsNavTabs />
      </div>

      <div class="flex-col-xs-12 flex-col-md-7 flex-col-lg-6 text-align-right">
        <a href="#" @click.prevent="exportTeamCmpToCSVPath" class="btn btn-theme btn-block-mobile"
           :class="{ disabled: !user_ids.length }">

          Export
        </a>
      </div>
    </div>

    <div class="module module-expanded">
      <div class="module-header module-header-break">
        <h2 class="no-margin">Team comparison</h2>

        <div class="module-functions">
          <div class="module-function padding-small-right input-wrapper input-wrapper-vertical">
            <label for="department" class="input-label">Department</label>
            <select id="department" class="input input-select"
                    v-model="department" @change="onDeptChanged">

              <option :value="0">Select department</option>
              <option :value="id" v-for="[id, name] in sortedDepartments" :key="id">
                {{ name }}
              </option>
            </select>
          </div>

          <div class="module-function padding-small-right input-wrapper input-wrapper-vertical position-relative">
            <label class="input-label" data-toggle-dropdown>Team member</label>
            <div class="input input-single-line inline-flex-xs justify-content-space-between align-items-center"
                 data-toggle-dropdown>

              <span class="margin-right">
                <span v-if="user_ids.length > 1">
                  {{ user_ids.length }} Team memebers
                </span>
                <span v-else>
                  {{ firstUserName || 'Select user(s)' }}
                </span>
              </span>
              <i class="symbol symbol-search"></i>
            </div>

            <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                 data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

              <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                <div class="input-group input-block input-group-horizontal">
                  <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                         placeholder="Search users" @input="filterUsers">

                  <label class="font-size-normalize btn btn-symbol btn-small btn-default">
                    <span class="sr-only">Search</span>
                    <i class="symbol symbol-search"></i>
                  </label>
                </div>
              </div>

              <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active list-group-toggle-multiple">

                <label class="list-group-item pointer-reference"
                       :class="{ active: user_ids.includes(Number(id)) }"
                       @click.prevent="onUserToggled(Number(id))"
                       v-show="!userRegex || userRegex.test(name)"
                       v-for="[id, name] in userOptions" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="checkbox" class="sr-only">
                      <span class="text-wrap-ellipsis">
                        {{ name }}
                      </span>
                    </span>
                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="module-function input-wrapper input-wrapper-vertical">
            <label for="date-range" class="input-label">Date range</label>
            <DateRangePicker :dateFrom="date_from" :dateTo="date_to" />
          </div>
        </div>
      </div>

      <div class="module-content">
        <div class="flex-grid flex-grid-fixed flex-grid-compact" v-if="dept">
          <div class="flex-col-xs-6 flex-col-sm-4 flex-col-md-3"
               v-for="(taskCategory, i) in taskCategories" :key="taskCategory">

            <div class="text-wrap-ellipsis">
              <span class="legend" :style="`background-color: ${taskCategoryColorFor(i)}`">
              </span>
              {{ taskCategory }}
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <table class="timetracker-table table-bordered text-vertical-align-middle"
                 v-if="user_ids.length">

            <thead>
              <tr>
                <th class="no-border-x" colspan="2">Team member</th>
                <th class="no-border-x text-align-center">Active</th>
                <th class="no-border-x">Hours logged</th>
                <th class="no-border-x">
                  &nbsp;
                  <span class="sr-only">Total hours</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="background-primary-alpha-1"
                  :class="{ 'background-primary-alpha-1': pinnedUserIds.has(id) }"
                  v-for="[id, name] in sortedUsers" :key="id">

                <td class="no-border-left timetracker-td timetracker-td-w timetracker-td-w-50 text-align-center">
                  <a href="#" @click.prevent="togglePinnedUser(id)">
                    <span class="font-size-large color-neutral-darker"
                         :class="{ active: pinnedUserIds.has(id) }">

                      <i class="symbol symbol-pin-alt symbol-pin-toggle"></i>
                    </span>
                  </a>
                </td>

                <td class="no-border-left timetracker-td timetracker-td-w timetracker-td-w-250">
                  <div class="flex-xs align-items-center">
                    <div class="flex-0-0">
                      <UserThumb v-bind="users[id]" />
                    </div>

                    <div class="flex-1-1 padding-small-left">
                      <p class="h4 no-margin font-weight-400">{{ name }}</p>
                    </div>
                  </div>
                </td>

                <td class="timetracker-td timetracker-td-w timetracker-td-w-100 text-align-center">
                  <i class="font-size-large symbol symbol-time" v-if="active_timelog_by_user_id[id]"></i>
                  <span v-else>&nbsp;</span>
                </td>

                <td class="timetracker-td timetracker-td-w padding-x">
                  <div class="progress background-neutral-alpha-4">
                    <template v-if="dept">
                      <template v-for="(totalHrs, taskCategory) in hrs_logged_by_usr_and_maybe_task_cat[id]"
                                :key="`${id}-${taskCategory}`">

                        <template v-if="totalHrs">
                          <div class="progress-bar"
                               data-tooltip-placement="top"
                               :data-tooltip-content="`
                                 <div>
                                   Task category: \`${taskCategory}\`
                                   <h6 class='color-neutral no-margin'>
                                     Time spent: ${totalHrs} hours
                                     (${pctHrsLoggedBy(id, taskCategory)}%)
                                   </h6>
                                 </div>`"
                               :style="taskCatProgressBarStyles(id, taskCategory)"
                               data-toggle-tooltip-hover>
                          </div>
                        </template>
                      </template>
                    </template>

                    <div class="progress-bar background-primary-dark"
                         :style="`width: ${pctHrsLoggedBy(id)}%`" v-else>
                    </div>
                  </div>
                </td>

                <td class="no-border-x timetracker-td timetracker-td-w timetracker-td-w-150 text-align-center">
                  <span class="font-weight-700"></span>
                  {{ totalhrsLoggedBy(id) }} / {{ maxNumHrs }} hrs
                </td>
              </tr>
            </tbody>
          </table>

          <span class="font-style-italic color-neutral" v-else>
            Please select at least one user to continue.
          </span>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { differenceInDays } from 'date-fns'

import Layout from '../Layout'
import MyAnalyticsNavTabs from '../components/MyAnalyticsNavTabs'
import DateRangePicker from '../components/DateRangePicker'
import UserThumb from '../components/UserThumb'

import { parseISODate } from '../lib/date'
import { humanize } from '../lib/string'

export default {
  name: 'TeamCmp',

  props: {
    dept: {
      type: Number,
    },
    departments: {
      type: Object,
      required: true,
    },
    job_ids_by_dept_id: {
      type: Object,
      required: true,
    },
    date_from: {
      type: String,
      required: true,
    },
    date_to: {
      type: String,
      required: true,
    },
    max_num_hours_worked_per_day: {
      type: Number,
      required: true,
    },
    user_ids: {
      type: Array,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
    user_ids_by_job_id: {
      type: Object,
      required: true,
    },
    hrs_logged_by_usr_and_maybe_task_cat: {
      type: Object,
      required: true,
    },
    active_timelog_by_user_id: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      department: this.dept || 0,
      userRegex: null,
      pinnedUserIds: new Set(),
    }
  },

  computed: {
    dateFrom() {
      return parseISODate(this.date_from)
    },

    dateTo() {
      return parseISODate(this.date_to)
    },

    dayDiff() {
      return differenceInDays(this.dateTo, this.dateFrom) + 1
    },

    sortedDepartments() {
      return Object.entries(this.departments).sort((d1, d2) => d1[1].localeCompare(d2[1]))
    },

    firstUserName() {
      if (this.user_ids.length) {
        const { first_name: first, last_name: last } = this.users[this.user_ids[0]]
        return `${first} ${last}`
      } else {
        return ''
      }
    },

    userOptions() {
      return Object.entries(this.users)
                   .map(([id, { first_name, last_name }]) => [id, `${first_name} ${last_name}`])
                   .sort((u1, u2) => u1[1].localeCompare(u2[1]))
    },

    sortedUsers() {
      const unpinnedUserIds = this.user_ids.filter(userId => !this.pinnedUserIds.has(userId))
      const pinnedUsers = [...this.pinnedUserIds].map(this.mapUsersFn).sort(this.sortUsersFn),
            unpinnedUsers = unpinnedUserIds.map(this.mapUsersFn).sort(this.sortUsersFn)

      return pinnedUsers.concat(unpinnedUsers)
    },

    maxNumHrs() {
      return this.dayDiff * this.max_num_hours_worked_per_day
    },

    taskCategories() {
      if (!this.dept) return []

      const entries = Object.entries(this.hrs_logged_by_usr_and_maybe_task_cat)
      if (!entries.length) return []

      const [_, totalHrsByTaskCatName] = entries[0]
      return Object.keys(totalHrsByTaskCatName).sort()
    },
  },

  methods: {
    exportTeamCmpToCSVPath() {
      const params = new URLSearchParams(location.search)
      params.set('csv', 1)

      location.href = `${location.pathname}?${params}`
    },


    togglePinnedUser(userId) {
      if (this.pinnedUserIds.has(userId)) {
        this.pinnedUserIds.delete(userId)
      } else {
        this.pinnedUserIds.add(userId)
      }
    },

    mapUsersFn(userId) {
      const { first_name, last_name } = this.users[userId]
      return [userId, `${first_name} ${last_name}`]
    },

    sortUsersFn(user1, user2) {
      return user1[1].localeCompare((user2[1]))
    } ,

    onDeptChanged() {
      let userIds
      if (this.department) {
        const jobIds = this.job_ids_by_dept_id[this.department] || []
        userIds = jobIds.flatMap(jobId => this.user_ids_by_job_id[jobId] || [])
      } else {
        userIds = []
        // userIds = [...this.user_ids]
      }

      const params = new URLSearchParams(location.search)
      if (this.department) {
        params.set('dept', this.department)
      } else {
        params.delete('dept')
      }
      params.delete('user')
      for (const userId of userIds) params.append('user', userId)

      this.$inertia.get(location.pathname, params, {
        only: ['dept', 'user_ids', 'hrs_logged_by_usr_and_maybe_task_cat', 'active_timelog_by_user_id'],
      })
    },

    filterUsers(e) {
      const userQuery = e.target.value.trim()
      if (!userQuery) {
        this.userRegex = null
        return
      }

      this.userRegex = new RegExp(userQuery, 'i')
    },

    onUserToggled(id) {
      const params = new URLSearchParams(location.search)
      params.delete('user')
      const userIds = [...this.user_ids], index = userIds.indexOf(id)
      if (index === -1) {
        userIds.push(id)
      } else {
        userIds.splice(index, 1)
      }
      for (const userId of userIds) params.append('user', userId)

      this.$inertia.get(location.pathname, params, {
        only: ['user_ids', 'hrs_logged_by_usr_and_maybe_task_cat', 'active_timelog_by_user_id'],
      })
    },

    pctHrsLoggedBy(userId, taskCategory = undefined) {
      if (taskCategory) {
        const entries = Object.entries(this.hrs_logged_by_usr_and_maybe_task_cat[userId]),
          index = entries.findIndex(([taskCat, _]) => taskCat === taskCategory)

        const [[_, totalhrsForTaskCat]] = entries.splice(index, 1)
        if (!entries.length) return 100.0

        let totalhrs = entries.reduce((accu, [_, totalHrs]) => accu + totalHrs, 0)
        totalhrs += totalhrsForTaskCat

        return ((totalhrsForTaskCat * 100) / totalhrs).toFixed(1)

      } else {
        return ((this.hrs_logged_by_usr_and_maybe_task_cat[userId] * 100) / this.maxNumHrs).toFixed(1)
      }
    },

    taskCategoryColorFor(index) {
      return this.$page.props.app_config.task_category_colors[index]
    },

    taskCatProgressBarStyles(userId, taskCategory) {
      const idx = this.taskCategories.indexOf(taskCategory)
      if (idx === -1) return {}

      return {
        width: `${this.pctHrsLoggedBy(userId, taskCategory)}%`,
        backgroundColor: this.taskCategoryColorFor(idx),
      }
    },

    totalhrsLoggedBy(userId) {
      if (this.dept) {
        return Object.values(this.hrs_logged_by_usr_and_maybe_task_cat[userId])
                     .reduce((accu, totalHrs) => accu + totalHrs, 0)
                     .toFixed(1)
      } else {
        return this.hrs_logged_by_usr_and_maybe_task_cat[userId]
      }
    },
  },

  components: {
    Layout,
    MyAnalyticsNavTabs,
    DateRangePicker,
    UserThumb,
  },
}
</script>

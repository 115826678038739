<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="h2 font-weight-300 no-margin-y">Jobs</div>
      </div>

      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="job-title-or-dept-name" class="sr-only">Job title or dept name</label>
              <input id="job-title-or-dept-name" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="jobTitle"
                     placeholder="Job title or dept name" @input="debouncedFilterJobs">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.jobs.new')" class="btn btn-theme btn-block-mobile">
              Add new +
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div class="module">
      <div class="module-content no-padding">
        <div class="table-wrapper" v-if="jobs.length">
          <table class="timetracker-table">
            <thead>
              <tr class="background-transparent">
                <th class="background-transparent no-border-top">Job Title</th>
                <th class="background-transparent no-border-top">Description</th>
                <th class="background-transparent no-border-top">Department</th>
                <th class="background-transparent no-border-top text-align-center">Task Categories</th>
                <th class="background-transparent no-border-top">Reports To</th>
                <th class="background-transparent no-border-top text-align-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="job in jobs" :key="job.id">
                <td>
                  <div class="text-wrap-ellipsis">
                    <Link :href="$route('admin.jobs.edit', { id: job.id })">
                      {{ job.title }}
                    </Link>
                  </div>
                </td>

                <td class="timetracker-td timetracker-td-w timetracker-td-w-300">
                  <h6 class="hide-nonmobile no-margin-top">Description</h6>

                  <div class="text-wrap-ellipsis" v-if="job.description">
                    {{ job.description }}
                  </div>
                  <span class="font-style-italic color-neutral" v-else>N/A</span>
                </td>

                <td class="timetracker-td timetracker-td-w timetracker-td-w-200">
                  <h6 class="hide-nonmobile no-margin-top">Department</h6>
                  <Link :href="$route('admin.departments.edit', { id: job.department.id })">
                    {{ job.department.name }}
                  </Link>
                </td>

                <td class="text-align-center">
                  <h6 class="hide-nonmobile no-margin-top">Task Categories</h6>
                  {{ job.task_category_count }}
                </td>

                <td>
                  <h6 class="hide-nonmobile no-margin-top">Reports To</h6>

                  <Link :href="$route('admin.jobs.edit', { id: job.report.id })"
                        v-if="job.report">

                    {{ job.report.title }}
                  </Link>
                  <span class="font-style-italic color-neutral" v-else>N/A</span>
                </td>

                <td class="timetracker-td-actions text-align-right">
                  <a href="#" class="btn btn-link btn-symbol" title="Delete job"
                     @click.prevent="confirmDeletion(job)">

                    <i class="symbol symbol-delete"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="font-style-italic color-neutral padding" v-else>
          There are no jobs yet.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import * as _ from 'lodash-es'

import Layout from '../Layout'

export default {
  name: 'JobList',

  props: {
    jobs: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
  },

  created() {
    this.debouncedFilterJobs = _.debounce(this.filterJobs,
                                          this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.jobTitle.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterJobs.cancel()
  },

  methods: {
    filterJobs(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
    },

    confirmDeletion({ id, title }) {
      if (!confirm(`Are you sure you to delete the '${title}' job?`)) return

      this.$inertia.delete(reverseUrl('admin.jobs.destroy', { id }))
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

export function projectStatusCSSClass(status) {
  switch (status) {
    case 'Design':
    case 'Development':
      return 'primary';
    case 'QA+Review':
    case 'Post-Live QC':
      return 'secondary';
    case 'Completed':
      return 'success';
    case 'Blocked':
      return 'error';
    default:
      return 'neutral';
  }
}

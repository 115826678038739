<template>
  <Layout>
    <div id="timetracker-error" class="text-align-center">
      <div class="container">
        <div class="module module-expanded background-transparent no-border no-shadow">
          <div class="module-content">
            <div class="special-primary color-neutral font-weight-900" v-if="status_code">{{ status_code }}</div>
            <p>{{ err_msg }}</p>
            <Link :href="referrer || $route('main.time')" class="btn btn-primary">Back</Link>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>


<script>
import { Link } from '@inertiajs/vue3'

import Layout from '../Layout'

export default {
  name: 'Error',

  props: {
    err_msg: {
      type: String,
      required: true
    },
    status_code: {
      type: Number,
    },
    referrer: {
      type: String,
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

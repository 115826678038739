<template>
  <Layout>
    <div class="flex-grid align-items-center">
      <div class="flex-col-xs-12 flex-col-md-5 flex-col-lg-6">
        <MyAnalyticsNavTabs />
      </div>

      <div class="flex-col-xs-12 flex-col-md-7 flex-col-lg-6 text-align-right">
        <DateRangePicker :dateFrom="date_from" :dateTo="date_to" />

        <span class="spacer">&nbsp;</span>

        <a href="#" @click.prevent="exportMyAnalyticsToXlsxPath" class="btn btn-theme btn-block-mobile">
          Export
        </a>
      </div>
    </div>

    <div class="module-grid align-items-stretch" ref="gridLayout"
         data-grid-flow="dense"
         data-grid-template-rows=" minmax(100px,min-content) minmax(180px,min-content) 1fr"
         data-grid-auto-rows="auto"
         data-grid-template-columns-xs="100%"
         data-grid-template-areas-xs="
           'sidebar'
           'overview'
           'scheduled'
           'log'
           'allocation'
           'activity'
           'latest'"
         data-grid-template-columns-md="minmax(235px,1fr) 8fr 18fr"
         data-grid-template-areas-md="
           'sidebar overview overview'
           'sidebar scheduled scheduled'
           'sidebar log log'
           'allocation allocation activity'
           'latest latest latest'"
         data-grid-template-columns-lg="minmax(235px,1fr) 4fr 2fr"
         data-grid-template-areas-lg="
           'sidebar overview allocation'
           'sidebar scheduled allocation'
           'sidebar log allocation'
           'sidebar log activity'
           'latest latest latest'">

      <MyAnalyticsUserProfile />
      <MyAnalyticsNumHrsByDate :mostActiveDays="most_active_days" />
      <!--MyAnalyticsNumHrsByDate :numHoursByDate="num_hours_by_date" v-if="dayDiff <= 7" /-->
      <MyAnalyticsSchedHrs v-bind="scheduled_hours" v-if="scheduled_hours" />
      <MyAnalyticsTimeLogChart :model="timelog_chart_model" :data="timelog_chart_data"
                               @model:changed="onModelChanged" />
      <TaskCatTimeAllocChart :data="tot_time_pct_by_task_cat" />
      <MyAnalyticsActivityCharts :mostActiveDays="most_active_days"
                                 :mostActiveHours="most_active_hours" />
      <MyAnalyticsLatestTimelogs :timelogs="timelogs" :recordCount="record_count"
                                 :page="page" :pageCount="page_count" :perPage="per_page" />
    </div>

    <TaskCatTimeDist :timeDist="time_dist" :user="user" :dateFrom="date_from" :dateTo="date_to" />
    <ProjTimeDist :projects="projects" :user="user" :dateFrom="date_from" :dateTo="date_to" />
  </Layout>
</template>

<script>
import ModuleGrid from '../imports/module-grid'
import Tooltip from '../imports/tooltip'

import Layout from '../Layout'
import MyAnalyticsNavTabs from '../components/MyAnalyticsNavTabs'
import DateRangePicker from '../components/DateRangePicker'
import MyAnalyticsUserProfile from '../components/MyAnalyticsUserProfile'
import MyAnalyticsNumHrsByDate from '../components/MyAnalyticsNumHrsByDate'
import MyAnalyticsSchedHrs from '../components/MyAnalyticsSchedHrs'
import MyAnalyticsLatestTimelogs from '../components/MyAnalyticsLatestTimelogs'
import MyAnalyticsTimeLogChart from '../components/MyAnalyticsTimeLogChart'
import TaskCatTimeAllocChart from '../components/TaskCatTimeAllocChart'
import MyAnalyticsActivityCharts from '../components/MyAnalyticsActivityCharts'
import ProjTimeDist from '../components/ProjTimeDist'
import TaskCatTimeDist from '../components/TaskCatTimeDist'

export default {
  name: 'MyAnalytics',

  props: {
    date_from: {
      type: String,
      required: true,
    },
    date_to: {
      type: String,
      required: true,
    },
    timelog_chart_model: {
      type: String,
      required: true,
    },
    timelog_chart_data: {
      type: Object,
      required: true,
    },
    // num_hours_by_date: {
    //   type: Object,
    //   required: true,
    // },
    scheduled_hours: {
      type: Object,
    },
    tot_time_pct_by_task_cat: {
      type: Object,
      required: true,
    },
    most_active_days: {
      type: Array,
      required: true,
    },
    most_active_hours: {
      type: Object,
      required: true,
    },
    timelogs: {
      type: Array,
      required: true,
    },
    record_count: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    page_count: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      required: true,
    },
    time_dist: {
      type: Array,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  mounted(){
    new ModuleGrid(this.$refs.gridLayout).render()
  },

  methods: {
    exportMyAnalyticsToXlsxPath() {
      const params = new URLSearchParams(location.search)
      params.set('xlsx', 1)

      location.href = `${location.pathname}?${params}`
    },
    onModelChanged(timelogChartModel) {
      const params = new URLSearchParams(location.search)
      params.set('timelog_chart_model', timelogChartModel)

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['timelog_chart_model', 'timelog_chart_data'],
      })
    },
  },

  components: {
    Layout,
    MyAnalyticsNavTabs,
    DateRangePicker,
    MyAnalyticsUserProfile,
    MyAnalyticsNumHrsByDate,
    MyAnalyticsSchedHrs,
    MyAnalyticsLatestTimelogs,
    MyAnalyticsTimeLogChart,
    TaskCatTimeAllocChart,
    MyAnalyticsActivityCharts,
    ProjTimeDist,
    TaskCatTimeDist,
  },
}
</script>

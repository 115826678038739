<template>
  <div class="h2 font-weight-300 time-today margin-bottom">{{ todayStr }}</div>

  <div class="module">
    <div class="module-content">
      <div class="flex-grid flex-grid-compact justify-content-space-between align-items-center text-align-center">
        <div class="flex-col-xs-12 flex-col-md-4 flex-col-lg-3">
          <div class="inline-flex align-items-center">
            <span class="h5 no-margin-y font-weight-400 margin-small-right">Today</span>
            <span class="h3 font-weight-600 no-margin color-inherit">
              {{ todaysTotalTime }}
            </span>
          </div>

          <span class="separator margin-small-x">|</span>

          <div class="inline-flex align-items-center">
            <span class="h5 no-margin-y font-weight-400 margin-small-right">This week</span>
            <span class="h3 font-weight-600 no-margin color-inherit">
              {{ thisWeeksTotalTime }}
            </span>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-md-8">
          <div class="table-wrapper">
            <table class="table-fixed">
              <tbody>
                <tr>
                  <td class="no-border" v-for="(totalTime, dateStr) in thisWeeksTotals" :key="dateStr">
                    <!-- @NOTE 3 day letter -->
                    <!-- classes to add
                      `background-primary color-background` => if day is today
                    -->
                    <span class="display-inline-block font-weight-700 padding-small-x radius"
                          :class="shortWeekdayCssClass(dateStr)">

                      {{ dateISOStrToShortWeeekDay(dateStr) }}
                    </span>
                    <br>
                    <span>
                      {{ secsToTimeStr(totalTime) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TZDateMini } from '@date-fns/tz';
import { formatDate, parseISODate, secondsToTimeStr } from '../lib/date'

export default {
  name: 'WeeklyOverview',

  props: {
    thisWeeksTotals: {
      type: Object,
      required: true
    },
  },

  computed: {
    now() {
      return new TZDateMini(Date.now(), this.$timeZone.value)
    },

    todayStr() {
      return formatDate(this.now, { format: 'long' })
    },

    todayISOStr() {
      return formatDate(this.now, { format: 'ISO' })
    },

    todaysTotalTime() {
      return secondsToTimeStr(this.thisWeeksTotals[this.todayISOStr])
    },

    thisWeeksTotalTime() {
      const totalTimeThisWeek = Object.values(this.thisWeeksTotals).reduce((accu, secs) => accu + secs, 0)
      return secondsToTimeStr(totalTimeThisWeek)
    },
  },

  methods: {
    secsToTimeStr(seconds) {
      return secondsToTimeStr(seconds)
    },

    dateISOStrToShortWeeekDay(isoDateStr) {
      return formatDate(parseISODate(isoDateStr), {
        format: 'short_weekday',
        timeZone: this.$timeZone
      })
    },

    shortWeekdayCssClass(isoDateStr) {
      return isoDateStr === this.todayISOStr ? ['background-primary', 'color-background'] : []
    },
  },
}
</script>

<template>
  <div :id="`${modelCls}-del-confirm-modal`"
       class="modal modal-expanded modal-default" data-modal-width="500px"
       data-modal-change-hash="false">

	  <form :action="action" class="padding" @submit.prevent="submitForm">
	  	<h2 class="color-inherit no-margin-top">Delete {{ modelCls }}</h2>
	  	<p>Are you sure you want to delete this {{ modelCls }}?</p>

	  	<label class="input-toggle input-toggle-primary padding-small-y p"
             v-if="syncedToWrike">

	  		<input type="checkbox" class="input" v-model="deleteOnWrike">
	  		<span class="input-label input-toggle-label font-weight-700">
          Also delete on Wrike?
	  		</span>
	  	</label>

	  	<div class="flex-grid flex-grid-compact flex-grid-fixed">
	  		<div class="flex-col-xs-12 flex-col-sm-6">
	  			<button class="btn btn-theme btn-block">Delete</button>
	  		</div>

	  		<div class="flex-col-xs-12 flex-col-sm-6">
          <a href="#" class="btn btn-theme-outline btn-block font-weight-400"
             data-toggle-modal-default-close>

           Cancel
         </a>
	  		</div>
	  	</div>
	  </form>
  </div>
</template>

<script>
import axios from 'axios'

import { stringifyErrors } from '../lib/string'

export default {
  name: 'ModelDelConfirmModal',

  props: {
    modelCls: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    successPath: {
      type: String,
      required: true,
    },
    syncedToWrike: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      deleteOnWrike: false,
    }
  },

  methods: {
    async submitForm(e) {
      try {
        const resp = await axios.delete(e.target.action, {
          data: {
            delete_on_wrike: this.deleteOnWrike,
          },
        })

        this.$inertia.get(this.successPath)
      } catch(err) {
        alert(`Unable to delete ${this.modelCls} due to:\n${stringifyErrors(err)}`)
      }
    },
  },
}
</script>

<template>
  <tr>
    <td class="text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-100">
      <div class="display-inline-block position-relative">
        <div class="display-inline-block">
          <div class="thumbnail-cluster">
            <div class="thumbnail thumbnail-micro"
                 v-for="responsibleId in responsibles" :key="responsibleId"
                 v-if="responsibles.length <= 2">

              <span class="thumbnail-text" :title="userNameById[responsibleId]">
                {{ userInitialsById[responsibleId] }}
              </span>
            </div>

            <template v-else>
              <div class="thumbnail thumbnail-micro">
                <span class="thumbnail-text" :title="userNameById[responsibles[0]]">
                  {{ userInitialsById[responsibles[0]] }}
                </span>
              </div>
              <div class="thumbnail thumbnail-micro">
                <span class="thumbnail-text">+ {{ responsibles.length - 1 }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </td>

    <td class="no-border-bottom timetracker-td">
      <Link :href="$linkTo('admin.tasks.edit', { viewArgs: [id], returnBack: true })"
            class="h4 color-inherit font-weight-400 no-margin-y display-inline-block">

        {{ title }}
      </Link>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Date due</span>
        <!-- @if has a due task -->
          <!--
            span
              @classes to add
              'color-error-dark' => priority task/ 24 hours or less due
        -->
        <span v-if="due_date || completed" :class="dueDateCSSClass">
          <i class="symbol symbol-success" v-if="completed"></i>
          <i class="symbol symbol-calendar" v-else></i>
          {{ completedOn || dueOn }}
        </span>
        <div class="color-neutral" v-else>
          <i class="symbol symbol-minus"></i>
          No due date set
        </div>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-200 text-align-center">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Status</span>
      <!-- @NOTE
        .tag
          classes to add
            `tag-neutral` => default/backlog/maintenance backlog/production backlog
            `tag-primary` => when in progress/production
            `tag-secondary` => when in review
            `tag-error` => when on hold
            `tag-success` => completed
      -->
      <div class="tag tag-neutral" :class="taskStatusTagCssClass" v-if="status">
        {{ status.name }}
      </div>
      <span v-else>&nbsp;</span>
    </td>
  </tr>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import { formatDate, parseISODate } from '../lib/date'
import { dueDateCSSClass, taskStatusCSSClass } from '../lib/task'

export default {
  name: 'ProjectTask',

  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    status: {
      type: Object,
    },
    due_date: {
      type: String,
    },
    completed: {
      type: String,
    },
    created: {
      type: String,
      required: true,
    },
    responsibles: {
      type: Array,
      required: true,
    },
    userNameById: {
      type: Object,
      required: true,
    },
    userInitialsById: {
      type: Object,
      required: true,
    },
  },

  computed: {
    dueDate() {
      if (!this.due_date) return

      return parseISODate(this.due_date)
    },

    dueOn() {
      if (!this.due_date) return ''

      return formatDate(this.dueDate, { format: 'short', timeZone: this.$timeZone })
    },

    completedOn() {
      if (!this.completed) return ''

      return formatDate(parseISODate(this.completed), { format: 'short', timeZone: this.$timeZone })
    },

    createdOn() {
      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },

    dueDateCSSClass() {
      return dueDateCSSClass(this.dueDate, this.$timeZone)
    },

    taskStatusTagCssClass() {
      return `tag-${taskStatusCSSClass(this.status?.name)}`
    },
  },

  components: {
    Link,
  },
}
</script>

<template>
  <Layout>
    <div class="h6 color-neutral-dark font-weight-400">
      <Link :href="$route('admin.projects.index')" class="color-inherit">
        Projects
      </Link>

      <span class="separator">&gt;&nbsp;</span>

      <Link :href="id ? $route('admin.projects.edit', { id }) : $route('admin.projects.new')"
            class="color-inherit font-weight-700">

        {{ id ? name : 'New project' }}
      </Link>
    </div>

    <ul class="tabs">
      <!-- @NOTE
        li
          .classes to add:
            'active' => 'if current template matches the contained list item'
      -->

      <li :class="{ active: currentTab === 'ProjectDetails' }">
        <a href="#" @click.prevent="currentTab = 'ProjectDetails'">Details</a>
      </li>
      <li :class="{ active: currentTab === 'ProjectTasks' }" v-if="id">
        <a href="#" @click.prevent="currentTab = 'ProjectTasks'">Tasks</a>
      </li>
    </ul>

    <KeepAlive>
      <component :is="currentTab" v-bind="currentTabProps" />
    </KeepAlive>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import Layout from '../Layout'
import ProjectDetails from './ProjectDetails'
import ProjectTasks from './ProjectTasks'

export default {
  name: 'ProjectForm',

  props: {
    id: {
      type: Number,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    billing_type: {
      type: String,
    },
    hourly_rate: {
      type: Number,
    },
    total_fee: {
      type: Number,
    },
    hours_budget: {
      type: Number,
    },
    fees_budget: {
      type: Number,
    },
    reset_budget_monthly: {
      type: Boolean,
    },
    budget_pct_threshold: {
      type: Number,
    },
    budget_pct_thres_exceeded_recipients: {
      type: Object,
    },
    created: {
      type: String,
    },
    synced_to_wrike: {
      type: Boolean,
    },
    status_id: {
      type: Number,
    },
    type_id: {
      type: Number,
    },
    parent_id: {
      type: Number,
    },
    parent: {
      type: Object,
    },
    parent_project_candidates: {
      type: Array,
      required: true,
    },
    client_id: {
      type: Number,
    },
    client: {
      type: Object,
    },
    project_statuses: {
      type: Object,
      required: true,
    },
    project_types: {
      type: Object,
      required: true,
    },
    proj_billing_types: {
      type: Array,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
    job_title_by_id: {
      type: Object,
      required: true,
    },
    job_ids_by_dept_id: {
      type: Object,
      required: true,
    },
    job_id_by_model_job_id: {
      type: Object,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
    user_ids_by_job_id: {
      type: Object,
      required: true,
    },
    user_ids_by_model_job_id: {
      type: Object,
      required: true,
    },
  },

  data() {
    const currentTab = location.hash.substring(1) === 'tasks' ? 'ProjectTasks' : 'ProjectDetails'

    return {
      currentTab,
    }
  },

  computed: {
    currentTabProps() {
      if (this.currentTab === 'ProjectDetails') {
        return {
          id: this.id,
          name: this.name,
          description: this.description,
          billingType: this.billing_type,
          hourlyRate: this.hourly_rate,
          totalFee: this.total_fee,
          hoursBudget: this.hours_budget,
          feesBudget: this.fees_budget,
          resetBudgetMonthly: this.reset_budget_monthly,
          budgetPctThreshold: this.budget_pct_threshold,
          budgetPctThresExceededRecipients: this.budget_pct_thres_exceeded_recipients,
          created: this.created,
          syncedToWrike: this.synced_to_wrike,
          statusId: this.status_id,
          typeId: this.type_id,
          parentId: this.parent_id,
          parentProjectCandidates: this.parent_project_candidates,
          parent: this.parent,
          clientId: this.client_id,
          client: this.client,
          projectStatuses: this.project_statuses,
          projectTypes: this.project_types,
          projBillingTypes: this.proj_billing_types,
          owners: this.owners,
          jobTitleById: this.job_title_by_id,
          jobIdsByDeptId: this.job_ids_by_dept_id,
          jobIdByModelJobId: this.job_id_by_model_job_id,
          users: this.users,
          user_ids_by_job_id: this.user_ids_by_job_id,
          userIdsByModelJobId: this.user_ids_by_model_job_id,
        }
      } else {
        return {
          id: this.id,
          users: this.users,
        }
      }
    },
  },

  watch: {
    currentTab(newTab) {
      if (newTab === 'ProjectTasks') {
        location.hash = '#tasks'
      } else {
        location.hash = ''
      }
    },
  },

  components: {
    Layout,
    Link,
    ProjectDetails,
    ProjectTasks,
  },
}
</script>

<template>
  <div class="module" data-grid-area="scheduled">
    <div class="module-content">
      <div class="flex-grid justify-content-space-between align-items-center flex-wrap">
        <div class="flex-child align-self-flex-start flex-1-1 margin-right-auto">
          <h5 class="no-margin-y">Total hours worked</h5>
        </div>
        <div class="flex-child align-self-center">
          <i class="legend legend-primary"></i> Billable
        </div>
        <div class="flex-child align-self-center">
          <i class="legend background-theme-polar"></i> Non-billable
        </div>
      </div>

      <p class="color-neutral margin-small-bottom">
        <span class="color-theme display-inline h2 no-margin">{{ timeWorked }}</span>
        <span> / {{ totalTime }}</span>
      </p>

      <div class="progress p">
        <div class="progress-bar progress-bar-primary" :style="`width: ${billableTimePct}%`"
             :title="`Billable time: ${billableTimePct.toFixed(1)}%`">
        </div>
        <div class="progress-bar background-theme-polar" :style="`width: ${nonBillableTimePct}%`"
             :title="`Non-billable time: ${nonBillableTimePct.toFixed(1)}%`">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { secondsToTimeStr } from '../lib/date'

export default {
  name: 'MyAnalyticsSchedHrs',

  props: {
    billable_time: {
      type: Number,
      required: true,
    },
    non_billable_time: {
      type: Number,
      required: true,
    },
    num_days: {
      type: Number,
      required: true,
    },
  },

  computed: {
    timeWorked() {
      return secondsToTimeStr(this.billable_time + this.non_billable_time)
    },
    billableTimePct() {
      return this.secs2TimePct(this.billable_time)
    },
    nonBillableTimePct() {
      return Math.min(this.secs2TimePct(this.non_billable_time), 100 - this.billableTimePct)
    },
    totalNumSecs() {
      return this.num_days * this.$page.props.app_settings.max_num_hours_worked_per_day * 3_600
    },
    totalTime() {
      return secondsToTimeStr(this.totalNumSecs)
    },
  },

  methods: {
    secs2TimePct(seconds) {
      return (seconds / this.totalNumSecs) * 100
    },
  },
}
</script>

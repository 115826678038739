<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <h1 class="h2 font-weight-300 no-margin-y">Insights on: {{ userFullName }}</h1>
      </div>

      <div class="flex-child flex-col-xs-12 flex-col-md-6 text-align-right">
        <a :href="usersPath" class="btn btn-neutral-glassy btn-block-mobile">
          Back
        </a>
      </div>
    </div>

    <div class="module module-large margin-large-y">
      <div class="module-content">
        <div class="flex-grid align-items-center">
          <div class="flex-col-xs-12 flex-col-lg-3">
            <div class="padding-large-right">
              <p class="h1 font-weight-400 no-margin-y">
                <strong class="sr-only">Name</strong>
                <span class="font-weight-400">{{ userFullName }}</span>
              </p>
              <p class="no-margin-y">
                <strong>Job Title:</strong>
                {{ user.job?.title || 'N/A' }}
              </p>
            </div>
          </div>

          <div class="flex-child flex-1-1">
            <form @submit.prevent="submitForm">
              <div class="flex-grid flex-grid-fixed flex-grid-expanded flex-grid-no-gutter-y align-items-center">
                <div class="flex-child flex-1-1">
                  <div class="flex-grid flex-nowrap flex-grid-no-gutter-y flex-grid-compact">
                    <div class="flex-child flex-1-1">
                      <div class="flex-sm">
                        <label for="date-from" class="input-label">From</label>
                        <input type="date" id="date-from"
                               class="input input-single-line input-block"
                               v-model="dateFrom" required>
                      </div>
                    </div>

                    <div class="flex-child flex-1-1">
                      <div class="flex-sm">
                        <label for="date-to" class="input-label">To</label>
                        <input type="date" id="date-to"
                               class="input input-single-line input-block" :max="today"
                               v-model="dateTo" required>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex-col-xs-12 flex-col-md-3 ">
                  <hr class="only-mobile">
                  <button class="btn btn-theme btn-block no-padding-x">Adjust</button>
                </div>
              </div>

              <div class="input-group input-group-horizontal input-group-responsive input-block">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <TaskCatTimeDist :timeDist="time_dist" :user="user" :dateFrom="date_from" :dateTo="date_to" />
    <ProjTimeDist :projects="projects" :user="user" :dateFrom="date_from" :dateTo="date_to" />

    <div class="h4 margin-bottom">Tasks</div>
    <div class="module">
      <div class="module-content no-padding">
        <div class="table-wrapper" v-if="tasks.length">
          <table class="timetracker-table">
            <thead>
              <tr class="background-transparent">
                <th class="background-transparent no-border-top">Name</th>
                <th class="background-transparent no-border-top">Category</th>
                <th class="background-transparent no-border-top text-align-center">Hours</th>
                <th class="background-transparent no-border-top text-align-center">
                  &nbsp;<div class="sr-only">% Time Graph</div>
                </th>
                <th class="background-transparent no-border-top text-align-center">% Time</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="row in tasks" :key="row.task.id">
                <td class="timetracker-td timetracker-td-w timetracker-td-w-250">
                  <div class="text-wrap-ellipsis">
                    <span class="font-weight-600">
                      {{ row.task.title }}
                    </span>
                  </div>

                  <div class="text-wrap-ellipsis">
                    {{ row.task.project.name }}
                  </div>
                </td>

                <td>
                  <h6 class="hide-nonmobile no-margin-top">Category</h6>
                  {{ row.task.category?.name || 'N/A' }}
                </td>

                <td class="text-align-center timetracker-td">
                  <h6 class="hide-nonmobile no-margin-top">Hours</h6>
                  {{ secsToTimeStr(row.hours) }}
                </td>

                <td class="text-align-center timetracker-td timetracker-td-w timetracker-td-w-250 text-vertical-align-middle">
                  <h6 class="hide-nonmobile no-margin-top sr-only">% Time Graph</h6>
                  <div class="text-vertical-align-middle display-inline-block progress margin-auto-x">
                    <div class="progress-bar progress-bar-primary"
                         :style="{ width: `calc(${row.pct_time} * 1%)`}">

                      <span class="sr-only">{{ formatPct(row.pct_time) }}</span>
                    </div>
                  </div>
                </td>

                <td class="text-align-center timetracker-td">
                  <h6 class="hide-nonmobile no-margin-top">% Time</h6>
                  {{ formatPct(row.pct_time) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="font-style-italic color-neutral padding" v-else>
          No tasks match the specified date range.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { TZDateMini } from '@date-fns/tz';

import Layout from '../Layout'
import ProjTimeDist from '../components/ProjTimeDist'
import TaskCatTimeDist from '../components/TaskCatTimeDist'

import { formatDate, parseISODate, secondsToTimeStr  } from '../lib/date'
import { formatNumber, formatPercent } from '../lib/number'

export default {
  name: 'UserInsights',

  props: {
    time_dist: {
      type: Array,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    date_from: {
      type: String,
      required: true,
    },
    date_to: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      _dateFrom: parseISODate(this.date_from),
      _dateTo: parseISODate(this.date_to),
    }
  },

  computed: {
    usersPath() {
      return reverseUrl('admin.users.index')
    },

    userFullName() {
      return `${this.user.first_name} ${this.user.last_name}`
    },

    today() {
      return formatDate(new TZDateMini(Date.now(), this.$timeZone.value), { format: 'ISO' })
    },

    dateFrom: {
      get() {
        return formatDate(this._dateFrom, { format: 'ISO' })
      },

      set(newValue) {
        let candidate = parseISODate(newValue)

        if (candidate > this._dateTo) {
          this.$nextTick(() => { this._dateFrom = this._dateTo })
        } else {
          this._dateFrom = candidate
        }
      },
    },

    dateTo: {
      get() {
        return formatDate(this._dateTo, { format: 'ISO' })
      },

      set(newValue) {
        let candidate = parseISODate(newValue)

        if (candidate < this._dateFrom) {
          this.$nextTick(() => { this._dateTo = this._dateFrom })
        } else {
          this._dateTo = candidate
        }
      },
    },
  },

  methods: {
    secsToTimeStr(seconds) {
      return secondsToTimeStr(seconds)
    },

    formatNum(number) {
      return formatNumber(number)
    },

    formatPct(number) {
      return formatPercent(number)
    },

    submitForm() {
      this.$inertia.reload({
        data: {
          date_from: this.dateFrom,
          date_to: this.dateTo,
        },
      })
    },
  },

  components: {
    Layout,
    ProjTimeDist,
    TaskCatTimeDist,
  },
}
</script>

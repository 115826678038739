<template>
  <tr>
    <td class="text-align-center no-border-bottom timetracker-td text-align-center timetracker-td timetracker-td-w timetracker-td-w-50">
      <label class="input-label no-padding">
        <input type="checkbox" class="input-inline" :disabled="!userCanDeleteTask" v-model="selected"
               @change="$emit('task:selected', { id, checked: $event.target.checked })">
        <span class="hide-nonmobile">Select task</span>
      </label>
    </td>

    <td class="no-border-bottom timetracker-td">
      <Link :href="$route('admin.tasks.edit', { id })"
            class="color-inherit font-weight-400 no-margin-y display-inline-block">

        {{ title }}
      </Link>

      <span class="hide-nonmobile no-margin-x no-margin-bottom outreach-table-mobile-label h6 color-neutral">
        Project
      </span>
      <span>&nbsp;</span>
      <Link :href="$route('admin.projects.edit', { id: project.id })" class="color-neutral-dark"
            v-if="$page.props.current_user.is_staff">

        {{ project.name }}
      </Link>
      <a href="#" v-else>{{ project.name }}</a>
    </td>

    <td class="text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Author</span>
      <UserThumb v-bind="authorById[authors[0]]" v-if="authors.length" />
    </td>

    <td class="text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Assignee</span>
      <UserThumb v-bind="responsibleById[responsibles[0]]" v-if="responsibles.length" />
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Date created</span>
      <i class="symbol symbol-file"></i>
      {{ createdOn }}
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Date due</span>
      <!-- @if has a due task -->
        <!--
          span
            @classes to add
            'color-error-dark' => priority task/ 24 hours or less due
      -->
      <span v-if="due_date || completed" :class="dueDateCSSClass">
        <i class="symbol symbol-success" v-if="completed"></i>
        <i class="symbol symbol-calendar" v-else></i>
        {{ completedOn || dueDate }}
      </span>
      <div class="color-neutral" v-else>
        <i class="symbol symbol-minus"></i>
        No due date set
      </div>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-200 text-align-center">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Status</span>

      <form class="tag no-margin-y" :class="statusCSSClass">
        <select class="input input-blend input-block no-border-bottom text-align-center"
                :value="status?.id || 0"
                @change="changeStatus">

          <option value="0">Select status</option>

          <optgroup :label="wrikeWf"
                    v-for="(statusIdByName, wrikeWf) in taskStatusOptions"
                    :key="wrikeWf">

            <option :value="id" v-for="(id, name) in statusIdByName" :key="id">
              {{ name }}
            </option>
          </optgroup>
        </select>
      </form>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-75 text-align-center">
      <template v-if="children.length">
        <a :href="`#task-${id}-children`" class="hide-nonmobile" data-toggle-accordion>
          <i class="symbol symbol-arrow-down symbol-arrow-up-toggle"></i> Toggle subtasks
        </a>
        <a :href="`#task-${id}-children`"
           class="btn btn-small btn-primary-outline btn-symbol btn-round" data-toggle-accordion>

          <i class="symbol symbol-arrow-down symbol-arrow-up-toggle"></i>
        </a>
      </template>
      <span class="spacer" v-else>&nbsp;</span>
    </td>

    <td class="no-border-bottom timetracker-td-actions text-align-center" width="50">
      <div class="bulk-actions position-relative" v-if="userCanDeleteTask">
        <button class="btn no-padding font-size-large" data-modal-disable-overlay="false"
                data-toggle-modal-default>

          <i class="symbol symbol-kebab-horizontal"></i>
          <span class="sr-only">More options</span>
        </button>

        <div class="modal modal-default" data-modal-width="400px">
          <div class="text-align-center">
            <h1 class="no-margin-top">More options</h1>
            <ul class='list-group list-group-small no-margin-y'>
              <li><a href="#" @click.prevent="confirmDeletion">Delete</a></li>
            </ul>
          </div>
        </div>
      </div>
      <span v-else>&nbsp;</span>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import { Link } from '@inertiajs/vue3'

import UserThumb from '../UserThumb'
import { formatDate, parseISODate } from '../../lib/date'
import { stringifyErrors } from '../../lib/string'
import { dueDateCSSClass, taskStatusCSSClass } from '../../lib/task'

export default {
  name: 'TaskRow',

  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    due_date: {
      type: String,
    },
    created: {
      type: String,
      required: true,
    },
    completed: {
      type: String,
    },
    synced_to_wrike: {
      type: Boolean,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    root_project: {
      type: Object,
    },
    status: {
      type: Object,
    },
    taskStatusOptions: {
      type: Object,
      required: true
    },
    authors: {
      type: Array,
      required: true,
    },
    authorById: {
      type: Object,
      required: true,
    },
    responsibles: {
      type: Array,
      required: true,
    },
    responsibleById: {
      type: Object,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
    allTasksSelected: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selected: false,
    }
  },

  watch: {
    allTasksSelected(newValue) {
      if (!this.userCanDeleteTask) return

      this.selected = newValue
      this.$emit('task:selected', { id: this.id, checked: newValue })
    },
  },

  computed: {
    userCanDeleteTask() {
      if (this.$onlyWorksOnFixedTasks.value) return false

      return this.$page.props.current_user.is_staff || this.authors.includes(this.$page.props.current_user.id)
    },

    createdOn() {
      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },

    dueOn() {
      if (!this.due_date) return

      return parseISODate(this.due_date)
    },

    dueDate() {
      if (!this.due_date) return ''

      return formatDate(this.dueOn, { format: 'short', timeZone: this.$timeZone })
    },

    completedOn() {
      if (!this.completed) return ''

      return formatDate(parseISODate(this.completed), { format: 'short', timeZone: this.$timeZone })
    },

    dueDateCSSClass() {
      return dueDateCSSClass(this.dueOn, this.$timeZone)
    },

    statusCSSClass() {
      return `tag-${taskStatusCSSClass(this.status?.name)}`
    },
  },

  methods: {
    async changeStatus() {
      const newStatus = Number(event.target.value) || null
      const resp = await axios.patch(this.$route('tasks.update', { id: this.id }), {
        status_id: newStatus,
      })

      const { task: { status } } = resp.data
      this.$emit('task:changed', { id: this.id, status })
    },

    async confirmDeletion() {
      if (!confirm(`Are you sure you want to delete task "${this.title}"?`)) return

      try {
        await axios.delete(reverseUrl('tasks.destroy', this.id), {
          data: {
            delete_on_wrike: this.synced_to_wrike,
          },
        })
      } catch(err) {
        alert(`Unable to delete task due to:\n${stringifyErrors(err)}`)
        return
      }

      this.$emit('task:deleted', { id: this.id })
    },
  },

  emits: ['task:selected', 'task:changed', 'task:deleted'],
  components: {
    Link,
    UserThumb,
  },
}
</script>

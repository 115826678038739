<template>
  <Layout>
    <div class="h2 font-weight-300 no-margin-y">
      {{ user.id ? 'Edit' : 'Add' }} team member
    </div>

    <div class="flex-grid flex-grid-fixed">
      <div class="flex-col-xs-12 flex-col-sm-9 flex-col-md-7">
        <form @submit.prevent="submitForm">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label class="input-label font-weight-700">Name</label>
            <div>
              <div class="flex-grid flex-grid-no-gutter-y glex-grid-compact">
                <div class="flex-col-6">
                  <input class="input input-single-line input-block"
                         :class="{ 'input-error': form.errors.first_name }"
                         minlength="2" maxlength="100"
                         ref="firstName" v-model.trim="form.first_name" required>
                </div>

                <div class="flex-col-6">
                  <input class="input input-single-line input-block"
                         :class="{ 'input-error': form.errors.first_name }"
                         minlength="2" maxlength="100" v-model.trim="form.last_name"
                         required>
                </div>
              </div>
            </div>

            <div class="input-info color-error font-weight-700" v-if="form.errors.first_name">
              {{ form.errors.first_name.join(', ') }}
            </div>
            <div class="input-info color-error font-weight-700" v-if="form.errors.last_name">
              {{ form.errors.last_name.join(', ') }}
            </div>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="email" class="input-label font-weight-700">Email</label>
            <input type="email" id="email" class="input input-single-line"
                   :class="{ 'input-error': form.errors.email }"
                   minlength="2" maxlength="100" autocomplete="username"
                   v-model.trim="form.email" required>

            <div class="input-info color-error font-weight-700" v-if="form.errors.email">
              {{ form.errors.email.join(', ') }}
            </div>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="location" class="input-label font-weight-700">Location</label>
            <input id="location" class="input input-single-line"
                   :class="{ 'input-error': form.errors.location }"
                   maxlength="255"
                   v-model.trim="form.location">

            <div class="input-info color-error font-weight-700" v-if="form.errors.location">
              {{ form.errors.location.join(', ') }}
            </div>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="timezone" class="input-label font-weight-700">Time zone</label>
            <select id="timezone" class="input input-select"
                    :class="{ 'input-error': form.errors.timezone }"
                    v-model="form.timezone" required>

              <option :value="tz" v-for="tz in all_timezones" :key="tz">
                {{ tz }}
              </option>
            </select>
            <div class="input-info color-error font-weight-700" v-if="form.errors.timezone">
              {{ form.errors.timezone.join(', ') }}
            </div>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="job_id" class="input-label font-weight-700">Job title</label>
            <select id="job_id" class="input input-select"
                    :class="{ 'input-error': form.errors.job_id }"
                    v-model="form.job_id" required>

              <option value=""></option>
              <option :value="id" v-for="[id, title] in allJobs" :key="id">
                {{ title }}
              </option>
            </select>
            <div class="input-info color-error font-weight-700" v-if="form.errors.job_id">
              {{ form.errors.job_id.join(', ') }}
            </div>
          </div>

          <template v-if="Object.keys(all_groups).length">
            <label class="input-label font-weight-700">Groups</label>
            <datalist id="group-options">
              <template v-for="(name, id) in all_groups" :key="id">
                <option :value="name" v-if="findGroupIndex(id) === -1" />
              </template>
            </datalist>

            <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap"
                 v-for="[id, name] in form.groups" :key="id">

              <div class="flex-child flex-1-1 no-margin-top">
                <label :for="`group-${id}`" class="sr-only">{{ name }}</label>
                <input :id="`group-${id}`" :value="name"
                       class="input input-single-line input-block"
                       list="group-options" @change="changeGroup(id, $event)">
              </div>

              <div class="flex-child flex-0-0 no-margin-top flex-xs">
                <a href="#" class="btn btn-error-glassy btn-symbol"
                   @click.prevent="removeGroup(id)">

                  <i class="symbol symbol-minus"></i>
                </a>
              </div>
            </div>

            <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap">
              <div class="flex-child flex-1-1 no-margin-top">
                <input list="group-options" class="input input-single-line input-block"
                       placeholder="Add group.." @change="addGroup($event)">
              </div>
            </div>
          </template>

          <template v-if="Object.keys(all_managers).length">
            <label class="input-label font-weight-700">Managers</label>
            <datalist id="manager-options">
              <template v-for="(fullName, id) in all_managers" :key="id">
                <option :value="fullName" v-if="findManagerIndex(id) === -1" />
              </template>
            </datalist>

            <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap"
                 v-for="[id, fullName] in form.managers" :key="id">

              <div class="flex-child flex-1-1 no-margin-top">
                <label :for="`manager-${id}`" class="sr-only">{{ fullName }}</label>
                <input :id="`manager-${id}`" :value="fullName"
                       class="input input-single-line input-block"
                       list="manager-options" @change="changeManager(id, $event)">
              </div>

              <div class="flex-child flex-0-0 no-margin-top flex-xs">
                <a href="#" class="btn btn-error-glassy btn-symbol"
                   @click.prevent="removeManager(id)">

                  <i class="symbol symbol-minus"></i>
                </a>
              </div>
            </div>

            <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap">
              <div class="flex-child flex-1-1 no-margin-top">
                <input list="manager-options" class="input input-single-line input-block"
                       placeholder="Add manager.." @change="addManager($event)">
              </div>
            </div>
          </template>

          <label class="input-label font-weight-700">Viewers</label>
          <datalist id="viewer-options">
            <template v-for="(fullName, id) in all_users" :key="id">
              <option :value="fullName" v-if="findUserIndex(id) === -1" />
            </template>
          </datalist>

          <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap"
               v-for="[id, fullName] in form.viewers" :key="id">

            <div class="flex-child flex-1-1 no-margin-top">
              <label :for="`viewer-${id}`" class="sr-only">{{ fullName }}</label>
              <input :id="`viewer-${id}`" :value="fullName"
                     class="input input-single-line input-block"
                     list="viewer-options" @change="changeViewer(id, $event)">
            </div>

            <div class="flex-child flex-0-0 no-margin-top flex-xs">
              <a href="#" class="btn btn-error-glassy btn-symbol"
                 @click.prevent="removeViewer(id)">

                <i class="symbol symbol-minus"></i>
              </a>
            </div>
          </div>

          <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap">
            <div class="flex-child flex-1-1 no-margin-top">
              <input list="viewer-options" class="input input-single-line input-block"
                     placeholder="Add viewer.." @change="addViewer($event)">
            </div>
          </div>

          <div class="p">
            <label class="input-label font-weight-700">More Team Member Options</label>

            <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
              <label for="is_active" class="input-toggle input-block p">
                <input type="checkbox" id="is_active" class="input"
                       v-model="form.is_active">
                <span class="input-label input-toggle-label">
                  Is Active?
                </span>
              </label>
            </div>

            <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
              <label for="is_staff" class="input-toggle input-block p">
                <input type="checkbox" id="is_staff" class="input"
                       v-model="form.is_staff">
                <span class="input-label input-toggle-label">
                  Is Staff? (i.e. has access to the Django admin?)
                </span>
              </label>
            </div>

            <div class="input-wrapper input-wrapper-vertical input-wrapper-block">
              <label for="is_superuser" class="input-toggle input-block p">
                <input type="checkbox" id="is_superuser" class="input"
                       v-model="form.is_superuser">
                <span class="input-label input-toggle-label">
                  Is Superuser?
                </span>
              </label>
            </div>
          </div>

          <hr class="margin-large-y">

          <div class="margin-large-top">
            <button class="btn btn-theme" :disabled="form.processing">Save</button>
            <Link :href="$returnBack() || $route('admin.users.index')"
                  class="btn btn-neutral-glassy margin-left">

              Go Back
            </Link>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios'
import { Link, useForm } from '@inertiajs/vue3'

import Layout from '../Layout'

export default {
  name: 'UserForm',

  props: {
    user: {
      type: Object,
      required: true,
    },
    all_jobs: {
      type: Object,
      required: true,
    },
    all_groups: {
      type: Object,
      required: true,
    },
    all_managers: {
      type: Object,
      required: true,
    },
    all_users: {
      type: Object,
      required: true,
    },
    all_timezones: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      email: props.user.email,
      is_active: props.user.is_active,
      is_staff: props.user.is_staff,
      is_superuser: props.user.is_superuser,
      location: props.user.location || '',
      timezone: props.user.timezone,
      job_id: props.user.job?.id || '',
      groups: Object.entries(props.user.groups).sort((g1, g2) => g1[1].localeCompare(g2[1])),
      managers: Object.entries(props.user.managers).sort((m1, m2) => m1[1].localeCompare(m2[1])),
      viewers: Object.entries(props.user.viewers).sort((u1, u2) => u1[1].localeCompare(u2[1])),
    })

    return { form }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.firstName.focus()
  },*/

  computed: {
    allJobs() {
      return Object.entries(this.all_jobs).sort((j1, j2) => j1[1].localeCompare(j2[1]))
    },
  },

  methods: {
    findGroupIndex(groupId) {
      const gId = String(groupId)
      return this.form.groups.findIndex(([id, _]) => id === gId)
    },

    findGroupEntryFor(name) {
      const n = name.trim().toLowerCase()
      return Object.entries(this.all_groups).find(([_, name]) => name.toLowerCase() === n)
    },

    addGroup(event) {
      const entry = this.findGroupEntryFor(event.target.value)
      if (entry && this.findGroupIndex(entry[0]) === -1) {
        this.form.groups.push(entry)
      }

      event.target.value = ''
    },

    changeGroup(id, event) {
      const entry = this.findGroupEntryFor(event.target.value)

      if (entry) {
        const index = this.findGroupIndex(entry[0])
        if (index === -1) this.form.groups[index][1] = entry[1]
      } else {
        event.target.value = this.all_groups[id]
      }
    },

    removeGroup(groupId) {
      const index = this.findGroupIndex(groupId)
      if (index === -1) return

      this.form.groups.splice(index, 1)
    },

    findManagerIndex(managerId) {
      const mgrId = String(managerId)
      return this.form.managers.findIndex(([id, _]) => id === mgrId)
    },

    findManagerEntryFor(fullName) {
      const name = fullName.trim().toLowerCase()
      return Object.entries(this.all_managers).find(([_, fullName]) => fullName.toLowerCase() === name)
    },

    addManager(event) {
      const entry = this.findManagerEntryFor(event.target.value)
      if (entry && this.findManagerIndex(entry[0]) === -1) {
        this.form.managers.push(entry)
      }

      event.target.value = ''
    },

    changeManager(id, event) {
      const entry = this.findManagerEntryFor(event.target.value)

      if (entry) {
        const index = this.findManagerIndex(entry[0])
        if (index === -1) this.form.managers[index][1] = entry[1]
      } else {
        event.target.value = this.all_managers[id]
      }
    },

    removeManager(managerId) {
      const index = this.findManagerIndex(managerId)
      if (index === -1) return

      this.form.managers.splice(index, 1)
    },

    findUserIndex(userId) {
      const uId = String(userId)
      return this.form.viewers.findIndex(([id, _]) => id === uId)
    },

    findViewerEntryFor(fullName) {
      const name = fullName.trim().toLowerCase()
      return Object.entries(this.all_users).find(([_, fullName]) => fullName.toLowerCase() === name)
    },

    addViewer(event) {
      const entry = this.findViewerEntryFor(event.target.value)
      if (entry && this.findUserIndex(entry[0]) === -1) {
        this.form.viewers.push(entry)
      }

      event.target.value = ''
    },

    changeViewer(id, event) {
      const entry = this.findViewerEntryFor(event.target.value)

      if (entry) {
        const index = this.findUserIndex(entry[0])
        if (index === -1) this.form.viewers[index][1] = entry[1]
      } else {
        event.target.value = this.all_users[id]
      }
    },

    removeViewer(viewerId) {
      const index = this.findUserIndex(viewerId)
      if (index === -1) return

      this.form.viewers.splice(index, 1)
    },

    submitForm(event) {
      const frm = this.form.transform(data => {
        return {
          ...data,
          is_active: data.is_active ? 1 : 0,
          is_staff: data.is_staff ? 1 : 0,
          is_superuser: data.is_superuser ? 1 : 0,
          job_id: data.job_id || null,
          groups: data.groups.map(([id, _]) => Number(id)),
          managers: data.managers.map(([id, _]) => Number(id)),
          viewers: data.viewers.map(([id, _]) => Number(id)),
        }
      })

      let url, method
      if (this.user.id) {
        url = reverseUrl('admin.users.update', { id: this.user.id })
        method = 'patch'
        // frm.patch(reverseUrl('admin.users.update', { id: this.user.id }))
      } else {
        url = reverseUrl('admin.users.create')
        method = 'post'
        // frm.post(reverseUrl('admin.users.create'))
      }

      frm.submit(method, url, {
        onSuccess: async () => {
          if (!frm.job_id) return

          try {
            const resp = await axios.get(reverseUrl('users.show', { id: this.user.id }))
            const { user } = resp.data
            const groups = Object.entries(user.groups).sort((g1, g2) => g1[1].localeCompare(g2[1]))
            this.form.groups.splice(0, this.form.groups.length, ...groups)
            const managers = Object.entries(user.managers).sort((m1, m2) => m1[1].localeCompare(m2[1]))
            this.form.managers.splice(0, this.form.managers.length, ...managers)
          } catch {
            location.reload()
          }
        },
      })
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="h2 font-weight-300 no-margin-y">Task status groups</div>
      </div>

      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="status-name" class="sr-only">Task status group</label>
              <input id="status-name" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="statusGroupName"
                     placeholder="Task status group" @input="debouncedFilterStatusGroups">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.task_status_groups.new')"
                  class="btn btn-theme btn-block-mobile">

              Add new +
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div class="module">
      <div class="module-content no-padding">
        <div class="table-wrapper" v-if="task_status_groups.length">
          <table class="timetracker-table">
            <thead>
              <tr class="background-transparent">
                <th class="background-transparent no-border-top">Task status group</th>
                <th class="background-transparent no-border-top text-align-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="status_group in task_status_groups" :key="status_group.id">
                <td>
                  <Link :href="$route('admin.task_status_groups.edit', { id: status_group.id })">
                    {{ status_group.name }}
                  </Link>
                </td>

                <td class="timetracker-td-actions text-align-right">
                  <a href="#" class="btn btn-link btn-symbol" title="Delete task status group"
                     @click.prevent="confirmDeletion(status_group)">

                    <i class="symbol symbol-delete"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="font-style-italic color-neutral padding" v-else>
          There are no task status groups yet.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import * as _ from 'lodash-es'

import Layout from '../Layout'

export default {
  name: 'TaskStatusGroupList',

  props: {
    task_status_groups: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
  },

  created() {
    this.debouncedFilterStatusGroups = _.debounce(this.filterStatusGroups,
                                                  this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.statusGroupName.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterStatusGroups.cancel()
  },

  methods: {
    filterStatusGroups(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
    },

    confirmDeletion({ id, name }) {
      if (!confirm(`Are you sure you to delete the '${name}' task stataus group?`)) return

      this.$inertia.delete(reverseUrl('admin.task_status_groups.destroy', { id }))
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

<template>
  <div class="timetracker-alert-container-side alert-container offset-nav-sticky offset-0-right position-fixed"
       v-if="messages.length">

    <FlashMessage paletteTag="success" symbolTag="success" :message="messages.join('. <br>')" />
  </div>
</template>

<script>
import FlashMessage from './FlashMessage'

export default {
  name: 'ToastNotifications',

  props: {
    messages: {
      type: Array,
      default: [],
    },
  },

  components: {
    FlashMessage,
  },
}
</script>

<template>
  <tr>
    <td class="text-align-center no-border-bottom timetracker-td text-align-center timetracker-td timetracker-td-w timetracker-td-w-50"
        v-if="$page.props.current_user.is_superuser">

      <label class="input-label no-padding">
        <input type="checkbox" class="input-inline" v-model="selected"
               @change="$emit('client:selected', { id, checked: $event.target.checked })">
        <span class="hide-nonmobile">Select client</span>
      </label>
    </td>

    <td class="no-border-bottom timetracker-td">
      <Link :href="$route('admin.clients.edit', { id })"
            class="h4 color-inherit font-weight-400 no-margin-y display-inline-block"
            v-if="$page.props.current_user.is_superuser">

        {{ name }}
      </Link>
      <span v-else>{{ name }}</span>
    </td>

    <td class="text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Account manager</span>
      <UserThumb v-bind="accountManager" v-if="accountManager" />
    </td>

    <td class="text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Sales rep</span>
      <UserThumb v-bind="salesRep" v-if="salesRep" />
    </td>

    <td class="hide-mobile text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="spacer">&nbsp;</span>
    </td>

    <td class="hide-mobile text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="spacer">&nbsp;</span>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-75 text-align-center">
      <template v-if="projects.length">
        <a :href="`#client-${id}-projects`" class="hide-nonmobile" data-toggle-accordion>
          <i class="symbol symbol-arrow-down symbol-arrow-up-toggle"></i> Toggle projects
        </a>
        <a :href="`#client-${id}-projects`"
           class="hide-mobile btn btn-small btn-primary-outline btn-symbol btn-round"
           data-toggle-accordion>

          <i class="symbol symbol-arrow-down symbol-arrow-up-toggle"></i>
        </a>
      </template>
      <span class="spacer" v-else>&nbsp;</span>
    </td>

    <td class="no-border-bottom timetracker-td-actions text-align-center" width="50"
        v-if="$page.props.current_user.is_superuser">

      <div class="bulk-actions position-relative">
        <button class="btn no-padding font-size-large" data-modal-disable-overlay="false"
                data-toggle-modal-default>

          <i class="symbol symbol-kebab-horizontal"></i>
          <span class="sr-only">More options</span>
        </button>

        <div class="modal modal-default" data-modal-width="400px">
          <div class="text-align-center">
            <h1 class="no-margin-top">More options</h1>
            <ul class='list-group list-group-small no-margin-y'>
              <li><a href="#" @click.prevent="confirmDeletion">Delete</a></li>
            </ul>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import { Link } from '@inertiajs/vue3'

import UserThumb from '../UserThumb'

import { stringifyErrors } from '../../lib/string'

export default {
  name: 'ClientRow',

  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    created: {
      type: String,
      required: true,
    },
    synced_to_wrike: {
      type: Boolean,
      required: true,
    },
    account_manager_id: {
      type: Number,
    },
    accountManagerById: {
      type: Object,
      required: true,
    },
    sales_rep_id: {
      type: Number,
    },
    salesRepById: {
      type: Object,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
    allClientsSelected: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selected: false,
    }
  },

  watch: {
    allClientsSelected(newValue) {
      this.selected = newValue
      this.$emit('client:selected', { id: this.id, checked: newValue })
    },
  },

  computed: {
    accountManager() {
      return this.accountManagerById[this.account_manager_id]
    },

    salesRep() {
      return this.salesRepById[this.sales_rep_id]
    },
  },

  methods: {
    async confirmDeletion() {
      if (!confirm(`Are you sure you want to delete client "${this.name}"?`)) return

      try {
        await axios.delete(reverseUrl('clients.destroy', this.id), {
          data: {
            delete_on_wrike: this.synced_to_wrike,
          },
        })
      } catch(err) {
        alert(`Unable to delete client due to:\n${stringifyErrors(err)}`)
        return
      }

      this.$emit('client:deleted', this.id)
    },
  },

  emits: ['client:selected', 'client:deleted'],
  components: {
    Link,
    UserThumb,
  },
}
</script>

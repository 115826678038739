<template>
  <div class="module" data-grid-area="latest">
    <div class="module-header">
      <h5 class="margin-small-top margin-bottom h5">Latest timelogs</h5>

      <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
        <Pagination :recordCount="recordCount"  :page="page" :pageCount="pageCount"
                    :perPage="perPage" :onPaginationLinkClicked="onPaginationLinkClicked" />
      </div>
    </div>

    <div class="module-content align-self-stretch">
      <div class="table-wrapper" v-if="timelogs.length">
        <table class="timetracker-table text-vertical-align-middle">
          <thead>
            <tr>
              <th>Date</th>
              <th>Start</th>
              <th>End</th>
              <th>Total</th>
              <th>Task</th>
              <th>Category</th>
              <th class="text-align-center">Status</th>
              <th>Project / client</th>
              <th class="text-align-center">Comment</th>
            </tr>
          </thead>

          <tbody>
            <MyAnalyticsTimelog v-bind="timelog" v-for="timelog in timelogs" :key="timelog.id" />
          </tbody>
        </table>
      </div>

      <div class="color-neutral font-style-italic" v-else>
        There are no timelogs yet.
      </div>
    </div>

    <div class="module-footer">
      <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
        <Pagination :recordCount="recordCount"  :page="page" :pageCount="pageCount"
                    :perPage="perPage" :onPaginationLinkClicked="onPaginationLinkClicked" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../pages/Pagination'
import MyAnalyticsTimelog from './MyAnalyticsTimelog'

export default {
  name: 'MyAnalyticsLatestTimelogs',

  props: {
    timelogs: {
      type: Array,
      required: true,
    },
    recordCount: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },

  methods: {
    onPaginationLinkClicked(page) {
      const params = new URLSearchParams(location.search)
      params.set('page', page)

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['timelogs', 'record_count', 'page', 'page_count', 'per_page'],
      })
    },
  },

  components: {
    Pagination,
    MyAnalyticsTimelog,
  },
}
</script>

<template>
  <ul class="tabs">
    <li :class="{ active: tabIsActive($route('insights.index')) }">
      <Link :href="$route('insights.index')">My analytics</Link>
    </li>

    <li :class="{ active: tabIsActive($route('insights.one_on_one_cmp')) }"
        v-if="isSuperuserOrManager">

      <Link :href="$route('insights.one_on_one_cmp') + `?user1=${$page.props.current_user.id}`">
        Compare
      </Link>
    </li>

    <li :class="{ active: tabIsActive($route('insights.team_cmp')) }" v-if="isSuperuserOrManager">
      <Link :href="$route('insights.team_cmp')">Team</Link>
    </li>
  </ul>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
  name: 'MyAnalyticsNavTabs',

  computed: {
    path() {
      return location.pathname
    },
    isSuperuserOrManager() {
      return this.$page.props.current_user.is_superuser || this.$page.props.current_user.is_manager
    },
  },

  methods: {
    tabIsActive(tabPath) {
      return this.path === tabPath
    },
  },

  components: {
    Link,
  },
}
</script>

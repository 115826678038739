<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="h2 font-weight-300 no-margin-y">Project types</div>
      </div>

      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="type-name" class="sr-only">Project type</label>
              <input id="type-name" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="typeName"
                     placeholder="Project type" @input="debouncedFilterTypes">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.project_types.new')"
                  class="btn btn-theme btn-block-mobile">

              Add new +
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div class="module">
      <div class="module-content no-padding">
        <div class="table-wrapper" v-if="project_types.length">
          <table class="timetracker-table">
            <thead>
              <tr class="background-transparent">
                <th class="background-transparent no-border-top">Project type</th>
                <th class="background-transparent no-border-top text-align-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="type in project_types" :key="type.id">
                <td>
                  <Link :href="$route('admin.project_types.edit', { id: type.id })">
                    {{ type.name }}
                  </Link>
                </td>

                <td class="timetracker-td-actions text-align-right">
                  <a href="#" class="btn btn-link btn-symbol" title="Delete project type"
                     @click.prevent="confirmDeletion(type)">

                    <i class="symbol symbol-delete"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="font-style-italic color-neutral padding" v-else>
          There are no project types yet.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import * as _ from 'lodash-es'

import Layout from '../Layout'

export default {
  name: 'ProjectTypeList',

  props: {
    project_types: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
  },

  created() {
    this.debouncedFilterTypes = _.debounce(this.filterTypes,
                                           this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.typeName.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterTypes.cancel()
  },

  methods: {
    filterTypes(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
    },
    confirmDeletion({ id, name }) {
      if (!confirm(`Are you sure you to delete the '${name}' project type?`)) return

      this.$inertia.delete(reverseUrl('admin.project_types.destroy', { id }))
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="h2 font-weight-300 no-margin-y">Departments</div>
      </div>

      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="department-name" class="sr-only">Department name</label>
              <input id="department-name" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="departmentName"
                     placeholder="Department name" @input="debouncedFilterDepartments">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.departments.new')" class="btn btn-theme btn-block-mobile">
              Add new +
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div class="module">
      <div class="module-content no-padding">
        <div class="table-wrapper" v-if="departments.length">
          <table class="timetracker-table">
            <thead>
              <tr class="background-transparent">
                <th class="background-transparent no-border-top">Department</th>
                <th class="background-transparent no-border-top">Description</th>
                <th class="background-transparent no-border-top text-align-center">Jobs</th>
                <th class="background-transparent no-border-top text-align-center">Members</th>
                <th class="background-transparent no-border-top text-align-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="dept in departments" :key="dept.id">
                <td>
                  <Link :href="$route('admin.departments.edit', { id: dept.id })">
                    {{ dept.name }}
                  </Link>
                </td>

                <td class="timetracker-td timetracker-td-w timetracker-td-w-300">
                  <h6 class="hide-nonmobile no-margin-top">Description</h6>

                  <div class="text-wrap-ellipsis" v-if="dept.description">
                    {{ dept.description }}
                  </div>
                  <span class="font-style-italic color-neutral" v-else>N/A</span>
                </td>

                <td class="text-align-center">
                  <h5 class="hide-nonmobile no-margin-top">Jobs</h5>
                  {{ dept.jobs_count }}
                </td>

                <td class="text-align-center">
                  <h5 class="hide-nonmobile no-margin-top">Members</h5>
                  <Link :href="$route('admin.departments.members', { id: dept.id })"
                        v-if="dept.users_count">

                    {{ dept.users_count }}
                  </Link>
                  <span v-else>{{ dept.users_count }}</span>
                </td>

                <td class="timetracker-td-actions text-align-right">
                  <a href="#" class="btn btn-link btn-symbol" title="Delete department"
                     @click.prevent="confirmDeletion(dept)">

                    <i class="symbol symbol-delete"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="font-style-italic color-neutral padding" v-else>
          There are no departments yet.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import * as _ from 'lodash-es'

import Layout from '../Layout'

export default {
  name: 'DepartmentList',

  props: {
    departments: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
  },

  created() {
    this.debouncedFilterDepartments = _.debounce(this.filterDepartments,
                                                 this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.departmentName.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterDepartments.cancel()
  },

  methods: {
    filterDepartments(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
    },

    confirmDeletion({ id, name, jobs_count }) {
      let msg = `Are you sure you to delete the '${name}' department?`
      if (jobs_count) {
        let jobNoun = 'job'
        if (jobs_count > 1) jobNoun += 's'
        msg += ` (warning: ${jobs_count} ${jobNoun} will be deleted if you decide to proceed)`
      }
      if (!confirm(msg)) return

      this.$inertia.delete(reverseUrl('admin.departments.destroy', { id }))
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

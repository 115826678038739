import axios from 'axios';

export function tinymceInitOpts() {
  return {
    height: 500,
    browser_spellcheck: true,
    contextmenu: false,
    paste_data_images: true,
    relative_urls: false,
    images_upload_credentials: true,
    images_upload_handler: imageUploadHandler,
    paste_tab_spaces: 2,
    powerpaste_block_drop: true,
    image_title: true,
    branding: false,
  };
}

// Breaking change product of the TinyMCE 5.x => 6.x migration:
// The images_upload_handler option is no longer passed a success or failure
// callback and instead requires a Promise to be returned with the upload result
// async imageUploadHandler(blobInfo, success, failure, progress) {
export async function imageUploadHandler(blobInfo, progress) {
  const data = new FormData();
  data.set('file', blobInfo.blob(), blobInfo.filename());

  const resp = await axios.post(reverseUrl('tinymce.images'), data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

  if ([400, 403].includes(resp.status)) {
    return Promise.reject(new Error('HTTP error: ' + resp.status));
    // failure('HTTP error: ' + resp.status, { remove: true });
  } else if ([422, 503].includes(resp.status)) {
    return Promise.reject(
      new Error('Server error: ' + resp.data.error || resp.statusText)
    );
    // failure('Server error: ' + resp.data.error || resp.statusText, {
    //   remove: true,
    // });
  } else if (resp.status < 200 || resp.status >= 300) {
    return Promise.reject(new Error('HTTP error: ' + resp.status));
    // failure('HTTP error: ' + resp.status);
  } else if (resp.status === 200) {
    const loc = resp.data.location;
    if (typeof loc !== 'string') {
      return Promise.reject(new Error('Invalid JSON: ' + JSON.stringify(resp.data)));
      // failure('Invalid JSON: ' + JSON.stringify(resp.data));
      // return;
    }

    return Promise.resolve(loc);
    // success(loc);
  }

  // const xhr = new XMLHttpRequest();
  // xhr.open('POST', reverseUrl('tinymce.images'));

  // const cookies = document.cookie.split(/;\s*/),
  //   xsrfCookieName = axios.defaults.xsrfCookieName,
  //   xsrfCookie = cookies.find((c) => c.startsWith(xsrfCookieName));
  // xhr.setRequestHeader(
  //   axios.defaults.xsrfHeaderName,
  //   xsrfCookie.substring(xsrfCookieName.length + 1)
  // );

  // xhr.onload = function () {
  //   if (xhr.status === 403) {
  //     failure('HTTP Error: ' + xhr.status, { remove: true });
  //     return;
  //   }

  //   if (xhr.status < 200 || xhr.status >= 300) {
  //     failure('HTTP Error: ' + xhr.status);
  //     return;
  //   }

  //   const json = JSON.parse(xhr.responseText);

  //   if (!json || typeof json.location != 'string') {
  //     failure('Invalid JSON: ' + xhr.responseText);
  //     return;
  //   }

  //   success(json.location);
  // };

  // xhr.onerror = function () {
  //   failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  // };

  // const formData = new FormData();
  // formData.append('file', blobInfo.blob(), blobInfo.filename());

  // xhr.send(formData);
}

<template>
  <div class="module" data-grid-area="overview">
    <div class="module-content">
      <div class="table-wrapper">
        <table class="table-fixed">
          <tr>
            <td class="no-border" v-for="{ dow, total_secs } in mostActiveDays" :key="dow">
              <span class="overview-day-name display-inline-block font-weight-700 padding-small-x radius"
                    :class="cssClassesFor(dow)">

                {{ dow }}
              </span>
              <br>
              <span class="overview-day-total">{{ secs2TimeStr(total_secs) }}</span>
            </td>

            <!--td class="no-border" v-for="(numSecs, date) in numHoursByDate" :key="date">
              <span class="overview-day-name display-inline-block font-weight-700 padding-small-x radius"
                    :class="cssClassesFor(date)">

                {{ fmtDt(date) }}
              </span>
              <br>
              <span class="overview-day-total">{{ secs2TimeStr(numSecs) }}</span>
            </td-->
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, parseISODate, secondsToTimeStr } from '../lib/date'

export default {
  name: 'MyAnalyticsNumHrsByDate',

  props: {
    mostActiveDays: {
      type: Array,
      required: true,
    },
    // numHoursByDate: {
    //   type: Object,
    //   required: true,
    // },
  },

  computed: {
    today() {
      return formatDate(new Date(), {
        format: 'short_weekday',
        timeZone: this.$timeZone,
      }).toUpperCase()
    },
  },

  methods: {
    cssClassesFor(dow) {
      if (this.today === dow) {
        return ['background-primary', 'color-background']
      } else {
        return ''
      }
    },
    // cssClassesFor(date) {
    //   return date === this.today ? ['background-primary', 'color-background'] : ''
    // },

    // fmtDt(date) {
    //   return formatDate(parseISODate(date), {
    //     format: 'short_weekday',
    //     timeZone: this.$timeZone,
    //   }).toUpperCase()
    // },

    secs2TimeStr(seconds) {
      return secondsToTimeStr(seconds)
    },
  },
}
</script>

<template>
  <Layout>
    <div id="timetracker-heading"
         class="flex-grid justify-content-space-between align-items-center">

      <div class="flex-col-xs-12  flex-col-md-6">
        <h1 class="no-margin">Groups</h1>
      </div>

      <div class="flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="groups-name" class="sr-only">Group   name</label>
              <input id="group-name" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="groupName"
                     placeholder="Group name" @input="debouncedFilterGroups">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.groups.new')" class="btn btn-block-mobile btn-theme">
              Add new +
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div id="timetracker-groups" class="module">
      <div class="module-header">
        <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
          <Pagination :recordCount="record_count"  :page="page" :pageCount="page_count"
                      :perPage="per_page" />
        </div>
        <hr class="hide-nonmobile no-margin-bottom">
      </div>

      <div class="module-content no-padding-x">
        <div class="table-wrapper">
         <table class="timetracker-table border-style-solid-bottom border-width-thin-bottom border-color-neutral-alpha-3">

           <template v-for="group in groups" :key="group.id">
             <GroupRow v-bind="group" />
           </template>
         </table>
       </div>
      </div>

      <div class="module-footer">
        <hr class="hide-nonmobile">
        <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
          <Pagination :recordCount="record_count" :page="page" :pageCount="page_count"
                      :perPage="per_page" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import * as _ from 'lodash-es'

import Layout from '../Layout'
import { Link } from '@inertiajs/vue3'
import Pagination from './Pagination'
import GroupRow from '../components/admin/GroupRow'

export default {
  name: 'GroupList',

  props: {
    groups: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
    record_count: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    page_count: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      required: true,
    },
  },

  created() {
    this.debouncedFilterGroups = _.debounce(this.filterGroups,
                                            this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.groupName.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterGroups.cancel()
  },

  methods: {
    filterGroups(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      params.delete('page')
      this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
    },
  },

  components: {
    Layout,
    Link,
    Pagination,
    GroupRow,
  },
}
</script>

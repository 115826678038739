<template>
  <AnonLayout>
    <div class="module-content padding-large-y">
      <form @submit.prevent="submitForm">
        <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
          <label for="new_password" class="input-label font-weight-700">New Password</label>
          <input type="password" id="new_password" class="input input-single-line"
                 :minlength="$page.props.app_config.minimum_password_length"
                 :maxlength="$page.props.app_config.maximum_password_length"
                 autocomplete="new-password"
                 ref="newPassword" v-model="form.new_password"  required>
        </div>

        <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
          <label for="new_password_confirmation" class="input-label font-weight-700">
            New password confirmation
          </label>
          <input type="password" id="new_password_confirmation"
                 class="input input-single-line"
                 :minlength="$page.props.app_config.minimum_password_length"
                 :maxlength="$page.props.app_config.maximum_password_length"
                 autocomplete="new-password"
                 v-model="form.new_password_confirmation" required>
        </div>

        <div class="margin-large-top">
          <button class="btn btn-theme btn-block" :disabled="form.processing">
            Change password
          </button>
        </div>
      </form>
    </div>
  </AnonLayout>
</template>

<script>
import { Link, useForm } from '@inertiajs/vue3'
import { toRefs } from 'vue'

import AnonLayout from '../AnonLayout'

export default {
  name: 'PasswordResetForm',

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      token: props.token,
      new_password: '',
      new_password_confirmation: '',
    })

    return {
      form,
    }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.newPassword.focus()
  },*/

  methods: {
    submitForm() {
      const { new_password: newPassword, new_password_confirmation: newPasswordConfirmation } = toRefs(this.form)
      if (new_password.value && new_password_confirmation.value) {
        if (new_password.value !== new_password_confirmation.value) {
          alert('Password must match its confirmation!')
          return
        }
      }

      this.form.post(reverseUrl('passwords.create'))
    },
  },

  components: {
    AnonLayout,
    Link,
  },
}
</script>

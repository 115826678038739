<template>
  <Layout>
    <div class="h6 color-neutral-dark font-weight-400">
      <Link :href="$route('admin.groups.index')" class="color-inherit">
        Groups
      </Link>

      <span class="separator">&gt;&nbsp;</span>

      <Link :href="id ? $route('admin.groups.edit', { id }) : $route('admin.groups.new')"
            class="color-inherit font-weight-700">

        {{ id ? name : 'New group' }}
      </Link>
    </div>

    <form @submit.prevent="saveNewGroup">
      <div class="module margin-large-top margin-bottom">
        <div class="module-header module-header-break padding-top no-margin-bottom padding-bottom align-items-center">
          <div class="flex-2-1 padding-right">
            <div class="h3 color-theme flex-1-1 no-margin-y">
              <label for="group-name" class="sr-only">Group name</label>
              <input id="group-name" class="input input-blend input-block"
                     placeholder="Group name" v-model.trim="_name" @change="onNameChanged"
                     required>
            </div>
          </div>

          <div class="module-functions flex-0-0 flex-nowrap flex-xs align-items-center justyify-content-space-between">
            <div class="module-function" v-if="id">
              <div class="color-neutral text-nowrap">Created on {{ createdOn }}</div>
            </div>

            <div class="module-function text-align-right" v-if="id">
              <div class="display-inline-block position-relative">
                <a href="#" class="btn padding-small font-size-large btn-link"
                  title="More options" data-toggle-dropdown>

                  <i class="symbol symbol-kebab-horizontal"></i>
                </a>

                <div class="dropdown dropdown-right dropdown-top-flush text-align-left"
                    data-dropdown-width="200px">

                  <ul class="list-group list-group-small no-margin-y">
                    <li class="no-border">
                      <a href="#" class="display-block dropdown-purger"
                        @click.prevent="$copyURLToClipboard">

                        <i class="symbol symbol-hyperlink"></i> Copy link
                      </a>
                    </li>

                    <li class="no-border">
                      <a href="#group-del-confirm-modal" @click.prevent
                        class="display-block dropdown-purger" data-toggle-modal-default>

                        <i class="symbol symbol-delete"></i> Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="margin-top text-align-right">
        <Link :href="$returnBack() || $route('admin.groups.index')"
              class="btn btn-theme-outline">

          {{ id ? 'Back' : 'Cancel' }}
        </Link>
        &nbsp;
        <button href="#" class="btn btn-theme" v-if="!id">
          {{ id ? 'Update' : 'Create' }} group
        </button>
      </div>
    </form>

    <ModelDelConfirmModal modelCls="group"
      :action="$route('admin.groups.destroy', { id })"
      :successPath="$returnBack() || $route('admin.groups.index')"
      :syncedToWrike="false"
      v-if="id" />
  </Layout>
</template>

<script>
import axios from 'axios'
import { Link } from '@inertiajs/vue3'

import Layout from '../Layout'
import ModelDelConfirmModal from './ModelDelConfirmModal'
import { formatDate, parseISODate } from '../lib/date'
import { stringifyValidationErrors } from '../lib/string'

export default {
  name: 'GroupForm',

  props: {
    id: {
      type: Number,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    created: {
      type: String,
    },
    synced_to_wrike: {
      type: Boolean,
    },
  },

  data() {
    return {
      _name: this.name,
      _description: this.description,
      syncedToWrike: this.synced_to_wrike || false,
    }
  },

  computed: {
    createdOn() {
      if (!this.created) return ''

      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },
  },

  methods: {
    async onNameChanged(_e) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('admin.groups.update', { id: this.id }), {
          name: this._name,
        })
      } catch(err) {
        alert(`Unable to change group name due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    async saveNewGroup() {
      try {
        const resp = await axios.post(reverseUrl('admin.groups.create'), {
          name: this._name,
        })

        const { id } = resp.data
        this.$inertia.get(reverseUrl('admin.groups.edit', { id }))
      } catch(err) {
        alert(`Unable to save new group due to:\n${stringifyValidationErrors(err)}`)
      }
    },
  },

  components: {
    Layout,
    Link,
    ModelDelConfirmModal,
  },
}
</script>

<template>
  <form @submit.prevent="saveNewProject" ref="form">
    <div class="module margin-large-top margin-bottom">
      <div class="module-header module-header-break padding-top no-margin-bottom padding-bottom align-items-center">
        <div class="flex-1-1 padding-right">
          <div class="h3 color-theme flex-1-1 no-margin-y">
            <label for="project-name" class="sr-only">Project name</label>
            <input id="project-name" class="input input-blend input-block"
                   placeholder="Project name" v-model.trim="_name" @change="onNameChanged"
                   required>
          </div>
        </div>

        <div class="module-functions flex-0-0 flex-nowrap flex-xs align-items-center justyify-content-space-between">
          <label class="input-toggle input-toggle-primary input-toggle-reverse margin-right text-nowrap">
              <input type="checkbox" class="input" v-model.trim="_syncedToWrike"
                     @change="onSyncedOnWrikeChanged">

            <span class="input-label input-toggle-label font-weight-700">Synced to Wrike</span>
          </label>

          <div class="module-function" v-if="id">
            <div class="color-neutral text-nowrap">Created on {{ createdOn }}</div>
          </div>

          <div class="module-function text-align-right" v-if="id">
            <div class="display-inline-block position-relative">
              <a href="#" class="btn padding-small font-size-large btn-link"
                 title="More options" data-toggle-dropdown>

                <i class="symbol symbol-kebab-horizontal"></i>
              </a>

              <div class="dropdown dropdown-right dropdown-top-flush text-align-left"
                   data-dropdown-width="200px">

                <ul class="list-group list-group-small no-margin-y">
                  <li class="no-border">
                    <a href="#" class="display-block dropdown-purger"
                       @click.prevent="$copyURLToClipboard">

                      <i class="symbol symbol-hyperlink"></i> Copy link
                    </a>
                  </li>

                  <li class="no-border">
                    <a href="#project-del-confirm-modal" @click.prevent
                       class="display-block dropdown-purger" data-modal-disable-overlay="false"
                       data-toggle-modal-default>

                      <i class="symbol symbol-delete"></i> Delete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="module-content no-padding-x no-padding-bottom">
        <div class="timetracker-module-subheader">
          <div class="flex-grid flex-grid-no-gutter flex-wrap align-items-stretch justify-content-start">
            <div class="flex-child flex-1-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs">

              <!-- @NOTE
                .btn
                  classes to add (@NOTE: should match up with the colors we set up originally through task.js: /1p21_timetracker_web/time_tracker_webapp/static/vue/src/lib/task.js)
                    `btn-neutral-glassy` => default/backlog/maintenance backlog/production backlog
                    `btn-primary-glassy` => when in progress/production
                    `btn-secondary-glassy` => when in review
                    `btn-error-glassy` => when on hold
                    `btn-success-glassy` => completed
              -->
              <a href="#"
                 class="btn btn-block btn-no-interaction no-radius overflow-hidden justify-content-center"
                 :class="projectStatusBtnCSSClass"
                 :data-toggle-dropdown="projectStatusOptions.length">

                <span class="text-nowrap">
                  <span class="color-base-alpha-5 margin-small-right hide-mobile">Status</span>
                  <span class="hide-nonmobile">&bull;&nbsp;</span>
                  <span class="color-theme">
                    {{ projectStatusName || 'N/A' }}
                  </span>&nbsp;
                  <i class="symbol symbol-arrow-down" v-if="projectStatusOptions.length"></i>
                </span>
              </a>

              <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                   data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px"
                   v-if="projectStatusOptions.length">

                <div class="list-group">
                  <!--
                    @NOTE

                    .list-group-item
                      classes to add:
                        `active` => when the contained input for status is checked
                  -->
                  <label class="list-group-item pointer-reference"
                         :class="{ active: _statusId === id }"
                         @click.prevent="onStatusChanged(id)"
                         v-for="[id, name] in projectStatusOptions" :key="id">

                    <span class="flex-grid flex-grid-no-gutter justify-content-space-between align-items-center flex-nowrap">
                      <span class="flex-child flex-1-1">
                        <input type="radio" name="status-id" :value="id" class="sr-only">
                        <span class="text-wrap-ellipsis">
                          <span :class="projectStatusBulletCSSClass(id)">&bull;&nbsp;</span>
                          {{ name }}
                        </span>
                      </span>

                      <span class="flex-child flex-0-0">
                        <i class="symbol symbol-check only-toggle flex-0-0"></i>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex-col-xs-12 flex-col-sm-9 flex-col-md-4 flex-col-lg-5 flex-0-3 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs"
                 v-if="typeName || projectTypeOptions.length">

              <a href="#"
                 class="btn btn-block btn-no-interaction no-radius font-weight-400 overflow-hidden justify-content-flex-start flex-0-0"
                 :data-toggle-dropdown="projectTypeOptions.length">

                <i class="position-relative offset-micro-top inline-flex-xs margin-small-top-negate margin-micro-right flex-0-0"
                 v-html="projectTypeSvgIcon">
                </i>

                <span class="margin-right text-nowrap">
                  Project type +
                </span>

                <span class="flex-xs justify-content-space-between align-items-center flex-1-1 text-align-left">
                  <span class="color-theme text-wrap-ellipsis font-weight-700">
                    <span v-if="typeName">{{ typeName }}</span>
                    <span class="color-neutral font-weight-400" v-else>N/A</span>
                  </span>&nbsp;
                  <i class="symbol symbol-arrow-down" v-if="projectTypeOptions.length"></i>
                </span>
              </a>

              <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                   data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                <label class="list-group-item pointer-reference" :class="{ active: _typeId === id }"
                       @click.prevent="onTypeChanged(id)"
                       v-for="[id, name] in projectTypeOptions" :key="id">

                  <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                    <span class="flex-child flex-1-1">
                      <input type="radio" name="type-id" :value="id" class="sr-only">
                      <span class="text-wrap-ellipsis">{{ name }}</span>
                    </span>
                    <span class="flex-child flex-0-0">
                      <i class="symbol symbol-check only-toggle flex-0-0"></i>
                    </span>
                  </span>
                </label>
              </div>

              <span class="flex-0-0 divider border-style-solid-right border-color-theme-polar-contrast border-width-thin-right align-self-stretch hide-mobile">
              </span>
            </div>

            <div class="flex-col-xs-12 flex-col-sm-9 flex-col-md-4 flex-col-lg-5 flex-0-3 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs">
              <a href="#"
                 class="btn btn-block btn-no-interaction no-radius font-weight-400 overflow-hidden justify-content-flex-start flex-0-0"
                 :data-toggle-dropdown="!id">

                <i class="position-relative offset-micro-top inline-flex-xs  margin-small-top-negate margin-micro-right flex-0-0"
                   v-html="clientSvgIcon">
                </i>

                <span class="margin-right text-nowrap">
                  Client +
                </span>

                <span class="flex-xs justify-content-space-between align-items-center flex-1-1 text-align-left">
                  <span class="color-theme text-wrap-ellipsis font-weight-700">
                    <span v-if="clientName || client?.name">
                      {{ clientName || client?.name }}
                    </span>
                    <span class="color-neutral font-weight-400" v-else>N/A</span>
                  </span>&nbsp;
                </span>
              </a>

              <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                   data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px"
                   v-if="!id">

                <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                  <div class="input-group input-block input-group-horizontal">
                    <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                           id="client-name" placeholder="Search clients"
                           @input="debouncedFetchClients">

                    <label for="client-name"
                           class="font-size-normalize btn btn-symbol btn-small btn-default">

                      <span class="sr-only">Search</span>
                      <i class="symbol symbol-search"></i>
                    </label>
                  </div>
                </div>
                <div class="list-group list-group-interactive">
                  <label class="list-group-item pointer-reference"
                         :class="{ active: _clientId === id }"
                         @click.prevent="onClientChanged(id, name)"
                         v-for="{ id, name } in clientOptions" :key="id">

                    <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                      <span class="flex-child flex-1-1">
                        <input type="radio" name="client-id" :value="id" class="sr-only">
                        <span class="text-wrap-ellipsis">{{ name }}</span>
                      </span>
                      <span class="flex-child flex-0-0">
                        <i class="symbol symbol-check only-toggle flex-0-0"></i>
                      </span>
                    </span>
                  </label>
                </div>
              </div>

              <span class="flex-0-0 divider border-style-solid-right border-color-theme-polar-contrast border-width-thin-right align-self-stretch hide-mobile">
              </span>
            </div>

            <div class="flex-col-xs-12 flex-col-md-6 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs"
                 v-if="parent || parentCandidates.length">

              <a href="#"
                 class="btn btn-block btn-no-interaction no-radius font-weight-400 overflow-hidden justify-content-flex-start flex-0-0"
                 :data-toggle-dropdown="parentCandidates.length">

                <i class="position-relative offset-micro-top inline-flex-xs  margin-small-top-negate margin-micro-right flex-0-0"
                  v-html="parentProjectSvgIcon">
                </i>

                <span class="margin-right text-nowrap">
                  Parent project +
                </span>

                <span class="flex-xs justify-content-space-between align-items-center flex-1-1 text-align-left">
                  <span class="color-theme text-wrap-ellipsis font-weight-700" v-if="parentName">
                    {{ parentName }}
                  </span>
                  <span class="color-neutral font-weight-400" v-else>N/A</span>
                </span>
              </a>

              <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                   data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px"
                   v-if="parentCandidates.length">

                <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                  <div class="input-group input-block input-group-horizontal">
                    <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                           id="parent-project" placeholder="Search projects"
                           @input="filterProjects">

                    <label for="parent-project"
                           class="font-size-normalize btn btn-symbol btn-small btn-default">

                      <span class="sr-only">Search</span>
                      <i class="symbol symbol-search"></i>
                    </label>
                  </div>
                </div>

                <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active ">
                  <!--
                    @NOTE

                    .list-group-item
                      classes to add:
                        `active` => when the contained input field is checked or has value
                  -->
                  <label class="list-group-item pointer-reference"
                         :class="{ active: _parentId === id }"
                         @click.prevent="onParentToogled(id, name)"
                         v-show="!parentRegex || parentRegex.test(name)"
                         v-for="{ id, name } in parentCandidates" :key="id">

                    <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                      <span class="flex-child flex-1-1">
                        <input type="radio" name="parent-id" :value="id" class="sr-only">
                        <span class="text-wrap-ellipsis">{{ name }}</span>
                      </span>
                      <span class="flex-child flex-0-0">
                        <i class="symbol symbol-check only-toggle flex-0-0"></i>
                      </span>
                    </span>
                  </label>
                </div>
              </div>

              <span class="flex-0-0 divider border-style-solid-right border-color-theme-polar-contrast border-width-thin-right align-self-stretch hide-mobile">
              </span>
            </div>

            <div class="flex-col-xs-12 flex-col-md-6 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs"
                 v-if="Object.keys(userNameAndId).length">

              <a href="#"
                 class="btn-group btn-group-horizontal btn-block-mobile btn-no-interaction no-shadow no-radius font-weight-400 overflow-hidden justify-content-flex-start flex-0-0"
                 data-toggle-dropdown>

                <div class="btn flex-0-0 font-weight-400 justify-content-flex-start">
                  <i class="position-relative offset-micro-top inline-flex-xs  margin-small-top-negate margin-micro-right flex-0-0"
                     v-html="ownersSvgIcon">
                  </i>

                  <span class="margin-right text-nowrap">
                    Owners +
                  </span>
                </div>

                <div class="btn no-padding-y no-border-left flex-1-0 font-weight-400 justify-content-flex-start align-items-center overflow-hidden no-padding-left">
                  <span v-if="_owners.size">{{ ownerNames }}</span>
                  <span class="color-neutral font-weight-400" v-else>N/A</span>
                </div>
              </a>

              <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                   data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                  <div class="input-group input-block input-group-horizontal">
                    <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                           id="owner-name" placeholder="Toggle project owners"
                           @input="filterOwners">

                    <label for="owner-name"
                           class="font-size-normalize btn btn-symbol btn-small btn-default">

                      <span class="sr-only">Search</span>
                      <i class="symbol symbol-search"></i>
                    </label>
                  </div>
                </div>

                <div class="list-group list-group-interactive">
                  <!--
                    @NOTE

                    .list-group-item
                      classes to add:
                        `active` => when the contained input field is checked or has value
                  -->
                  <label class="list-group-item pointer-reference"
                         :class="{ active: _owners.has(id) }"
                         @click.prevent="onOwnerToggled(id)"
                         v-show="!ownerRegex || ownerRegex.test(name)"
                         v-for="[name, id] in userNameAndId" :key="id">

                    <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                      <span class="flex-child flex-1-1">
                        <input type="radio" name="owner-id" :value="id" class="sr-only">
                        <span class="text-wrap-ellipsis">{{ name }}</span>
                      </span>
                      <span class="flex-child flex-0-0">
                        <i class="symbol symbol-check only-toggle flex-0-0"></i>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex-col-xs-12 flex-col-md-8 flex-col-lg-9 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs flex-wrap">
              <ModelJobs :modelId="id" modelCls="Project"
                         :jobTitleById="jobTitleById" :jobIdsByDeptId="jobIdsByDeptId"
                         :jobIdByModelJobId="jobIdByModelJobId" :users="users"
                         :user_ids_by_job_id="user_ids_by_job_id" :userIdsByJobId="userIdsByJobId"
                         @userJob:added="onUserJobAdded"
                         @userJob:removed="onUserJobRemoved" />


              <span class="flex-0-0 divider border-style-solid-right border-color-theme-polar-contrast border-width-thin-right align-self-stretch hide-mobile">
              </span>
            </div>
            <div class="flex-col-xs-12 flex-col-md-4 flex-col-lg-3 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs color-inherit text-decoration-none pointer-reference"
                 data-toggle-accordion>

              <div class="btn btn-block btn-no-interaction no-radius font-weight-400 overflow-hidden justify-content-flex-start flex-0-0 background-neutral-alpha-1">

                <i class="position-relative offset-micro-top inline-flex-xs margin-small-top-negate margin-micro-right flex-0-0"
                  v-html="billingTypeSvgIcon">
                </i>

                <span class="margin-right text-nowrap">Billing type</span>

                <span class="flex-xs justify-content-space-between align-items-center flex-1-1 text-align-left">
                  <span class="color-theme text-wrap-ellipsis font-weight-700 flex-1-1">
                    {{ billingTypeStr }}
                  </span>&nbsp;
                  <i class="symbol symbol-arrow-down"></i>
                </span>
              </div>

              <span class="flex-0-0 divider border-style-solid-right border-color-theme-polar-contrast border-width-thin-right align-self-stretch hide-mobile">
              </span>
            </div>

            <div class="accordion flex-col-xs-12 flex-0-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top position-relative flex-xs">
              <div class="padding-x flex-1-1">
                <div class="flex-grid flex-grid-expanded flex-grid-no-gutter-y align-items-flex-start">
                  <div class="flex-col-xs-12 flex-col-md-6 flex-col-lg-5 flex-0-0">
                    <div class="flex-grid flex-grid-compact flex-grid-no-gutter-y">
                      <div class="flex-col-xs-12 flex-col-sm-4">
                        <label for="billing-type"
                               class="input-wrapper input-wrapper-vertical input-wrapper-block p">

                          <span class="input-label">Billing type</span>
                          <select id="billing-type"
                                  class="input input-single-line input-block"
                                  v-model="_billingType" @change="onBillingTypeChanged">

                            <option :value="value" v-for="[value, label] in projBillingTypeOptions">
                              {{ label }}
                            </option>
                          </select>
                        </label>
                      </div>

                      <div class="flex-col-xs-12 flex-col-sm-4" :class="{ disabled: false }">
                        <label for="hourly-rate"
                               class="input-wrapper input-wrapper-vertical input-wrapper-block p">

                          <span class="input-label">Hourly rate</span>
                          <div class="input-group input-group-horizontal input-block">
                            <span class="btn btn-no-interaction btn btn-default font-weight-400 padding-micro-right no-shadow">
                              $
                            </span>
                            <input type="number" min="1" id="hourly-rate"
                                   class="input input-single-line input-block no-border-left padding-micro-left no-shadow"
                                   placeholder="100" step="1"
                                   :required="hourlyRateIsRequired"
                                   v-model.number="_hourlyRate" @change="onHourlyRateChanged">
                          </div>
                        </label>
                      </div>

                      <div class="flex-col-xs-12 flex-col-sm-4" :class="{ disabled: false }">
                        <label for="total-fee"
                               class="input-wrapper input-wrapper-vertical input-wrapper-block p">

                          <span class="input-label">Total fee</span>
                          <div class="input-group input-group-horizontal input-block">
                            <span class="btn btn-no-interaction btn btn-default font-weight-400 padding-micro-right no-shadow">
                              $
                            </span>
                            <input type="number" id="total-fee"
                                   class="input input-single-line input-block no-border-left padding-micro-left no-shadow"
                                   placeholder="1000" min="1" step="1"
                                   :required="totalFeeIsRequired"
                                   v-model.number="_totalFee" @change="onTotalFeeChanged">
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="flex-col-xs-12 flex-col-lg-7 flex-0-0" :class="{ disabled: false }">
                    <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
                      <span class="input-label">Budget</span>
                      <div class="input-group input-group-horizontal input-block input-group-responsive text-nowrap">
                        <select class="input input-single-line flex-1-0" v-model="budget"
                                @change="persistBillingData">

                          <option value="hours">Total project hours</option>
                          <option value="fees" v-show="!['flat_fee', 'monthly_fee'].includes(_billingType)">
                            Total project fees
                          </option>
                        </select>

                        <input type="number" name="hours-budget" min="1"
                               step="1" placeholder="20"
                               class="input input-single-line flex-1-0"
                               :disabled="['flat_fee', 'monthly_fee'].includes(_billingType)"
                               :required="hoursBudgetIsRequired"
                               v-model.number="_hoursBudget" @change="onHoursBudgetChanged"
                               v-if="budget === 'hours'">
                        <input type="number" name="fees-budget" min="1"
                               step="1" placeholder="1000"
                               class="input input-single-line flex-1-0"
                               :required="feesBudgetIsRequired"
                               v-model.number="_feesBudget" @change="onFeesBudgetChanged" v-else>

                        <label for="reset-budget-monthly"
                               class="input input-single-line flex-1-0 flex-xs justify-content-space-between">

                          Reset budget monthly?
                          &nbsp;
                          <input type="checkbox" id="reset-budget-monthly"
                                 class="margin-small-left input input-inline align-self-center"
                                 :class="{ disabled: ['flat_fee', 'monthly_fee'].includes(_billingType) }"
                                 v-model="_resetBudgetMonthly" @change="persistBillingData">
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="flex-col-xs-12 flex-col-md-6 flex-col-lg-5 flex-1-0" :class="{ disabled: false }">
                    <div class="flex-grid flex-grid-no-gutter-y flex-nowrap">
                      <div class="flex-child flex-0-0 align-self-flex-end">
                        <label for="notifications-enabled"
                               class="input-wrapper input-wrapper-vertical input-wrapper-block p">

                          <span class="input-label">Notifications enabled?</span>
                          <select id="notifications-enabled" class="input input-single-line input-block"
                                  v-model="notificationsEnabled">

                            <option :value="false">No</option>
                            <option :value="true" v-show="_billingType">Yes</option>
                          </select>
                        </label>
                      </div>

                      <div class="flex-child flex-1-0 align-self-flex-end">
                        <label for="budget-pct-threshold" class="p">
                          <span class="input-label padding-small-right">Notify us when project is at</span>
                          <input type="number" step="1" max="100" min="1" id="budget-pct-threshold"
                                 class="input input-single-line color-inherit font-weight-400 no-padding-x text-align-center"
                                 :class="{ disabled: !notificationsEnabled }" style="width: 7ch"
                                 :required="notificationsEnabled" placeholder="80"
                                 v-model.number="_budgetPctThreshold" @change="persistBillingData">
                          <span class="input-label padding-small-left">% of budget</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="flex-col-xs-12 flex-col-md-6 flex-col-lg-7 flex-1-1"
                       v-show="notificationsEnabled && _budgetPctThreshold && ownersAndAdmins.length">

                    <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
                      <span class="input-label">Notification recipients</span>
                      <a href="#"
                         class="input input-select input-block text-decoration-none color-inherit"
                         data-toggle-dropdown>

                        <span v-show="Object.keys(_budgetPctThresExceededRecipients).length">
                          {{ notificationRecipients }}
                        </span>
                        <span class="color-neutral font-weight-400"
                              v-show="!Object.keys(_budgetPctThresExceededRecipients).length">

                          Select recipients
                        </span>
                      </a>

                      <div class="dropdown dropdown-top-flush dropdown-left no-padding"
                           data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

                        <div class="padding-small position-sticky background-theme offset-0-x offset-0-top">
                          <div class="input-group input-block input-group-horizontal">
                            <input class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right"
                                   id="recipient-name" placeholder="Toggle notification recipients"
                                   @input="filterRecipients">

                            <label for="recipient-name"
                                   class="font-size-normalize btn btn-symbol btn-small btn-default">

                              <span class="sr-only">Search</span>
                              <i class="symbol symbol-search"></i>
                            </label>
                          </div>
                        </div>

                        <div class="list-group list-group-interactive">
                          <!--
                            @NOTE

                            .list-group-item
                              classes to add:
                                `active` => when the contained input field is checked or has value
                          -->
                          <label class="list-group-item pointer-reference"
                                 :class="{ active: id in _budgetPctThresExceededRecipients }"
                                 @click.prevent="onRecipientToggled(id)"
                                 v-show="!recipientRegex || recipientRegex.test(name)"
                                 v-for="[id, name] in ownersAndAdmins" :key="id">

                            <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
                              <span class="flex-child flex-1-1">
                                <input type="radio" name="recipient-id" :value="id" class="sr-only">
                                <span class="text-wrap-ellipsis">{{ name }}</span>
                              </span>
                              <span class="flex-child flex-0-0">
                                <i class="symbol symbol-check only-toggle flex-0-0"></i>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Editor :api-key="$page.props.tinymce_api_key"
                :init="$tinymceInitOpts"
                :plugins="$page.props.tinymce_plugins"
                :toolbar="$page.props.tinymce_toolbar"
                v-model.trim="_description"
                @change="onDescriptionChanged" />
      </div>
    </div>

    <div class="margin-top text-align-right">
      <Link :href="$returnBack() || $route('admin.projects.index')"
            class="btn btn-theme-outline">

        {{ id ? 'Back' : 'Cancel' }}
      </Link>
      &nbsp;
      <button href="#" class="btn btn-theme" v-if="!id">
        {{ id ? 'Update' : 'Create' }} project
      </button>
    </div>
  </form>

  <ModelDelConfirmModal modelCls="project"
                        :action="$route('projects.destroy', { id })"
                        :successPath="$returnBack() || $route('admin.projects.index')"
                        :syncedToWrike="syncedToWrike"
                        v-if="id" />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'

import { Link } from '@inertiajs/vue3'
import Editor from '@tinymce/tinymce-vue';

import ModelJobs from '../components/ModelJobs'
import ModelDelConfirmModal from './ModelDelConfirmModal'
import projectTypeIcon from '../img/icon-project-type.svg?raw'
import clientIcon from '../img/icon-client.svg?raw'
import parentProjectIcon from '../img/icon-parent-project.svg?raw'
import ownersIcon from '../img/icon-owners.svg?raw'
import billingTypeIcon from '../img/icon-billing-type.svg?raw'

import { formatDate, parseISODate } from '../lib/date'
import { projectStatusCSSClass } from '../lib/project'
import { humanize, stringifyValidationErrors } from '../lib/string'

export default {
  name: 'ProjectDetails',

  props: {
    id: {
      type: Number,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    billingType: {
      type: String,
    },
    hourlyRate: {
      type: Number,
    },
    totalFee: {
      type: Number,
    },
    hoursBudget: {
      type: Number,
    },
    feesBudget: {
      type: Number,
    },
    resetBudgetMonthly: {
      type: Boolean,
    },
    budgetPctThreshold: {
      type: Number,
    },
    budgetPctThresExceededRecipients: {
      type: Object,
    },
    created: {
      type: String,
    },
    syncedToWrike: {
      type: Boolean,
    },
    statusId: {
      type: Number,
    },
    typeId: {
      type: Number,
    },
    parentId: {
      type: Number,
    },
    parent: {
      type: Object,
    },
    parentProjectCandidates: {
      type: Array,
      required: true,
    },
    clientId: {
      type: Number,
    },
    client: {
      type: Object,
    },
    projectStatuses: {
      type: Object,
      required: true,
    },
    projectTypes: {
      type: Object,
      required: true,
    },
    projBillingTypes: {
      type: Array,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
    jobTitleById: {
      type: Object,
      required: true,
    },
    jobIdsByDeptId: {
      type: Object,
      required: true,
    },
    jobIdByModelJobId: {
      type: Object,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
    user_ids_by_job_id: {
      type: Object,
      required: true,
    },
    userIdsByModelJobId: {
      type: Object,
      required: true,
    },
  },

  data() {
    const _owners = new Set(this.owners.map(String))
    if (location.pathname === reverseUrl('admin.projects.new') && !_owners.size) {
      _owners.add(String(this.$page.props.current_user.id))
    }

    const userIdsByJobId = {}
    for (const [modelJobId, userIds] of Object.entries(this.userIdsByModelJobId)) {
      const jobId = this.jobIdByModelJobId[modelJobId]

      userIds.forEach(userId => {
        userIdsByJobId[jobId] ||= new Set()
        userIdsByJobId[jobId].add(userId)
      })
    }

    return {
      _name: this.name,
      _description: this.description,
      _billingType: this.billingType,
      _hourlyRate: this.hourlyRate,
      _totalFee: this.totalFee,
      _hoursBudget: this.hoursBudget,
      _feesBudget: this.feesBudget,
      budget: this.feesBudget ? 'fees' : 'hours',
      _resetBudgetMonthly: this.resetBudgetMonthly,
      _budgetPctThreshold: this.budgetPctThreshold,
      _budgetPctThresExceededRecipients: _.clone(this.budgetPctThresExceededRecipients),
      notificationsEnabled: !!this.budgetPctThreshold,
      _syncedToWrike: this.syncedToWrike || false,
      _statusId: this.statusId,
      _typeId: this.typeId,
      typeName: this.projectTypes[this.typeId] || '',
      typeRegex: null,
      _parentId: this.parentId,
      parentName: this.parent?.name || '',
      parentRegex: null,
      _clientId: this.clientId,
      clientOptions: [],
      clientName: '',
      _owners,
      ownerRegex: null,
      recipientRegex: null,
      userIdsByJobId,
    }
  },

  async created() {
    this.debouncedFetchClients = _.debounce(this.fetchClients,
                                            this.$page.props.app_config.autocomplete_debounce_min)

    await this.fetchClients()
  },

  beforeUnmount() {
    this.debouncedFetchClients.cancel()
  },

  computed: {
    ownersAndAdmins() {
      return Object.entries(this.users)
                   .filter(([id, { is_superuser }]) => this._owners.has(id) || is_superuser)
                   .map(([id, { first_name, last_name }]) => [id, `${first_name} ${last_name}`])
    },

    notificationRecipients() {
      return Object.keys(this._budgetPctThresExceededRecipients).map(id => {
        const { first_name, last_name } = this.users[id]
        return `${first_name} ${last_name}`
      }).sort().join(', ')
    },

    billingTypeStr() {
      return this._billingType ? humanize(this._billingType) : 'N/A'
    },

    projBillingTypeOptions() {
      const options = this.projBillingTypes.map(type => {
        const t = type === 'non_billable' ? type.replace('_', '-') : type
        return [type, humanize(t)]
      })
      options.splice(0, 0, [null, 'Select type'])
      return options
    },

    hourlyRateIsRequired() {
      if (this._billingType === null) return false
      return !(this._billingType === 'non_billable' && this._hoursBudget)
    },

    totalFeeIsRequired() {
      return ['flat_fee', 'monthly_fee'].includes(this._billingType)
    },

    hoursBudgetIsRequired() {
      if (['flat_fee', 'monthly_fee'].includes(this._billingType)) return true
      return ['hourly', 'non_billable'].includes(this._billingType) && !this._feesBudget
    },

    feesBudgetIsRequired() {
      return ['hourly', 'non_billable'].includes(this._billingType) && !this._hoursBudget
    },

    createdOn() {
      if (!this.created) return ''

      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },

    userNameAndId() {
      return Object.entries(this.users).map(([id, { first_name: first, last_name: last }]) => {
        return [`${first} ${last}`, id]
      }).sort()
    },

    projectStatusOptions() {
      const options = Object.entries(this.projectStatuses).sort((s1, s2) => s1[1].localeCompare(s2[1]))
      options.push([null, 'Clear status'])
      return options
    },

    projectStatusName() {
      return this.projectStatuses[this._statusId]
    },

    projectStatusBtnCSSClass() {
      return `btn-${projectStatusCSSClass(this.projectStatusName)}-glassy`
    },

    projectTypeOptions() {
      const options = Object.entries(this.projectTypes).sort((t1, t2) => t1[1].localeCompare(t2[1]))
      options.push([null, 'Clear type'])
      return options
    },

    parentCandidates() {
      return this.parentProjectCandidates.filter(proj => proj.synced_to_wrike === this._syncedToWrike)
    },

    ownerNames() {
      return ' ' + [...this._owners].map(id => {
        const { first_name: first, last_name: last } = this.users[id]
        return `${first} ${last.charAt(0)}.`
      }).sort().join(', ')
    },

    projectTypeSvgIcon() {
      return projectTypeIcon
    },

    clientSvgIcon() {
      return clientIcon
    },

    parentProjectSvgIcon() {
      return parentProjectIcon
    },

    ownersSvgIcon() {
      return ownersIcon
    },

    billingTypeSvgIcon() {
      return billingTypeIcon
    },
  },

  methods: {
    async onBillingTypeChanged() {
      if (['flat_fee', 'monthly_fee'].includes(this._billingType)) {
        this.budget = 'hours'
        this._resetBudgetMonthly = this._billingType === 'monthly_fee'
      }
      if (!this.id) return

      if (this._billingType === null) {
        this._hourlyRate = null
        this._totalFee = null
        this._hoursBudget = null
        this._feesBudget = null
        this._resetBudgetMonthly = false
        this.notificationsEnabled = false
        this._budgetPctThreshold = null
        for (const k in Object.keys(this._budgetPctThresExceededRecipients))
          delete this._budgetPctThresExceededRecipients[k]
      }
      await this.persistBillingData()
    },

    calculateHoursBudget() {
      if (!['flat_fee', 'monthly_fee'].includes(this._billingType)) return
      if (!this._hourlyRate || !this._totalFee) return

      this._hoursBudget = Math.floor(this._totalFee / this._hourlyRate)
    },

    async onHourlyRateChanged() {
      if (this._billingType === 'non_billable' && this._hourlyRate) this.budget = 'fees'

      if (!this.hourlyRateIsRequired) {
        this.$nextTick(() => this._hourlyRate = null)
        return
      }

      this.calculateHoursBudget()

      if (!this.id) return
      await this.persistBillingData()
    },

    async onTotalFeeChanged() {
      if (!this.totalFeeIsRequired) {
        this.$nextTick(() => this._totalFee = null)
        return
      }

      this.calculateHoursBudget()

      if (!this.id) return
      await this.persistBillingData()
    },

    async onHoursBudgetChanged() {
      if (this._hoursBudget) {
        this._feesBudget = null
        if (this._billingType === 'non_billable') this._hourlyRate = null
      }
      if (!this.id) return

      await this.persistBillingData()
    },

    async onFeesBudgetChanged() {
      if (this._feesBudget) this._hoursBudget = null
      if (!this.id) return

      await this.persistBillingData()
    },

    assembleBillingData() {
      const data = {
        billing_type: this._billingType,
        hourly_rate: this.hourlyRateIsRequired ? this._hourlyRate : null,
        total_fee: this.totalFeeIsRequired ? this._totalFee : null,
        hours_budget: this.hoursBudgetIsRequired ? this._hoursBudget : null,
        fees_budget: this.feesBudgetIsRequired ? this._feesBudget : null,
        reset_budget_monthly: this._resetBudgetMonthly,
      }

      if (this._budgetPctThreshold) {
        data.budget_pct_threshold = this._budgetPctThreshold
        data.budget_pct_thres_exceeded_recipients = this._budgetPctThresExceededRecipients
      } else {
        data.budget_pct_threshold = null
        data.budget_pct_thres_exceeded_recipients = {}
      }

      return data
    },

    async persistBillingData() {
      if (!this.id) return

      this.$nextTick(async () => {
        if (!this.$refs.form.reportValidity()) return

        try {
          await axios.patch(reverseUrl('projects.update', { id: this.id }),
                            this.assembleBillingData())
        } catch(err) {
          alert(`Unable to change project billing data due to:\n${stringifyValidationErrors(err)}`)
        }
      })
    },

    resetClientOptions() {
      this.clientOptions.splice(0, this.clientOptions.length)
    },

    async fetchClients(e = undefined) {
      const q = e ? e.target.value.trim() : ''

      // if (q.length < 3) {
      //   this.resetClientOptions()
      //   return
      // }

      try {
        const resp = await axios.get(reverseUrl('clients.index'), {
          params: {
            q,
          },
        })

        const { clients } = resp.data
        this.clientOptions.splice(0, this.clientOptions.length, ...clients)
      } catch(err) {
        console.error(`Unable to load client suggestions matching '${q}' due to: ${err}`)
      }
    },

    async onNameChanged(_e) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('projects.update', { id: this.id }), {
          name: this._name,
        })
      } catch(err) {
        alert(`Unable to change project name due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    async onDescriptionChanged(_e, _editor) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('projects.update', { id: this.id }), {
          description: this._description.trim(),
        })
      } catch(err) {
        let errMsg = 'Unable to change project description due to:'
        errMsg += `\n${stringifyValidationErrors(err)}`
        alert(errMsg)
      }
    },

    onClientChanged(id, name) {
      if (this._clientId && id === this._clientId) {
        this._clientId = 0
        this.clientName = ''
        if (!this.id) this._syncedToWrike = false
      } else {
        this._clientId = id
        this.clientName = name
        if (!this.id) {
          this._syncedToWrike = this.clientOptions.find(co => co.id === id)?.synced_to_wrike || false
        }
      }

      this._parentId = 0
      this.parentName = ''
      this.$inertia.reload({
        data: {
          client_id: id,
        },
        only: ['parent_project_candidates'],
      })
    },

    async onStatusChanged(newStatusId) {
      this._statusId = newStatusId
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('projects.update', { id: this.id }), {
          status_id: this._statusId,
        })
      } catch(err) {
        alert(`Unable to change project status due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    filterTypes(e) {
      const typeQuery = e.target.value.trim()
      if (!typeQuery) {
        this.typeRegex = null
        return
      }

      this.typeRegex = new RegExp(typeQuery, 'i')
    },

    async onTypeChanged(id) {
      if (this._typeId && id === this._typeId) {
        this._typeId = null
        this.typeName = ''
      } else {
        this._typeId = id
        this.typeName = this.projectTypes[id] || ''
      }
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('projects.update', { id: this.id }), {
          type_id: this._typeId,
        })
      } catch(err) {
        alert(`Unable to change project type due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    filterOwners(e) {
      const ownerQuery = e.target.value.trim()
      if (!ownerQuery) {
        this.ownerRegex = null
        return
      }

      this.ownerRegex = new RegExp(ownerQuery, 'i')
    },

    async onOwnerToggled(id) {
      if (this._owners.has(id)) {
        this._owners.delete(id)
      } else {
        this._owners.add(id)
      }
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('projects.update', { id: this.id }), {
          owner_ids: [...this._owners],
        })
      } catch(err) {
        alert(`Unable to change project owners due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    filterRecipients(e) {
      const recipientQuery = e.target.value.trim()
      if (!recipientQuery) {
        this.recipientRegex = null
        return
      }

      this.recipientRegex = new RegExp(recipientQuery, 'i')
    },

    async onRecipientToggled(id) {
      if (this._budgetPctThresExceededRecipients[id]) {
        delete this._budgetPctThresExceededRecipients[id]
      } else {
        this._budgetPctThresExceededRecipients[id] = this.budgetPctThresExceededRecipients[id] || null
      }
      if (!this.id) return

      await this.persistBillingData()
    },

    filterProjects(e) {
      const parentQuery = e.target.value.trim()
      if (!parentQuery) {
        this.parentRegex = null
        return
      }

      this.parentRegex = new RegExp(parentQuery, 'i')
    },

    async onParentToogled(id, name) {
      if (this._parentId && id === this._parentId) {
        this._parentId = 0
        this.parentName = ''
      } else {
        this._parentId = id
        this.parentName = name
      }
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('projects.update', { id: this.id }), {
          parent_id: this._parentId || null,
        })
      } catch(err) {
        alert(`Unable to change project parent due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    async onSyncedOnWrikeChanged(_e) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('projects.update', { id: this.id }), {
          synced_to_wrike: this._syncedToWrike,
        })
      } catch(err) {
        let errMsg = 'Unable to change project Wrike sync pref due to:'
        errMsg += `\n${stringifyValidationErrors(err)}`
        alert(errMsg)
      }
    },

    onUserJobAdded({ userId, jobId }) {
      this.userIdsByJobId[jobId] ||= new Set()
      this.userIdsByJobId[jobId].add(userId)
    },

    onUserJobRemoved({ userId, jobId }) {
      if (!(jobId in this.userIdsByJobId)) return

      this.userIdsByJobId[jobId].delete(userId)
    },

    async saveNewProject() {
      if (!this.$refs.form.reportValidity()) return

      if (!this._clientId) {
        alert('Please select a client to proceed')
        return
      }

      if (!this._owners.size) {
        alert('Please select at least one owner')
        return
      }

      const data = {
        name: this._name,
        description: this._description,
        synced_to_wrike: this._syncedToWrike,
        client_id: this._clientId,
      }
      if (this._statusId) data.status_id = this._statusId
      if (this._typeId) data.type_id = this._typeId
      if (this._owners) data.owner_ids = [...this._owners]
      if (this._parentId) data.parent_id = this._parentId
      const user_ids_by_job_id = {}
      for (const [jobId, userIds] of Object.entries(this.userIdsByJobId)) {
        if (!userIds.size) continue

        user_ids_by_job_id[jobId] = [...userIds]
      }
      data.user_ids_by_job_id = user_ids_by_job_id
      Object.assign(data, this.assembleBillingData())

      try {
        const resp = await axios.post(reverseUrl('projects.create'), data)

        const { id } = resp.data
        this.$inertia.get(reverseUrl('admin.projects.edit', { id }))
      } catch(err) {
        alert(`Unable to save new project due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    projectStatusBulletCSSClass(projStatusId) {
      const projStatusName = this.projectStatuses[projStatusId]
      return `color-${projectStatusCSSClass(projStatusName)}`
    },
  },

  components: {
    Link,
    Editor,
    ModelJobs,
    ModelDelConfirmModal,
  },
}
</script>

<template>
  <Layout>
    <div class="h2 font-weight-300 no-margin-y">
      {{ department.id ? 'Edit' : 'Add' }} Department
    </div>

    <div class="flex-grid flex-grid-fixed">
      <div class="flex-col-xs-12 flex-col-sm-9 flex-col-md-7">
        <form @submit.prevent="submitForm">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="name" class="input-label font-weight-700">Name</label>
            <input id="name" class="input input-single-line"
                   :class="{ 'input-error': form.errors.name }"
                   minlength="2" maxlength="100"
                   ref="departmentName" v-model.trim="form.name" required>

            <div class="input-info color-error font-weight-700" v-if="form.errors.name">
              {{ form.errors.name.join(', ') }}
            </div>
          </div>

          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="description" class="input-label font-weight-700">Description</label>
            <textarea id="description" class="input input-multiple-line input-block"
                      v-model.trim="form.description"></textarea>
          </div>

          <label class="input-label font-weight-700">Job titles</label>
          <datalist id="job-options">
            <template v-for="[id, title] in allJobs" :key="id">
              <option :value="title" v-if="findJobIndex(id) === -1" />
            </template>
          </datalist>

          <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap"
               v-for="[id, title] in form.jobs" :key="id">

            <div class="flex-child flex-1-1 no-margin-top">
              <label :for="`job-title-${id}`" class="sr-only">{{ title }}</label>
              <input :id="`job-title-${id}`" :value="title"
                     class="input input-single-line input-block"
                     list="job-options" @change="changeJob(id, $event)">
            </div>

            <div class="flex-child flex-0-0 no-margin-top flex-xs">
              <a href="#" class="btn btn-error-glassy btn-symbol"
                 @click.prevent="removeJob(id)">

                <i class="symbol symbol-minus"></i>
              </a>
            </div>
          </div>

          <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap">
            <div class="flex-child flex-1-1 no-margin-top">
              <input list="job-options" class="input input-single-line input-block"
                     placeholder="Add job.." @change="addJob($event)">
            </div>
          </div>

          <hr class="margin-large-y">

          <div class="margin-large-top">
            <button class="btn btn-theme" :disabled="form.processing">Save</button>
            <Link :href="$route('admin.departments.index')"
                  class="btn btn-neutral-glassy margin-left">

              Go Back
            </Link>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link, useForm } from '@inertiajs/vue3'

import Layout from '../Layout'

export default {
  name: 'DepartmentForm',

  props: {
    department: {
      type: Object,
      required: true,
    },
    all_jobs: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      name: props.department.name,
      description: props.department.description,
      jobs: Object.entries(props.department.jobs).sort((j1, j2) => j1[1].localeCompare(j2[1])),
    })

    return { form }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.departmentName.focus()
  },*/

  computed: {
    allJobs() {
      return Object.entries(this.all_jobs).sort((j1, j2) => j1[1].localeCompare(j2[1]))
    },
  },

  methods: {
    findJobIndex(jobId) {
      const jId = String(jobId)
      return this.form.jobs.findIndex(([id, _]) => id === jId)
    },

    findJobEntryFor(title) {
      const jobTitle = title.trim().toLowerCase()
      return Object.entries(this.all_jobs).find(([_, title]) => title.toLowerCase() === jobTitle)
    },

    addJob(event) {
      const entry = this.findJobEntryFor(event.target.value)
      if (entry && this.findJobIndex(entry[0]) === -1) {
        this.form.jobs.push(entry)
      }

      event.target.value = ''
    },

    changeJob(id, event) {
      const entry = this.findJobEntryFor(event.target.value)

      if (entry) {
        const index = this.findJobIndex(entry[0])
        if (index !== -1) this.form.jobs[index][1] = entry[1]
      } else {
        event.target.value = this.all_jobs[id]
      }
    },

    removeJob(jobId) {
      const index = this.findJobIndex(jobId)
      if (index === -1) return

      this.form.jobs.splice(index, 1)
    },

    submitForm() {
      const frm = this.form.transform(data => {
        return {
          ...data,
          jobs: data.jobs.map(([id, _]) => Number(id))
        }
      })

      if (this.department.id) {
        frm.patch(reverseUrl('admin.departments.update', { id: this.department.id }))
      } else {
        frm.post(reverseUrl('admin.departments.create'))
      }
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

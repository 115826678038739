<template>
  <div class="module margin-top">
    <div class="module-header">
      <div class="module-title">{{ status }}</div>
    </div>

    <div class="module-content no-padding">
      <draggable :modelValue="visibleTasks" item-key="id" handle=".draggable-task" ghostClass="disabled"
                 :move="onMove" @update:modelValue="updateTaskList" v-if="visibleTasks.length">

        <template #item="{element}">
          <TaskRow :task="element" :status="status" :taskStatusOptions="taskStatusOptions"
                    @open:taskStatusModal="$emit('open:taskStatusModal', $event)"
                    @task:changed="changeTask"
                    @task:deleted="deleteTask" />
        </template>
      </draggable>

      <div class="font-style-italic color-neutral padding border-width-thin-top border-style-solid-top border-color-neutral-alpha-3"
           v-else>

          No favorite tasks with status '{{ status }}'
        </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'
import draggable from 'vuedraggable'

import TaskRow from '../components/TaskRow'

export default {
  name: 'StatusTasks',

  props: {
    status: {
      type: String,
      required: true
    },
    tasks: {
      type: Array,
      required: true
    },
    taskStatusOptions: {
      type: Object,
      required: true
    },
  },

  data() {
    const onFavoritesPage = location.pathname.endsWith(this.$route('main.favorites')),
          visibleTasks = onFavoritesPage ? this.tasks.filter(t => t.is_favorite) : this.tasks

    return {
      visibleTasks,
      onFavoritesPage,
      validTaskMove: false,
    }
  },

  mounted() {
    this.$emitter.on('taskStatus:changed', this.onTaskStatusChanged)
    this.$emitter.on('timelogs:filtered', this.onTimelogsFiltered)
    this.$emitter.on('timelog:added', this.onTimelogAdded)
  },

  unmounted() {
    this.$emitter.off('timelogs:filtered', this.onTimelogsFiltered)
    this.$emitter.off('timelog:added', this.onTimelogAdded)
    this.$emitter.off('taskStatus:changed', this.onTaskStatusChanged)
  },

  methods: {
    changeTask({ id, changes }) {
      const idx = this.visibleTasks.findIndex(t => t.id === id)
      if (idx === -1) return

      const task = this.visibleTasks[idx]
      Object.assign(task, changes)
      if (task.status?.name !== this.status) {
        const [task] = this.visibleTasks.splice(idx, 1)
        this.$emitter.emit('taskStatus:changed', task)
      }

      if ('status' in changes) this.$emit('taskStatus:changed')
    },

    deleteTask(taskId) {
      const index = this.visibleTasks.findIndex(t => t.id === id)
      if (index === -1) return

      this.visibleTasks.splice(index, 1)
    },

    onTaskStatusChanged(task) {
      if (task.status?.name !== this.status) return

      this.visibleTasks.push(task)
    },

    onTimelogsFiltered() {
      const tasks = this.onFavoritesPage ? this.tasks.filter(t => t.is_favorite) : this.tasks
      this.visibleTasks.splice(0, this.visibleTasks.length, ...tasks)
    },

    async onTimelogAdded({ timelog }) {
      const taskId = timelog.task.id
      const t = this.visibleTasks.find(t => t.id === taskId)
      if (!t) return

      const resp = await axios.get(this.$route('tasks.show', { id: taskId }))
      const { task } = resp.data
      t.total_time = task.total_time

      this.changeTask({
        id: taskId,
        changes: {
          status: task.status,
          category: task.category,
        }
      })
    },

    async onMove(event, _originalEvent) {
      const taskId = event.draggedContext.element.id,
            task = this.visibleTasks.find(t => t.id === taskId)
      if (!task) return

      const priority = event.draggedContext.futureIndex,
            dragDir = priority < event.draggedContext.index ? 'asc' : 'desc'
      if (dragDir === 'asc') {
        this.validTaskMove = event.relatedContext.element.priority || !priority
      } else {
        this.validTaskMove = this.tasks.every(t => t.priority !== null)
        this.validTaskMove &&= priority === this.visibleTasks.length - 1 || priority
      }
      if (!this.validTaskMove) return false // cancel move

      await axios.patch(this.$route('tasks.update', { id: taskId }), {
        priority,
        drag_dir: dragDir,
      })
      task.priority = priority

      const resp = await axios.get(this.$route('tasks.index'), {
        params: {
          status: this.status,
        }
      })
      const { tasks: reprioritizedTasks } = resp.data
      for (const reprioritizedTask of reprioritizedTasks) {
        const task = this.visibleTasks.find(t => t.id === reprioritizedTask.id)
        if (!task) continue

        task.priority = reprioritizedTask.priority
      }
    },

    updateTaskList(newValue) {
      if (!this.validTaskMove) return

      this.visibleTasks.splice(0, this.visibleTasks.length, ...newValue)
      this.validTaskMove = false
    },
  },

  emits: ['open:taskStatusModal', 'taskStatus:changed'],
  components: {
    draggable,
    TaskRow,
  },
}
</script>

<template>
  <form @submit.prevent="submitForm" class="flex-grid flex-grid-compact align-items-flex-end">
    <div class="flex-col-xs-12 flex-col-md-10">
      <div class="flex-grid flex-grid-fixed flex-grid-compact no-margin-bottom ">

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="account_manager_id" class="input-label no-padding-top padding-small-bottom">
                Account manager
              </label>

              <input id="account_manager_id" class="input input-single-line input-block"
                     list="account-managers" placeholder="Select account manager"
                     ref="accountManager" @change="onAccMgrChanged">
              <datalist id="account-managers">
                <option :value="fullName" v-for="[id, fullName] in accountManagers" :key="id" />
              </datalist>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="sales_rep_id" class="input-label no-padding-top padding-small-bottom">
                Sales rep
              </label>

              <input id="sales_rep_id" class="input input-single-line input-block"
                     list="sales-reps" placeholder="Select sales rep"
                     ref="salesRep" @change="onSalesRepChanged">
              <datalist id="sales-reps">
                <option :value="fullName" v-for="[id, fullName] in salesReps" :key="id" />
              </datalist>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-child flex-1-1">
      <button class="btn btn-theme-outline btn-block margin-top">Filter clients</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ClientFilters',

  props: {
    accountManagerById: {
      type: Object,
      required: true,
    },
    salesRepById: {
      type: Object,
      required: true,
    },
  },

  data() {
    const params = new URLSearchParams(location.search)

    return {
      accountManagerId: Number(params.get('account_manager') || 0),
      salesRepId: Number(params.get('sales_rep') || 0),
    }
  },

  mounted() {
    const params = new URLSearchParams(location.search)
    const accountManagerId = params.get('account_manager')
    if (accountManagerId) {
      const index = this.accountManagers.findIndex(([id]) => id === accountManagerId)
      if (index !== -1) this.$refs.accountManager.value = this.accountManagers[index][1]
    }

    const salesRepId = params.get('sales_rep')
    if (salesRepId) {
      const index = this.salesReps.findIndex(([id]) => id === salesRepId)
      if (index !== -1) this.$refs.salesRep.value = this.salesReps[index][1]
    }
  },

  computed: {
    accountManagers() {
      return Object.entries(this.accountManagerById).map(([userId, { first_name, last_name }]) => {
        return [userId, [first_name, last_name].join(' ')]
      }).sort(([a1, a2]) => a1[1].localeCompare(a2[1]))
    },

    salesReps() {
      return Object.entries(this.salesRepById).map(([userId, { first_name, last_name }]) => {
        return [userId, [first_name, last_name].join(' ')]
      }).sort(([a1, a2]) => a1[1].localeCompare(a2[1]))
    },
  },

  methods: {
    accountManagerIdFor(name) {
      const index = this.accountManagers.findIndex(([userId, fullName]) => fullName === name)
      if (index === -1) return 0

      return Number(this.accountManagers[index][0])
    },

    onAccMgrChanged(event) {
      this.accountManagerId = this.accountManagerIdFor(event.target.value.trim())
    },

    salesRepIdFor(name) {
      const index = this.salesReps.findIndex(([userId, fullName]) => fullName === name)
      if (index === -1) return 0

      return Number(this.salesReps[index][0])
    },

    onSalesRepChanged(event) {
      this.salesRepId = this.salesRepIdFor(event.target.value.trim())
    },

    submitForm() {
      const params = new URLSearchParams(location.search)
      params.delete('account_manager')
      if (this.accountManagerId) params.set('account_manager', this.accountManagerId)
      params.delete('sales_rep')
      if (this.salesRepId) params.set('sales_rep', this.salesRepId)
      params.delete('page')

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['clients', 'page', 'page_count'],
      })
    },
  },
}
</script>

<template>
  <Link :href="$route('main.index')" id="timetracker-logo"
        class="nav-block justify-content-center padding-small-x flex-0-0" v-html="logoSvgIcon">
  </Link>

  <button class="only-mobile timetracker-nav-toggle btn background-theme-polar-active color-theme-polar-active btn-symbol btn-no-radius border-color-transparent position-relative flex-0-0 position-fixed offset-0-top offset-0-right"
          data-toggle-accordion>

    <i class="symbol symbol-burger symbol-close-toggle"></i>
  </button>

  <div class="accordion accordion-mobile timetracker-nav-accordion flex-1-1 flex-xs flex-direction-column">
    <ul class="nav-list timetracker-nav-list nav-list-responsive flex-1-1 margin-large-bottom">
      <li class="nav-item-label margin-large-top margin-small-bottom">
        <span class="h5 no-margin font-weight-400">Time tracking</span>
      </li>
      <li>
        <Link :href="$route('main.time')" class="color-inherit-hover position-relative">
          <span class="nav-icon flex-0-0" v-html="timerSvgIcon"></span>
          <span class="nav-item-text flex-1-1">Timer</span>
        </Link>
      </li>

      <li class="nav-item-label margin-large-top margin-small-bottom">
        <span class="h5 no-margin font-weight-400">Management</span>
      </li>
      <li>
        <Link :href="$route('admin.clients.index')"
              class="color-inherit-hover position-relative">

          <span class="nav-icon flex-0-0" v-html="clientsSvgIcon"></span>
          <span class="nav-item-text flex-1-1">Clients</span>
        </Link>
      </li>
      <li>
        <Link :href="$route('admin.projects.index')"
              class="color-inherit-hover position-relative">

          <span class="nav-icon flex-0-0" v-html="projectsSvgIcon"></span>
          <span class="nav-item-text flex-1-1">Projects</span>
        </Link>
      </li>
      <li>
        <Link :href="$route('admin.tasks.index')"
              class="color-inherit-hover position-relative">

          <span class="nav-icon flex-0-0" v-html="tasksSvgIcon"></span>
          <span class="nav-item-text flex-1-1">Tasks</span>
        </Link>
      </li>

      <!--li class="nav-item-label margin-large-top margin-small-bottom">
        <span class="h5 no-margin font-weight-400">Analytics</span>
      </li>
      <li>
        <Link href="#" class="color-inherit-hover position-relative">
          <span class="nav-icon flex-0-0" v-html="reportsSvgIcon"></span>
          <span class="nav-item-text flex-1-1">Reports</span>
        </Link>
      </li-->
      <li>
        <Link :href="$route('insights.index')" class="color-inherit-hover position-relative">
          <span class="nav-icon flex-0-0" v-html="insightsSvgIcon"></span>
          <span class="nav-item-text flex-1-1">Insights</span>
        </Link>
      </li>

      <template v-if="$page.props.current_user.is_staff">
        <li class="nav-item-label margin-large-top margin-small-bottom">
          <span class="h5 no-margin font-weight-400">Admin</span>
        </li>
        <li>
          <Link :href="$route('admin.departments.index')"
                class="color-inherit-hover position-relative">

            <span class="nav-icon flex-0-0" v-html="departmentsSvgIcon"></span>
            <span class="nav-item-text flex-1-1">Departments</span>
          </Link>
        </li>
        <li>
          <Link :href="$route('admin.groups.index')"
                class="color-inherit-hover position-relative">

            <span class="nav-icon flex-0-0" v-html="groupsSvgIcon"></span>
            <span class="nav-item-text flex-1-1">Groups</span>
          </Link>
        </li>
        <li>
          <Link :href="$route('admin.jobs.index')"
                class="color-inherit-hover position-relative">

            <span class="nav-icon flex-0-0" v-html="jobsSvgIcon"></span>
            <span class="nav-item-text flex-1-1">Jobs</span>
          </Link>
        </li>
      </template>

      <template v-if="$page.props.current_user.is_superuser">
        <li>
          <Link :href="$route('admin.project_statuses.index')"
                class="color-inherit-hover position-relative">

            <span class="nav-icon flex-0-0" v-html="settingsSvgIcon"></span>
            <span class="nav-item-text flex-1-1">Project statuses</span>
          </Link>
        </li>
        <li>
          <Link :href="$route('admin.project_types.index')"
                class="color-inherit-hover position-relative">

            <span class="nav-icon flex-0-0" v-html="settingsSvgIcon"></span>
            <span class="nav-item-text flex-1-1">Project types</span>
          </Link>
        </li>
        <li>
          <Link :href="$route('admin.task_statuses.index')"
                class="color-inherit-hover position-relative">

            <span class="nav-icon flex-0-0" v-html="settingsSvgIcon"></span>
            <span class="nav-item-text flex-1-1">Task statuses</span>
          </Link>
        </li>
        <li>
          <Link :href="$route('admin.task_status_groups.index')"
                class="color-inherit-hover position-relative">

            <span class="nav-icon flex-0-0" v-html="settingsSvgIcon"></span>
            <span class="nav-item-text flex-1-1">Task status groups</span>
          </Link>
        </li>
        <li>
          <Link :href="$route('admin.task_categories.index')"
                class="color-inherit-hover position-relative">

            <span class="nav-icon flex-0-0" v-html="settingsSvgIcon"></span>
            <span class="nav-item-text flex-1-1">Task categories</span>
          </Link>
        </li>
      </template>

      <li v-if="$page.props.current_user.is_staff">
        <Link :href="$route('admin.users.index')"
              class="color-inherit-hover position-relative">

          <span class="nav-icon flex-0-0" v-html="usersSvgIcon"></span>
          <span class="nav-item-text flex-1-1">Team</span>
        </Link>
      </li>
    </ul>

    <div id="timetracker-sidebar-utility"
         class="margin-auto-top padding-x padding-small-y position-sticky offset-0-bottom offset-0-x">

      <div class="flex-grid flex-grid-compact flex-grid-no-gutter-y align-items-center flex-nowrap">
        <div class="flex-child flex-0-0">
          <div class="thumbnail thumbnail-small radius-small">
            <span class="thumbnail-text">{{ currentUserInitials }}</span>
          </div>
        </div>

        <div class="flex-child flex-1-1">
          <span class="text-wrap-ellipsis">More options</span>
        </div>

        <div class="flex-child flex-0-0">
          <a href="#"
             class="font-size-large color-inherit color-neutral-light-hover btn-symbol padding-y"
             data-toggle-dropdown>

            <i class="symbol symbol-kebab-horizontal"></i>
          </a>

          <ul class="dropdown position-absolute  dropdown-bottom-flush dropdown-right padding"
              data-dropdown-width="100%" data-dropdown-max-height="300px">

            <li>
              <Link :href="$route('users.edit_profile')" class="color-inherit-hover position-relative">
                Profile
              </Link>
            </li>

            <li>
              <Link :href="$route('settings.index')" class="color-inherit-hover position-relative">
                Settings
              </Link>
            </li>

            <li>
              <Link :href="$route('auth.logout')" @click="logout"
                    class="color-inherit-hover position-relative">
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3'

import logoIcon from './img/icon-logo.svg?raw'
import timerIcon from './img/icon-timer.svg?raw'
import clientsIcon from './img/icon-clients.svg?raw'
import projectsIcon from './img/icon-clients.svg?raw'
import tasksIcon from './img/icon-tasks.svg?raw'
import reportsIcon from './img/icon-reports.svg?raw'
import insightsIcon from './img/icon-insights.svg?raw'
import settingsIcon from './img/icon-settings.svg?raw'
import departmentsIcon from './img/icon-departments.svg?raw'
import jobsIcon from './img/icon-jobs.svg?raw'
import usersIcon from './img/icon-users.svg?raw'
import groupsIcon from './img/icon-groups.svg?raw'

export default {
  name: 'Sidebar',

  computed: {
    currentUserInitials() {
      return this.$page.props.current_user.first_name[0] + this.$page.props.current_user.last_name[0]
    },

    logoSvgIcon() {
      return logoIcon
    },

    timerSvgIcon() {
      return timerIcon
    },

    clientsSvgIcon() {
      return clientsIcon
    },

    projectsSvgIcon() {
      return projectsIcon
    },

    tasksSvgIcon() {
      return tasksIcon
    },

    reportsSvgIcon() {
      return reportsIcon
    },

    insightsSvgIcon() {
      return insightsIcon
    },

    settingsSvgIcon() {
      return settingsIcon
    },

    departmentsSvgIcon() {
      return departmentsIcon
    },

    jobsSvgIcon() {
      return jobsIcon
    },

    usersSvgIcon() {
      return usersIcon
    },

    groupsSvgIcon() {
      return groupsIcon
    },
  },

  methods: {
    logout(event) {
      this.$inertia.delete(event.target.href)
    },
  },

  components: {
    Link,
  },
}
</script>

<template>
  <div id="timetracker">
    <aside id="timetracker-sidebar" class="theme-inverse pointer-reference background-theme-contrast">
      <div id="timetracker-sidebar-nav" class="nav nav-vertical background-transparent">
        <Sidebar />
      </div>
    </aside>

    <StickyTimer v-show="onMyTasksOrFavsPage && timerStarted" />

    <main id="timetracker-main" class="padding-large-y">
      <div class="container">
        <FlashMessages />
        <ToastNotifications :messages="notifications" />

        <div class="flex-grid flex-grid-fixed align-items-center" v-if="this.$page.component === 'Time'">
          <div class="flex-col-xs-12 flex-col-sm-8 flex-col-md-6">
            <ul class="tabs">
              <li :class="{ active: onTimePage }">
                <Link :href="$route('main.time')">Time</Link>
              </li>
              <li :class="{ active: location.pathname.endsWith($route('main.my_tasks')) }">
                <Link :href="$route('main.my_tasks')">My tasks</Link>
              </li>
              <li :class="{ active: location.pathname.endsWith($route('main.favorites')) }">
                <Link :href="$route('main.favorites')">Favorites</Link>
              </li>
            </ul>
          </div>

          <div class="flex-col-xs-12 flex-col-sm-4 flex-col-md-6 flex-md text-align-center text-decoration-line-under justify-content-flex-end"
               v-if="onTimePage && !$impersonatingUser.value">

            <a href="#"
               class="color-neutral-dark text-decoration-underline color-theme-hover"
               :class="{ disabled: !!timerStarted }"
               @click.prevent="openManualTimeEntryModal">

              Add manual entry +
            </a>

            <ManualTimeEntryModal :taskCategoryOptions="taskCategoryOptions"
                                  :modalOpen="manualEntryModalIsOpen"
                                  @manualTimeEntryModal:closed="manualEntryModalIsOpen = false" />
          </div>
        </div>

        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import Sidebar from './Sidebar'
import StickyTimer from './components/StickyTimer'
import FlashMessages from './components/FlashMessages'
import ToastNotifications from './components/ToastNotifications'
import ManualTimeEntryModal from './components/ManualTimeEntryModal'

export default {
  name: 'Layout',

  data() {
    return {
      timerStarted: !!localStorage.getItem('timer'),
      manualEntryModalIsOpen: false,
      notifications: [],
    }
  },

  mounted() {
    this.$emitter.on('start:timer', this.onStartTimer)
    this.$emitter.on('timer:started', this.onStartTimer)
    this.$emitter.on('stop:timer', this.onStopTimer)
    this.$emitter.on('timer:stopped', this.onStopTimer)
    if (this.onTimePage) this.$emitter.on('timelog:added', this.onTimelogAdded)
  },

  unmounted() {
    if (this.onTimePage) this.$emitter.off('timelog:added', this.onTimelogAdded)
    this.$emitter.off('timer:stopped', this.onStopTimer)
    this.$emitter.off('stop:timer', this.onStopTimer)
    this.$emitter.off('timer:started', this.onStartTimer)
    this.$emitter.off('start:timer', this.onStartTimer)
  },

  computed: {
    location() {
      return window.location
    },

    onTimePage() {
      return location.pathname.endsWith(this.$route('main.time'))
    },

    onMyTasksOrFavsPage() {
      return [this.$route('main.my_tasks'), this.$route('main.favorites')].some(path => {
        return location.pathname.endsWith(path)
      })
    },

    taskCategoryOptions() {
      if ('task_categories' in this.$page.props) {
        return Object.entries(this.$page.props.task_categories)
                     .sort((c1, c2) => c1[1].localeCompare(c2[1]))
      } else {
        return []
      }
    },
  },

  methods: {
    onStartTimer() {
      this.timerStarted = true
    },

    onStopTimer() {
      this.timerStarted = false
    },

    onTimelogAdded() {
      this.notifications.push('Your time was posted!')
      setTimeout(() => this.notifications.splice(0, this.notifications.length),
                 this.$page.props.app_config.timer_state_reset_delay_millis)
    },

    openManualTimeEntryModal() {
      if (this.timerStarted) return

      this.manualEntryModalIsOpen = true
    },
  },

  components: {
    Link,
    Sidebar,
    StickyTimer,
    FlashMessages,
    ToastNotifications,
    ManualTimeEntryModal,
  },
}
</script>

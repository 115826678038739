import { TZDateMini } from '@date-fns/tz';

export function taskStatusCSSClass(status) {
  if (/(?:progress|production)/i.exec(status)) {
    return 'primary';
  } else if (/review/i.exec(status)) {
    return 'secondary';
  } else if (/(?:on hold|cancelled|blocked)/i.exec(status)) {
    return 'error';
  } else if (/completed/i.exec(status)) {
    return 'success';
  } else {
    return 'neutral';
  }
}

export function dueDateCSSClass(dueDate = undefined, timeZone = undefined) {
  let dueSoon = false,
    now = new Date();
  if (timeZone) now = new TZDateMini(now.getTime(), timeZone.value);

  if (dueDate) {
    dueSoon = (dueDate.getTime() - now.getTime()) / 1_000 / 3_600 <= 24;
  }

  return {
    'color-error-dark': dueSoon,
  };
}

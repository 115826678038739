<template>
  <Layout>
    <div id="timetracker-heading" class="flex-grid justify-content-space-between align-items-center">
      <div class="flex-col-xs-12">
        <h1 class="no-margin">Edit Profile</h1>
      </div>
    </div>

    <form id="timetracker-profile" class="module module-expanded" @submit.prevent="submitForm">
      <div class="module-content padding-large-y">
        <div class="module module-expanded margin-large-bottom">
          <div class="module-content padding-large">
            <div class="flex-grid align-items-center">
              <div class="flex-child flex-0-0">
                <span class="thumbnail pointer-reference" :class="{ 'background-transparent': avatar }">
                  <UserThumb :first_name="first_name" :last_name="last_name" :avatar="avatar"
                             :small="false" />

                  <a href="#" class="btn btn-small btn-symbol btn-theme btn-round only-hover margin-auto-left"
                     data-toggle-modal-default>

                    <i class="symbol symbol-pencil"></i>
                  </a>

                  <div class="modal modal-default font-size-normalize"
                       data-modal-title="Upload new profile image">

                    <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
                      <label for="avatar" class="sr-only">Profile image</label>
                      <input id="avatar" type="file" class="input input-single-line"
                             :accept="$page.props.allowed_img_exts.join(',')"
                             @input="form.avatar = $event.target.files[0]" ref="avatar">
                    </div>

                    <div class="text-align-right">
                      <a href="#" @click.prevent="changeAvatar" class="btn btn-theme"
                         data-toggle-modal-default-close>

                        Apply
                      </a>
                      <span class="spacer">&nbsp;</span>
                      <a href="#" class="btn btn-neutral-glassy" data-toggle-modal-default-close>
                        Cancel
                      </a>
                    </div>
                  </div>
                </span>
              </div>

              <div class="flex-child flex-1-1">
                <h1 class="no-margin-y">{{ fullName }}</h1>
                <p>
                  <span v-if="job">{{ job.title }}</span>
                  <span class="color-neutral font-style-italic" v-else>Not set</span>
                </p>

                <p class="no-margin-bottom" v-if="location">
                  <i class="symbol symbol-map"></i>
                  {{ location }}
                </p>
              </div>

              <div class="flex-child flex-1-0 text-align-right">
                <a href="#" class="btn btn-block-mobile btn-theme-outline">View my stats</a>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grid flex-grid-fixed flex-grid-expanded flex-grid-no-gutter-y margin-large-bottom">
          <div class="flex-col-xs-12 flex-col-md-6">
            <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
              <label for="first_name" class="input-label font-weight-700">First name</label>
              <input class="input input-single-line"
                     :class="{ 'input-error': form.errors.first_name }"
                     minlength="2" maxlength="100"
                     ref="firstName" v-model.trim="form.first_name" required>
              <div class="input-info color-error font-weight-700" v-if="form.errors.first_name">
                {{ form.errors.first_name.join(', ') }}
              </div>
            </div>
          </div>

          <div class="flex-col-xs-12 flex-col-md-6">
            <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
              <label for="last_name" class="input-label font-weight-700">Last name</label>
              <input class="input input-single-line"
                     :class="{ 'input-error': form.errors.last_name }"
                     minlength="2" maxlength="100" v-model.trim="form.last_name"
                     required>
            </div>
            <div class="input-info color-error font-weight-700" v-if="form.errors.last_name">
              {{ form.errors.last_name.join(', ') }}
            </div>
          </div>

          <div class="flex-col-xs-12 flex-col-md-6">
            <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
              <label for="email" class="input-label font-weight-700">Email</label>
              <input type="email" id="email" class="input input-single-line"
                     :class="{ 'input-error': form.errors.email }"
                     minlength="2" maxlength="100" autocomplete="username"
                     v-model.trim="form.email" required>
            </div>
            <div class="input-info color-error font-weight-700" v-if="form.errors.email">
              {{ form.errors.email.join(', ') }}
            </div>
          </div>

          <div class="flex-col-xs-12 flex-col-md-6">
            <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
              <label for="phone" class="input-label font-weight-700">Phone</label>
              <input type="tel" id="phone" class="input input-single-line"
                     :class="{ 'input-error': form.errors.phone }"
                     minlength="10" maxlength="20"
                     autocomplete="tel"
                     v-model.trim="form.phone">
            </div>
            <div class="input-info color-error font-weight-700" v-if="form.errors.phone">
              {{ form.errors.phone.join(', ') }}
            </div>
          </div>
        </div>

        <div class="margin-large-bottom">
          <h1>Password change</h1>

          <div class="flex-grid flex-grid-fixed flex-grid-expanded flex-grid-no-gutter-y">
            <div class="flex-col-xs-12 flex-col-md-6">
              <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
                <label for="new_password" class="input-label font-weight-700">New password</label>
                <input type="password" id="new_password" class="input input-single-line"
                       :class="{ 'input-error': form.errors.new_password }"
                       :minlength="$page.props.app_config.minimum_password_length"
                       :maxlength="$page.props.app_config.maximum_password_length"
                       autocomplete="new-password"
                       :required="!!form.new_password_confirmation" v-model.trim="form.new_password">
              </div>
              <div class="input-info color-error font-weight-700" v-if="form.errors.new_password">
                {{ form.errors.new_password.join(', ') }}
              </div>
            </div>

            <div class="flex-col-xs-12 flex-col-md-6">
              <div class="input-wrapper input-wrapper-block input-wrapper-vertical p">
                <label for="new_password_confirmation" class="input-label font-weight-700">
                  Password confirmation
                </label>
                <input type="password" id="new_password_confirmation" class="input input-single-line"
                       :class="{ 'input-error': form.errors.new_password_confirmation }"
                       :minlength="$page.props.app_config.minimum_password_length"
                       :maxlength="$page.props.app_config.maximum_password_length"
                       autocomplete="new-password"
                       :required="!!form.new_password" v-model.trim="form.new_password_confirmation">
              </div>
              <div class="input-info color-error font-weight-700"
                    v-if="form.errors.new_password_confirmation">

                {{ form.errors.new_password_confirmation.join(', ') }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex-md justify-content-space-between align-items-center">
          <button type="submit" class="btn btn-theme btn-block-mobile" :disabled="form.processing">
            Save
          </button>

          <span class="spacer margin-small-right">&nbsp;</span>

          <Link :href="$returnBack() || $route('admin.users.index')"
                class="btn btn-neutral-glassy btn-block-mobile">

            Go back
          </Link>

          <span class="spacer margin-small-right margin-auto-left">&nbsp;</span>

          <button class="btn btn-error-glassy font-weight-400 btn-block-mobile"
                  data-toggle-modal-default>

            Delete account
          </button>
          <div class="modal modal-default" data-modal-title="Account deletion confirmation"
               data-modal-width="525px">

            <p>Your account is about to be deleted. Are you sure you want to proceed?</p>

            <a href="#" class="btn btn-theme btn-block margin-small-bottom"
               data-toggle-modal-default-close>

              I changed my mind, please cancel!
            </a>
            <button class="btn btn-error btn-block"
                    @click.prevent="$inertia.delete($route('users.delete_profile'))">

              Yes, I'm sure!
            </button>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>

<script>
import { Link, useForm } from '@inertiajs/vue3'
import { toRefs } from 'vue'

import Layout from '../Layout'
import UserThumb from '../components/UserThumb'

export default {
  name: 'UserProfile',

  props: {
    first_name: {
      type: String,
      required: true,
    },
    last_name: {
      type: String,
      required: true,
    },
    job: {
      type: Object,
    },
    email: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
    },
    location: {
      type: String,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
    },
  },

  setup(props) {
    const form = useForm({
      first_name: props.first_name,
      last_name: props.last_name,
      email: props.email,
      phone: props.phone,
      new_password: '',
      new_password_confirmation: '',
      location: props.location,
      timezone: props.timezone,
      avatar: null,
    })

    return { form }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.firstName.focus()
  },*/

  computed: {
    fullName() {
      return `${this.first_name} ${this.last_name}`
    },
  },

  methods: {
    confirmAccountDeletion() {
      if (!confirm('Are you sure you want to delete your account?')) return

      this.$inertia.delete(reverseUrl('users.delete_profile'))
    },

    changeAvatar() {
      if (!this.form.avatar) return

      this.form.transform(data => ({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        avatar: data.avatar,
      })).post(reverseUrl('users.update_profile'), {
        onSuccess: () => {
          this.$refs.avatar.value = ''
        },
      })
    },

    submitForm(event) {
      if (!event.target.checkValidity()) return

      const { new_password: newPassword, new_password_confirmation: newPasswordConfirmation } = toRefs(this.form)
      if (new_password.value && new_password_confirmation.value) {
        if (new_password.value !== new_password_confirmation.value) {
          alert('Password must match its confirmation!')
          return
        }
      }

      const minPasswdLen = this.$page.props.app_config.minimum_password_length
      if (newPassword.value.length < minPasswdLen) {
        alert(`Password needs to be at least ${minPasswdLen} characters long!`)
        return
      }
      const maxPasswdLen = this.$page.props.app_config.maximum_password_length
      if (newPassword.value.length > maxPasswdLen) {
        alert(`Password needs to be shorter than ${maxPasswdLen} characters long!`)
        return
      }

      this.form.post(reverseUrl('users.update_profile'), {
        forceFormData: true,
        onSuccess: () => {
          newPassword.value = ''
          newPasswordConfirmation.value = ''
        },
      })
    },
  },

  components: {
    Link,
    Layout,
    UserThumb,
  },
}
</script>

<template>
  <div class="module margin-large-top margin-bottom">
    <div class="module-header module-header-break align-items-center no-margin-bottom">
      <div class="flex-1-1 padding-right">
        <p class="h4  no-margin-top">Tasks</p>
      </div>
      <hr class="hide-nonmobile no-margin-y">

      <div class="module-functions flex-xs align-items-center justify-content-flex-end no-margin-y">
        <div class="module-function">
          <a :href="$linkTo('admin.tasks.new', { qryStrParams: { project_id: id } })"
              class="btn btn-block-mobile color-primary-hover color-primary-focus">

            <span>
              <i class="symbol symbol-window-success"></i>
              Add task +
            </span>
          </a>
        </div>
      </div>
      <hr class="hide-nonmobile no-margin-y">
    </div>

    <div class="module-content no-padding-x no-padding-bottom">
      <table class="timetracker-table">
        <ProjectTask v-bind="task" :userNameById="userNameById" :userInitialsById="userInitialsById"
                     v-for="task in tasks" :key="task.id" />
      </table>
    </div>
  </div>

</template>

<script>
import axios from 'axios'

import ProjectTask from './ProjectTask'

export default {
  name: 'ProjectTasks',

  props: {
    id: {
      type: Number,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tasks: [],
    }
  },

  async created() {
    try {
      const resp = await axios.get(reverseUrl('tasks.index'), {
        params: {
          project: this.id,
          include_responsibles: true,
          include_tasks_not_assigned_to_user: !this.$onlyWorksOnFixedTasks.value,
        },
      })

      const { tasks } = resp.data
      this.tasks.splice(0, this.tasks.length, ...tasks)
    } catch(err) {
      console.error(`Unable to load tasks for project #${this.id} due to: ${err}`)
    }
  },

  computed: {
    userInitialsById() {
      return Object.fromEntries(
        Object.entries(this.users).map(([id, { first_name: first, last_name: last }]) => {
          return [id, first.charAt(0) + last.charAt(0)]
        })
      )
    },
    userNameById() {
      return Object.fromEntries(
        Object.entries(this.users).map(([id, { first_name: first, last_name: last }]) => {
          return [id, `${first} ${last}`]
        })
      )
    },
  },

  components: {
    ProjectTask,
  },
}
</script>

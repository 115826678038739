<template>
  <div class="margin-large-top">
    <form class="flex-grid flex-grid-fixed flex-grid-compact flex-grid-no-gutter-y align-items-flex-end"
          @submit.prevent="filterTimelogs">

      <div class="flex-col-xs-12 flex-col-md-10">
        <div class="flex-grid flex-grid-fixed flex-grid-compact">
          <div class="flex-col-xs-12 flex-col-md-3">
            <div class="position-relative">
              <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
                <label for="timelog-client-name-filter" class="input-label no-padding-top padding-small-bottom">
                  Client
                </label>
                <input type="text" id="timelog-client-name-filter" class="input input-single-line input-block"
                       placeholder="Select or type client name"
                       list="timelog-filter-client-options" autocomplete="off"
                       v-model="clientName" ref="clientName"
                       @input="debouncedAutocompleteClientName" @change="setClientId">

                <datalist id="timelog-filter-client-options">
                  <option :value="client.name"
                          v-for="client in clientOptions" :key="client.id" />
                </datalist>
              </div>
            </div>
          </div>

          <div class="flex-col-xs-12 flex-col-md-3">
            <div class="position-relative">
              <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
                <label for="timelog-project-name-filter" class="input-label no-padding-top padding-small-bottom">
                  Project
                </label>
                <input type="text" id="timelog-project-name-filter" class="input input-single-line input-block"
                       placeholder="Select or type project name"
                       list="timelog-filter-project-options" autocomplete="off"
                       v-model="projectName"
                       @input="debouncedAutocompleteProjectName" @change="setProjectId">

                <datalist id="timelog-filter-project-options">
                  <option :value="project.name"
                          v-for="project in projectOptions" :key="project.id" />
                </datalist>
              </div>
            </div>
          </div>

          <div class="flex-col-xs-12 flex-col-md-2">
            <div class="position-relative">
              <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
                <label for="timelog-task-status-filter" class="input-label no-padding-top padding-small-bottom">
                  Task status
                </label>

                <select id="timelog-task-status-filter" class="input input-single-line input-block"
                        v-model.number="taskStatusId">

                  <option :value="0">Select task status</option>
                  <optgroup :label="wrikeWf"
                            v-for="(statusIdByName, wrikeWf) in taskStatusOptions"
                            :key="wrikeWf">

                    <option :value="id" v-for="(id, name) in statusIdByName" :key="id">
                      {{ name }}
                    </option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>

          <div class="flex-col-xs-12 flex-col-md-2">
            <div class="position-relative">
              <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
                <label for="timelog-task-category-filter"
                       class="input-label no-padding-top padding-small-bottom">

                  Task category
                </label>

                <select id="timelog-task-category-filter"
                        class="input input-single-line input-block"
                        v-model.number="taskCategoryId">

                  <option :value="0">Select task category</option>
                  <option :value="id" v-for="[id, name] in taskCategoryOptions" :key="id">
                    {{ name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="flex-col-xs-12 flex-col-md-2">
            <div class="position-relative">
              <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
                <label for="timelog-date-range-filter" class="input-label no-padding-top padding-small-bottom">
                  Date range
                </label>

                <select id="timelog-date-range-filter" class="input input-single-line input-block"
                        v-model="date">

                  <option :value="value" v-for="(label, value) in dateOptions" :key="value">{{ label }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-col-xs-12 flex-col-md-2">
        <div class="flex-grid flex-grid-fixed flex-grid-compact">
          <div class="flex-col-12">
            <button class="btn btn-theme btn-block no-padding-x" :disabled="processing">
              Apply filters
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear, sub } from 'date-fns'
import { TZDateMini } from '@date-fns/tz';
import * as _ from 'lodash-es'

import { formatDate } from '../lib/date'

export default {
  name: 'TimelogFilters',

  props: {
    clients: {
      type: Array,
      required: true
    },
    taskStatusOptions: {
      type: Object,
      required: true
    },
    taskCategoryOptions: {
      type: Array,
      required: true
    },
    dateFrom: {
      type: String,
    },
    dateTo: {
      type: String,
    },
  },

  data() {
    const params = new URLSearchParams(location.search)

    return {
      clientId: Number(params.get('client_id')),
      clientName: '',
      clientOptions: [],
      projectId: Number(params.get('project_id')),
      projectName: '',
      projectOptions: [],
      taskStatusId: Number(params.get('task_status')),
      taskCategoryId: Number(params.get('task_category')),
      date: params.get('date') || '',
      processing: false,
    }
  },

  async created() {
    this.$watch(
      () => this.clients.length,
      (_length) => {
        this.clientOptions.splice(0, this.clientOptions.length, ...this.clients)
      }
    )
    if (!this.date) {
      const dateFrom = this.$page.props.date_from, dateTo = this.$page.props.date_to

      for (const dateParam of Object.keys(this.dateOptions)) {
        const [from, to] = this.dateParamToDateRange(dateParam)
        if (from === dateFrom && to === dateTo) {
          this.date = dateParam
        }
      }
    }

    if (this.projectId) {
      let url = this.$route('projects.show', { id: this.projectId })
      url += `?include_client=1`
      const resp = await axios.get(url)
      const { project } = resp.data
      const client = Object.assign({}, project.client)
      delete project.client
      this.projectOptions.splice(0, this.projectOptions.length, project)
      this.projectName = project.name
      this.clientOptions.splice(0, this.clientOptions.length, client)
      this.clientName = client.name
    } else if (this.clientId) {
      const resp = await axios.get(this.$route('clients.show', { id: this.clientId }))
      const { client } = resp.data
      this.clientOptions.splice(0, this.clientOptions.length, client)
      this.clientName = client.name
    }

    this.debouncedAutocompleteClientName = _.debounce(this.autocompleteClientName,
                                                      this.$page.props.app_config.autocomplete_debounce_min)
    this.debouncedAutocompleteProjectName = _.debounce(this.autocompleteProjectName,
                                                       this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    if (![reverseUrl('main.my_tasks'), reverseUrl('main.favorites')].includes(location.pathname)) {
      return
    }

    this.$refs.clientName.focus()
  },*/

  beforeUnmount() {
    this.debouncedAutocompleteClientName.cancel()
    this.debouncedAutocompleteProjectName.cancel()
  },

  computed: {
    dateOptions() {
      return {
        other: 'Other',
        today: 'Today',
        last_3_days: 'Last 3 days',
        this_week: 'This week',
        last_week: 'Last week',
        last_2_weeks: 'Last 2 weeks',
        this_month: 'This month',
        last_month: 'Last month',
        last_3_months: 'Last 3 months',
        last_6_months: 'Last 6 months',
        this_year: 'This year',
        last_year: 'Last year',
      }
    },

    onTimePage() {
      return location.pathname.endsWith(this.$route('main.time'))
    },
  },

  methods: {
    async autocompleteClientName(event) {
      const q = event.target.value

      const params = new URLSearchParams()
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) params.set('q', q)
      const resp = await axios.get(`${this.$route('clients.index')}?${params}`)
      const { clients } = resp.data
      this.clientOptions.splice(0, this.clientOptions.length, ...clients)
    },

    clearProjectOptions() {
      this.projectOptions.splice(0, this.projectOptions.length)
    },

    async setClientId(event) {
      const clientName = event.target.value.trim().toLowerCase()
      const client = this.clientOptions.find(c => c.name.toLowerCase() === clientName)
      this.clientId = client?.id || 0
      if (!this.clientId) {
        setTimeout(() => {
          event.target.value = ''

          this.projectId = 0
          this.projectName = ''
        },
        this.$page.props.app_config.autocomplete_field_reset_delay)
      }

      this.clearProjectOptions()
      await this.autocompleteProjectName()
    },

    async autocompleteProjectName(event) {
      if (!this.clientId) {
        this.clearProjectOptions()
        return
      }

      const q = event?.target?.value || ''
      const params = new URLSearchParams()
      params.set('client_id', this.clientId)
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) params.set('q', q)
      const resp = await axios.get(`${this.$route('projects.index')}?${params}`)
      const { projects } = resp.data
      const dupeProjects = Object.values(Object.entries(Object.groupBy(projects, p => p.name)).flatMap(([_, arr]) => arr.length > 1 ? arr : []))
      for (const dupeProj of dupeProjects) {
        const idx = projects.findIndex(proj => proj.id === dupeProj.id)
        if (idx === -1) continue

        const replaceProj = Object.assign(dupeProj, { name: `${dupeProj.name} [${dupeProj.id}]` })
        projects.splice(idx, 1, replaceProj)
      }
      this.projectOptions.splice(0, this.projectOptions.length, ...projects)
    },

    async setProjectId(event) {
      const projectName = event.target.value.trim().toLowerCase(),
        match = / \[(\d+)\]$/.exec(projectName)
      let project
      if(match) {
        const projId = Number(match[1])
        project = this.projectOptions.find(p => p.id === projId)
      } else {
        project = this.projectOptions.find(p => p.name.toLowerCase() === projectName)
      }
      this.projectId = project?.id || 0
      if (!this.projectId) {
        setTimeout(() => event.target.value = '', this.$page.props.app_config.autocomplete_field_reset_delay)
      }
    },

    dateParamToDateRange(dateParam) {
      const today = new TZDateMini(Date.now(), this.$timeZone.value),
            oneMonthAgo = sub(today, { months: 1 }),
            oneYearAgo = sub(today, { years: 1 }),
            format = 'ISO'

      switch(dateParam) {
        case 'today':
          return [formatDate(today, { format }),
                  formatDate(today, { format })]
        case 'last_3_days':
          const twoDaysAgo = sub(today, { days: 2 })
          return [formatDate(twoDaysAgo, { format }),
                  formatDate(today, { format })]
        case 'this_week':
          return [formatDate(startOfWeek(today), { format }),
                  formatDate(today, { format })]
        case 'last_week':
          const oneWeekAgo = sub(today, { weeks: 1 })
          return[formatDate(startOfWeek(oneWeekAgo), { format }),
                 formatDate(endOfWeek(oneWeekAgo), { format })]
        case 'last_2_weeks':
          return [formatDate(sub(today, { weeks: 2, days: -1 }), { format }),
                  formatDate(today, { format })]
        case 'this_month':
          return [formatDate(startOfMonth(today), { format }),
                  formatDate(endOfMonth(today), { format })]
        case 'last_month':
          return [formatDate(startOfMonth(oneMonthAgo), { format }),
                  formatDate(endOfMonth(oneMonthAgo), { format })]
        case 'last_3_months':
          return [formatDate(sub(today, { months: 3, days: -1 }), { format }),
                  formatDate(today, { format })]
        case 'last_6_months':
          return [formatDate(sub(today, { months: 6, days: -1 }), { format }),
                  formatDate(today, { format })]
        case 'this_year':
          return [formatDate(startOfYear(today), { format }),
                  formatDate(today, { format })]
        case 'last_year':
          return [formatDate(startOfYear(oneYearAgo), { format }),
                  formatDate(endOfYear(oneYearAgo), { format })]
        default:
          return [this.$page.props.date_from, this.$page.props.date_to]
      }
    },

    async filterTimelogs() {
      const params = { date_from: this.dateFrom, date_to: this.dateTo }

      const qryStrParams = new URLSearchParams(location.search),
        userId = qryStrParams.get('user_id');
      if (userId) params.user_id = userId

      if (this.date) {
        params.date = this.date
        const [dateFrom, dateTo] = this.dateParamToDateRange(this.date)
        params.date_from = dateFrom
        params.date_to = dateTo
      }

      if (this.projectId) params.project_id = this.projectId
      if (this.clientId) params.client_id = this.clientId
      if (this.taskStatusId) params.task_status = this.taskStatusId
      if (this.taskCategoryId) params.task_category = this.taskCategoryId

      this.$emit('filter:timelogs', {
        filters: params,
        onBefore: visit => this.processing = true,
        onFinish: visit => this.processing = false,
      })
    },
  },

  emits: ['filter:timelogs'],
}
</script>

<template>
  <AnonLayout>
    <div class="module-content padding-large-y">
      <div class="flex-grid flex-grid-fixed flex-grid-compact">
        <div class="flex-col-xs-12">
          <div class="input-label font-weight-700 no-padding-y">Log in with:</div>
        </div>

        <div class="flex-col-xs-12">
          <a :href="$route('wrike.login')" class="btn btn-block btn-neutral-outline no-padding-x"
             :class="{ disabled: loginLinkClicked || form.processing }"
             @click="loginLinkClicked = true">

            <img class="margin-small-right" :src="wrikeIconUrl"> Wrike
          </a>
        </div>

        <div class="flex-col-xs-12">
          <a :href="$route('azure.login')" class="btn btn-block btn-neutral-outline no-padding-x"
             :class="{ disabled: loginLinkClicked || form.processing }"
             @click="loginLinkClicked = true">

            <img class="margin-small-right" :src="microsoftIconUrl"> Office 365
          </a>
        </div>
      </div>

      <div class="margin-large-top">
        <div class="alert alert-error margin-bottom border-color-error no-radius color-error-dark"
             v-if="form.errors.password">

          {{ form.errors.password.join(', ') }}
        </div>

        <form @submit.prevent="form.post($route('auth.sessions'))">
          <div class="input-wrapper input-wrapper-block input-wrapper-vertical">
            <label for="email" class="input-label font-weight-700">Email</label>
            <input type="email" id="email" class="input input-single-line" autocomplete="username"
                   v-model="form.email" ref="email" required>
          </div>

          <div class="input-wrapper input-wrapper-block input-wrapper-vertical">
            <label for="password" class="input-label font-weight-700">Password</label>
            <input type="password" id="password" class="input input-single-line"
                   autocomplete="current-password" v-model="form.password" required>
          </div>

          <div class="flex-grid flex-grid-compact flex-grid-no-gutter-y margin-top">
            <div class="flex-col-6">
              <label class="p">
                <input type="checkbox" v-model="form.remember_me">
                Remember me
              </label>
            </div>

            <div class="flex-col-6">
              <div class="p text-align-right">
                <a :href="$route('password_resets.new')" class="color-inherit" >Forgot password?</a>
              </div>
            </div>
          </div>

          <div class="margin-large-top">
            <button class="btn btn-theme btn-block" :disabled="form.processing || loginLinkClicked">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </AnonLayout>
</template>

<script>
import { Link, useForm } from '@inertiajs/vue3'

import AnonLayout from '../AnonLayout'
import wrikeIcon from '../img/icon-wrike.svg'
import microsoftIcon from '../img/icon-microsoft.svg'

export default {
  name: 'Login',

  setup(props) {
    const form = useForm({
      email: '',
      password: '',
      remember_me: true,
    })

    return {
      form,
    }
  },

  data() {
    return {
      loginLinkClicked: false,
    }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.email.focus()
  },*/

  computed: {
    wrikeIconUrl() {
      return wrikeIcon
    },

    microsoftIconUrl() {
      return microsoftIcon
    },
  },

  components: {
    Link,
    AnonLayout,
  },
}
</script>

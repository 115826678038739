<template>
  <div class="module margin-top">
    <div class="module-header">
      <div class="module-title">{{ relativeDate }}</div>
    </div>

    <div class="module-content no-padding">
      <draggable :modelValue="visibleTasks" item-key="id" handle=".draggable-task" ghostClass="disabled"
                 :move="onMove" @update:modelValue="updateTaskList" v-if="visibleTasks.length">

        <template #item="{element}">
          <TaskRow :task="element" :date="date" :taskStatusOptions="taskStatusOptions"
                   @open:taskStatusModal="$emit('open:taskStatusModal', $event)"
                   @task:changed="$emitter.emit('task:changed', $event)"
                   @task:deleted="$emitter.emit('task:deleted', $event)"
                   @timelog:changed="changeTimelog"
                   @timelog:deleted="deleteTimelog" />
        </template>
      </draggable>

      <div class="font-style-italic color-neutral padding  border-width-thin-top border-style-solid-top border-color-neutral-alpha-3"
           v-else>

          No activity on {{ formattedDate }}
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import * as _ from 'lodash-es'
import { sub } from 'date-fns'
import { TZDateMini } from '@date-fns/tz';

import TaskRow from '../components/TaskRow'

import { formatDate, parseISODate } from '../lib/date'

export default {
  name: 'DateTasks',

  props: {
    date: {
      type: String,
      required: true
    },
    tasks: {
      type: Array,
      required: true
    },
    taskStatusOptions: {
      type: Object,
      required: true
    },
  },

  data() {
    const onFavoritesPage = location.pathname.endsWith(this.$route('main.favorites')),
          visibleTasks = onFavoritesPage ? this.tasks.filter(t => t.is_favorite) : this.tasks

    return {
      visibleTasks,
      onFavoritesPage,
      validTaskMove: false,
    }
  },

  mounted() {
    this.$emitter.on('timelogs:filtered', this.onTimelogsFiltered)
    this.$emitter.on('timelog:added', this.onTimelogAdded)
    this.$emitter.on('task:changed', this.onTaskChanged)
    this.$emitter.on('task:reprioritized', this.onTaskReprioritized)
    this.$emitter.on('task:deleted', this.onTaskDeleted)
  },

  unmounted() {
    this.$emitter.off('timelogs:filtered', this.onTimelogsFiltered)
    this.$emitter.off('timelog:added', this.onTimelogAdded)
    this.$emitter.off('task:changed', this.onTaskChanged)
    this.$emitter.off('task:reprioritized', this.onTaskReprioritized)
    this.$emitter.off('task:deleted', this.onTaskDeleted)
  },

  computed: {
    now() {
      return new TZDateMini(Date.now(), this.$timeZone.value)
    },

    today() {
      return formatDate(this.now, { format: 'ISO' })
    },

    relativeDate() {
      switch(this.date) {
        case this.today:
          return 'Today'
        case formatDate(sub(this.now, { days: 1 }), { format: 'ISO' }):
          return 'Yesterday'
        default:
          return this.formattedDate
      }
    },

    formattedDate() {
      return formatDate(parseISODate(this.date))
    },
  },

  methods: {
    async changeTimelog({ taskId, timelogId, changes }) {
      const task = this.visibleTasks.find(t => t.id === taskId)
      if (!task) return

      const timelog = task.timelogs.find(tl => tl.id === timelogId)
      if (!timelog) return

      for (const [attr, val] of Object.entries(changes)) {
        if (typeof val === 'string' || typeof val === 'number') {
          timelog[attr] = val
        } else {
          Object.assign(timelog[attr], val) // timespan { lower, upper }
        }
      }

      if ('timespan' in changes) {
        task.timelogs.sort((tl1, tl2) => tl1.timespan.lower - tl2.timespan.lower)
        this.$emit('timelog:changed', { taskId, timelogId, changes, date: this.date })
      }
    },

    async deleteTimelog({ taskId, timelogId }) {
      const taskIdx = this.visibleTasks.findIndex(t => t.id === taskId)
      if (taskIdx === -1) return

      const task = this.visibleTasks[taskIdx]
      const tlIdx = task.timelogs.findIndex(tl => tl.id === timelogId)
      if (tlIdx === -1) return

      const [timelog] = task.timelogs.splice(tlIdx, 1)
      if (!task.timelogs.length) this.visibleTasks.splice(taskIdx, 1)

      this.$emit('timelog:deleted', { timelog, date: this.date })
    },

    sortVisibleTasks() {
      const [nullPriority, nonNullPriority] = _.partition(this.visibleTasks, task => task.priority === null)
      const sortedTasks = nonNullPriority.sort((t1, t2) => {
        const priorityCmp = t1.priority - t2.priority
        if (priorityCmp) return priorityCmp

        return t1.title.localeCompare(t2.title)
      })

      const [nullDueDate, nonNullDueDate] = _.partition(nullPriority, task => task.due_date === null)
      sortedTasks.push(
        ...nonNullDueDate.sort((t1, t2) => {
          const dueDateCmp = t1.due_date.localeCompare(t2.due_date)
          if (dueDateCmp) return dueDateCmp

          return t1.title.localeCompare(t2.title)
        })
      )

      sortedTasks.push(...nullDueDate.sort((t1, t2) => t1.due_date.localeCompare(t2.due_date)))

      this.visibleTasks.splice(0, this.visibleTasks.length, ...sortedTasks)
    },

    onTimelogsFiltered() {
      const tasks = this.onFavoritesPage ? this.tasks.filter(t => t.is_favorite) : this.tasks
      this.visibleTasks.splice(0, this.visibleTasks.length, ...tasks)
    },

    async onTimelogAdded({ timelog }) {
      const date = formatDate(new Date(timelog.timespan.lower * 1_000), {
        format: 'ISO',
        timeZone: this.$timeZone,
      })
      if (date !== this.date) return

      let { task } = timelog
      const tl = _.cloneDeep(timelog)
      delete tl.task
      const t = this.visibleTasks.find(t => t.id === task.id)

      if (t) {
        Object.assign(t, {
          status: task.status,
          category: task.category,
        })

        t.timelogs.push(tl)
      } else {
        task.timelogs = [tl]
        this.visibleTasks.push(task)
      }
    },

    onTaskChanged({ id, changes }) {
      const task = this.visibleTasks.find(t => t.id === id)
      if (!task) return

      Object.assign(task, changes)
    },

    async onTaskReprioritized({ id, date }) {
      if (date === this.date) return

      const task = this.visibleTasks.find(t => t.id === id)
      if (!task) return

      const params = new URLSearchParams()
      params.set('date', this.date)
      const resp = await axios.get(`${this.$route('tasks.index')}?${params}`)
      const { tasks: reprioritizedTasks } = resp.data
      for (const reprioritizedTask of reprioritizedTasks) {
        const task = this.visibleTasks.find(t => t.id === reprioritizedTask.id)
        if (!task) continue

        task.priority = reprioritizedTask.priority
      }

      this.sortVisibleTasks()
    },

    onTaskDeleted(id) {
      const index = this.visibleTasks.findIndex(t => t.id === id)
      if (index === -1) return

      this.visibleTasks.splice(index, 1)
    },

    async onMove(event, _originalEvent) {
      const taskId = event.draggedContext.element.id,
            task = this.visibleTasks.find(t => t.id === taskId)
      if (!task) return

      const priority = event.draggedContext.futureIndex,
            dragDir = priority < event.draggedContext.index ? 'asc' : 'desc'
      if (dragDir === 'asc') {
        this.validTaskMove = event.relatedContext.element.priority || !priority
      } else {
        this.validTaskMove = this.tasks.every(t => t.priority !== null)
        this.validTaskMove &&= priority === this.visibleTasks.length - 1 || priority
      }
      if (!this.validTaskMove) return false // cancel move

      await axios.patch(this.$route('tasks.update', { id: taskId }), {
        priority,
        drag_dir: dragDir,
      })
      task.priority = priority

      const resp = await axios.get(this.$route('tasks.index'), {
        params: {
          date: String(this.date),
        }
      })
      const { tasks: reprioritizedTasks } = resp.data
      for (const reprioritizedTask of reprioritizedTasks) {
        const task = this.visibleTasks.find(t => t.id === reprioritizedTask.id)
        if (!task) continue

        task.priority = reprioritizedTask.priority
      }

      this.$emitter.emit('task:reprioritized', { id: taskId, date: this.date })
    },

    updateTaskList(newValue) {
      if (!this.validTaskMove) return

      this.visibleTasks.splice(0, this.visibleTasks.length, ...newValue)
      this.validTaskMove = false
    },
  },

  components: {
    draggable,
    TaskRow,
  },
  emits: ['open:taskStatusModal', 'timelog:changed', 'timelog:deleted'],
}
</script>

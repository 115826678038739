<template>
  <Layout>
    <div class="h2 font-weight-300 no-margin-y">
      {{ task_status.id ? 'Edit' : 'Add' }} task status
    </div>

    <div class="flex-grid flex-grid-fixed">
      <div class="flex-col-xs-12 flex-col-sm-9 flex-col-md-7">
        <form @submit.prevent="submitForm">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="name" class="input-label font-weight-700">Name</label>
            <input id="name" class="input input-single-line"
                   :class="{ 'input-error': form.errors.name }"
                   minlength="2" maxlength="50"
                   ref="statusName" v-model.trim="form.name" required>

            <div class="input-info color-error font-weight-700" v-if="form.errors.name">
              {{ form.errors.name.join(', ') }}
            </div>
          </div>

          <hr class="margin-large-y">

          <div class="margin-large-top">
            <button class="btn btn-theme" :disabled="form.processing">Save</button>
            <Link :href="$route('admin.task_statuses.index')"
                  class="btn btn-neutral-glassy margin-left">

              Go Back
            </Link>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link, useForm } from '@inertiajs/vue3'

import Layout from '../Layout'

export default {
  name: 'TaskStatusForm',

  props: {
    task_status: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      name: props.task_status.name,
    })

    return { form }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.statusName.focus()
  },*/

  methods: {
    submitForm() {
      if (this.task_status.id) {
        this.form.patch(reverseUrl('admin.task_statuses.update', {
          id: this.task_status.id,
        }))
      } else {
        this.form.post(reverseUrl('admin.task_statuses.create'))
      }
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

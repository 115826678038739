<template>
  <template v-for="[userId, jobIds] in jobIdsByUserId" :key="userId">
    <div class="flex-0-0 position-relative flex-xs align-items-center padding-small flex-nowrap"
         v-for="jobId in jobIds" :key="`${userId}-${jobId}`">

      <div class="flex-0-0">
        <UserThumb v-bind="users[userId]" />
      </div>
      <div class="flex-1-1 padding-small-left hide-mobile hide-md">
        <p class="no-margin">{{ users[userId].first_name }} {{ users[userId].last_name }}</p>
        <p class="no-margin color-neutral">
          {{ jobTitleById[jobId] }}
        </p>
      </div>
    </div>
  </template>

  <div class="flex-0-0 position-relative flex-xs align-items-center padding-small flex-nowrap">
    <div  data-toggle-dropdown  class="flex-0-0">
      <div class="thumbnail thumbnail-small background-neutral-alpha-2 ">
        <span class="thumbnail-text color-neutral"><i class="symbol symbol-plus"></i></span>
      </div>
    </div>
    <div  data-toggle-dropdown  class="flex-1-1 padding-small-left">
      <p class="no-margin">Add team member</p>
    </div>

    <div class="dropdown dropdown-top-flush dropdown-left no-padding"
         data-dropdown-width="max(100%,300px)" data-dropdown-max-height="400px">

      <div class="padding-small position-sticky background-theme z-index-1 offset-0-x offset-0-top">
        <select class="font-size-normalize input input-select input-small input-block font-weight-600 margin-small-bottom"
                v-model.number="jobId" @change="userName = ''">

          <option :value="0">Select job</option>
          <option :value="id" v-for="[title, id] in jobOptions" :key="id">
            {{ title }}
          </option>
        </select>

        <div class="input-group input-block input-group-horizontal">
          <input placeholder="Toggle team members" v-model.trim="userName"
                 class="font-size-normalize input input-single-line input-small input-block font-weight-600 no-border-right">

          <label class="font-size-normalize btn btn-symbol btn-small btn-default">
            <span class="sr-only">Search</span>
            <i class="symbol symbol-search"></i>
          </label>
        </div>
      </div>

      <div class="list-group list-group-interactive list-group-toggle list-group-toggle-allow-no-active list-group-toggle-multiple">
        <label class="list-group-item pointer-reference"
               :class="{ active: userHasJob(id) }"
               @click.prevent="onUserToggled(id, $event)"
               v-show="!userNameRegex || userNameRegex.test(name)"
               v-for="[name, id] in userNameAndId" :key="id">

          <span class="flex-grid flex-nowrap flex-grid-no-gutter justify-content-space-between flex-wrap align-items-center">
            <span class="flex-child flex-1-1">
              <input type="radio" name="owner-id" :value="id" class="sr-only">
              <span class="text-wrap-ellipsis">{{ name }}</span>
            </span>
            <span class="flex-child flex-0-0">
              <i class="symbol symbol-check only-toggle flex-0-0"></i>
            </span>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'

import UserThumb from './UserThumb'
import { stringifyValidationErrors } from '../lib/string'

export default {
  name: 'ModelJobs',

  props: {
    modelId: {
      type: Number,
    },
    modelCls: {
      type: String,
      required: true,
    },
    jobTitleById: {
      type: Object,
      required: true,
    },
    jobIdsByDeptId: {
      type: Object,
      required: true,
    },
    jobIdByModelJobId: {
      type: Object,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
    user_ids_by_job_id: {
      type: Object,
      required: true,
    },
    userIdsByJobId: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      jobId: 0,
      userName: '',
      _userIdsByJobId: _.cloneDeep(this.userIdsByJobId),
    }
  },

  computed: {
    jobIdsByUserId() {
      const userIds = Object.values(this._userIdsByJobId).flatMap(userIds => [...userIds])

       const sortedUserIds = userIds.sort((uid1, uid2) => {
        const firstNameCmp = this.users[uid1].first_name.localeCompare(this.users[uid2].first_name)
        if (firstNameCmp) return firstNameCmp

        return this.users[uid1].last_name.localeCompare(this.users[uid2].last_name)
      })

      const res = {}
      sortedUserIds.forEach(userId => {
        for(const [jobId, userIds] of Object.entries(this._userIdsByJobId)) {
          if (!userIds.has(userId)) continue

          res[userId] ||= []
          res[userId].push(jobId)
        }
      })

      return Object.entries(res)
                   .map(([userId, jobIds]) => [Number(userId), jobIds])
                   .sort(([uid1], [uid2]) => sortedUserIds.indexOf(uid1) - sortedUserIds.indexOf(uid2))
    },

    createdOn() {
      if (!this.created) return ''

      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },

    jobOptions() {
      return Object.entries(this.jobTitleById).map(([id, title]) => [title, id]).sort()
    },

    userNameRegex() {
      if (!this.userName) return

      return new RegExp(this.userName, 'i')
    },

    userNameAndId() {
      let users = Object.entries(this.users)
      if (this.jobId) {
        const entry = Object.entries(this.jobIdsByDeptId).find(([_, jobIds]) => jobIds.includes(this.jobId))
        if (entry) {
          const userIds = new Set(entry[1].flatMap(jobId => [...(this.user_ids_by_job_id[jobId] || [])]))
          users = users.filter(([id, _]) => userIds.has(Number(id)))
        }
      }

      return users.map(([id, { first_name: first, last_name: last }]) => {
        return [`${first} ${last}`, Number(id)]
      }).sort()
    },
  },

  methods: {
    userHasJob(userId) {
      if (!this.jobId) return false
      if (!(this.jobId in this._userIdsByJobId)) return false

      return this._userIdsByJobId[this.jobId].has(userId)
    },

    async onUserToggled(userId, event) {
      if (!this.jobId) return

      this._userIdsByJobId[this.jobId] ||= new Set()

      if (this._userIdsByJobId[this.jobId].has(userId)) {
        if (this.modelId) {
          const entry = Object.entries(this.jobIdByModelJobId).find(([_, jobId]) => jobId === Number(this.jobId))
          if (!entry) return

          try {
            await axios.delete(reverseUrl('model_roles.destroy'), {
              data: {
                user_id: userId,
                model_job_id: entry[0],
              },
            })
          } catch(err) {
            const jobTitle = this.jobTitleById[this.jobId],
                  { first_name: first, last_name: last } = this.users[userId]

            let warn = `Unable to unassign job '${jobTitle}' to user '${first} ${last}'`
            warn += ` due to:\n${stringifyValidationErrors(err)}`
            alert(warn)
            return
          }

          this.$emit('userJob:removed', { userId, jobId: this.jobId })
        }

        this._userIdsByJobId[this.jobId].delete(userId)
      } else {
        if (this.modelId) {
          try {
            await axios.post(reverseUrl('model_roles.create'), {
              model_cls: this.modelCls,
              model_id: this.modelId,
              job_id: this.jobId,
              user_id: userId,
            })
          } catch(err) {
            const jobTitle = this.jobTitleById[this.jobId],
                  { first_name: first, last_name: last } = this.users[userId]

            let warn = `Unable to assign job '${jobTitle}' to user '${first} ${last}'`
            warn += ` due to:\n${stringifyValidationErrors(err)}`
            alert(warn)
            return
          }

          this.$emit('userJob:added', { userId, jobId: this.jobId })
        }

        this._userIdsByJobId[this.jobId].add(userId)
      }

      event.target.classList.toggle('active')
    },
  },

  emits: ['userJob:added', 'userJob:removed'],
  components: {
    UserThumb,
  },
}
</script>

import { formatDuration, formatISO, parseISO } from 'date-fns';
import { TZDateMini } from '@date-fns/tz';

export function parseISODate(isoDateStr) {
  return parseISO(isoDateStr);
}

export function parseUnixTimestamp(unixTime, timeZone = undefined) {
  const date = new Date(unixTime * 1_000);
  if (!timeZone) return date;

  return new TZDateMini(date.getTime(), timeZone.value);
}

export function formatSecondsAsDuration(seconds) {
  const hours = Math.floor(seconds / 3_600),
    hrsRem = seconds % 3_600;
  const minutes = Math.floor(hrsRem / 60);

  return formatDuration({ hours, minutes });
}

const DEFAULT_TIME_FORMAT = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
});

const EU_TIME_FORMAT = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
});

export function formatTime(date, { format = 'US', timeZone = undefined } = {}) {
  if (timeZone) date = new TZDateMini(date.getTime(), timeZone.value);

  switch (format) {
    case 'EU':
      return EU_TIME_FORMAT.format(date);
    default:
      return DEFAULT_TIME_FORMAT.format(date);
  }
}

const DEFAULT_DATE_FORMAT = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const LONG_DATE_FORMAT = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
});

const SHORT_DATE_FORMAT = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const TINY_DATE_FORMAT = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});

const MONTH_DAY_DATE_FORMAT = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
});

const SHORT_WEEKDAY_DATE_FORMAT = new Intl.DateTimeFormat('en-US', {
  weekday: 'short',
});

export function formatDate(date, { format = 'pretty', timeZone = undefined } = {}) {
  if (timeZone) date = new TZDateMini(date.getTime(), timeZone.value);

  switch (format) {
    case 'ISO':
      return formatISO(date, { representation: 'date' });
    case 'long':
      return LONG_DATE_FORMAT.format(date);
    case 'short':
      return SHORT_DATE_FORMAT.format(date);
    case 'tiny':
      return TINY_DATE_FORMAT.format(date);
    case 'month_day':
      return MONTH_DAY_DATE_FORMAT.format(date);
    case 'short_weekday':
      return SHORT_WEEKDAY_DATE_FORMAT.format(date);
    default:
      return DEFAULT_DATE_FORMAT.format(date);
  }
}

const SHORT_DATETIME_FORMAT = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

const CLOCK_DATETIME_FORMAT = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
});

export function formatDateTime(date, { format = 'short' } = {}) {
  switch (format) {
    case 'clock':
      return CLOCK_DATETIME_FORMAT.format(date);
    default:
      return SHORT_DATETIME_FORMAT.format(date);
  }
}

function hrsAndMinsToTimeStr(hours, minutes) {
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export function secondsToTimeStr(seconds) {
  const hrs = Math.floor(seconds / 3_600),
    mins = Math.floor((seconds % 3_600) / 60);

  return hrsAndMinsToTimeStr(hrs, mins);
}

export function timeStrToHrsAndMins(timeStr) {
  return timeStr.split(':', 2).map(Number);
}

export function utcOffset() {
  const tzOffset = new Date().getTimezoneOffset(),
    sign = tzOffset > 0 ? '-' : '',
    hours = Math.abs(tzOffset / 60),
    minutes = Math.abs(tzOffset % 60);

  return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0'
  )}:00`;
}

export function hhMMToSecs(hhMM) {
  const [hrs, mins] = timeStrToHrsAndMins(hhMM);
  return hrs * 3_600 + mins * 60;
}

<template>
  <div class="module flex-xs flex-direction-column align-items-stretch"
       data-grid-area="activity" data-align-self-lg="self-start">

    <h5 class="no-margin-y">Activity</h5>
    <div class="flex-1-1 flex-xs flex-direction-column justify-content-center">
      <h6 class="color-inherit">Most active days</h6>

      <div ref="mostActiveDaysChart" v-if="Object.keys(this.mostActiveDays).length"></div>
      <span class="font-style-italic color-neutral" v-else>Not enough data to render chart yet.</span>
    </div>

    <hr class="flex-0-0">

    <div class="flex-1-1 flex-xs flex-direction-column justify-content-center">
      <h6 class="color-inherit">Most active hours</h6>

      <div ref="mostActiveHoursChart" v-if="Object.keys(this.mostActiveHours).length"></div>
      <span class="font-style-italic color-neutral" v-else>Not enough data to render chart yet.</span>
    </div>
  </div>
</template>

<script>
import MostActiveDaysChart from '../lib/charts/insights/d3ActiveDays'
import MostActiveHoursChart from '../lib/charts/insights/d3ActiveHours'

export default {
  name: 'MyAnalyticsActivityCharts',

  props: {
    mostActiveDays: {
      type: Object,
      required: true,
    },
    mostActiveHours: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mostActiveDaysChart: null,
      mostActiveHoursChart: null,
    }
  },

  watch: {
    mostActiveDays(newData) {
      this.mostActiveDaysChart.render({
        data: newData,
      })
    },
    mostActiveHours(newData) {
      this.mostActiveHoursChart.render({
        data: newData,
      })
    },
  },

  mounted() {
    if (this.$refs.mostActiveDaysChart) {
      this.mostActiveDaysChart = new MostActiveDaysChart(this.$refs.mostActiveDaysChart)
      this.mostActiveDaysChart.render({
        data: this.mostActiveDays,
      })
    }

    if (this.$refs.mostActiveHoursChart) {
      this.mostActiveHoursChart = new MostActiveHoursChart(this.$refs.mostActiveHoursChart)
      this.mostActiveHoursChart.render({
        data: this.mostActiveHours,
      })
    }
  },
}
</script>

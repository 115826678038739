import 'vite/modulepreload-polyfill';
import { createApp, computed, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import mitt from 'mitt';
import * as Sentry from '@sentry/vue';

import { imageUploadHandler, tinymceInitOpts } from './lib/tinymce';

// import { Application } from '@hotwired/stimulus';
// import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

import './css/_style.scss';
import FrameWork from './framework.customglobal';

import Layout from './Layout';

const authPlugin = {
  install: (app, _options) => {
    app.config.globalProperties.$onlyWorksOnFixedTasks = computed(() => {
      const pageProps = app.config.globalProperties.$page.props;

      return !!pageProps.app_settings.groups_for_users_with_fixed_tasks.find(
        (group) => {
          return Object.entries(pageProps.current_user.groups).find(
            ([_, name]) => name === group
          );
        }
      );
    });

    app.config.globalProperties.$impersonatingUser = computed(() => {
      const pageProps = app.config.globalProperties.$page.props;

      return Boolean(
        pageProps.current_user.id !== pageProps.effective_user?.id &&
          pageProps.effective_user
      );
    });

    app.config.globalProperties.$impersonatingUserHasROAccess = computed(() => {
      const pageProps = app.config.globalProperties.$page.props;

      return (
        app.config.globalProperties.$impersonatingUser.value &&
        !pageProps.current_user.is_superuser
      );
    });

    app.config.globalProperties.$timeZone = computed(() => {
      const pageProps = app.config.globalProperties.$page.props;
      return pageProps.effective_user?.timezone || pageProps.current_user.timezone;
    });
  },
};

const clipboardPlugin = {
  install: (app, _options) => {
    app.config.globalProperties.$copyURLToClipboard = () => {
      navigator.clipboard.writeText(location.href);
    };
  },
};

const emitter = mitt();
const mittPlugin = {
  install: (app, _options) => {
    app.config.globalProperties.$emitter = emitter;
  },
};

const navigatorPlugin = {
  install: (app, _options) => {
    app.config.globalProperties.$isMobile = navigator.userAgent.includes('Mobile');
  },
};

// create a plugin to use window.reverseUrl in our Components
const routePlugin = {
  install: (app, _options) => {
    app.config.globalProperties.$route = window.reverseUrl;

    app.config.globalProperties.$linkTo = function (
      route,
      { viewArgs = [], qryStrParams = {}, returnBack = false } = {}
    ) {
      const params = new URLSearchParams(location.search);
      const qryStrP = new URLSearchParams(qryStrParams);
      let destination = this.$route(route, ...viewArgs);

      if (!['main.my_tasks', 'main.favorites'].includes(route)) {
        const keys = ['date_from', 'date_to'];
        let [dateFrom, dateTo] = keys.map((k) => qryStrP.get(k));
        if (!dateFrom || !dateTo) {
          [dateFrom, dateTo] = keys.map((k) => params.get(k));

          if (dateFrom && dateTo) {
            qryStrP.set('date_from', dateFrom);
            qryStrP.set('date_to', dateTo);
          }
        }
      }

      const userId = params.get('user_id');
      if (userId) qryStrP.set('user_id', userId);

      if (returnBack) {
        let retTo = location.pathname;
        if ([...params.keys()].length) retTo += `?${params}`;
        if (location.hash) retTo += location.hash;
        qryStrP.set('retTo', retTo);
      }

      if ([...qryStrP.keys()].length) destination += `?${qryStrP}`;

      return destination;
    };

    app.config.globalProperties.$returnBack = function () {
      const params = new URLSearchParams(location.search);
      const returnTo = params.get('retTo');
      if (returnTo && returnTo.startsWith('/')) return returnTo;
    };
  },
};

const tinyMCEPlugin = {
  install: (app, _options) => {
    app.config.globalProperties.$tinymceInitOpts = tinymceInitOpts();
  },
};

// window.Stimulus = Application.start();
// const context = require.context('./controllers', true, /\.js$/);
// Stimulus.load(definitionsFromContext(context));

createInertiaApp({
  // resolve: (name) => require(`./pages/${name}`),
  // resolve: (name) => {
  //   const page = require(`./pages/${name}`).default;
  //   page.layout ??= Layout;
  //   return page;
  // },
  resolve: (name) => {
    const pages = import.meta.glob('./pages/**/*.vue', { eager: true });
    return pages[`./pages/${name}.vue`];
  },
  // resolve: async (name) => {
  //   const page = await import(`./pages/${name}`);
  //   // page.default.layout ??= Layout;
  //   // page.default.layout = page.default.layout || Layout
  //   return page.default;
  // },

  setup({ el, App, props, plugin }) {
    const vueApp = createApp({ render: () => h(App, props) });

    const sentryDsn = props.initialPage.props.vue_sentry_dsn;
    if (sentryDsn) {
      Sentry.init({
        app: vueApp,
        dsn: sentryDsn,
        integrations: [Sentry.replayIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: props.initialPage.props.sentry_traces_sample_rate,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [/^https:\/\/time-tracker-v2\.1p21\.io/],
        // tracePropagationTargets: ['localhost', /^https:\/\/time-tracker-v2\.1p21\.io/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    vueApp.use(plugin);
    vueApp.use(authPlugin);
    vueApp.use(clipboardPlugin);
    vueApp.use(mittPlugin);
    vueApp.use(navigatorPlugin);
    vueApp.use(routePlugin);
    vueApp.use(tinyMCEPlugin);

    vueApp.mount(el);
  },
});

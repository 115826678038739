<template>
  <div id="timetracker-timer-sticky"
       class="position-sticky theme-inverse background-gradient-90-from-secondary-to-success z-index-1000">

    <div class="padding-x padding-small-y">
      <div class="flex-grid flex-grid-fixed flex-grid-compact flex-grid-no-gutter-y justify-content-space-between align-items-center">
        <div class="flex-col-7 flex-col-sm-8 flex-col-md-9">
          <p class="no-margin-y text-wrap-ellipsis">
            <span class="font-weight-600">{{ clientName }}</span>
            <span class="spacer">&nbsp;|&nbsp;</span>
            <span>{{ projectName }}</span>
            <span class="spacer">&nbsp;|&nbsp;</span>
            <span>{{ taskTitle }}</span>
          </p>
        </div>

        <div class="flex-col-5 flex-col-sm-4 flex-col-md-3 text-align-right">
          <div class="inline-flex align-items-center">
            <!-- @NOTE button
              classes to add

              'active' => if timer is active
              '' => if timer is NOT active
            -->
            <button class="btn btn-round btn-symbol btn-small btn-theme-outline"
                    :class="{ active: !!timerStartedAt }"
                    @click="toggleTimer">

              <i class="symbol symbol-pause"></i>
            </button>

            <div class="text-leading-whole inline-flex no-margin-y h3 font-weight-400">
              <span class="spacer">&nbsp;</span>
              <div>{{ hours }}</div>:<div>{{ minutes }}</div>:<div>{{ seconds }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'StickyTimer',

  data() {
    return {
      clientName: '',
      projectName: '',
      taskId: 0,
      taskTitle: '',
      timerStartedAt: null,
      intervalId: null,
      hours: '00',
      minutes: '00',
      seconds: '00',
    }
  },

  beforeMount() {
    const timer = localStorage.getItem('timer')
    if (!timer) return

    const { timerStartedAt, clientName, projectName, taskId, taskTitle } = JSON.parse(timer)
    this.timerStartedAt = Date.parse(timerStartedAt)
    this.clientName = clientName
    this.projectName = projectName
    this.taskId = taskId
    this.taskTitle = taskTitle

    this.startTimer()
  },

  mounted() {
    this.$emitter.on('start:timer', this.onStartTimer)
    this.$emitter.on('stop:timer', this.onStopTimer)
  },

  unmounted() {
    this.$emitter.off('stop:timer', this.onStopTimer)
    this.$emitter.off('start:timer', this.onStartTimer)

    if (this.intervalId) clearInterval(this.intervalId)
  },

  methods: {
    startTimer() {
      this.intervalId = setInterval(() => {
        const elapsedSecs = (new Date() - this.timerStartedAt) / 1_000
        this.hours = String(Math.floor(elapsedSecs / 3_600)).padStart(2, '0')
        this.minutes = String(Math.floor((elapsedSecs % 3_600) / 60)).padStart(2, '0')
        this.seconds = String(Math.floor((elapsedSecs % 3_600) % 60)).padStart(2, '0')
      }, 1_000)
    },

    stopTimer() {
      if (!this.intervalId) return

      clearInterval(this.intervalId)
      this.intervalId = null
      this.clientName = ''
      this.projectName = ''
      this.taskId = 0
      this.taskTitle = ''
    },

    toggleTimer() {
      if (this.timerStartedAt) {
        const taskId = Number(this.taskId)
        this.stopTimer()
        this.$emitter.emit('stop:timer', taskId)
      } else {
        this.timerStartedAt = new Date()
        this.startTimer()
        this.$emitter.emit('start:timer', this.taskId)
      }
    },

    async onStartTimer(taskId) {
      if (this.intervalId) return

      this.taskId = taskId
      if (!this.clientName || !this.projectName || !this.taskTitle) {
        const resp = await axios.get(this.$route('tasks.show', { id: taskId }))
        const { task } = resp.data
        this.taskTitle = task.title
        this.projectName = task.project.name
        this.clientName = task.project.client.name
      }

      this.hours = this.minutes = this.seconds = '00'
      this.timerStartedAt = new Date()
      this.startTimer()
      this.$emitter.emit('timer:started', taskId)
    },

    onStopTimer(taskId) {
      this.stopTimer()
      this.$emitter.emit('timer:stopped', taskId)
    },
  },
}
</script>

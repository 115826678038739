<template>
  <div id="m-rogue-timer" class="modal modal-default modal-expanded"
       @after_destroy_fw_modal="$emit('rougeTimerModal:closed')">

    <h3 class="color-error no-margin-top flex-xs align-items-center">
      <span class="h2 color-inherit display-inline no-margin-y margin-small-right">
        <i class="symbol symbol-error-inverse"></i>
      </span>
      You left a timer running!
    </h3>
    <p>Please fix your time entry below</p>

    <div class="flex-grid flex-grid-fixed flex-grid-no-gutter-y margin-micro-bottom">
      <div class="flex-col-xs-12 flex-col-sm-2">
        <strong class="input-label font-weight-700 no-padding">Client</strong>
      </div>
      <div class="flex-col-xs-12 flex-col-sm-10">
        <div class="text-wrap-ellipsis">{{ clientName }}</div>
      </div>
    </div>

    <div class="flex-grid flex-grid-fixed flex-grid-no-gutter-y margin-micro-bottom">
      <div class="flex-col-xs-12 flex-col-sm-2">
        <strong class="input-label font-weight-700 no-padding">Project</strong>
      </div>
      <div class="flex-col-xs-12 flex-col-sm-10">
        <div class="text-wrap-ellipsis">{{ projectName }}</div>
      </div>
    </div>

    <div class="flex-grid flex-grid-fixed flex-grid-no-gutter-y margin-micro-bottom">
      <div class="flex-col-xs-12 flex-col-sm-2">
        <strong class="input-label font-weight-700 no-padding">Task</strong>
      </div>
      <div class="flex-col-xs-12 flex-col-sm-10">
        <div class="text-wrap-ellipsis">{{ taskTitle }}</div>
      </div>
    </div>

    <div class="flex-grid flex-grid-fixed flex-grid-no-gutter-y margin-micro-bottom">
      <div class="flex-col-xs-12 flex-col-sm-2">
        <strong class="input-label font-weight-700 no-padding">Date</strong>
      </div>
      <div class="flex-col-xs-12 flex-col-sm-10">
        <div class="text-wrap-ellipsis">{{ date }}</div>
      </div>
    </div>

    <form @submit.prevent="submitForm">
      <div class="flex-grid flex-grid-fixed flex-grid-no-gutter-y margin-micro-bottom">
        <div class="flex-col-xs-12">
          <div class="input-wrapper input-wrapper-block input-wrapper-vertical">
            <label for="rogue-timer-comment" class="input-label font-weight-700">Comment</label>
            <textarea id="rogue-timer-comment" class="input input-multiple-line input-block"
                      v-model="_comment"></textarea>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-4">
          <div class="input-wrapper input-wrapper-block input-wrapper-vertical">
            <label for="rogue-timer-start-time" class="input-label font-weight-700">Start time</label>
            <input type="time" id="rogue-timer-start-time" class="input input-single-line input-block"
                   :value="startTime" disabled>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-4">
          <div class="input-wrapper input-wrapper-block input-wrapper-vertical input-error">
            <label for="rogue-timer-end-time" class="input-label font-weight-700">End time *</label>
            <input type="time" id="rogue-timer-end-time" class="input input-single-line input-block"
                   :max="maxEndTime" v-model="endTime" ref="endTime" required>
            <span class="input-info color-error">Enter the correct end time</span>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-4">
          <div class="input-wrapper input-wrapper-block input-wrapper-vertical input-error">
            <span class="input-label font-weight-700 spacer">&nbsp;</span>
            <button class="btn btn-theme">Update time entry</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

import Modal from '../imports/modal'
import { formatDate, formatTime } from '../lib/date'
import { stringifyValidationErrors } from '../lib/string'

export default {
  name: 'RougeTimerModal',

  props: {
    clientName: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
    taskTitle: {
      type: String,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
    timerStartedAt: {
      type: Date,
    },
    timelogId: {
      type: Number,
      required: true,
    },
    modalOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      _comment: this.comment,
      endTime: '',
    }
  },

  watch: {
    modalOpen(modalIsOpen) {
      if (!modalIsOpen) return

      new Modal(this.$el, null, {
        changeHash: false,
        width: '640px',
      }).create()

      this.$refs.endTime.focus()
    },
  },

  computed: {
    date() {
      return this.timerStartedAt ? formatDate(this.timerStartedAt) : ''
    },
    startTime() {
      return this.timerStartedAt ? formatTime(this.timerStartedAt, { format: 'EU' }) : '00:00'
    },
    maxEndTime() {
      if (!this.timerStartedAt) return ''

      const endTime = new Date(this.timerStartedAt.getTime() + this.$page.props.app_settings.max_num_hours_worked_per_timelog * 3_600 * 1_000)
      return formatTime(endTime, { format: 'EU' })
    },
  },

  methods: {
    async submitForm() {
      try {
        const resp = await axios.patch(reverseUrl('timelogs.update', this.timelogId), {
          comment: this._comment,
          end_time: this.endTime,
        })

        new Modal(this.$el).destroy()
        const { timelog } = resp.data
        this.$emit('timelog:added', timelog)
      } catch(err) {
        alert(`Unable to set end time for time entry due to:\n${stringifyValidationErrors(err)}`)
      }
    },
  },

  emits: ['timelog:added', 'rougeTimerModal:closed'],
}
</script>

<template>
  <AnonLayout>
    <div class="module-content no-margin-y padding-large-y">
      <div class="text-align-center padding-large-y">
        <img :src="loginLockIconUrl">
        <h1 class="margin-small-top">{{ confirmation }}</h1>
        <p>{{ warning }}</p>
        <Link :href="$route('auth.login')" class="btn btn-theme margin-top">Close</Link>
      </div>
    </div>

  </AnonLayout>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import AnonLayout from '../AnonLayout'
import loginLockIcon from '../img/login-lock.svg'

export default {
  name: 'PasswordResetInstructionsSent',

  props: {
    confirmation: {
      type: String,
      required: true
    },
    warning: {
      type: String,
      required: true
    },
  },

  computed: {
    loginLockIconUrl() {
      return loginLockIcon
    },
  },

  components: {
    AnonLayout,
    Link,
  },
}
</script>

<template>
  <div class="inline-flex-md position-relative">
    <div class="btn-group btn-group-horizontal btn-block-mobile">
      <a href="#" @click.prevent="shiftTimelineBackward" class="btn btn-default btn-symbol">
        <i class="symbol symbol-arrow-left"></i>
      </a>

      <a href="#date-range" class="btn btn-default" data-toggle-dropdown>
        {{ dateFromStr }} - {{ dateToStr }}
      </a>

      <a href="#" @click.prevent="shiftTimelineForward" class="btn btn-default btn-symbol"
         :class="{ disabled: _dateTo.getTime() === today.getTime() }">

        <i class="symbol symbol-arrow-right"></i>
      </a>
    </div>

    <div id="date-range" class="dropdown dropdown-top-flush dropdown-right"
         data-dropdown-width="max(800px,100%)">

      <div class="flex-grid">
        <div class="flex-col-xs-12 flex-col-sm-6">
          <h5 class="no-margin-top">Date range</h5>
          <ul class="list-group list-group-interactive">
            <li v-for="[key, value] in fixedDateRangeOptions" :key="key">
              <a href="#" @click.prevent="changeDateRangeToFixed(key)" class="dropdown-purger">
                {{ value }}
              </a>
            </li>
          </ul>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6">
          <h5 class="no-margin-top">Custom</h5>

          <form @submit.prevent="changeDateRangeToCustom">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block margin-small-bottom">
              <label for="start_date" class="input-label">Start date</label>
              <input type="date" id="start_date" class="input input-single-line input-block"
                     :max="todayStr" v-model="startDate" required>
            </div>

            <div class="input-wrapper input-wrapper-vertical input-wrapper-block margin-small-bottom">
              <label for="end_date" class="input-label">End date</label>
              <input type="date" id="end_date" class="input input-single-line input-block"
                     :max="todayStr" v-model="endDate" required>
            </div>

            <button class="btn btn-theme">Apply</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  add,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfWeek,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns'
import { TZDateMini } from '@date-fns/tz';
import * as _ from 'lodash-es'

import { formatDate, parseISODate } from '../lib/date'

export default {
  name: 'DateRangePicker',

  props: {
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      startDate: this.dateFrom,
      endDate: this.dateTo,
    }
  },

  computed: {
    _dateFrom() {
      return parseISODate(this.dateFrom)
    },
    dateFromStr() {
      return formatDate(this._dateFrom, { format: 'month_day' })
    },
    _dateTo() {
      return parseISODate(this.dateTo)
    },
    dateToStr() {
      return formatDate(this._dateTo, { format: 'month_day' })
    },
    dayDiff() {
      return differenceInDays(this._dateTo, this._dateFrom) + 1
    },
    today() {
      return new Date(new TZDateMini(Date.now(), this.$timeZone.value).setHours(0, 0, 0, 0))
    },
    todayStr() {
      return formatDate(this.today, { format: 'ISO' })
    },
    fixedDateRangeOptions() {
      return [
        ['this_week', 'This week'],
        ['last_week', 'Last week'],
        ['last_2_weeks', 'Last 2 weeks'],
        ['this_month', 'This month'],
        ['last_month', 'Last month'],
        ['last_3_months', 'Last 3 months'],
        ['last_6_months', 'Last 6 months'],
        ['this_year', 'This year'],
        ['last_year', 'Last year'],
      ]
    },
  },

  methods: {
    reload(params) {
      this.$inertia.get(location.pathname, params)
    },
    changeDateRangeToFixed(option) {
      let dateFrom, dateTo = this.today

      switch(option) {
        case 'this_week':
          dateFrom = startOfWeek(this.today)
          break
        case 'last_week':
          dateFrom = startOfWeek(sub(this.today, { weeks: 1 }))
          dateTo = endOfWeek(dateFrom)
          break
        case 'last_2_weeks':
          dateFrom = sub(this.today, { weeks: 2, days: -1 })
          break
        case 'this_month':
          dateFrom = startOfMonth(this.today)
          break
        case 'last_month':
          dateFrom = startOfMonth(sub(this.today, { months: 1 }))
          dateTo = endOfMonth(dateFrom)
          break
        case 'last_3_months':
          dateFrom = sub(this.today, { months: 3, days: -1 })
          break
        case 'last_6_months':
          dateFrom = sub(this.today, { months: 6, days: -1 })
          break
        case 'this_year':
          dateFrom = startOfYear(this.today)
          break
        case 'last_year':
          dateFrom = startOfYear(sub(this.today, { years: 1 }))
          dateTo = endOfYear(dateFrom)
          break
      }

      const params = new URLSearchParams(location.search)
      dateFrom = formatDate(dateFrom, { format: 'ISO' })
      params.set('date_from', dateFrom)
      dateTo = formatDate(dateTo, { format: 'ISO' })
      params.set('date_to', dateTo)
      this.reload(params)

      this.startDate = dateFrom
      this.endDate = dateTo
    },
    shiftTimelineBackward() {
      const params = new URLSearchParams(location.search)
      params.set('date_from', formatDate(sub(this._dateFrom, { days: this.dayDiff }), { format: 'ISO' }))
      params.set('date_to', formatDate(sub(this._dateTo, { days: this.dayDiff }), { format: 'ISO' }))

      this.reload(params)
    },
    shiftTimelineForward() {
      if (this._dateTo.getTime() === this.today.getTime()) return

      const params = new URLSearchParams(location.search)
      params.set('date_from', formatDate(add(this._dateFrom, { days: this.dayDiff }), { format: 'ISO' }))
      let dateTo = add(this._dateTo, { days: this.dayDiff })
      dateTo = _.minBy([dateTo, this.today], date => date.getTime())
      params.set('date_to', formatDate(dateTo, { format: 'ISO' }))

      this.reload(params)
    },
    changeDateRangeToCustom() {
      const params = new URLSearchParams(location.search)
      params.set('date_from', this.startDate)
      params.set('date_to', this.endDate)

      this.reload(params)
    },
  },
}
</script>

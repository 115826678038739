<template>
  <div class="modal modal-default modal-constricted" @after_destroy_fw_modal="resetTask">
    <div class="padding-small-x">
      <label class="input-wrapper input-wrapper-vertical input-wrapper-block">
        <span class="input-label font-weight-700">Select task status group</span>

        <select class="input-select" @change="swapTaskStatuses">
          <option :value="statusGroupName" :selected="statusGroupName === taskStatusGroupName"
                  v-for="statusGroupName in Object.keys(options)" :key="statusGroupName">

            {{ statusGroupName }}
          </option>
        </select>
      </label>

      <h4>Select task status</h4>
    </div>

    <div class="list-group list-group-interactive list-group-compact">
      <a href="#" @click.prevent="changeStatus(id)"
         class="list-group-item background-transparent background-primary-alpha-2-hover background-primary-alpha-2-toggle background-primary-alpha-2-hover pointer-reference no-border-y flex-xs justify-content-space-between flex-wrap align-items-center dropdown-purger"
         v-for="[name, id] in taskStatusNamesAndIds" :key="id">

        <span class="flex-1-1">{{ name }}</span>
        <i class="symbol symbol-check only-toggle flex-0-0"></i>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Modal from '../imports/modal'

export default {
  name: 'TaskStatusModal',

  props: {
    id: {
      type: Number,
      required: true
    },
    statusId: {
      type: Number,
    },
    options: {
      type: Object,
      required: true
    },
    modalOpen: {
      type: Boolean,
      required: true
    },
  },

  data() {
    let taskStatusGroupName = this.$page.props.current_user.job?.task_status_group?.name,
      taskStatusNamesAndIds
    if (taskStatusGroupName && taskStatusGroupName in this.options) {
      taskStatusNamesAndIds = Object.entries(this.options[taskStatusGroupName])
    } else {
      const entries = Object.entries(this.options)
      taskStatusGroupName = entries.length ? entries[0][0] : ''
      taskStatusNamesAndIds = entries.length ? Object.entries(entries[0][1]) : []
    }

    return {
      taskStatusGroupName,
      taskStatusNamesAndIds,
    }
  },

  watch: {
    modalOpen(modalIsOpen) {
      if (!modalIsOpen) return

      new Modal(this.$el, null, {
        changeHash: false,
        disableOverlay: false,
        width: '480px',
      }).create()
    },
  },

  methods: {
    swapTaskStatuses(event) {
      this.taskStatusNamesAndIds.splice(
        0,
        this.taskStatusNamesAndIds.length,
        ...Object.entries(this.options[event.target.value]),
      )
    },

    async changeStatus(newStatus) {
      const resp = await axios.patch(this.$route('tasks.update', { id: this.id }), {
        status_id: newStatus,
      })
      const { task: { status } } = resp.data

      new Modal(this.$el).destroy()
      this.$emitter.emit('task:changed', { id: this.id, changes: { status } })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="flex-grid">
      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="h2 font-weight-300 no-margin-y">
          Team members in the `{{ department.name }}` department
        </div>
      </div>

      <div class="flex-child flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="member-name" class="sr-only">Member name</label>
              <input id="member-name" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="memberName"
                     placeholder="Member name" @input="debouncedFilterMembers">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.users.new')" class="btn btn-theme btn-block-mobile">
              Add new +
            </Link>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.departments.index')"
                  class="btn btn-neutral-glassy btn-block-mobile">

              Go back
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div class="module">
      <div class="module-content no-padding">
        <div class="table-wrapper" v-if="members.length">
          <table class="timetracker-table">
            <thead>
              <tr class="background-transparent">
                <th class="background-transparent no-border-top">Name</th>
                <th class="background-transparent no-border-top">Job title</th>
                <th class="background-transparent no-border-top">Reports to</th>
                <th class="background-transparent no-border-top">Groups</th>
                <th class="background-transparent no-border-top">Viewers</th>
                <th class="background-transparent no-border-top">Time zone</th>
                <th class="background-transparent no-border-top text-align-center">Status</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="member in members" :key="member.id">
                <td>
                  <Link :href="$linkTo('admin.users.edit', { viewArgs: [member.id], returnBack: true })">
                    {{ member.first_name }} {{ member.last_name }}
                  </Link>
                </td>

                <td>
                  <Link :href="$linkTo('admin.jobs.edit', { viewArgs: [member.job.id], returnBack: true })">
                    {{ member.job.title }}
                  </Link>
                </td>

                <td>
                  <Link :href="$linkTo('admin.jobs.edit', { viewArgs: [member.job.report.id], returnBack: true })"
                        v-if="member.job.report">

                    {{ member.job.report.title }}
                  </Link>
                  <span v-else>&nbsp;</span>
                </td>

                <td>
                  <span v-if="member.groups.length">
                    {{ member.groups.join(',') }}
                  </span>
                  <span v-else>&nbsp;</span>
                </td>

                <td>
                  <template v-for="(viewerName, viewerId, idx) in member.viewers" :key="viewerId">
                    <Link :href="$linkTo('admin.users.edit', { viewArgs: [viewerId], returnBack: true })">
                      {{ viewerName }}
                    </Link>
                    <span v-if="idx < Object.keys(member.viewers).length - 1">,&nbsp;</span>
                  </template>
                </td>

                <td>{{ member.timezone }}</td>

                <td class="text-align-center">{{ member.is_active ? 'Active' : 'Inactive' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="font-style-italic color-neutral padding" v-else>
          There are no members yet.
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import * as _ from 'lodash-es'

import Layout from '../Layout'

export default {
  name: 'DepartmentMembers',

  props: {
    department: {
      type: Object,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
    members: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.debouncedFilterMembers = _.debounce(this.filterMembers,
                                             this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.memberName.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterMembers.cancel()
  },

  methods: {
    filterMembers(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      this.$inertia.reload({ data: Object.fromEntries(params.entries()) })
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

<template>
  <Layout>
    <div id="timetracker-heading" class="flex-grid justify-content-space-between align-items-center">
      <div class="flex-col-xs-12  flex-col-md-6">
        <h1 class="no-margin">Tasks</h1>
      </div>

      <div class="flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="task-title" class="sr-only">Task title</label>
              <input id="task-title" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="taskTitle"
                     placeholder="Task title" @input="debouncedFilterTasks">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.tasks.new')" class="btn btn-block-mobile btn-theme">
              Add new +
            </Link>
          </div>
        </div>
      </div>

      <div class="flex-col-xs-12">
        <TaskFilters :taskStatusOptions="task_statuses"
                     :authorOptions="author_options"
                     :responsibleOptions="responsible_options" />
      </div>
    </div>

    <div id="timetracker-tasks" class="module">
      <div class="module-header no-padding-x">
        <div class="module-functions no-margin-top margin-small-bottom no-padding-top padding-small-bottom padding-x flex-md flex-wrap justify-content-flex-end align-items-center  border-width-thin-bottom border-style-solid-bottom border-color-neutral-alpha-2">

          <div class="module-function margin-small-right">
            <label class="input-toggle font-weight-600">
              <input type="checkbox" v-model="overdue" @change="onOverdueToggled">
              <span class="input-toggle-label">Show overdue tasks</span>
            </label>
          </div>

          <div class="module-function">
            <label class="input-toggle font-weight-600">
              <input type="checkbox" v-model="complete" @change="onCompleteToggled">
              <span class="input-toggle-label">Show complete tasks</span>
            </label>
          </div>
        </div>

        <div class="module-functions no-margin no-padding-y padding-x flex-xs flex-wrap justify-content-space-between align-items-center">
          <Pagination :recordCount="record_count" :page="page" :pageCount="page_count"
                      :perPage="per_page" />
        </div>
        <hr class="hide-nonmobile no-margin-bottom">
      </div>

      <div class="module-content no-padding-x">
        <div class="table-wrapper">
         <table class="timetracker-table border-style-solid-bottom border-width-thin-bottom border-color-neutral-alpha-3 text-vertical-align-middle">

          <tr class="hide-mobile">
            <th class="font-weight-700 color-theme text-align-center color-theme timetracker-td timetracker-td-w timetracker-td-w-50">
              <label class="input-label no-padding">
                <input type="checkbox" class="input-inline" v-model="allTasksSelected">
                <span class="hide-nonmobile">Select all tasks</span>
              </label>
            </th>

            <th class="font-weight-700 color-theme">
              Task and Project
              <a href="#" @click.prevent="sortTasksBy('title')"
                 class="color-inherit text-decoration-none text-vertical-align-middle display-inline-flex"
                 v-html="dirIconFor('title')">
              </a>
            </th>

            <th class="font-weight-700 color-theme text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
              Author
            </th>

            <th class="font-weight-700 color-theme text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
              Assignee
            </th>

            <th class="font-weight-700 color-theme">
              Created
              <a href="#" @click.prevent="sortTasksBy('created')"
                 class="color-inherit text-decoration-none text-vertical-align-middle display-inline-flex"
                 v-html="dirIconFor('created')">
              </a>
            </th>

            <th class="font-weight-700 color-theme">
              Due
              <a href="#" @click.prevent="sortTasksBy('due_date')"
                 class="color-inherit text-decoration-none text-vertical-align-middle display-inline-flex"
                 v-html="dirIconFor('due_date')">
              </a>
            </th>

            <th class="font-weight-700 color-theme text-align-center">Status</th>
            <th class="font-weight-700 color-theme text-align-center">Subtasks</th>

            <th class="color-theme timetracker-td-actions text-align-center" width="50">
              <div class="bulk-actions position-relative">
                <button class="btn no-padding font-size-large" data-modal-disable-overlay="false"
                        data-toggle-modal-default>

                  <i class="symbol symbol-kebab-horizontal"></i>
                  <span class="sr-only">More options</span>
                </button>

                <div class="modal modal-default" data-modal-width="400px">
                  <div class="text-align-center">
                    <h1 class="no-margin-top">More options</h1>
                    <ul class='list-group list-group-small no-margin-y'>
                      <li>
                        <a href="#" @click.prevent="confirmDeleteSelected">Delete selected</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </th>
          </tr>

          <template v-for="task in tasks" :key="task.id">
            <TaskRow v-bind="task" :allTasksSelected="allTasksSelected"
                     :authorById="authorById" :responsibleById="responsibleById"
                     :taskStatusOptions="task_statuses"
                     @task:selected="onTaskSelectionChanged"
                     @task:changed="onTaskChanged"
                     @task:deleted="onTaskDeleted" />

            <tbody class="accordion" :id="`task-${task.id}-children`"
                   data-accordion-change-hash="false">

              <ChildTask v-bind="child" :parentSelected="isTaskSelected(task.id)"
                         :authorById="authorById" :responsibleById="responsibleById"
                         :taskStatusOptions="task_statuses"
                         @task:selected="onTaskSelectionChanged"
                         @task:changed="onTaskChanged"
                         @task:deleted="onTaskDeleted"
                         v-for="child in task.children" :key="child.id" />
            </tbody>
          </template>
         </table>
       </div>
      </div>

      <div class="module-footer">
        <hr class="hide-nonmobile">
        <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
          <Pagination :recordCount="record_count" :page="page" :pageCount="page_count"
                      :perPage="per_page" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'

import Layout from '../Layout'
import Pagination from './Pagination'
import TaskFilters from '../components/admin/TaskFilters'
import TaskRow from '../components/admin/TaskRow'
import ChildTask from '../components/admin/ChildTask'
import ascDirIcon from '../img/icon-dir-asc.svg?raw'
import descDirIcon from '../img/icon-dir-desc.svg?raw'
import indeterDirIcon from '../img/icon-dir-indeter.svg?raw'

import { stringifyValidationErrors } from '../lib/string'

export default {
  name: 'TaskList',

  props: {
    tasks: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    date_from: {
      type: String,
    },
    date_to: {
      type: String,
    },
    project: {
      type: Number,
      required: true,
    },
    client: {
      type: Number,
      required: true,
    },
    sort: {
      type: String,
      default: 'due_date',
    },
    dir: {
      type: String,
      default: ({ sort }) => sort === 'created' ? 'desc' : 'asc',
    },
    record_count: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    page_count: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      required: true,
    },
    // authors: {
    //   type: Array,
    //   required: true,
    // },
    author_options: {
      type: Object,
      required: true,
    },
    task_authors: {
      type: Array,
      required: true,
    },
    // responsibles: {
    //   type: Array,
    //   required: true,
    // },
    responsible_options: {
      type: Object,
      required: true,
    },
    task_responsibles: {
      type: Array,
      required: true,
    },
    task_statuses: {
      type: Object,
      required: true
    },
  },

  data() {
    const params = new URLSearchParams(location.search)

    return {
      selectedTasks: new Set([]),
      allTasksSelected: false,
      overdue: Number(params.get('overdue') || 0) === 1,
      complete: Number(params.get('complete') || 0) === 1,
    }
  },

  created() {
    this.debouncedFilterTasks = _.debounce(this.filterTasks,
                                           this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.taskTitle.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterTasks.cancel()
  },

  computed: {
    ascDirSvgIcon() {
      return ascDirIcon
    },
    descDirSvgIcon() {
      return descDirIcon
    },
    indeterDirSvgIcon() {
      return indeterDirIcon
    },
    authorById() {
      return Object.fromEntries(this.task_authors.map(user => [user.id, user]))
    },
    responsibleById() {
      return Object.fromEntries(this.task_responsibles.map(user => [user.id, user]))
    },
  },

  methods: {
    dirIconFor(column) {
      if (this.sort !== column) return this.indeterDirSvgIcon

      return this.dir === 'desc' ? this.descDirSvgIcon : this.ascDirSvgIcon
    },

    filterTasks(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      params.delete('page')

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['tasks', 'task_authors', 'task_responsibles', 'q', 'page', 'page_count'],
      })
    },

    onBooleanFilterToggled(filter) {
      const params = new URLSearchParams(location.search)
      params.delete(filter)
      if (this[filter]) params.set(filter, 1)

      this.$inertia.get(location.pathname, params, {
        only: ['tasks', 'task_authors', 'task_responsibles', 'page', 'page_count'],
      })
    },

    onOverdueToggled() {
      this.onBooleanFilterToggled('overdue')
    },

    onCompleteToggled() {
      this.onBooleanFilterToggled('complete')
    },

    onTaskSelectionChanged({ id, checked }) {
      if (checked) {
        this.selectedTasks.add(id)
      } else {
        this.selectedTasks.delete(id)
      }
    },

    onTaskChanged({ id, status }) {
      const task = this.tasks.find(task => task.id === id)
      if (!task) return

      Object.assign(task.status, status)
      const params = new URLSearchParams(location.search), statusQryStrParam = params.get('status')
      if (statusQryStrParam === null) return

      if (task.status?.id !== Number(statusQryStrParam)) return

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['tasks', 'task_authors', 'task_responsibles', 'page', 'page_count'],
      })
    },

    isTaskSelected(id) {
      return computed(() => this.selectedTasks.has(id))
    },

    async confirmDeleteSelected() {
      if (!this.selectedTasks.size) {
        alert('Please select at least 1 task to proceed')
        return
      }

      let warning = 'Are you sure you want to delete'
      let taskCount = 'task'
      if (this.selectedTasks.size !== 1) taskCount += 's'
      warning += ` ${this.selectedTasks.size} ${taskCount}?`
      if (!confirm(warning)) return

      const data = { ids: [...this.selectedTasks] }

      try {
        await axios.delete(reverseUrl('tasks.destroy_many'), {
          data,
        })
      } catch(err) {
        alert(`Unable to delete task(s) due to:\n${stringifyValidationErrors(err)}`)
        return
      }

      this.$inertia.reload({ only: ['tasks'] })
    },

    onTaskDeleted({ id, parentId = 0 } = {}) {
      const taskId = parentId || id,
            index = this.tasks.findIndex(t => t.id === taskId)
      if (index === -1) return

      if (parentId) {
        const children = this.tasks[index].children
        const idx = children.findIndex(t => t.id === id)
        if (idx === -1) return

        children.splice(idx, 1)
      } else {
        this.tasks.splice(index, 1)
      }
    },

    sortTasksBy(column) {
      const params = new URLSearchParams(location.search)
      let dir
      if (column === this.sort) {
        dir = this.dir === 'desc' ? 'asc' : 'desc'
      } else {
        params.set('sort', column)
        dir = column === 'created' ? 'desc' : 'asc'
      }
      params.set('dir', dir)

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['tasks', 'task_authors', 'task_responsibles', 'sort', 'dir'],
      })
    },
  },

  components: {
    Layout,
    Link,
    Pagination,
    TaskFilters,
    TaskRow,
    ChildTask,
  },
}
</script>

<template>
  <div class="timetracker-alert-container alert-container no-padding-x offset-nav-sticky padding-top position-sticky"
       v-if="$page.props.flash_success.length || $page.props.flash_error.length">

    <FlashMessage paletteTag="success" symbolTag="success"
                  :message="$page.props.flash_success.join('. <br>')" />

    <FlashMessage paletteTag="caution" symbolTag="caution"
                  :message="$page.props.flash_error.join('. <br>')" />
  </div>
</template>

<script>
import FlashMessage from './FlashMessage'

export default {
  name: 'FlashMessages',

  components: {
    FlashMessage,
  },
}
</script>

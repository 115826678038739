<template>
  <form @submit.prevent="submitForm" class="flex-grid flex-grid-compact align-items-flex-end">
    <div class="flex-col-xs-12 flex-col-md-6 flex-3-0">
      <div class="flex-grid flex-grid-fixed flex-grid-compact no-margin-bottom align-items-flex-end ">
        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="client_id" class="input-label no-padding-top padding-small-bottom">Client</label>

              <input id="client_id" class="input input-single-line input-block"
                     placeholder="Select client" autocomplete="off" list="client-options"
                     ref="clientName"
                     @input="debouncedAutocompleteClientName" @change="setClientId">

              <datalist id="client-options">
                <option :value="client.name" v-for="client in clientOptions" :key="client.id" />
              </datalist>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="status_id" class="input-label no-padding-top padding-small-bottom">
                Project status
              </label>

              <select id="status_id" class="input input-single-line input-block" v-model.number="statusId">
                <option value="0">Select status</option>
                <option :value="id" v-for="[id, name] in projectStatuses" :key="id">{{ name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="_type_id" class="input-label no-padding-top padding-small-bottom">
                Project type
              </label>

              <select id="_type_id" class="input input-single-line input-block" v-model.number="typeId">
                <option value="0">Select type</option>
                <option :value="id" v-for="[id, name] in projectTypes" :key="id">{{ name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="time_range" class="input-label no-padding-top padding-small-bottom">
                Time range
              </label>

              <select id="time_range" class="input input-single-line input-block" v-model="timeRange">
                <option value="">Select time range</option>
                <option :value="value" v-for="(label, value) in timeRangeOptions" :key="value">
                  {{ label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-child flex-1-3">
      <div class="flex-md align-items-flex-end">
        <div class="flex-0-1">
          <label class="input-label no-padding-top no-padding-right padding-small-bottom display-block">
            Show Overdue <span class="hide-md">Projects</span>
          </label>
          <div class="btn-group btn-group-horizontal btn-group-interactive btn-group-toggle btn-block">
            <button @click.prevent="overdue = !overdue"
               class="btn no-padding-x border-color-neutral-alpha-3 background-transparent color-neutral font-weight-inherit background-theme-active color-theme-active"
               :class="{ active: overdue }">

              On
            </button>
            <button href="#" @click.prevent="overdue = !overdue"
               class="btn no-padding-x border-color-neutral-alpha-3 background-transparent color-neutral font-weight-inherit background-theme-active color-theme-active"
               :class="{ active: !overdue }">

              Off
            </button>
          </div>
        </div>

        <div class="hide-mobile margin-small-right"><span class="spacer"></span></div>

        <div class="flex-0-1">
          <label class="input-label no-padding-top no-padding-right padding-small-bottom display-block">
            Show Complete <span class="hide-md">Projects</span>
          </label>
          <div class="btn-group btn-group-horizontal btn-group-interactive btn-group-toggle btn-block">
            <button @click.prevent="complete = !complete"
               class="btn no-padding-x border-color-neutral-alpha-3 background-transparent color-neutral font-weight-inherit background-theme-active color-theme-active"
               :class="{ active: complete }">

              On
            </button>
            <button href="#" @click.prevent="complete = !complete"
               class="btn no-padding-x border-color-neutral-alpha-3 background-transparent color-neutral font-weight-inherit background-theme-active color-theme-active"
               :class="{ active: !complete }">

              Off
            </button>
          </div>
        </div>

        <div class="hide-mobile margin-small-right"><span class="spacer"></span></div>
        <button class="btn padding-small-x btn-theme-outline btn-block-mobile margin-top flex-1-1">Filter projects</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'

export default {
  name: 'ProjectFilters',

  props: {
    project_status_by_id: {
      type: Object,
      required: true,
    },
    project_type_by_id: {
      type: Object,
      required: true,
    },
    timeRangeOptions: {
      type: Object,
      default(rawProps) {
        return {
          due_today: 'Due today',
          due_this_week: 'Due this week',
          due_this_month: 'Due this month',
          created_today: 'Created today',
          created_this_week: 'Created this week',
          created_this_year: 'Created this year',
          created_last_year: 'Created last year',
        }
      },
    },
  },

  data() {
    const params = new URLSearchParams(location.search)

    return {
      clientId: 0,
      clientOptions: [],
      statusId: 0,
      typeId: 0,
      timeRange: '',
      overdue: Number(params.get('overdue') || 0) === 1,
      complete: Number(params.get('complete') || 0) === 1,
    }
  },

  created() {
    const params = new URLSearchParams(location.search)
    this.clientId = Number(params.get('client'))
    this.statusId = Number(params.get('status'))
    this.typeId = Number(params.get('type'))
    this.timeRange = params.get('time_range')
    if (!Object.keys(this.timeRangeOptions).includes(this.timeRange)) this.timeRange = ''

    this.debouncedAutocompleteClientName = _.debounce(this.autocompleteClientName,
                                                      this.$page.props.app_config.autocomplete_debounce_min)
  },

  async mounted() {
    const clientParams = {}
    if (this.clientId > 0) clientParams.id = this.clientId

    const resp = await axios.get(reverseUrl('clients.index'), { params: clientParams })
    const { clients } = resp.data
    this.clientOptions.splice(0, this.clientOptions.length, ...clients)

    if (this.clientId > 0 && this.clientOptions.length) {
      this.$refs.clientName.value = this.clientOptions[0].name
    }
  },

  beforeUnmount() {
    this.debouncedAutocompleteClientName.cancel()
  },

  computed: {
    projectStatuses() {
      return Object.entries(this.project_status_by_id).sort((s1, s2) => s1[1].localeCompare(s2[1]))
    },
    projectTypes() {
      return Object.entries(this.project_type_by_id).sort((t1, t2) => t1[1].localeCompare(t2[1]))
    },
  },

  methods: {
    async autocompleteClientName(event) {
      const q = event.target.value

      const params = new URLSearchParams()
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) params.set('q', q)
      const resp = await axios.get(`${this.$route('clients.index')}?${params}`)
      const { clients } = resp.data
      this.clientOptions.splice(0, this.clientOptions.length, ...clients)
    },

    async setClientId(event) {
      const clientName = event.target.value.trim().toLowerCase()
      const client = this.clientOptions.find(c => c.name.toLowerCase() === clientName)
      this.clientId = client?.id || 0
      if (!this.clientId) {
        setTimeout(() => event.target.value = '',
        this.$page.props.app_config.autocomplete_field_reset_delay)
      }
    },

    submitForm() {
      const params = new URLSearchParams(location.search)
      params.delete('client')
      if (this.clientId) params.set('client', this.clientId)
      params.delete('status')
      if (this.statusId) params.set('status', this.statusId)
      params.delete('type')
      if (this.typeId) params.set('type', this.typeId)
      params.delete('time_range')
      if (this.timeRange) params.set('time_range', this.timeRange)
      params.delete('overdue')
      if (this.overdue) params.set('overdue', 1)
      params.delete('complete')
      if (this.complete) params.set('complete', 1)
      params.delete('page')

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['projects', 'owners', 'page', 'page_count']
      })
    },
  },
}
</script>

<template>
  <tr class="background-primary-alpha-1">
    <td class="hide-mobile padding-left timetracker-td timetracker-td-w timetracker-td-w-50"
        v-if="$page.props.current_user.is_superuser">

      <span class="spacer">&nbsp;</span>
    </td>

    <td class="text-align-center no-border-bottom timetracker-td text-align-center">
      <span class="flex-md">
        <template v-if="$page.props.current_user.is_superuser">
          <label class="input-label no-padding margin-right">
            <input type="checkbox" class="input-inline" v-model="selected"
                   @change="$emit('project:selected', { id, checked: $event.target.checked })">
            <span class="hide-nonmobile">Select subproject</span>
          </label>

          <br class="hide-nonmobile">
          <br class="hide-nonmobile">
        </template>

        <Link :href="$route('admin.projects.edit', { id })"
              class="no-margin-y display-inline-block color-inherit"
              v-if="$page.props.current_user.is_superuser">

          {{ name }}
        </Link>
        <span v-else>{{ name }}</span>
      </span>
    </td>

    <td class="text-align-center no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Owner</span>
      <UserThumb v-bind="ownerById[owners[0]]" v-if="owners.length" />
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Date Created</span>
      <i class="symbol symbol-file"></i>
      {{ createdOn }}
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-150">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Date due</span>
      <!-- @if has a due task -->
        <!--
          span
            @classes to add
            'color-error-dark' => priority task/ 24 hours or less due
      -->
      <span v-if="due_date || completed" :class="dueDateCSSClass">
        <i class="symbol symbol-success" v-if="completed"></i>
        <i class="symbol symbol-calendar" v-else></i>
        {{ completedOn || dueDate }}
      </span>
      <span v-else>&nbsp;</span>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-200 text-align-center">
      <span class="hide-nonmobile no-margin outreach-table-mobile-label h6 color-neutral">Status</span>
      <!-- @NOTE
        .tag
          classes to add
            `tag-neutral` => default/backlog/maintenance backlog/production backlog
            `tag-primary` => when in progress/production
            `tag-secondary` => when in review
            `tag-error` => when on hold
            `tag-success` => completed
      -->
      <div class="tag" :class="statusCSSClass" v-if="status">
        {{ status?.name }}
      </div>
    </td>

    <td class="no-border-bottom timetracker-td timetracker-td-w timetracker-td-w-75 text-align-center hide-mobile">
      <span class="spacer">&nbsp;</span>
    </td>

    <td class="no-border-bottom timetracker-td-actions text-align-center" width="50"
        v-if="$page.props.current_user.is_superuser">

      <div class="bulk-actions position-relative">
        <button class="btn no-padding font-size-large" data-modal-disable-overlay="false"
                data-toggle-modal-default>

          <i class="symbol symbol-kebab-horizontal"></i>
          <span class="sr-only">More options</span>
        </button>

        <div class="modal modal-default" data-modal-width="400px">
          <div class="text-align-center">
            <h1 class="no-margin-top">More options</h1>
            <ul class='list-group list-group-small no-margin-y'>
              <li><a href="#" @click.prevent="confirmDeletion">Delete</a></li>
            </ul>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import { Link } from '@inertiajs/vue3'

import UserThumb from '../UserThumb'
import { formatDate, parseISODate } from '../../lib/date'
import { projectStatusCSSClass } from '../../lib/project'
import { stringifyErrors } from '../../lib/string'
import { dueDateCSSClass } from '../../lib/task'

export default {
  name: 'ChildProject',

  props: {
    id: {
      type: Number,
      required: true,
    },
    parent_id: {
      type: Number,
    },
    name: {
      type: String,
      required: true,
    },
    due_date: {
      type: String,
    },
    created: {
      type: String,
      required: true,
    },
    completed: {
      type: String,
    },
    synced_to_wrike: {
      type: Boolean,
      required: true,
    },
    status: {
      type: Object,
    },
    owners: {
      type: Array,
      required: true,
    },
    ownerById: {
      type: Object,
      required: true,
    },
    parentSelected: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: false,
    }
  },

  watch: {
    'parentSelected.value'(newValue) {
      this.selected = newValue
      this.$emit('project:selected', { id: this.id, parentId: this.parent_id, checked: newValue })
    },
  },

  computed: {
    createdOn() {
      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },

    dueOn() {
      if (!this.due_date) return

      return parseISODate(this.due_date)
    },

    dueDate() {
      if (!this.due_date) return

      return formatDate(this.dueOn, { format: 'short', timeZone: this.$timeZone })
    },

    completedOn() {
      if (!this.completed) return ''

      return formatDate(parseISODate(this.completed), { format: 'short', timeZone: this.$timeZone })
    },

    dueDateCSSClass() {
      return dueDateCSSClass(this.dueOn, this.$timeZone)
    },

    statusCSSClass() {
      return `tag-${projectStatusCSSClass(this.status?.name)}`
    },
  },

  methods: {
    async confirmDeletion() {
      if (!confirm(`Are you sure you want to delete project "${this.name}"?`)) return

      try {
        await axios.delete(reverseUrl('projects.destroy', this.id), {
          data: {
            delete_on_wrike: this.synced_to_wrike,
          },
        })
      } catch(err) {
        alert(`Unable to delete project due to:\n${stringifyErrors(err)}`)
        return
      }

      this.$emit('project:deleted', { id: this.id, parentId: this.parent_id })
    },
  },

  emits: ['project:selected', 'project:deleted'],
  components: {
    Link,
    UserThumb,
  },
}
</script>

<template>
  <AnonLayout>
    <div class="module-content no-margin-y padding-large-y">
      <div class="text-align-center padding-large-y">
        <img :src="iconLoginClapUrl">
        <h1 class="margin-small-top">Your password<br> has been changed.</h1>
        <Link :href="$route('auth.login')" class="btn btn-theme margin-top">Close</Link>
      </div>
    </div>
  </AnonLayout>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import AnonLayout from '../AnonLayout'
import iconLoginClap from '../img/login-clap.svg'

export default {
  name: 'PasswordChangeConfirmation',

  computed: {
    iconLoginClapUrl() {
      return iconLoginClap
    },
  },

  components: {
    AnonLayout,
    Link,
  },
}
</script>

<template>
  <div id="timetracker-login" class="padding-large-y timetracker-anon-ui-wrapper align-self-center">
    <div class="container">
      <FlashMessages />

      <div class="text-align-center color-background margin-large-bottom" v-html="logoSvgIcon">
      </div>

      <div class="module module-expanded">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessages from './components/FlashMessages'
import logoIcon from './img/icon-logo.svg?raw'

export default {
  name: 'AnonLayout',

  computed: {
    logoSvgIcon() {
      return logoIcon
    },
  },

  components: {
    FlashMessages,
  },
}
</script>

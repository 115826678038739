<template>
  <div class="p alert" :class="`alert-${paletteTag}`" v-if="message && !closed">
    <span class="alert-close btn btn-no-interaction color-inherit btn-symbol order-99"
          @click="closed = true">

      <i class="symbol symbol-close"></i>
    </span>

    <span class="btn btn-large btn-symbol btn-round btn-no-interaction margin-small-right color-background"
         :class="`background-${paletteTag}`">
      <i class="symbol" :class="`symbol-${symbolTag}`"></i>
    </span>

    <div class="outreach-alert-content display-inline">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'FlashMessage',

  props: {
    paletteTag: {
      type: String,
      default: 'primary',
    },
    symbolTag: {
      type: String,
      default: 'info',
    },
    message: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      closed: false,
      removeSuccessEventListener: null,
    }
  },

  created() {
    this.removeSuccessEventListener = this.$inertia.on('success', (_event) => {
      this.closed = false
    })
  },

  unmounted() {
    this.removeSuccessEventListener()
  },
}
</script>

<template>
  <div class="module" data-grid-area="sidebar">
    <div class="module-content padding-large-y text-align-center align-self-stretch">
      <div class="flex-grid flex-grid-fixed text-align-center">
        <div class="flex-col-xs-12">
          <span class="thumbnail pointer-reference">
            <UserThumb :first_name="user.first_name"
                       :last_name="user.last_name"
                       :avatar="user.avatar" :small="false" />
            <span class="badge badge-success"></span>
          </span>

          <h2 class="no-margin-bottom color-inherit text-leading-compact">
            {{ user.first_name }} {{ user.last_name }}
          </h2>

          <p>
            <span v-if="user.job">{{ user.job.title }}</span>
            <span class="color-neutral font-style-italic" v-else>Not set</span>
          </p>

          <Link :href="$impersonatingUser.value ? '#' : $linkTo('users.edit_profile', { returnBack: true })"
                class="btn btn-theme-outline btn-round btn-small"
                :class="{ disabled: $impersonatingUser.value }">

            Edit
          </Link>
        </div>

        <div class="flex-col-xs-6 flex-col-md-12">
          <span class="btn btn-symbol btn-small btn-error-glassy font-size-large btn-round">
            <i class="symbol symbol-map"></i>
          </span>

          <p class="no-margin-bottom margin-small-top">
            <strong>Location</strong><br>
            <span v-if="user.location">{{ user.location }}</span>
            <span class="color-neutral font-style-italic" v-else>Not set</span>
          </p>
        </div>

        <div class="flex-col-xs-6 flex-col-md-12">
          <span class="btn btn-symbol btn-small btn-primary-glassy font-size-large btn-round">
            <i class="symbol symbol-time"></i>
          </span>

          <p class="no-margin-bottom margin-small-top">
            <strong>Typical hours</strong><br>
            <span class="color-neutral font-style-italic" v-if="true">Not available</span>
            <span v-else>M-F 9am-5pm US/Pacific</span>
          </p>
        </div>

        <div class="flex-col-xs-6 flex-col-md-12">
          <span class="btn btn-symbol btn-small btn-success-glassy font-size-large btn-round">
            <i class="symbol symbol-calendar"></i>
          </span>

          <p class="no-margin-bottom margin-small-top">
            <strong>Project availability</strong><br>
            Available
          </p>
        </div>

        <div class="flex-col-xs-6 flex-col-md-12">
          <span class="btn btn-symbol btn-small btn-caution-glassy font-size-large btn-round">
            <i class="symbol symbol-sun"></i>
          </span>
          <p class="no-margin-bottom margin-small-top">
            <strong>Upcoming vacations</strong><br>
            <span class="color-neutral font-style-italic" v-if="true">Not available</span>
            <span v-else>Feb 22, 1986 – Feb 25, 1986</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'

import UserThumb from './UserThumb'

export default {
  name: 'MyAnalyticsUserProfile',

  computed: {
    user() {
      return this.$page.props.effective_user || this.$page.props.current_user
    },
  },

  components: {
    Link,
    UserThumb,
  },
}
</script>

<template>
  <Layout>
    <div class="h6 color-neutral-dark font-weight-400">
      <Link :href="$route('admin.clients.index')" class="color-inherit">
        Clients
      </Link>

      <span class="separator">&gt;&nbsp;</span>

      <Link :href="id ? $route('admin.clients.edit', { id }) : $route('admin.clients.new')"
            class="color-inherit font-weight-700">

        {{ id ? name : 'New client' }}
      </Link>
    </div>

    <form @submit.prevent="saveNewClient">
      <div class="module margin-large-top margin-bottom">
        <div class="module-header module-header-break padding-top no-margin-bottom padding-bottom align-items-center">
          <div class="flex-2-1 padding-right">
            <div class="h3 color-theme flex-1-1 no-margin-y">
              <label for="client-name" class="sr-only">Client name</label>
              <input id="client-name" class="input input-blend input-block"
                     placeholder="Client name" v-model.trim="_name" @change="onNameChanged"
                     required>
            </div>
          </div>

          <div class="module-functions flex-0-0 flex-nowrap flex-xs align-items-center justyify-content-space-between">
            <div class="module-function" v-if="id">
              <div class="color-neutral text-nowrap">Created on {{ createdOn }}</div>
            </div>

            <div class="module-function text-align-right" v-if="id">
              <div class="display-inline-block position-relative">
                <a href="#" class="btn padding-small font-size-large btn-link"
                   title="More options" data-toggle-dropdown>

                  <i class="symbol symbol-kebab-horizontal"></i>
                </a>

                <div class="dropdown dropdown-right dropdown-top-flush text-align-left"
                     data-dropdown-width="200px">

                  <ul class="list-group list-group-small no-margin-y">
                    <li class="no-border">
                      <a href="#" class="display-block dropdown-purger"
                         @click.prevent="$copyURLToClipboard">

                        <i class="symbol symbol-hyperlink"></i> Copy link
                      </a>
                    </li>

                    <li class="no-border">
                      <a href="#client-del-confirm-modal" @click.prevent
                         class="display-block dropdown-purger" data-modal-disable-overlay="false"
                         data-toggle-modal-default>

                        <i class="symbol symbol-delete"></i> Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="module-content no-padding-x no-padding-bottom">
          <div class="timetracker-module-subheader flex-md flex-wrap align-items-center">
            <div class="flex-1-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top align-self-stretch flex-md align-items-center justify-content-flex-end">
              <div class="flex-0-0 padding-x">
                <div class=" input-wrapper input-wrapper-horizontal input-wrapper-block justify-content-flex-end">
                  <label class="input-toggle input-toggle-primary input-toggle-reverse padding-small-y">
                    <input type="checkbox" class="input" v-model.trim="syncedToWrike"
                           @change="onSyncedOnWrikeChanged">
                    <span class="input-label input-toggle-label font-weight-700">
                      Synced to Wrike
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-2-0 border-style-solid-top border-color-theme-polar-contrast border-width-thin-top align-self-stretch flex-xs align-items-center padding-small-x flex-wrap">
            <ModelJobs :modelId="id" modelCls="Client"
                       :jobTitleById="job_title_by_id" :jobIdsByDeptId="job_ids_by_dept_id"
                       :jobIdByModelJobId="job_id_by_model_job_id" :users="users"
                       :user_ids_by_job_id="user_ids_by_job_id" :userIdsByJobId="userIdsByJobId"
                       @userJob:added="onUserJobAdded"
                       @userJob:removed="onUserJobRemoved" />
          </div>

          <Editor :api-key="$page.props.tinymce_api_key"
                  :init="$tinymceInitOpts"
                  :plugins="$page.props.tinymce_plugins"
                  :toolbar="$page.props.tinymce_toolbar"
                  v-model.trim="_description"
                  @change="onDescriptionChanged" />
        </div>
      </div>

      <div class="margin-top text-align-right">
        <Link :href="$returnBack() || $route('admin.clients.index')"
              class="btn btn-theme-outline">

          {{ id ? 'Back' : 'Cancel' }}
        </Link>
        &nbsp;
        <button href="#" class="btn btn-theme" v-if="!id">
          {{ id ? 'Update' : 'Create' }} client
        </button>
      </div>
    </form>

    <ModelDelConfirmModal modelCls="client"
      :action="$route('clients.destroy', { id })"
      :successPath="$returnBack() || $route('admin.clients.index')"
      :syncedToWrike="synced_to_wrike"
      v-if="id" />
  </Layout>
</template>

<script>
import axios from 'axios'
import Editor from '@tinymce/tinymce-vue';
import { Link } from '@inertiajs/vue3'

import Layout from '../Layout'
import ModelJobs from '../components/ModelJobs'
import ModelDelConfirmModal from './ModelDelConfirmModal'
import { formatDate, parseISODate } from '../lib/date'
import { stringifyValidationErrors } from '../lib/string'

export default {
  name: 'ClientForm',

  props: {
    id: {
      type: Number,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    created: {
      type: String,
    },
    synced_to_wrike: {
      type: Boolean,
    },
    job_title_by_id: {
      type: Object,
      required: true,
    },
    job_ids_by_dept_id: {
      type: Object,
      required: true,
    },
    job_id_by_model_job_id: {
      type: Object,
      required: true,
    },
    users: {
      type: Object,
      required: true,
    },
    user_ids_by_job_id: {
      type: Object,
      required: true,
    },
    user_ids_by_model_job_id: {
      type: Object,
      required: true,
    },
  },

  data() {
    const userIdsByJobId = {}
    for (const [modelJobId, userIds] of Object.entries(this.user_ids_by_model_job_id)) {
      const jobId = this.job_id_by_model_job_id[modelJobId]

      userIds.forEach(userId => {
        userIdsByJobId[jobId] ||= new Set()
        userIdsByJobId[jobId].add(userId)
      })
    }

    return {
      _name: this.name,
      _description: this.description,
      userIdsByJobId,
      syncedToWrike: this.synced_to_wrike || false,
    }
  },

  computed: {
    createdOn() {
      if (!this.created) return ''

      return formatDate(parseISODate(this.created), { format: 'short', timeZone: this.$timeZone })
    },
  },

  methods: {
    async onNameChanged(_e) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('clients.update', { id: this.id }), {
          name: this._name,
        })
      } catch(err) {
        alert(`Unable to change client name due to:\n${stringifyValidationErrors(err)}`)
      }
    },

    async onSyncedOnWrikeChanged(_e) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('clients.update', { id: this.id }), {
          synced_to_wrike: this.syncedToWrike,
        })
      } catch(err) {
        let errMsg = 'Unable to change client Wrike sync pref due to:'
        errMsg += `\n${stringifyValidationErrors(err)}`
        alert(errMsg)
      }
    },

    async onDescriptionChanged(_e, _editor) {
      if (!this.id) return

      try {
        await axios.patch(reverseUrl('clients.update', { id: this.id }), {
          description: this._description.trim(),
        })
      } catch(err) {
        let errMsg = 'Unable to change client description due to:'
        errMsg += `\n${stringifyValidationErrors(err)}`
        alert(errMsg)
      }
    },

    onUserJobAdded({ userId, jobId }) {
      this.userIdsByJobId[jobId] ||= new Set()
      this.userIdsByJobId[jobId].add(userId)
    },

    onUserJobRemoved({ userId, jobId }) {
      if (!(jobId in this.userIdsByJobId)) return

      this.userIdsByJobId[jobId].delete(userId)
    },

    async saveNewClient() {
      const user_ids_by_job_id = {}
      for (const [jobId, userIds] of Object.entries(this.userIdsByJobId)) {
        if (!userIds.size) continue

        user_ids_by_job_id[jobId] = [...userIds]
      }

      try {
        const resp = await axios.post(reverseUrl('clients.create'), {
          name: this._name,
          description: this._description,
          user_ids_by_job_id,
          synced_to_wrike: this.syncedToWrike,
        })

        const { id } = resp.data
        this.$inertia.get(reverseUrl('admin.clients.edit', { id }))
      } catch(err) {
        alert(`Unable to save new client due to:\n${stringifyValidationErrors(err)}`)
      }
    },
  },

  components: {
    Layout,
    Link,
    Editor,
    ModelJobs,
    ModelDelConfirmModal,
  },
}
</script>

<template>
  <form @submit.prevent="submitForm" class="flex-grid flex-grid-compact align-items-flex-end">
    <div class="flex-col-xs-12 flex-col-md-10 flex-col-lg-9">
      <div class="flex-grid flex-grid-fixed flex-grid-compact no-margin-bottom align-items-flex-end">
        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="client_id" class="input-label no-padding-top padding-small-bottom">Client</label>

              <input id="client_id" class="input input-single-line input-block"
                     placeholder="Select client" autocomplete="off" list="client-options"
                     ref="clientName"
                     @input="debouncedAutocompleteClientName" @change="setClientId">

              <datalist id="client-options">
                <option :value="client.name" v-for="client in clientOptions" :key="client.id" />
              </datalist>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="status_id" class="input-label no-padding-top padding-small-bottom">
                Task status
              </label>

              <select id="status_id" class="input input-single-line input-block" v-model.number="statusId">
                <option value="0">Select status</option>
                <option :value="id" v-for="[id, name] in taskStatuses" :key="id">{{ name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
            <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
              <label for="time_range" class="input-label no-padding-top padding-small-bottom">
                Time range
              </label>

              <select id="time_range" class="input input-single-line input-block" v-model="timeRange">
                <option value="">Select time range</option>
                <option :value="value" v-for="(label, value) in timeRangeOptions" :key="value">
                  {{ label }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
              <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
                <label for="task-author" class="input-label no-padding-top padding-small-bottom">
                  Author
                </label>

                <input id="task-author" class="input input-single-line input-block"
                       :value="authorOptions[[...authorIds]?.[0]] || ''" list="author-options"
                       placeholder="Select author" @change="onAuthorChanged">

                <datalist id="author-options">
                  <option :value="name" v-for="name in sortedAuthorOptions"></option>
                </datalist>
              </div>
          </div>
        </div>

        <div class="flex-col-xs-12 flex-col-sm-6 flex-col-md-1 flex-1-1">
          <div class="position-relative">
              <div class="input-wrapper input-wrapper-vertical input-wrapper-block no-padding no-margin">
                <label for="task-author" class="input-label no-padding-top padding-small-bottom">
                  Assignee
                </label>

                <input id="task-responsible" class="input input-single-line input-block"
                       :value="responsibleOptions[[...responsibleIds]?.[0]] || ''"
                       list="responsible-options"
                       placeholder="Select assignee" @change="onResponsibleChanged">

                <datalist id="responsible-options">
                  <option :value="name" v-for="name in sortedResponsibleOptions"></option>
                </datalist>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-child flex-1-0">
      <div class="flex-md align-items-flex-end">
        <div class="hide-mobile margin-small-right"><span class="spacer"></span></div>
        <button class="btn btn-theme-outline btn-block-mobile margin-top no-padding-x flex-1-1">
          Filter tasks
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash-es'

export default {
  name: 'TaskFilters',

  props: {
    taskStatusOptions: {
      type: Object,
      required: true,
    },
    // authors: {
    //   type: Array,
    //   required: true,
    // },
    authorOptions: {
      type: Object,
      required: true,
    },
    // responsibles: {
    //   type: Array,
    //   required: true,
    // },
    responsibleOptions: {
      type: Object,
      required: true,
    },
    timeRangeOptions: {
      type: Object,
      default(rawProps) {
        return {
          due_today: 'Due today',
          due_this_week: 'Due this week',
          due_this_month: 'Due this month',
          created_today: 'Created today',
          created_this_week: 'Created this week',
          created_this_year: 'Created this year',
          created_last_year: 'Created last year',
        }
      },
    },
  },

  data() {
    const params = new URLSearchParams(location.search)
    const clientId = Number(params.get('client'))
    const statusId = Number(params.get('status'))
    let timeRange = params.get('time_range')
    if (!Object.keys(this.timeRangeOptions).includes(this.timeRange)) timeRange = ''
    // const authorIds = new Set(this.authors)
    const authorIds = new Set(params.getAll('author'))
    // const responsibleIds = new Set(this.responsibles)
    const responsibleIds = new Set(params.getAll('assignee'))

    return {
      clientId,
      clientOptions: [],
      statusId,
      timeRange,
      authorIds,
      responsibleIds,
    }
  },

  created() {
    this.debouncedAutocompleteClientName = _.debounce(this.autocompleteClientName,
                                                      this.$page.props.app_config.autocomplete_debounce_min)
  },

  async mounted() {
    const clientParams = {}
    if (this.clientId > 0) clientParams.id = this.clientId

    const resp = await axios.get(reverseUrl('clients.index'), { params: clientParams })
    const { clients } = resp.data
    this.clientOptions.splice(0, this.clientOptions.length, ...clients)

    if (this.clientId > 0 && this.clientOptions.length) {
      this.$refs.clientName.value = this.clientOptions[0].name
    }
  },

  beforeUnmount() {
    this.debouncedAutocompleteClientName.cancel()
  },

  computed: {
    taskStatuses() {
      const res = [], seen = new Set()

      for (const [wrikeWf, statusIdByName] of Object.entries(this.taskStatusOptions)) {
        for (const [name, id] of Object.entries(statusIdByName)) {
          if (seen.has(id)) continue

          seen.add(id)
          res.push([id, name])
        }
      }

      return res.sort((s1, s2) => s1[1].localeCompare(s2[1]))
    },
    sortedAuthorOptions() {
      return this.sortValuesCaseInsensitivelyFrom(this.authorOptions)
    },
    sortedResponsibleOptions() {
      return this.sortValuesCaseInsensitivelyFrom(this.responsibleOptions)
    },
  },

  methods: {
    sortValuesCaseInsensitivelyFrom(object) {
      return Object.values(object).sort((k1, k2) => k1.localeCompare(k2, 'en', {
        sensitivity: 'base',
      }))
    },

    async autocompleteClientName(event) {
      const q = event.target.value

      const params = new URLSearchParams()
      if (q.length >= this.$page.props.app_config.autocomplete_qry_min_len) params.set('q', q)
      const resp = await axios.get(`${this.$route('clients.index')}?${params}`)
      const { clients } = resp.data
      this.clientOptions.splice(0, this.clientOptions.length, ...clients)
    },

    async setClientId(event) {
      const clientName = event.target.value.trim().toLowerCase()
      const client = this.clientOptions.find(c => c.name.toLowerCase() === clientName)
      this.clientId = client?.id || 0
      if (!this.clientId) {
        setTimeout(() => event.target.value = '',
        this.$page.props.app_config.autocomplete_field_reset_delay)
      }
    },

    onAuthorChanged(event) {
      this.authorIds.clear()
      const target = event.target, value = target.value.toLowerCase()
      const entry = Object.entries(this.authorOptions).find(([_, name]) => name.toLowerCase() === value)

      if (entry) {
        const [id, name] = entry
        target.value = name
        this.authorIds.add(id)
      } else {
        target.value = ''
      }
    },

    onResponsibleChanged(event) {
      this.responsibleIds.clear()
      const target = event.target, value = target.value.toLowerCase()
      const entry = Object.entries(this.responsibleOptions).find(([_, name]) => name.toLowerCase() === value)

      if (entry) {
        const [id, name] = entry
        target.value = name
        this.responsibleIds.add(id)
      } else {
        target.value = ''
      }
    },

    submitForm() {
      const params = new URLSearchParams(location.search)
      params.delete('client')
      if (this.clientId) params.set('client', this.clientId)
      params.delete('status')
      if (this.statusId) params.set('status', this.statusId)
      params.delete('time_range')
      if (this.timeRange) params.set('time_range', this.timeRange)
      params.delete('author')
      for (const authorId of this.authorIds) params.append('author', authorId)
      params.delete('assignee')
      for (const responsibleId of this.responsibleIds) params.append('assignee', responsibleId)
      params.delete('page')

      this.$inertia.get(location.pathname, params, {
        only: ['tasks', 'task_authors', 'task_responsibles', 'page', 'page_count']
      })
    },
  },
}
</script>

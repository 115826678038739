<template>
  <Layout>
    <div class="h2 font-weight-300 no-margin-y">
      {{ task_status_group.id ? 'Edit' : 'Add' }} task status group
    </div>

    <div class="flex-grid flex-grid-fixed">
      <div class="flex-col-xs-12 flex-col-sm-9 flex-col-md-7">
        <form @submit.prevent="submitForm">
          <div class="input-wrapper input-wrapper-vertical input-wrapper-block p">
            <label for="name" class="input-label font-weight-700">Name</label>
            <input id="name" class="input input-single-line"
                   :class="{ 'input-error': form.errors.name }"
                   minlength="2" maxlength="50"
                   ref="statusGroupName" v-model.trim="form.name" required>

            <div class="input-info color-error font-weight-700" v-if="form.errors.name">
              {{ form.errors.name.join(', ') }}
            </div>
          </div>

          <label class="input-label font-weight-700">Task statuses</label>
          <datalist id="task-status-options">
            <template v-for="[id, name] in allTaskStatuses" :key="id">
              <option :value="name" v-if="findTaskStatusIndex(id) === -1" />
            </template>
          </datalist>

          <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap"
               v-for="[id, name] in form.taskStatuses" :key="id">

            <div class="flex-child flex-1-1 no-margin-top">
              <label :for="`task-status-{id}`" class="sr-only">{{ name }}</label>
              <input :id="`task-status-${id}`" :value="name"
                     class="input input-single-line input-block"
                     list="task-status-options" @change="changeTaskStatus(id, $event)">
            </div>

            <div class="flex-child flex-0-0 no-margin-top flex-xs">
              <a href="#" class="btn btn-error-glassy btn-symbol"
                 @click.prevent="removeTaskStatus(id)">

                <i class="symbol symbol-minus"></i>
              </a>
            </div>
          </div>

          <div class="flex-grid flex-grid-compact flex-grid-fixed flex-nowrap">
            <div class="flex-child flex-1-1 no-margin-top">
              <input list="task-status-options" class="input input-single-line input-block"
                     placeholder="Add task status.." @change="addTaskStatus($event)">
            </div>
          </div>

          <hr class="margin-large-y">

          <div class="margin-large-top">
            <button class="btn btn-theme" :disabled="form.processing">Save</button>
            <Link :href="$route('admin.task_status_groups.index')"
                  class="btn btn-neutral-glassy margin-left">

              Go Back
            </Link>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Link, useForm } from '@inertiajs/vue3'

import Layout from '../Layout'

export default {
  name: 'TaskStatusGroupForm',

  props: {
    task_status_group: {
      type: Object,
      required: true,
    },
    all_task_statuses: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      name: props.task_status_group.name,
      taskStatuses: Object.entries(props.task_status_group.task_statuses).sort((s1, s2) => s1[1].localeCompare(s2[1])),
    })

    return { form }
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.statusGroupName.focus()
  },*/

  computed: {
    allTaskStatuses() {
      return Object.entries(this.all_task_statuses).sort((s1, s2) => s1[1].localeCompare(s2[1]))
    },
  },

  methods: {
    findTaskStatusIndex(taskStatusId) {
      const statusId = String(taskStatusId)
      return this.form.taskStatuses.findIndex(([id, _]) => id === statusId)
    },

    findTaskStatusEntryFor(name) {
      const statusName = name.trim().toLowerCase()
      return Object.entries(this.all_task_statuses).find(([_, name]) => name.toLowerCase() === statusName)
    },

    addTaskStatus(event) {
      const entry = this.findTaskStatusEntryFor(event.target.value)
      if (entry && this.findTaskStatusIndex(entry[0]) === -1) {
        this.form.taskStatuses.push(entry)
      }

      event.target.value = ''
    },

    changeTaskStatus(id, event) {
      const entry = this.findTaskStatusEntryFor(event.target.value)

      if (entry) {
        const index = this.findTaskStatusIndex(entry[0])
        if (index !== -1) this.form.taskStatuses[index][1] = entry[1]
      } else {
        event.target.value = this.all_task_statuses[id]
      }
    },

    removeTaskStatus(taskStatusId) {
      const index = this.findTaskStatusIndex(taskStatusId)
      if (index === -1) return

      this.form.taskStatuses.splice(index, 1)
    },

    submitForm() {
      const frm = this.form.transform(data => {
        return {
          ...data,
          task_statuses: data.taskStatuses.map(([id, _]) => Number(id))
        }
      })

      if (this.task_status_group.id) {
        frm.patch(reverseUrl('admin.task_status_groups.update', {
          id: this.task_status_group.id,
        }))
      } else {
        frm.post(reverseUrl('admin.task_status_groups.create'))
      }
    },
  },

  components: {
    Link,
    Layout,
  },
}
</script>

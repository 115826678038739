<template>
  <Layout>
    <div id="timetracker-heading" class="flex-grid justify-content-space-between align-items-center">
      <div class="flex-col-xs-12  flex-col-md-6">
        <h1 class="no-margin">Projects</h1>
      </div>

      <div class="flex-col-xs-12 flex-col-md-6">
        <div class="module-functions text-align-right">
          <div class="module-function">
            <div class="input-group input-group-horizontal input-block">
              <label for="project-name" class="sr-only">Project name</label>
              <input id="project-name" type="search" :value="q"
                     class="input input-single-line font-weight-600 no-border-right"
                     ref="projectName"
                     placeholder="Project name" @input="debouncedFilterProjects">
            </div>
          </div>

          <div class="module-function">
            <Link :href="$route('admin.projects.new')" class="btn btn-block-mobile btn-theme"
                  v-if="$page.props.current_user.is_superuser">

              Add new +
            </Link>
          </div>
        </div>
      </div>

      <div class="flex-col-xs-12">
        <ProjectFilters :project_status_by_id="project_status_by_id"
                        :project_type_by_id="project_type_by_id" />
      </div>
    </div>

    <div id="timetracker-projects" class="module">
      <div class="module-header">
        <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
          <Pagination :recordCount="record_count" :page="page" :pageCount="page_count"
                      :perPage="per_page" />
        </div>
        <hr class="hide-nonmobile no-margin-bottom">
      </div>

      <div class="module-content no-padding-x">
        <div class="table-wrapper">
         <table class="timetracker-table border-style-solid-bottom border-width-thin-bottom border-color-neutral-alpha-3 text-vertical-align-middle">

          <tr class="hide-mobile">
            <th class="font-weight-700 color-theme text-align-center color-theme timetracker-td timetracker-td-w timetracker-td-w-50"
                v-if="$page.props.current_user.is_superuser">

              <label class="input-label no-padding">
                <input type="checkbox" class="input-inline" v-model="allProjectsSelected">
                <span class="hide-nonmobile">Select all projects</span>
              </label>
            </th>

            <th class="font-weight-700 color-theme">
              Project and client
              <a href="#" @click.prevent="sortProjectsBy('name')"
                 class="color-inherit text-decoration-none text-vertical-align-middle display-inline-flex"
                 v-html="dirIconFor('name')">
              </a>
            </th>

            <th class="font-weight-700 color-theme text-align-center">Owner</th>

            <th class="font-weight-700 color-theme">
              Created
              <a href="#" @click.prevent="sortProjectsBy('created')"
                 class="color-inherit text-decoration-none text-vertical-align-middle display-inline-flex"
                 v-html="dirIconFor('created')">
              </a>
            </th>

            <th class="font-weight-700 color-theme">
              Due
              <a href="#" @click.prevent="sortProjectsBy('due_date')"
                 class="color-inherit text-decoration-none text-vertical-align-middle display-inline-flex"
                 v-html="dirIconFor('due_date')">
              </a>
            </th>

            <th class="font-weight-700 color-theme text-align-center">Status</th>
            <th class="font-weight-700 color-theme text-align-center">Subprojects</th>

            <th class="color-theme timetracker-td-actions text-align-center" width="50"
                v-if="$page.props.current_user.is_superuser">

              <div class="bulk-actions position-relative">
                <button class="btn no-padding font-size-large" data-modal-disable-overlay="false"
                        data-toggle-modal-default>

                  <i class="symbol symbol-kebab-horizontal"></i>
                  <span class="sr-only">More options</span>
                </button>

                <div class="modal modal-default" data-modal-width="400px">
                  <div class="text-align-center">
                    <h1 class="no-margin-top">More options</h1>
                    <ul class='list-group list-group-small no-margin-y'>
                      <li>
                        <a href="#" @click.prevent="confirmDeleteSelected">Delete selected</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </th>
          </tr>

          <template v-for="project in projects" :key="project.id">
            <ProjectRow v-bind="project" :allProjectsSelected="allProjectsSelected"
                        :ownerById="ownerById"
                        @project:selected="onProjectSelectionChanged"
                        @project:deleted="onProjectDeleted" />

            <tbody class="accordion" :id="`project-${project.id}-children`"
                   data-accordion-change-hash="false">

              <ChildProject v-bind="child" :parentSelected="isProjectSelected(project.id)"
                            :ownerById="ownerById"
                            @project:selected="onProjectSelectionChanged"
                            @project:deleted="onProjectDeleted"
                v-for="child in project.children" :key="child.id" />
            </tbody>
          </template>
         </table>
       </div>
      </div>

      <div class="module-footer">
        <hr class="hide-nonmobile">
        <div class="module-functions no-margin no-padding flex-xs flex-wrap justify-content-space-between align-items-center">
          <Pagination :recordCount="record_count" :page="page" :pageCount="page_count"
                      :perPage="per_page" />
        </div>
      </div>
    </div>
  </Layout>
</template>


<script>
import axios from 'axios'
import * as _ from 'lodash-es'
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'

import Layout from '../Layout'
import Pagination from './Pagination'
import ProjectFilters from '../components/admin/ProjectFilters'
import ProjectRow from '../components/admin/ProjectRow'
import ChildProject from '../components/admin/ChildProject'
import ascDirIcon from '../img/icon-dir-asc.svg?raw'
import descDirIcon from '../img/icon-dir-desc.svg?raw'
import indeterDirIcon from '../img/icon-dir-indeter.svg?raw'

import { stringifyValidationErrors } from '../lib/string'

export default {
  name: 'ProjectList',

  props: {
    projects: {
      type: Array,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    date_from: {
      type: String,
    },
    date_to: {
      type: String,
    },
    client: {
      type: Number,
      required: true,
    },
    sort: {
      type: String,
      default: 'name',
    },
    dir: {
      type: String,
      default: ({ sort }) => sort === 'created' ? 'desc' : 'asc',
    },
    record_count: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    page_count: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      required: true,
    },
    project_status_by_id: {
      type: Object,
      required: true,
    },
    project_type_by_id: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedProjects: new Set([]),
      allProjectsSelected: false,
    }
  },

  created() {
    this.debouncedFilterProjects = _.debounce(this.filterProjects,
                                              this.$page.props.app_config.autocomplete_debounce_min)
  },

  /*mounted() {
    if (this.$isMobile) return

    this.$refs.projectName.focus()
  },*/

  beforeUnmount() {
    this.debouncedFilterProjects.cancel()
  },

  computed: {
    ownerById() {
      return Object.fromEntries(this.owners.map(user => [user.id, user]))
    },
    ascDirSvgIcon() {
      return ascDirIcon
    },
    descDirSvgIcon() {
      return descDirIcon
    },
    indeterDirSvgIcon() {
      return indeterDirIcon
    },
  },

  methods: {
    dirIconFor(column) {
      if (this.sort !== column) return this.indeterDirSvgIcon

      return this.dir === 'desc' ? this.descDirSvgIcon : this.ascDirSvgIcon
    },

    filterProjects(e) {
      const query = e.target.value.trim()
      if (query === this.q) return

      const params = new URLSearchParams(location.search)
      params.set('q', query)
      params.delete('page')

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['projects', 'owners', 'q', 'page', 'page_count'],
      })
    },

    onProjectSelectionChanged({ id, checked }) {
      if (checked) {
        this.selectedProjects.add(id)
      } else {
        this.selectedProjects.delete(id)
      }
    },

    isProjectSelected(id) {
      return computed(() => this.selectedProjects.has(id))
    },

    async confirmDeleteSelected() {
      if (!this.selectedProjects.size) {
        alert('Please select at least 1 project to proceed')
        return
      }

      let warning = 'Are you sure you want to delete'
      let projectCount = 'project'
      if (this.selectedProjects.size !== 1) projectCount += 's'
      warning += ` ${this.selectedProjects.size} ${projectCount}?`
      if (!confirm(warning)) return

      const data = { ids: [...this.selectedProjects] }

      try {
        await axios.delete(reverseUrl('projects.destroy_many'), {
          data,
        })
      } catch(err) {
        alert(`Unable to delete project(s) due to:\n${stringifyValidationErrors(err)}`)
        return
      }

      this.$inertia.reload({ only: ['projects'] })
    },

    onProjectDeleted({ id, parentId = 0 } = {}) {
      const projectId = parentId || id,
            index = this.projects.findIndex(p => p.id === projectId)
      if (index === -1) return

      if (parentId) {
        const children = this.projects[index].children
        const idx = children.findIndex(p => p.id === id)
        if (idx === -1) return

        children.splice(idx, 1)
      } else {
        this.projects.splice(index, 1)
      }
    },

    sortProjectsBy(column) {
      const params = new URLSearchParams(location.search)
      let dir
      if (column === this.sort) {
        dir = this.dir === 'desc' ? 'asc' : 'desc'
      } else {
        params.set('sort', column)
        dir = column === 'created' ? 'desc' : 'asc'
      }
      params.set('dir', dir)

      this.$inertia.reload({
        data: Object.fromEntries(params.entries()),
        only: ['projects', 'owners', 'sort', 'dir'],
      })
    },
  },

  components: {
    Layout,
    Link,
    Pagination,
    ProjectFilters,
    ProjectRow,
    ChildProject,
  },
}
</script>
